import React from 'react';
import { t } from '../../i18n';

export default function HeaderTags() {
  return (
    <>
      <title>Clubs Poker</title>
      <meta name="description" content={t('metaContentInfo')} />
      <meta
        property="og:title"
        content="Join Tbird24's poker game on Clubs Poker"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={process.env.REACT_APP_PUBLIC_URL} />
      <meta property="og:site_name" content="Clubs Poker" />
      <meta property="og:description" content={t('metaContentInfo')} />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_PUBLIC_URL}/facebook-og.png`}
      />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:title"
        content="Join Tbird24's poker game on Clubs Poker"
      />
      <meta name="twitter:description" content={t('metaContentInfo')} />
      <meta name="twitter:site" content="@playonclubs" />
      <meta
        name="twitter:image"
        content={`${process.env.REACT_APP_PUBLIC_URL}/twitter-og.png`}
      />
    </>
  );
}
