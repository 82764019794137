import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import Cable from 'actioncable';

import { PlayerContext } from '../contexts/PlayerContext';

import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import Spinner from '../components/Spinner';
import { buildURL } from '../utils/httpUtils';

import {
  TOURNAMENTS_SHOW,
  REQUEST_JOIN_TOURNAMENT,
  REJECT_REQUEST_JOIN_TOURNAMENT,
  APPROVE_REQUEST_JOIN_TOURNAMENT,
  START_TOURNAMENT,
  REQUEST_REBUY_TOURNAMENT,
  REJECT_REQUEST_REBUY_TOURNAMENT,
  APPROVE_REQUEST_REBUY_TOURNAMENT,
  SIT_OUT_TOURNAMENT,
  SIT_IN_TOURNAMENT,
  PAUSE_TOURNAMENT,
  RESUME_TOURNAMENT,
} from '../services/APIService';

export default function TournamentsShowScreen() {
  let history = useHistory();
  const tournamentUUID = useParams().tournamentUUID;
  // TODO: setTournament and setIsLoading are not used, both pieces of state might be useless
  const [tournament] = useState(null);
  const [producer, setProducer] = useState(null);
  const [isLoading] = useState(true);
  const [tableSessionUUID, setTableSessionUUID] = useState('');
  const { player } = useContext(PlayerContext);

  React.useEffect(() => {
    TOURNAMENTS_SHOW({ tournamentUUID })
      .then((response) => {
        if (response.data.status === 'ok') {
          const _tableSessionUUID = response.data.table_session_uuid;
          setTableSessionUUID(_tableSessionUUID);
          history.push(
            `/tournaments/${tournamentUUID}/table-sessions/${_tableSessionUUID}`,
          );
        } else {
          // handle error
        }
      })
      .catch((error) => {
        history.push('/404');
      });
  }, [tournamentUUID]);

  React.useEffect(() => {
    const cableParamsPairs = [
      { key: 'jwt', value: player.jwt },
      { key: 'player_uuid', value: player.uuid },
    ];
    const cableConsumer = Cable.createConsumer(
      buildURL(
        `${process.env.REACT_APP_API_ENDPOINT}/cable`,
        cableParamsPairs,
      ).toString(),
    );
    const subscription = cableConsumer.subscriptions.create(
      {
        channel: 'TournamentsChannel',
        player_uuid: player.uuid,
        player_display: player.display,
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: () => {},
      },
    );
    setProducer(subscription);
    return () => {
      subscription.unsubscribe();
      cableConsumer.disconnect();
    };
  }, [player.jwt, player.uuid, player.display]);

  const renderTournamentRequest = (tournamentRequest, requestIndex) => {
    if (tournamentRequest.request_type === 'join-tournament') {
      return (
        <div className="tournament-request">
          <p>{`${tournamentRequest.request_data.player_user_name} is requesting to join`}</p>
          <p
            onClick={() => {
              APPROVE_REQUEST_JOIN_TOURNAMENT({
                producer,
                tournamentUUID,
                tableSessionUUID,
                tournamentNonce: tournament.nonce,
                requestIndex,
                creatorUUID: player.uuid,
              });
            }}
          >
            APPROVE
          </p>
          <p
            onClick={() => {
              REJECT_REQUEST_JOIN_TOURNAMENT({
                producer,
                tournamentUUID,
                tableSessionUUID,
                tournamentNonce: tournament.nonce,
                requestIndex,
                creatorUUID: player.uuid,
              });
            }}
          >
            REJECT
          </p>
        </div>
      );
    }
    if (tournamentRequest.request_type === 'rebuy-tournament') {
      return (
        <div className="tournament-request">
          <p>{`${tournamentRequest.request_data.player_user_name} is requesting to rebuy`}</p>
          <p
            onClick={() => {
              APPROVE_REQUEST_REBUY_TOURNAMENT({
                producer,
                tournamentUUID,
                tableSessionUUID,
                tournamentNonce: tournament.nonce,
                requestIndex,
                creatorUUID: player.uuid,
              });
            }}
          >
            APPROVE
          </p>
          <p
            onClick={() => {
              REJECT_REQUEST_REBUY_TOURNAMENT({
                producer,
                tournamentUUID,
                tableSessionUUID,
                tournamentNonce: tournament.nonce,
                requestIndex,
                creatorUUID: player.uuid,
              });
            }}
          >
            REJECT
          </p>
        </div>
      );
    }
  };

  if (!isLoading) {
    return (
      <div className="screen tournaments-show-screen">
        <Helmet>
          <title>Clubs Poker</title>
        </Helmet>
        <MobileAccessibilityBanner />
        <SiteNoticeBanner />

        <div className="collapsible-sidebar-content-container">
          <PlayerCollapsibleSidebar startClosed={true} />
          <div className="content">
            <div className="tournament-notification-center">
              {tournament.tournament_requests.map(
                (tournamentRequest, requestIndex) => {
                  return renderTournamentRequest(
                    tournamentRequest,
                    requestIndex,
                  );
                },
              )}
            </div>

            <div className="tournament-info">
              <p>{`Tournament UUID: ${tournament.uuid}`}</p>
              <p>{`Hosted By: ${tournament.host.display}`}</p>
            </div>

            <div className="tournament-table-sessions">
              <p>Tournament Table Sessions</p>
              {tournament.table_sessions.map((tableSession, index) => {
                return (
                  <div
                    className="tournament-table-session"
                    key={`tournament-table-session-${index}`}
                  >
                    <p className="tournament-table-session-text">
                      {tableSession.uuid}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="tournament-actions">
              <div
                className="tournament-action join-tournament-button"
                onClick={() => {
                  REQUEST_JOIN_TOURNAMENT({
                    producer,
                    tournamentUUID,
                    tableSessionUUID,
                    tournamentNonce: tournament.nonce,
                    creatorUUID: player.uuid,
                    creatorUserName: player.display,
                  });
                }}
              >
                <p className="join-tournament-button-text">
                  Request Join Tournament
                </p>
              </div>

              <div
                className="tournament-action start-tournament-button"
                onClick={() => {
                  START_TOURNAMENT({
                    producer,
                    tournamentUUID,
                    tableSessionUUID,
                    tournamentNonce: tournament.nonce,
                    creatorUUID: player.uuid,
                  });
                }}
              >
                <p className="start-tournament-button-text">Start Tournament</p>
              </div>

              <div
                className="tournament-action request-rebuy-button"
                onClick={() => {
                  REQUEST_REBUY_TOURNAMENT({
                    producer,
                    tournamentUUID,
                    tableSessionUUID,
                    tournamentNonce: tournament.nonce,
                    creatorUUID: player.uuid,
                    creatorUserName: player.display,
                  });
                }}
              >
                <p className="request-rebuy-button-text">Request Rebuy</p>
              </div>

              <div
                className="tournament-action sit-out-tournament"
                onClick={() => {
                  SIT_OUT_TOURNAMENT({
                    producer,
                    tournamentUUID,
                    tableSessionUUID,
                    tournamentNonce: tournament.nonce,
                    creatorUUID: player.uuid,
                  });
                }}
              >
                <p>Sit out tournament</p>
              </div>

              <div
                className="tournament-action sit-in-tournament"
                onClick={() => {
                  SIT_IN_TOURNAMENT({
                    producer,
                    tournamentUUID,
                    tableSessionUUID,
                    tournamentNonce: tournament.nonce,
                    creatorUUID: player.uuid,
                  });
                }}
              >
                <p>Sit in Tournament</p>
              </div>

              <div
                className="tournament-action pause-tournament"
                onClick={() => {
                  PAUSE_TOURNAMENT({
                    producer,
                    tournamentUUID,
                    tableSessionUUID,
                    tournamentNonce: tournament.nonce,
                    creatorUUID: player.uuid,
                  });
                }}
              >
                <p>Pause Tournament</p>
              </div>

              <div
                className="tournament-action resume-tournament"
                onClick={() => {
                  RESUME_TOURNAMENT({
                    producer,
                    tournamentUUID,
                    tableSessionUUID,
                    tournamentNonce: tournament.nonce,
                    creatorUUID: player.uuid,
                  });
                }}
              >
                <p>Resume Tournament</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner
          spinnerType="chase"
          style={{
            position: 'absolute',
            top: window.innerHeight / 2 - 25,
            left: window.innerWidth / 2 - 25,
          }}
        />
      </div>
    );
  }
}
