import React, { useState, useContext, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { PlayerContext } from '../../contexts/PlayerContext';
import { t } from '../../i18n';
import { numberDisplay } from '../../poker/helpers';
import AnalyticsService from '../../services/AnalyticsService';
import { INIT_PAYMENT } from '../../services/APIService';
import { isFloatValuePositive, setNumberValue } from '../displayHelpers';
import Spinner from '../Spinner';

const tipOptions = [
  { tipAmount: 178, serverTimeDescription: '2 hours' },
  { tipAmount: 535, serverTimeDescription: '6 hours' },
  { tipAmount: 2142, serverTimeDescription: '1 day' },
];

export const monthlyServerCosts = 66400;
const customTipAmountDefaultValue = 1000;
const customTipAmountPlaceholder = '$10.00';

export default function TipTheDealerModalContent({ closeModal }) {
  const [selectedTipOption, setSelectedTipOption] = useState(0);
  const [customTipAmount, setCustomTipAmount] = useState(
    customTipAmountDefaultValue,
  );
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { player } = useContext(PlayerContext);
  const customTipAmountRef = useRef(null);

  useEffect(() => {
    AnalyticsService.opensTipTheDealerPopup(player.uuid, player.display);
  }, []);

  function changeSelectedTipOption(e) {
    const nextValue = +e.target.value;
    setSelectedTipOption(nextValue);
    if (nextValue === tipOptions.length) {
      setTimeout(() => {
        customTipAmountRef.current.focus && customTipAmountRef.current.focus();
      }, 10);
    }
  }

  const tipAmountToSubmit =
    selectedTipOption === tipOptions.length
      ? customTipAmount
      : tipOptions[selectedTipOption].tipAmount;

  async function initPayment(event) {
    event.preventDefault();
    event.stopPropagation();
    setIsFetchingData(true);
    const response = await INIT_PAYMENT({
      jwt: player.jwt,
      tipAmount: tipAmountToSubmit,
    });
    setIsFetchingData(false);
    if (response.data.status === 'ok') {
      window.open(response.data.checkout_url, '_blank');
      AnalyticsService.tipsTheDealer(
        player.uuid,
        player.display,
        tipAmountToSubmit,
      );
      closeModal();
    } else {
      // TODO: Display an error
    }
  }

  return (
    <div className="tip-the-dealer-content">
      <div className="custom-spacer" style={{ height: 15 }} />
      <div className="tip-the-dealer-paragraph">
        {t('tipTheDealerParagraph1')}
      </div>
      <div className="custom-spacer" style={{ height: 70 }} />
      <div className="tip-the-dealer-paragraph">
        <span>{t('tipTheDealerParagraph2')}</span>{' '}
        <span className="bold-sentence">
          {t(
            'tipTheDealerServerMonthlyCost',
            numberDisplay(monthlyServerCosts / 100, false),
          )}
        </span>
      </div>
      <div className="custom-spacer" style={{ height: 20 }} />
      <form className="clubs-form tip-the-dealer-form" onSubmit={initPayment}>
        <div className="options-container">
          {tipOptions.map(({ tipAmount, serverTimeDescription }, i) => (
            <label key={`tip-option-label-${i}`} className="tip-option-label">
              <input
                className="tip-option-radio-input"
                type="radio"
                name="tip-option"
                value={i}
                checked={selectedTipOption === i}
                onChange={changeSelectedTipOption}
              />
              <span className="spacer" />
              <span className="tip-option-label-tip-amount">
                ${numberDisplay(tipAmount / 100, true)}
              </span>
              <span className="divider">|</span>
              <span className="tip-option-label-server-time">
                {t('tipTheDealerOptionTemplate', serverTimeDescription)}
              </span>
            </label>
          ))}
          <label className="tip-option-label">
            <input
              className="tip-option-radio-input"
              type="radio"
              name="tip-option"
              value={tipOptions.length}
              checked={selectedTipOption === tipOptions.length}
              onChange={changeSelectedTipOption}
            />
            <span className="spacer" />
            <NumberFormat
              className="input-field custom-tip-amount-input"
              placeholder={customTipAmountPlaceholder}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              prefix="$"
              fixedDecimalScale="true"
              maxLength={8}
              isAllowed={isFloatValuePositive}
              value={customTipAmount && customTipAmount / 100}
              onChange={setNumberValue(setCustomTipAmount)}
              onFocus={() => setSelectedTipOption(tipOptions.length)}
              getInputRef={customTipAmountRef}
            />
            <span className="divider">|</span>
            <span className="tip-option-label-server-time">
              {t('tipTheDealerCustomOption')}
            </span>
          </label>
        </div>

        <div className="custom-spacer" style={{ height: 40 }} />
        <button
          className="default-submit-button"
          type="submit"
          disabled={!tipAmountToSubmit || tipAmountToSubmit < 100}
        >
          {isFetchingData ? (
            <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
          ) : (
            <p className="submit-button-text">
              {t(
                'tipTheDealerButtonCTA',
                numberDisplay((tipAmountToSubmit || 0) / 100, true),
              )}
            </p>
          )}
        </button>
      </form>
    </div>
  );
}
