import React from 'react';
import AnalyticsService from '../services/AnalyticsService';
import headsUpArrow from '../assets/svgs/heads-up-arrow.svg';
import { t } from '../i18n';

const BLOG_URL = 'https://blog.clubspokernow.com/clubs-poker-swag-release/';

export default function SeasonalCTA({ player }) {
  // TODO: Some form of CMS to automatically manage the contents of the SeasonalCTA,
  // as well as the dates when it is displayed
  let date = new Date();
  let dateLimit = new Date(2022, 1, 1);

  if (date >= dateLimit) {
    return null;
  }

  return (
    <div className="seasonal-cta">
      <img className="heads-up-arrow" src={headsUpArrow} alt="Heads up" />
      <span className="">{t('wantToGiveSwag')}</span>
      <a
        href={BLOG_URL}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => player && AnalyticsService.opensBlog(player, BLOG_URL)}
      >
        {' ' + t('seeHowToQualify')}
      </a>
    </div>
  );
}
