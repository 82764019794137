const cardsList = [
  {
    suit: 'clubs',
    rank: 'ace',
    img: `${process.env.PUBLIC_URL}/assets/cards/ac.svg`,
  },
  {
    suit: 'clubs',
    rank: 'two',
    img: `${process.env.PUBLIC_URL}/assets/cards/2c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'three',
    img: `${process.env.PUBLIC_URL}/assets/cards/3c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'four',
    img: `${process.env.PUBLIC_URL}/assets/cards/4c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'five',
    img: `${process.env.PUBLIC_URL}/assets/cards/5c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'six',
    img: `${process.env.PUBLIC_URL}/assets/cards/6c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'seven',
    img: `${process.env.PUBLIC_URL}/assets/cards/7c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'eight',
    img: `${process.env.PUBLIC_URL}/assets/cards/8c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'nine',
    img: `${process.env.PUBLIC_URL}/assets/cards/9c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'ten',
    img: `${process.env.PUBLIC_URL}/assets/cards/10c.svg`,
  },
  {
    suit: 'clubs',
    rank: 'jack',
    img: `${process.env.PUBLIC_URL}/assets/cards/jc.svg`,
  },
  {
    suit: 'clubs',
    rank: 'queen',
    img: `${process.env.PUBLIC_URL}/assets/cards/qc.svg`,
  },
  {
    suit: 'clubs',
    rank: 'king',
    img: `${process.env.PUBLIC_URL}/assets/cards/kc.svg`,
  },
  {
    suit: 'hearts',
    rank: 'ace',
    img: `${process.env.PUBLIC_URL}/assets/cards/ah.svg`,
  },
  {
    suit: 'hearts',
    rank: 'two',
    img: `${process.env.PUBLIC_URL}/assets/cards/2h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'three',
    img: `${process.env.PUBLIC_URL}/assets/cards/3h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'four',
    img: `${process.env.PUBLIC_URL}/assets/cards/4h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'five',
    img: `${process.env.PUBLIC_URL}/assets/cards/5h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'six',
    img: `${process.env.PUBLIC_URL}/assets/cards/6h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'seven',
    img: `${process.env.PUBLIC_URL}/assets/cards/7h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'eight',
    img: `${process.env.PUBLIC_URL}/assets/cards/8h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'nine',
    img: `${process.env.PUBLIC_URL}/assets/cards/9h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'ten',
    img: `${process.env.PUBLIC_URL}/assets/cards/10h.svg`,
  },
  {
    suit: 'hearts',
    rank: 'jack',
    img: `${process.env.PUBLIC_URL}/assets/cards/jh.svg`,
  },
  {
    suit: 'hearts',
    rank: 'queen',
    img: `${process.env.PUBLIC_URL}/assets/cards/qh.svg`,
  },
  {
    suit: 'hearts',
    rank: 'king',
    img: `${process.env.PUBLIC_URL}/assets/cards/kh.svg`,
  },
  {
    suit: 'spades',
    rank: 'ace',
    img: `${process.env.PUBLIC_URL}/assets/cards/as.svg`,
  },
  {
    suit: 'spades',
    rank: 'two',
    img: `${process.env.PUBLIC_URL}/assets/cards/2s.svg`,
  },
  {
    suit: 'spades',
    rank: 'three',
    img: `${process.env.PUBLIC_URL}/assets/cards/3s.svg`,
  },
  {
    suit: 'spades',
    rank: 'four',
    img: `${process.env.PUBLIC_URL}/assets/cards/4s.svg`,
  },
  {
    suit: 'spades',
    rank: 'five',
    img: `${process.env.PUBLIC_URL}/assets/cards/5s.svg`,
  },
  {
    suit: 'spades',
    rank: 'six',
    img: `${process.env.PUBLIC_URL}/assets/cards/6s.svg`,
  },
  {
    suit: 'spades',
    rank: 'seven',
    img: `${process.env.PUBLIC_URL}/assets/cards/7s.svg`,
  },
  {
    suit: 'spades',
    rank: 'eight',
    img: `${process.env.PUBLIC_URL}/assets/cards/8s.svg`,
  },
  {
    suit: 'spades',
    rank: 'nine',
    img: `${process.env.PUBLIC_URL}/assets/cards/9s.svg`,
  },
  {
    suit: 'spades',
    rank: 'ten',
    img: `${process.env.PUBLIC_URL}/assets/cards/10s.svg`,
  },
  {
    suit: 'spades',
    rank: 'jack',
    img: `${process.env.PUBLIC_URL}/assets/cards/js.svg`,
  },
  {
    suit: 'spades',
    rank: 'queen',
    img: `${process.env.PUBLIC_URL}/assets/cards/qs.svg`,
  },
  {
    suit: 'spades',
    rank: 'king',
    img: `${process.env.PUBLIC_URL}/assets/cards/ks.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'ace',
    img: `${process.env.PUBLIC_URL}/assets/cards/ad.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'two',
    img: `${process.env.PUBLIC_URL}/assets/cards/2d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'three',
    img: `${process.env.PUBLIC_URL}/assets/cards/3d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'four',
    img: `${process.env.PUBLIC_URL}/assets/cards/4d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'five',
    img: `${process.env.PUBLIC_URL}/assets/cards/5d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'six',
    img: `${process.env.PUBLIC_URL}/assets/cards/6d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'seven',
    img: `${process.env.PUBLIC_URL}/assets/cards/7d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'eight',
    img: `${process.env.PUBLIC_URL}/assets/cards/8d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'nine',
    img: `${process.env.PUBLIC_URL}/assets/cards/9d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'ten',
    img: `${process.env.PUBLIC_URL}/assets/cards/10d.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'jack',
    img: `${process.env.PUBLIC_URL}/assets/cards/jd.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'queen',
    img: `${process.env.PUBLIC_URL}/assets/cards/qd.svg`,
  },
  {
    suit: 'diamonds',
    rank: 'king',
    img: `${process.env.PUBLIC_URL}/assets/cards/kd.svg`,
  },
];

export const cardsDictionary = cardsList.reduce((result, card) => {
  const { rank, suit, img: imagePath } = card;
  const image = new Image();
  image.src = imagePath;
  result[`${card.suit}-${card.rank}`] = {
    suit,
    rank,
    imagePath,
    image,
  };
  return result;
}, {});

export default cardsList;
