import React, { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { t } from '../../i18n';

export default function PlayerLoginModal({
  closeModal,
  isFetchingData,
  formValidation,
  signinInit,
  resetFormErrors = () => {},
}) {
  const [userHandleField, setUserHandleField] = useState('email');
  const [email, setEmail] = useState('');
  const [country, setCountry] = React.useState({});
  const [phoneNumber, setPhoneNumber] = React.useState('');

  const submitForm = (event) => {
    event.preventDefault();
    if (userHandleField === 'email') {
      signinInit({ email });
    }
    if (userHandleField === 'phone' && country.dialCode && phoneNumber) {
      signinInit({
        phone: {
          phoneCountryCode: country.dialCode,
          phoneNumber: phoneNumber.slice(country.dialCode.length),
        },
      });
    }
  };

  const switchUserHandle = (target) => () => {
    setEmail('');
    setCountry({});
    setPhoneNumber('');
    resetFormErrors();
    setUserHandleField(target);
  };

  const setAmericaFirst = () => {
    if (_.isEmpty(country)) {
      setCountry({ countryCode: 'us' });
    }
  };

  return (
    <div className="modal player-login-modal" onClick={closeModal}>
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('signIn')}</h1>
        {userHandleField === 'email' ? (
          <h2 className="modal-subtitle">
            {t('wellSendEmailConfirmationCode')}
          </h2>
        ) : null}
        {userHandleField === 'phone' ? (
          <h2 className="modal-subtitle">
            {t('wellSendPhoneConfirmationCode')}
          </h2>
        ) : null}
        <form className="clubs-form player-login-form" onSubmit={submitForm}>
          {userHandleField === 'email' ? (
            <div className="validated-input-field">
              <input
                className={
                  'email-input ' + (formValidation.email ? 'error' : '')
                }
                type="email"
                required
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {formValidation.email ? (
                <span className="form-validation">
                  {formValidation.email[0]}
                </span>
              ) : (
                <></>
              )}
            </div>
          ) : null}
          {userHandleField === 'phone' ? (
            <div className="validated-input-field">
              <PhoneInput
                placeholder={t('phoneNumber')}
                onFocus={setAmericaFirst}
                country={country.countryCode}
                value={phoneNumber}
                onChange={(phoneNumber, country) => {
                  setPhoneNumber(phoneNumber);
                  if (country.countryCode !== 'ca') {
                    setCountry(country);
                  }
                }}
                // This is needed because of an unaddressed issue in react-phone-input-2, more info in:
                // https://github.com/bl00mber/react-phone-input-2/issues/298
                onEnterKeyPress={submitForm}
              />
              {formValidation.phone ? (
                <span className="form-validation">
                  {formValidation.phone[0]}
                </span>
              ) : null}
            </div>
          ) : null}
          <button
            className="button submit-button request-code-button"
            type="submit"
          >
            {isFetchingData ? (
              <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
            ) : (
              <p>{t('requestCode')}</p>
            )}
          </button>
        </form>
        <div className="authentication-alt-options">
          {userHandleField === 'email' ? (
            <button
              className="authentication-alt-option glowing-dot-option"
              onClick={switchUserHandle('phone')}
            >
              {formValidation.email ? <div className="glowing-dot" /> : null}
              <div>{t('signInWithPhone')}</div>
            </button>
          ) : null}
          {userHandleField === 'phone' ? (
            <button
              className="authentication-alt-option glowing-dot-option"
              onClick={switchUserHandle('email')}
            >
              {formValidation.phone ? <div className="glowing-dot" /> : null}
              <div>{t('signInWithEmail')}</div>
            </button>
          ) : null}
          <Link
            to="/onboarding/host-signup"
            className="authentication-alt-option glowing-dot-option"
          >
            {formValidation.email || formValidation.phone ? (
              <div className="glowing-dot" />
            ) : null}
            <div>{t('createAccountInstead')}</div>
          </Link>
        </div>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    </div>
  );
}
