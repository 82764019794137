import React from 'react';
import { t } from '../../i18n';

export default function FinishGameModal({ closeModal, finishGame }) {
  return (
    <div className="modal finish-game-modal" onClick={closeModal}>
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('finishGame')}</h1>
        <h2 className="modal-subtitle">
          {t('gameWillEndAndPlayersCanViewSummary')}
        </h2>
        <div
          className="default-submit-button finish-game"
          onClick={() => {
            finishGame();
            closeModal();
          }}
        >
          <p>{t('confirmFinishGame')}</p>
        </div>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    </div>
  );
}
