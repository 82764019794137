import React, { useCallback, useEffect, useRef, useState } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { throttle, noop, isEqual } from 'lodash';
import Dropdown from 'react-dropdown';
import DarkModal from './modals/DarkModal';
import logo from '../assets/brand/logo-unicolor.png';
import videoRoomIcon from '../assets/svgs/video-room.svg';
import gearIcon from '../assets/svgs/gear.svg';
import xIcon from '../assets/svgs/x-icon.svg';
import Spinner from './Spinner';
import AnalyticsService from '../services/AnalyticsService';

function adjustVideoIFrameStyle(iframeRef, containerWidth) {
  if (iframeRef && iframeRef.style) {
    iframeRef.style.width = `${containerWidth - 5}px`;
    iframeRef.style.position = 'absolute';
    iframeRef.style.right = 0;
    iframeRef.style.top = 0;
    iframeRef.style.bottom = 0;
  }
  return iframeRef;
}

function ResizablePanel({
  minWidth,
  maxWidth,
  toggleContainerIsOpen,
  panelClassName = '',
  sidebarWidth,
  setSidebarWidth,
  isHidden,
  showResizer = true,
  panelControlTabs,
  chatroomPaneIsMinimized,
  children,
}) {
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const hiddenByDragWidth = 5; // this is the width of the resizer
  const dragActionHideThreshold = 0.8; // hide the panel when dragged to less than 80% of the min size
  const dragActionHideLine = Math.floor(minWidth * dragActionHideThreshold);

  useEffect(
    function unhideDragActionWhenNotHidden() {
      if (!isHidden) {
        setIsMinimized(false);
      }
    },
    [isHidden, setIsMinimized],
  );

  useEffect(
    function resizeCursor() {
      const [rootElement] = document.getElementsByTagName('body');
      if (rootElement && rootElement.style) {
        if (isResizing) {
          rootElement.style.cursor = 'col-resize';
        } else {
          rootElement.style.cursor = 'default';
        }
      }
      return () => {
        if (rootElement && rootElement.style) {
          rootElement.style.cursor = 'default';
        }
      };
    },
    [isResizing],
  );

  const startResizing = useCallback((mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    throttle((mouseMoveEvent) => {
      if (isResizing) {
        const targetWidth = window.innerWidth - mouseMoveEvent.clientX;
        if (targetWidth < dragActionHideLine) {
          if (!isMinimized) {
            setIsMinimized(true);
            toggleContainerIsOpen();
            chatroomPaneIsMinimized();
          }
          return;
        } else if (isMinimized) {
          setIsMinimized(false);
          toggleContainerIsOpen();
        }
        setSidebarWidth(Math.max(Math.min(targetWidth, maxWidth), minWidth));
      }
    }, 20),
    [isResizing, isMinimized, setIsMinimized, setSidebarWidth],
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', stopResizing);
    const [rootElement] = document.getElementsByTagName('html');
    let previousOverflowValue,
      overwroteOverflowValue = false;
    if (rootElement && rootElement.style) {
      previousOverflowValue = rootElement.style.overflow;
      rootElement.style.overflow = 'hidden';
      overwroteOverflowValue = true;
    }
    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResizing);
      if (overwroteOverflowValue && rootElement && rootElement.style) {
        rootElement.style.overflow = previousOverflowValue;
      }
    };
  }, [resize, stopResizing]);

  return (
    <div
      ref={sidebarRef}
      className={['resizable-panel', panelClassName].filter((s) => s).join(' ')}
      style={{
        width: sidebarWidth,
        right:
          isHidden || isMinimized
            ? -sidebarWidth + (isMinimized ? hiddenByDragWidth : 0)
            : 0,
      }}
      onMouseDown={(e) => e.preventDefault()}
    >
      {panelControlTabs}
      {showResizer ? (
        <div
          className={['app-sidebar-resizer', isResizing ? 'is-resizing' : null]
            .filter((s) => s)
            .join(' ')}
          onMouseDown={startResizing}
        />
      ) : null}
      <div className="app-sidebar-pane">
        <div
          className={['resize-overlay', isResizing ? 'is-resizing' : null]
            .filter((s) => s)
            .join(' ')}
        />
        <div className="app-sidebar-content">{children}</div>
      </div>
    </div>
  );
}

function PanelControlTabs({
  chatRoomConfig,
  highlighted,
  containerIsOpen,
  toggleContainerIsOpen,
  userIsConsideredPlayer,
  playerIsHost,
  openChatroomSettings,
  isInVideoRoom,
}) {
  if (
    (chatRoomConfig.state !== 'open' && containerIsOpen) ||
    (containerIsOpen &&
      chatRoomConfig.state === 'open' &&
      chatRoomConfig.allowed_participants === 'players' &&
      !userIsConsideredPlayer &&
      !playerIsHost)
  ) {
    return (
      <div className="control-tab" onClick={toggleContainerIsOpen}>
        <img className="control-tab-icon" src={xIcon} alt="Close panel" />
      </div>
    );
  }

  if (chatRoomConfig.state === 'open' && containerIsOpen && !isInVideoRoom) {
    return (
      <div className="control-tab" onClick={toggleContainerIsOpen}>
        <img className="control-tab-icon" src={xIcon} alt="Close panel" />
      </div>
    );
  }

  if (chatRoomConfig.state === 'open' && containerIsOpen) {
    return (
      <div
        className="control-tab discreet-control-tab"
        onClick={openChatroomSettings}
      >
        <img
          className="control-tab-icon"
          src={gearIcon}
          alt="Configure Chat Room"
        />
      </div>
    );
  }

  return (
    <div
      className={[
        'control-tab',
        'pill-control-tab',
        highlighted ? 'highlighted' : '',
        chatRoomConfig.state === 'open' ? 'highlighted-border' : '',
      ]
        .filter((s) => s)
        .join(' ')}
      onClick={toggleContainerIsOpen}
    >
      <img
        className="control-tab-icon"
        src={videoRoomIcon}
        alt="Join Chat Room"
      />
      <span className="control-tab-text">Chat</span>
    </div>
  );
}

const enabledChannelsKeyOptions = [
  { value: 'video', label: 'Allow video' },
  { value: 'audio', label: 'Audio & text only' },
];

const enabledChannelsKeyToCollection = {
  video: ['video', 'audio', 'text'],
  audio: ['audio', 'text'],
};

const allowedParticipantsKeyOptions = [
  { value: 'everyone', label: 'Anyone can join' },
  {
    value: 'players',
    label: 'Only players can join',
  },
];

function ChatRoomEntranceSettings({
  joinChatroom = noop,
  toggleContainerIsOpen,
  chatRoomConfig,
}) {
  const videoIsEnabled = !!chatRoomConfig.enabled_channels?.includes('video');
  const [videoDefault, setVideoDefault] = useState(videoIsEnabled);
  const [audioDefault, setAudioDefault] = useState(true);

  useEffect(
    function turnOffVideoIfDisabled() {
      if (!videoIsEnabled && videoDefault) {
        setVideoDefault(false);
      }
    },
    [videoIsEnabled],
  );

  function submitHandler(e) {
    e.preventDefault();
    joinChatroom({ videoDefault, audioDefault });
  }

  return (
    <div className="chat-room-guest-content">
      <h2 className="chat-room-entrance-header">
        <img className="chat-room-header-logo" src={logo} alt="Clubs logo" />
        <span className="chat-room-bold-title">Clubs</span>
        <span className="chat-room-regular-title">chat</span>
      </h2>
      <h3 className="chat-room-entrance-subheader">Chatroom is live!</h3>
      <p className="chat-room-entrance-message">
        Clubs chat includes video, audio, and text chat all wrapped into one.
        But no smack-talking, okay!?
      </p>
      <div className="custom-spacer" style={{ height: 20 }} />
      <form className="clubs-form" onSubmit={submitHandler}>
        <div className="form-checkbox-row">
          <label className="chat-room-checkbox-label">
            <input
              className="chat-room-checkbox"
              name="video-default"
              type="checkbox"
              checked={videoDefault}
              disabled={!videoIsEnabled}
              onChange={(e) => setVideoDefault(!videoDefault)}
            />
            <span>Join with video</span>
          </label>
        </div>
        <div className="form-checkbox-row">
          <label className="chat-room-checkbox-label">
            <input
              className="chat-room-checkbox"
              name="audio-default"
              type="checkbox"
              checked={audioDefault}
              onChange={() => setAudioDefault(!audioDefault)}
            />
            <span>Join with audio</span>
          </label>
        </div>
        <button type="submit" className="submit-button centered-button">
          <p>Join chatroom</p>
        </button>
        <button
          type="button"
          className="link-like-button centered-button"
          onClick={toggleContainerIsOpen}
        >
          Maybe later
        </button>
      </form>
    </div>
  );
}

function ChatRoomEntrance({
  playerIsHost,
  userIsConsideredPlayer,
  hostDisplay,
  chatRoomConfig,
  initChatRoom,
  joinVideo,
  joinChatroom,
  containerIsOpen,
  toggleContainerIsOpen,
}) {
  const [enabledChannelsKey, setEnabledChannelsKey] = useState('video');
  const [allowedParticipants, setAllowedParticipants] = useState('everyone');
  const [initializingChatRoom, setInitializingChatRoom] = useState(false);

  useEffect(
    function joinChatRoomIfConditionsAreMet() {
      if (playerIsHost && containerIsOpen) {
        joinVideo();
      }
    },
    [playerIsHost, containerIsOpen, chatRoomConfig, joinVideo],
  );

  function submitInitChatRoom(e) {
    e.preventDefault();
    if (initializingChatRoom) {
      return;
    }

    const enabledChannels = enabledChannelsKeyToCollection[enabledChannelsKey];

    setInitializingChatRoom(true);
    return initChatRoom({
      enabled_channels: enabledChannels,
      allowed_participants: allowedParticipants,
    });
  }

  if (playerIsHost) {
    return (
      <div className="chat-room-host-setup">
        <h2 className="chat-room-entrance-header">
          <img className="chat-room-header-logo" src={logo} alt="Clubs logo" />
          <span className="chat-room-bold-title">Clubs</span>
          <span className="chat-room-regular-title">chat</span>
        </h2>
        <h3 className="chat-room-entrance-subheader">Start the chatroom</h3>
        <p className="chat-room-entrance-message">
          Clubs chat includes video, audio, and text chat all wrapped into one.
          But no smack-talking, okay!?
        </p>
        <form className="clubs-form" onSubmit={submitInitChatRoom}>
          <div className="form-section">
            <Dropdown
              className="select-field enabled-channels"
              value={enabledChannelsKey}
              options={enabledChannelsKeyOptions}
              onChange={({ value }) => setEnabledChannelsKey(value)}
            />
            <Dropdown
              className="select-field allowed-participants"
              value={allowedParticipants}
              options={allowedParticipantsKeyOptions}
              onChange={({ value }) => setAllowedParticipants(value)}
            />
          </div>
          <div className="form-section">
            <button type="submit" className="submit-button centered-button">
              {initializingChatRoom ? (
                <Spinner
                  spinnerType="chase"
                  style={{ width: 16, height: 16 }}
                />
              ) : (
                <p>Start chatroom</p>
              )}
            </button>
            <button
              type="button"
              className="link-like-button centered-button"
              onClick={toggleContainerIsOpen}
            >
              Maybe later
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    const userCanJoin =
      chatRoomConfig.state === 'open' &&
      ((chatRoomConfig.allowed_participants === 'players' &&
        userIsConsideredPlayer) ||
        chatRoomConfig.allowed_participants === 'everyone');

    if (userCanJoin) {
      return (
        <ChatRoomEntranceSettings
          joinChatroom={joinChatroom}
          toggleContainerIsOpen={toggleContainerIsOpen}
          chatRoomConfig={chatRoomConfig}
        />
      );
    }

    const subHeader =
      chatRoomConfig.state !== 'open'
        ? 'Chatroom not started'
        : chatRoomConfig.allowed_participants === 'players' &&
          !userIsConsideredPlayer
        ? "Can't join chatroom"
        : 'Join the chatroom';

    return (
      <div className="chat-room-guest-content">
        <h2 className="chat-room-entrance-header">
          <img className="chat-room-header-logo" src={logo} alt="Clubs logo" />
          <span className="chat-room-bold-title">Clubs</span>
          <span className="chat-room-regular-title">chat</span>
        </h2>
        <h3 className="chat-room-entrance-subheader">{subHeader}</h3>
        <p className="chat-room-entrance-message">
          Clubs chat includes video, audio, and text chat all wrapped into one.
        </p>
        <div className="custom-spacer" style={{ height: 20 }} />
        {chatRoomConfig.state !== 'open' ? (
          <p className="chat-room-entrance-message">
            Waiting for the host, {hostDisplay}, to start the chatroom.
          </p>
        ) : null}
        {chatRoomConfig.state === 'open' &&
        chatRoomConfig.allowed_participants === 'players' &&
        !userIsConsideredPlayer ? (
          <p className="chat-room-entrance-message">
            The chatroom is in progress, but the host has only allowed players
            at the table to join it.
          </p>
        ) : null}
        <button
          type="button"
          className="link-like-button centered-button"
          onClick={toggleContainerIsOpen}
        >
          <p>OK, got it!</p>
        </button>
      </div>
    );
  }
}

function getEnabledChannelsKeyFromValue(channels) {
  for (const key in enabledChannelsKeyToCollection) {
    const value = enabledChannelsKeyToCollection[key];
    if (isEqual([...channels].sort(), [...value].sort())) {
      return key;
    }
  }
  return 'video'; // default value
}

function ChatRoomSettingsModal({
  chatRoomConfig,
  playerIsHost,
  updateChatRoomConfig,
  leaveChatRoom,
  submitEndChatRoom,
  closeModal,
}) {
  const [enabledChannelsKey, setEnabledChannelsKey] = useState(
    getEnabledChannelsKeyFromValue(chatRoomConfig.enabled_channels),
  );
  const [allowedParticipants, setAllowedParticipants] = useState(
    chatRoomConfig.allowed_participants,
  );
  const [updatingChatRoom, setUpdatingChatRoom] = useState(false);

  function submitUpdateChatRoomConfig(e) {
    e.preventDefault();
    if (updatingChatRoom) {
      return;
    }

    const enabledChannels = enabledChannelsKeyToCollection[enabledChannelsKey];

    setUpdatingChatRoom(true);
    setTimeout(closeModal, 600);
    return updateChatRoomConfig({
      enabled_channels: enabledChannels,
      allowed_participants: allowedParticipants,
    });
  }

  if (playerIsHost) {
    return (
      <DarkModal
        modalClassName="chat-room-config-modal"
        title="Chatroom settings"
        closeModal={closeModal}
      >
        <div className="custom-spacer" style={{ height: 20 }} />
        <form className="clubs-form" onSubmit={submitUpdateChatRoomConfig}>
          <div className="form-section">
            <Dropdown
              className="select-field enabled-channels"
              value={enabledChannelsKey}
              options={enabledChannelsKeyOptions}
              onChange={({ value }) => setEnabledChannelsKey(value)}
            />
            <Dropdown
              className="select-field allowed-participants"
              value={allowedParticipants}
              options={allowedParticipantsKeyOptions}
              onChange={({ value }) => setAllowedParticipants(value)}
            />
          </div>
          <div className="form-section">
            <button type="submit" className="submit-button centered-button">
              {updatingChatRoom ? (
                <Spinner
                  spinnerType="chase"
                  style={{ width: 16, height: 16 }}
                />
              ) : (
                <p>Update chatroom</p>
              )}
            </button>
          </div>
        </form>
        <div className="custom-spacer" style={{ height: 12 }} />
        <button className="red-text-button" onClick={leaveChatRoom}>
          Leave chatroom
        </button>
        <button className="red-text-button" onClick={submitEndChatRoom}>
          End chatroom for everyone
        </button>
      </DarkModal>
    );
  }

  return (
    <DarkModal
      modalClassName="chat-room-player-modal"
      title="Leave chatroom"
      closeModal={closeModal}
    >
      <div className="custom-spacer" style={{ height: 20 }} />
      <div className="modal-subtitle">
        You can re-join the chatroom at any time.
      </div>
      <div className="custom-spacer" style={{ height: 160 }} />
      <button
        type="submit"
        className="submit-button centered-button"
        onClick={leaveChatRoom}
      >
        <p>Leave chatroom</p>
      </button>
    </DarkModal>
  );
}

export default function VideoRoomContainer({
  tableSessionUUID,
  tournamentUUID,
  playerUUID,
  playerDisplay,
  playerIsHost,
  hostDisplay,
  gameIsActive = false,
  userIsConsideredPlayer,
  containerWidth,
  setVideoRoomWidth,
  toggleContainerIsOpen,
  containerIsOpen = false,
  joinVideo = noop,
  leaveVideoRoom = noop,
  isInVideoRoom = false,
  chatRoomConfig = {},
  initChatRoom = noop,
  endChatRoom = noop,
  updateChatRoomConfig = noop,
  sendsTextMessageInChatroom = noop,
  chatroomPaneIsMinimized = noop,
}) {
  const [videoIFrameRef, setVideoIFrameRef] = useState();
  const [activeModal, setActiveModal] = useState(null);
  const [jitsiExternalApi, setJitsiExternalApi] = useState();
  const [videoDefault, setVideoDefault] = useState(true);
  const [audioDefault, setAudioDefault] = useState(true);
  const isVideoEnabled = (chatRoomConfig.enabled_channels || []).includes(
    'video',
  );

  useEffect(
    function leaveVideoIfChatRoomIsNotOpen() {
      if (isInVideoRoom) {
        if (chatRoomConfig.state !== 'open') {
          leaveVideoRoom();
        }

        if (
          chatRoomConfig.allowed_participants === 'players' &&
          !userIsConsideredPlayer
        ) {
          leaveVideoRoom();
        }
      }
    },
    [
      chatRoomConfig.state,
      chatRoomConfig.allowed_participants,
      userIsConsideredPlayer,
    ],
  );

  useEffect(
    function syncCameraChannelState() {
      // Turn off camera and camera controls if 'video' channel is not enabled

      function videoMuteStatusChanged({ muted }) {
        if (!isVideoEnabled && !muted) {
          jitsiExternalApi.executeCommand('toggleVideo');
        }
      }

      if (jitsiExternalApi) {
        if (!isVideoEnabled) {
          jitsiExternalApi.isVideoMuted().then((isVideoMuted) => {
            if (!isVideoMuted) {
              jitsiExternalApi.executeCommand('toggleVideo');
            }
          });
        }
        jitsiExternalApi.addListener(
          'videoMuteStatusChanged',
          videoMuteStatusChanged,
        );
      }

      return () => {
        if (jitsiExternalApi) {
          jitsiExternalApi.removeListener(
            'videoMuteStatusChanged',
            videoMuteStatusChanged,
          );
        }
      };
    },
    [isVideoEnabled, jitsiExternalApi],
  );

  function joinChatroom({ videoDefault, audioDefault }) {
    setVideoDefault(videoDefault);
    setAudioDefault(audioDefault);

    joinVideo();
  }

  function leaveChatRoom(e) {
    e.preventDefault();

    if (isInVideoRoom) {
      leaveVideoRoom();
    }
    if (containerIsOpen) {
      toggleContainerIsOpen();
    }
    setActiveModal(null);
  }

  function submitEndChatRoom(e) {
    e.preventDefault();
    if (
      chatRoomConfig.state === 'closed' ||
      typeof chatRoomConfig.state === 'undefined'
    ) {
      setActiveModal(null);
      return;
    }

    const result = endChatRoom();
    leaveVideoRoom();
    if (containerIsOpen) {
      toggleContainerIsOpen();
    }
    setActiveModal(null);
    return result;
  }

  return (
    <ResizablePanel
      panelClassName={`video-room-container ${
        containerIsOpen ? 'open' : 'closed'
      }`}
      sidebarWidth={containerWidth}
      setSidebarWidth={(value) => {
        setVideoRoomWidth(value);
        adjustVideoIFrameStyle(videoIFrameRef, value);
      }}
      minWidth={325}
      maxWidth={585}
      toggleContainerIsOpen={toggleContainerIsOpen}
      isHidden={!containerIsOpen}
      showResizer={isInVideoRoom}
      panelControlTabs={
        <PanelControlTabs
          chatRoomConfig={chatRoomConfig}
          highlighted={!gameIsActive}
          containerIsOpen={containerIsOpen}
          toggleContainerIsOpen={toggleContainerIsOpen}
          userIsConsideredPlayer={userIsConsideredPlayer}
          playerIsHost={playerIsHost}
          openChatroomSettings={() => setActiveModal('chat-room-settings')}
          isInVideoRoom={isInVideoRoom}
        />
      }
      chatroomPaneIsMinimized={chatroomPaneIsMinimized}
    >
      {activeModal === 'chat-room-settings' ? (
        <ChatRoomSettingsModal
          chatRoomConfig={chatRoomConfig}
          playerIsHost={playerIsHost}
          updateChatRoomConfig={updateChatRoomConfig}
          leaveChatRoom={leaveChatRoom}
          submitEndChatRoom={submitEndChatRoom}
          closeModal={() => setActiveModal(null)}
        />
      ) : null}
      <div className={`provider-container ${!containerIsOpen ? 'hidden' : ''}`}>
        {isInVideoRoom ? (
          <JitsiMeeting
            roomName={`clubs-table-session-${tableSessionUUID}`}
            configOverwrite={{
              startWithAudioMuted: !audioDefault,
              startWithVideoMuted: !videoDefault || !isVideoEnabled,
              disableModeratorIndicator: true,
              startScreenSharing: false,
              enableEmailInStats: false,
              enableWelcomePage: false,
              disableSelfViewSettings: false,
              disablePolls: true,
              disableDeepLinking: true,
              prejoinConfig: {
                enabled: false,
              },
              readOnlyName: true,
              toolbarButtons: [
                'camera',
                'chat',
                'microphone',
                'settings',
                'videoquality',
                'shareaudio',
              ].filter((s) => s),
              conferenceInfo: {
                alwaysVisible: [],
              },
              filmstrip: {
                disableResizable: true,
                disableStageFilmstrip: true,
              },
              hideConferenceSubject: true,
              channelLastN: -1,
            }}
            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
              SHOW_CHROME_EXTENSION_BANNER: false,
              SHOW_BRAND_WATERMARK: false,
              SHOW_DEEP_LINKING_IMAGE: false,
              SHOW_JITSI_WATERMARK: false,
              SHOW_POWERED_BY: false,
              SHOW_PROMOTIONAL_CLOSE_PAGE: false,
              VERTICAL_FILMSTRIP: true,
              VIDEO_LAYOUT_FIT: 'width',
            }}
            userInfo={{
              displayName: playerDisplay,
            }}
            onApiReady={(externalApi) => {
              // The following block forces the chat room to be in tile view
              externalApi.addListener('tileViewChanged', ({ enabled }) => {
                if (!enabled) {
                  externalApi.executeCommand('setTileView', true);
                }
              });

              externalApi.addListener('videoMuteStatusChanged', ({ muted }) => {
                if (muted) {
                  AnalyticsService.chatroomVideoIsDisabled(
                    playerUUID,
                    playerDisplay,
                    tableSessionUUID,
                    tournamentUUID,
                  );
                } else {
                  AnalyticsService.chatroomVideoIsEnabled(
                    playerUUID,
                    playerDisplay,
                    tableSessionUUID,
                    tournamentUUID,
                  );
                }
              });

              externalApi.addListener('audioMuteStatusChanged', ({ muted }) => {
                if (muted) {
                  AnalyticsService.chatroomAudioIsDisabled(
                    playerUUID,
                    playerDisplay,
                    tableSessionUUID,
                    tournamentUUID,
                  );
                } else {
                  AnalyticsService.chatroomAudioIsEnabled(
                    playerUUID,
                    playerDisplay,
                    tableSessionUUID,
                    tournamentUUID,
                  );
                }
              });

              externalApi.addListener('outgoingMessage', (_event) => {
                sendsTextMessageInChatroom();
              });

              /*
                TODO: Figure out how to find elements inside the iFrame so we can remove the Jitsi watermark
                from the chat room.

                The consensus is that modern browser's security policies regarding cross domain access makes
                it impossible to access content in the iFrame if it comes from a different domain, unless the
                origini domain from where the iFrame comes opts into relaxing the same origin default policy.

                Old, but still relevant SO answer: https://stackoverflow.com/a/4724942

                Additional information on the topic:
                  - https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-XSS-Protection
                  - https://en.wikipedia.org/wiki/Same-origin_policy#Relaxing_the_same-origin_policy
              */

              setJitsiExternalApi(externalApi);
            }}
            getIFrameRef={(iframeRef) => {
              adjustVideoIFrameStyle(iframeRef, containerWidth);
              setVideoIFrameRef(iframeRef);
            }}
          />
        ) : (
          <ChatRoomEntrance
            playerIsHost={playerIsHost}
            userIsConsideredPlayer={userIsConsideredPlayer}
            hostDisplay={hostDisplay}
            chatRoomConfig={chatRoomConfig}
            initChatRoom={initChatRoom}
            joinVideo={joinVideo}
            leaveVideoRoom={leaveVideoRoom}
            isInVideoRoom={isInVideoRoom}
            joinChatroom={joinChatroom}
            containerIsOpen={containerIsOpen}
            toggleContainerIsOpen={toggleContainerIsOpen}
          />
        )}
      </div>
    </ResizablePanel>
  );
}
