import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PlayerContext } from '../contexts/PlayerContext';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import Cookies from 'js-cookie';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import GuestSignupFormSidebar from '../components/GuestSignupFormSidebar';
import PlayerConfirmEmailSidebar from '../components/PlayerConfirmEmailSidebar';
import YourSummarySection from '../components/YourSummarySection';
import Spinner from '../components/Spinner';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import { t } from '../i18n';
import {
  PLAYER_SIGNUP_INIT,
  PLAYER_SIGNUP_CONFIRM,
} from '../services/APIService';
import AnalyticsService from '../services/AnalyticsService';

import CARDS from '../poker/cards';
import { numberDisplay, userNameDisplay } from '../poker/helpers';
import { TOURNAMENT_SUMMARY } from '../services/APIService';
import greenCheckbox from '../assets/icons/green-checkbox.png';
import curvyArrow from '../assets/svgs/curvy-arrow.svg';
import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';
import PlayerSummaryBadge from '../components/PlayerSummaryBadge';
import TipTheDealerSummarySection from '../components/TipTheDealerSummarySection';
import TipTheDealerLightModal from '../components/modals/TipTheDealerLightModal';

export default function TournamentsShowSummaryScreen() {
  let history = useHistory();
  let location = useLocation();
  let routeSource = new URLSearchParams(location.search).get('ref');
  const eventEmitter = useContext(EventEmitterContext);
  const tournamentUUID = useParams().tournamentUUID;
  const { player, setPlayer } = useContext(PlayerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [activeContent, setActiveContent] = useState('signup-form');
  const [guestSignupFormValidation, setGuestSignupFormValidation] = useState(
    {},
  );
  const [
    playerConfirmEmailFormValidation,
    setPlayerConfirmEmailFormValidation,
  ] = useState({});
  const [summary, setSummary] = useState(null);
  const [activeModal, setActiveModal] = React.useState();

  async function signupGuest(signupPayload) {
    setIsFetchingData(true);
    let playerCreateData = Object.assign(signupPayload, {
      referralUUID: Cookies.get('referralUUID'),
    });
    const response = await PLAYER_SIGNUP_INIT(playerCreateData);
    setIsFetchingData(false);
    if (response.data.status === 'ok') {
      const _player = response.data.player;
      Cookies.set('player', JSON.stringify(_player), {
        expires: 3650,
      });
      Cookies.remove('referralUUID');
      setPlayer(_player);
      return true;
    } else {
      setGuestSignupFormValidation(response.data.details);
      return false;
    }
  }

  async function signupVerifyAuthCode(otp) {
    setIsFetchingData(true);
    const response = await PLAYER_SIGNUP_CONFIRM({ email: player.email, otp });
    setIsFetchingData(false);
    if (response.data.status === 'ok') {
      const _player = response.data.player;
      Cookies.set('player', JSON.stringify(_player), {
        expires: 3650,
      });
      setPlayer(_player);
      Cookies.remove('email');
      Cookies.remove('playerUUID');
      window.location.reload();
    } else {
      setPlayerConfirmEmailFormValidation(response.data.details);
    }
  }

  function displayTournamentStanding(standing) {
    if (standing === '1st') {
      return (
        <p className="standing">
          <span className="img" role="img" aria-label="gold medal">
            🥇
          </span>
        </p>
      );
    } else if (standing === '2nd') {
      return (
        <p className="standing">
          <span className="img" role="img" aria-label="silver medal">
            🥈
          </span>
        </p>
      );
    } else if (standing === '3rd') {
      return (
        <p className="standing">
          <span className="img" role="img" aria-label="bronze medal">
            🥉
          </span>
        </p>
      );
    } else {
      return <p className="standing">{standing}</p>;
    }
  }

  React.useEffect(() => {
    TOURNAMENT_SUMMARY({
      tournamentUUID,
      playerUUID: player.uuid,
      jwt: player.jwt,
    }).then((response) => {
      if (response.data.status === 'redirect') {
        history.push('/');
      } else if (response.data.status === 'ok') {
        setSummary(response.data.summary);
        setIsLoading(false);
        AnalyticsService.viewsPostGameSummary(
          player,
          'tournament',
          routeSource,
        );
      }
    });
  }, [tournamentUUID]);

  let LeftSidebar = <></>;
  if (player && player.guest) {
    if (activeContent === 'signup-form') {
      LeftSidebar = (
        <GuestSignupFormSidebar
          player={player}
          startClosed={false}
          isCollapsible={false}
          signupGuest={async (signupPayload) => {
            const success = await signupGuest(signupPayload);
            if (success) {
              setActiveContent('email-confirmation');
            }
          }}
          isFetchingData={isFetchingData}
          guestSignupFormValidation={guestSignupFormValidation}
          resetFormValidation={() => setGuestSignupFormValidation({})}
        />
      );
    } else if (activeContent === 'email-confirmation') {
      LeftSidebar = (
        <PlayerConfirmEmailSidebar
          player={player}
          startClosed={false}
          isCollapsible={false}
          isFetchingData={isFetchingData}
          reEnterSignupDetails={() => {
            setActiveContent('signup-form');
          }}
          formValidation={playerConfirmEmailFormValidation}
          verifyAuthCode={signupVerifyAuthCode}
          resetFormValidation={() => setPlayerConfirmEmailFormValidation({})}
        />
      );
    }
  } else {
    LeftSidebar = (
      <PlayerCollapsibleSidebar startClosed={false} isCollapsible={false} />
    );
  }

  if (!isLoading) {
    let guestSignupOverlay = <></>;
    if (summary.response_role === 'guest') {
      guestSignupOverlay = (
        <div className="guest-summary-overlay">
          <div className="overlay-content">
            <h2>
              <span>{t('almostDoneUser', player.display)}</span>
              {t('finishAccountToAccessSummary')}
            </h2>
            <ul>
              <li>
                <img src={greenCheckbox} alt="checkbox" />{' '}
                {t('getAllTheGameStats')}
              </li>
              <li>
                <img src={greenCheckbox} alt="checkbox" />{' '}
                {t('saveResultsToGameHistory')}
              </li>
              <li>
                <img src={greenCheckbox} alt="checkbox" /> {t('becomeAHost')}
              </li>
            </ul>
            <p>
              {t('youCan')}{' '}
              <Link to="/" target="_blank">
                {t('learnMoreAboutClubs')}
              </Link>
              . {t('wedLoveToHearFeedback')}{' '}
              <a
                href="https://blog.clubspokernow.com/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('contactUsLabelLowercase')}
              </a>{' '}
              {t('anytime')}.
            </p>
          </div>
          <img className="curvy-arrow" src={curvyArrow} alt="Signup graphic" />
        </div>
      );
    }

    let largest_pot_cards = [
      CARDS.find(
        (c) =>
          c.rank === summary.awards.largest_pot_won.hand[0].rank &&
          c.suit === summary.awards.largest_pot_won.hand[0].suit,
      ),
      CARDS.find(
        (c) =>
          c.rank === summary.awards.largest_pot_won.hand[1].rank &&
          c.suit === summary.awards.largest_pot_won.hand[1].suit,
      ),
    ];

    return (
      <div className="screen tournament-show-summary-screen">
        <Helmet>
          <title>{t('summaryScreenTitle')}</title>
        </Helmet>
        <MobileAccessibilityBanner />
        <SiteNoticeBanner />

        {activeModal === 'tip-the-dealer' ? (
          <TipTheDealerLightModal closeModal={() => setActiveModal(null)} />
        ) : null}

        <div className="collapsible-sidebar-content-container">
          {LeftSidebar}
          <div
            className="content"
            onClick={() => {
              eventEmitter.emit('close-sidebar-if-open', {});
            }}
          >
            <div className="title-section">
              <p
                className="back-button"
                onClick={() => {
                  history.push('/lobby');
                }}
              >{`< Back`}</p>
              <p className="table-session-title">{`No Limit Hold'em Tournament`}</p>
              <div className="title-section-secondary">
                <p className="table-session-host">
                  {t('hostedBySimple')}{' '}
                  <span>{summary.tournament.host.display}</span>
                </p>
                <p className="spacer">|</p>
                <p className="table-session-date">
                  {t('playedOn')}{' '}
                  <span>
                    {Moment.unix(summary.tournament.date).format('MMM DD YYYY')}
                  </span>
                </p>
                <p className="spacer">|</p>
                <p className="table-session-hands-dealt">
                  {t('handsDealt')}{' '}
                  <span>{summary.tournament.hands_dealt}</span>
                </p>
                <p className="spacer">|</p>
                <p className="tournament-total-buyins">
                  {t('totalBuyins')}{' '}
                  <span>{summary.tournament.total_buyins}</span>
                </p>
              </div>
              <div className="title-section-secondary">
                <p className="table-session-host">
                  {t('startingStackSize')}{' '}
                  <span>
                    {summary.tournament.starting_stack_size} {t('bigBlinds')}
                  </span>
                </p>
                <p className="spacer">|</p>
                <p className="table-session-date">
                  {t('levelDuration')}{' '}
                  <span>
                    {summary.tournament.level_duration / 60} {t('minutes')}
                  </span>
                </p>
                <p className="spacer">|</p>
                <p className="table-session-date">
                  {t('blindStructure')}{' '}
                  <span>{summary.tournament.blind_structure}</span>
                </p>
              </div>
              <hr />
            </div>
            <div className="awards-section">
              <div className="award biggest-winner-award">
                <p className="award-icon">
                  <span role="img" aria-label="money">
                    🥇
                  </span>
                </p>
                <div className="award-information">
                  <p className="award-title">{t('tournamentWinner')}</p>
                  <p className="award-content">
                    {userNameDisplay(summary.awards.tournament_winner.display)}
                  </p>
                </div>
              </div>
              <div className="award most-hands-won-award">
                <p className="award-icon">
                  <span role="img" aria-label="trophy">
                    🏆
                  </span>
                </p>
                <div className="award-information">
                  <p className="award-title">{t('mostHandsWon')}</p>
                  <p className="award-content">
                    <span className="chip-amount">
                      {summary.awards.most_hands_won.hands_won}{' '}
                    </span>
                    by {userNameDisplay(summary.awards.most_hands_won.display)}
                  </p>
                </div>
              </div>
              <div className="award largest-pot-award">
                <div className="largest-pot-hand">
                  <img
                    src={largest_pot_cards[0].img}
                    alt={`${largest_pot_cards[0].rank}-of-${largest_pot_cards[0].suit}`}
                  />
                  <img
                    src={largest_pot_cards[1].img}
                    alt={`${largest_pot_cards[1].rank}-of-${largest_pot_cards[1].suit}`}
                  />
                </div>
                <div className="award-information">
                  <p className="award-title">{t('largestPot')}</p>
                  <p className="award-content">
                    <span className="chip-amount">
                      +
                      {numberDisplay(
                        summary.awards.largest_pot_won.pot_size / 100,
                      )}{' '}
                    </span>
                    by {userNameDisplay(summary.awards.largest_pot_won.display)}{' '}
                    (
                    <a
                      className="view-hand-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/hand-analyzer/${summary.awards.largest_pot_won.hand_uuid}`}
                    >
                      {t('viewHand')}
                    </a>
                    )
                  </p>
                </div>
              </div>
            </div>
            <YourSummarySection
              summaryType="tournament"
              playerSummary={summary.player}
              options={{
                decimalDisplay: false,
              }}
            />
            <TipTheDealerSummarySection
              openTipTheDealerModal={() => setActiveModal('tip-the-dealer')}
            />
            <div className="player-tournament-sessions-section">
              <p className="section-title">
                {t('players')} ({summary.player_tournament_sessions.length}{' '}
                {t('total')})
              </p>
              {summary.player_tournament_sessions.map(
                (player_tournament_session, index) => {
                  return (
                    <div
                      className="player-tournament-session"
                      key={`player-table-session-${index}`}
                    >
                      {displayTournamentStanding(
                        player_tournament_session.standing,
                      )}
                      <p className="display">
                        {userNameDisplay(player_tournament_session.display)}
                      </p>
                      <div className="hands-played-container">
                        <p className="hands-played">
                          {player_tournament_session.hands_played}
                        </p>
                        <p className="hands-played-label label">{t('hands')}</p>
                      </div>
                      <div className="hands-won-container">
                        <p className="hands-won">
                          {player_tournament_session.hands_won}
                        </p>
                        <p className="hands-won-label label">{t('handsWon')}</p>
                      </div>
                      <div className="hand-strength-container">
                        <p className="hand-strength">
                          {player_tournament_session.hands_played >= 10
                            ? player_tournament_session.hand_strength
                            : '-'}
                        </p>
                        <p className="hand-strength-label label">
                          {t('handStrength')}
                        </p>
                      </div>
                      <div className="total-buyin-container">
                        <p className="total-buyin">
                          {player_tournament_session.total_entries}
                        </p>
                        <p className="total-buyin-label label">
                          {t('totalEntries')}
                        </p>
                      </div>
                      <div className="player-summary-badges">
                        {player_tournament_session.host_removals.length ? (
                          <PlayerSummaryBadge
                            content={t(
                              'playerRemovedByTheHost',
                              player_tournament_session.host_removals.length,
                            )}
                          />
                        ) : null}
                        {player_tournament_session.added_chips ? (
                          <PlayerSummaryBadge
                            content={t(
                              'playerHadChipsAddedByTheHost',
                              numberDisplay(
                                player_tournament_session.added_chips / 100,
                                summary.tournament.decimal_display,
                              ),
                            )}
                          />
                        ) : null}
                        {player_tournament_session.pocketed_chips ? (
                          <PlayerSummaryBadge
                            content={t(
                              'playerHadChipsRemovedByTheHost',
                              numberDisplay(
                                player_tournament_session.pocketed_chips / 100,
                                summary.tournament.decimal_display,
                              ),
                            )}
                          />
                        ) : null}
                      </div>
                    </div>
                  );
                },
              )}
            </div>
            {guestSignupOverlay}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner
          spinnerType="chase"
          style={{
            position: 'absolute',
            top: window.innerHeight / 2 - 25,
            left: window.innerWidth / 2 - 25,
          }}
        />
      </div>
    );
  }
}
