import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { PlayerContext } from '../contexts/PlayerContext';
import { useHistory } from 'react-router-dom';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import PlayerAccountForm from '../components/forms/PlayerAccountForm';
import {
  PLAYER_ACCOUNT,
  UPDATE_EMAIL_CONFIRM,
  UPDATE_EMAIL_START,
  UPDATE_PHONE_CONFIRM,
  UPDATE_PHONE_START,
  UPDATE_PLAYER_ACCOUNT,
} from '../services/APIService';
import Spinner from '../components/Spinner';
import ConfirmPlayerLogoutModal from '../components/modals/ConfirmPlayerLogoutModal';
import AnalyticsService from '../services/AnalyticsService';
import { t } from '../i18n';
import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';
import UpdateEmailModal from '../components/modals/UpdateEmailModal';
import UpdatePhoneModal from '../components/modals/UpdatePhoneModal';

export default function AccountScreen() {
  let history = useHistory();
  const { player, setPlayer } = useContext(PlayerContext);

  const [isLoading, setIsLoading] = useState(true);
  const [accountPlayer, setAccountPlayer] = useState(null);
  const [fieldIsUpdating, setFieldIsUpdating] = useState('');
  const [checkMarkField, setCheckMarkField] = useState('');
  const [fieldFailedValidation, setFieldFailedValidation] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [activeModalIdent, setActiveModalIdent] = useState('');

  const updatePlayerAccount = async (playerAccountData) => {
    setFieldIsUpdating(true);
    const response = await UPDATE_PLAYER_ACCOUNT({
      userName: playerAccountData.userName,
      jwt: player.jwt,
    });
    setFieldIsUpdating('');
    if (response.data.status === 'ok') {
      setFieldFailedValidation(false);
      Cookies.set('player', JSON.stringify(response.data.player), {
        expires: 3650,
      });
      setPlayer(response.data.player);
      setAccountPlayer(response.data.player);
    } else if (response.data.status === 'error') {
      setFieldFailedValidation(true);
    }
  };

  const updateEmailStart = async (email) => {
    setFieldIsUpdating('email');
    const response = await UPDATE_EMAIL_START({ jwt: player.jwt, email });
    setFieldIsUpdating('');

    if (response.data.status === 'ok') {
      setValidationErrors({});
    } else {
      setValidationErrors(response.data.details);
    }
    return response;
  };

  const updateEmailConfirm = async (email, otp) => {
    setFieldIsUpdating('email');
    const response = await UPDATE_EMAIL_CONFIRM({
      jwt: player.jwt,
      email,
      otp,
    });
    setFieldIsUpdating('');

    if (response.data.status === 'ok') {
      const _player = response.data.player;
      Cookies.set('player', JSON.stringify(_player), {
        expires: 3650,
      });
      setValidationErrors({});
      setCheckMarkField('email');
      setPlayer(_player);
      setAccountPlayer(_player);
    } else {
      setValidationErrors(response.data.details);
    }
    return response;
  };

  const updatePhoneStart = async (phone) => {
    setFieldIsUpdating('phone');
    const response = await UPDATE_PHONE_START({ jwt: player.jwt, phone });
    setFieldIsUpdating('');

    if (response.data.status === 'ok') {
      setValidationErrors({});
    } else {
      setValidationErrors(response.data.details);
    }
    return response;
  };

  const updatePhoneConfirm = async (phone, otp) => {
    setFieldIsUpdating('phone');
    const response = await UPDATE_PHONE_CONFIRM({
      jwt: player.jwt,
      phone,
      otp,
    });
    setFieldIsUpdating('');

    if (response.data.status === 'ok') {
      const _player = response.data.player;
      Cookies.set('player', JSON.stringify(_player), {
        expires: 3650,
      });
      setValidationErrors({});
      setCheckMarkField('phone');
      setPlayer(_player);
      setAccountPlayer(_player);
    } else {
      setValidationErrors(response.data.details);
    }
    return response;
  };

  React.useEffect(() => {
    PLAYER_ACCOUNT({ uuid: player.uuid, jwt: player.jwt }).then((response) => {
      setAccountPlayer(response.data.player);
      setIsLoading(false);
    });
  }, []);

  const logoutPlayer = () => {
    Cookies.remove('player');
    Cookies.remove('playerUUID');
    Cookies.remove('phoneNumber');
    AnalyticsService.logUserOutEvent(player);
    history.push('/');
    history.go();
  };

  return (
    <div className="screen account-screen">
      <Helmet>
        <title>{t('lobbySiteTitle')}</title>
      </Helmet>
      <MobileAccessibilityBanner />
      <SiteNoticeBanner />

      <div className="collapsible-sidebar-content-container">
        <PlayerCollapsibleSidebar startClosed={false} isCollapsible={false} />
        <div className="content">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <PlayerAccountForm
                player={accountPlayer}
                updatePlayerAccount={updatePlayerAccount}
                fieldIsUpdating={fieldIsUpdating}
                checkMarkField={checkMarkField}
                setCheckMarkField={setCheckMarkField}
                fieldFailedValidation={fieldFailedValidation}
                openUpdateEmailModal={() => setActiveModalIdent('update-email')}
                openUpdatePhoneModal={() => setActiveModalIdent('update-phone')}
              />
              <p
                onClick={() => {
                  setActiveModalIdent('confirm-logout');
                }}
                className="logout"
              >
                {t('logout')}
              </p>
            </>
          )}
          {activeModalIdent === 'update-email' ? (
            <UpdateEmailModal
              closeModal={() => {
                setActiveModalIdent('');
                setValidationErrors({});
              }}
              updateEmailStart={updateEmailStart}
              updateEmailConfirm={updateEmailConfirm}
              formValidation={validationErrors}
              isFetchingData={fieldIsUpdating === 'email'}
            />
          ) : null}
          {activeModalIdent === 'update-phone' ? (
            <UpdatePhoneModal
              closeModal={() => {
                setActiveModalIdent('');
                setValidationErrors({});
              }}
              updatePhoneStart={updatePhoneStart}
              updatePhoneConfirm={updatePhoneConfirm}
              formValidation={validationErrors}
              isFetchingData={fieldIsUpdating === 'phone'}
            />
          ) : null}
          {activeModalIdent === 'confirm-logout' ? (
            <ConfirmPlayerLogoutModal
              logoutPlayer={logoutPlayer}
              closeModal={() => {
                setActiveModalIdent('');
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
