import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { PlayerContext } from '../contexts/PlayerContext';
import Dropdown from 'react-dropdown';
import Moment from 'moment';
import Spinner from '../components/Spinner';
import brandMark from '../assets/brand/brand-mark.svg';
// import heroTable from '../assets/landing/hero-table.png';
import sendLinkToTable from '../assets/landing/send-link-to-table.png';
import sendLinkToTableMobile from '../assets/landing/send-link-to-table-mobile.png';
import brandedTable from '../assets/landing/on-deck-w-players.png';
import videoChatroom from '../assets/landing/video-chatroom.png';
import postGameSummary from '../assets/landing/post-game-summary.svg';
import gameMode from '../assets/landing/game-mode.png';
import annieDukeYoutube from '../assets/landing/annie-duke-youtube.png';
import joe from '../assets/landing/joe.png';
import branchLogo from '../assets/landing/branch-logo.png';
import onDeckLogo from '../assets/landing/on-deck-logo.png';
import cwLogo from '../assets/landing/cw-logo.png';
import geLogo from '../assets/landing/ge-logo.png';
import { t } from '../i18n';

import { TABLE_STATES } from './CompanyLandingScreenHandStates';
import {
  PLAYER_SIGNIN_INIT,
  PLAYER_SIGNIN_OTP,
  RESEND_PHONE_CONFIRMATION_CODE,
} from '../services/APIService';
import PokerTable from '../components/PokerTable';
import PlayerLoginModal from '../components/modals/PlayerLoginModal';
import VerifyAuthCodeModal from '../components/modals/VerifyAuthCodeModal';

const CANVAS_ID = Math.random().toString(36).slice(2).replace(/[0-9]/g, '');

export default function CompanyLandingScreen() {
  let history = useHistory();
  const { setPlayer } = React.useContext(PlayerContext);
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [playerLoginFormValidation, setPlayerLoginFormValidation] =
    React.useState({});
  const [playerAuthCodeFormValidation, setPlayerAuthCodeFormValidation] =
    React.useState({});

  const [activeTableState, setActiveTableState] = React.useState(
    TABLE_STATES[0],
  );
  const activeTableStateCursorRef = React.useRef(0);
  const [renderDimensions, setRenderDimensions] = React.useState({
    width: Math.max(window.innerWidth, 1000),
    height: Math.max(window.innerHeight, 625),
  });
  const [activeModalIdent, setActiveModalIdent] = React.useState('');
  const [signinPayload, setSigninPayload] = React.useState({});
  const [playerUUID, setPlayerUUID] = React.useState('');
  const [codeResending, setCodeResending] = React.useState(false);

  const resendCode = () => {
    setCodeResending(true);
    setTimeout(() => {
      setCodeResending(false);
    }, 3000);
    RESEND_PHONE_CONFIRMATION_CODE({ playerUUID });
  };

  const [formEmail, setFormEmail] = React.useState('');
  const [formEventDate, setFormEventDate] = React.useState('');
  const [formNumPlayers, setFormNumPlayers] = React.useState('');
  const [formPackageSelect, setFormPackageSelect] = React.useState('');
  const [isWaitingFormResponse, setIsWaitingFormResponse] =
    React.useState(false);
  const [showFormSuccessMessage, setShowFormSuccessMessage] =
    React.useState(false);

  // Tick the table state of the poker table animation
  React.useEffect(() => {
    setInterval(() => {
      if (activeTableStateCursorRef.current >= TABLE_STATES.length - 1) {
        activeTableStateCursorRef.current = 0;
      } else {
        activeTableStateCursorRef.current =
          activeTableStateCursorRef.current + 1;
      }
      setActiveTableState(TABLE_STATES[activeTableStateCursorRef.current]);
    }, 1000);
  }, []);

  // Use javascript to resize the canvas height to be a consistent relation to it's width
  React.useEffect(() => {
    function handleResize() {
      let canvas = document.querySelector('canvas.poker-table');
      if (window.innerWidth >= 651) {
        let canvasWidth = 1.0 * window.innerWidth - 0.06 * window.innerWidth;
        let canvasHeight = 0.5 * canvasWidth;
        setRenderDimensions({
          width: Math.max(canvasWidth + 400, 1000),
          height: Math.max(canvasHeight + 200, 625),
        });
        canvas.style.marginTop = `20px`;
        canvas.style.top = 0;
        canvas.style.left = 0;
      } else {
        let canvasWidth = window.innerWidth;
        let canvasHeight = window.innerWidth / 2;
        setRenderDimensions({
          width: Math.max(canvasWidth + 150, 1000),
          height: Math.max(canvasHeight + 75, 625),
        });
        canvas.style.marginTop = `25px`;
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreenNavExpanded, setSmallScreenNavExpanded] =
    React.useState(false);

  const renderLogo = (includeCompaniesLabel) => {
    return (
      <div className="header-left">
        <Link className="logo" to="/">
          <img src={brandMark} alt="" className="brand-mark" />
          <p className="brand-name">
            <span className="clubs">Clubs</span> poker
          </p>
          <p className="now">now</p>
        </Link>
        {includeCompaniesLabel ? (
          <p className="companies-label">{t('clCompaniesLabel')}</p>
        ) : null}
      </div>
    );
  };

  const renderNavItems = (screenSize) => {
    let className = 'nav-container-' + screenSize;

    let extraNavItemsHTML = (
      <div className="extra-nav-items-container">
        <Link to="/" className="nav-item">
          {t('clHeaderForFriends')}
        </Link>
        <a
          className="nav-item"
          href="https://blog.clubspokernow.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('headerBlog')}
        </a>
      </div>
    );

    let extraNavItems =
      screenSize === 'small-screen' ? extraNavItemsHTML : null;

    return (
      <div className={className}>
        <a
          href="#how-it-works"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('howItWorksLabel')}
        </a>
        <a
          href="#features"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('featuresLabel')}
        </a>
        <a
          href="#why-poker"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('clWhyPokerLabel')}
        </a>
        <a
          href="#pricing"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('clPricingLabel')}
        </a>
        <a
          href="https://blog.clubspokernow.com/contact-us/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('contactUsLabel')}
        </a>
        {extraNavItems}
      </div>
    );
  };

  const closeNavIfOpen = () => {
    if (smallScreenNavExpanded) {
      setSmallScreenNavExpanded(false);
    }
  };

  const renderTextSection = (heading, description1, arrowCta) => {
    return (
      <div>
        <div className="heading">{heading}</div>
        <div className="description-1">{description1}</div>
        {arrowCta ? renderArrowCta(arrowCta[0], arrowCta[1]) : null}
      </div>
    );
  };

  const renderHeroSignupCta = () => {
    return (
      <div className="hero-signup-cta">
        <a href="#book-now">
          <div className="cta-large-button">
            <p className="large-button-text">{t('clHeroCta')}</p>
          </div>
        </a>
      </div>
    );
  };

  const renderArrowCta = (linkText, href) => {
    return (
      <div className="arrow-cta">
        <div className="arrow">&#10140;</div>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
      </div>
    );
  };

  let ActiveModal = <></>;
  if (activeModalIdent === 'login') {
    ActiveModal = (
      <PlayerLoginModal
        closeModal={() => {
          setActiveModalIdent('');
        }}
        isFetchingData={isFetchingData}
        formValidation={playerLoginFormValidation}
        resetFormErrors={() => setPlayerLoginFormValidation({})}
        signinInit={(signinPayload) => {
          setIsFetchingData(true);
          setSigninPayload(signinPayload);
          PLAYER_SIGNIN_INIT(signinPayload).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              setPlayerUUID(response.data.player_uuid);
              setActiveModalIdent('verify-auth-code');
            } else {
              setPlayerLoginFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  } else if (activeModalIdent === 'verify-auth-code') {
    ActiveModal = (
      <VerifyAuthCodeModal
        closeModal={() => {
          setActiveModalIdent('');
        }}
        signinPayload={signinPayload}
        codeResending={codeResending}
        isFetchingData={isFetchingData}
        resendCode={resendCode}
        formValidation={playerAuthCodeFormValidation}
        verifyAuthCode={(otp) => {
          setIsFetchingData(true);
          PLAYER_SIGNIN_OTP({ ...signinPayload, otp }).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              Cookies.set('player', JSON.stringify(response.data.player), {
                expires: 3650,
              });
              setPlayer(response.data.player);
              history.push('/lobby');
            } else {
              setPlayerAuthCodeFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  }

  const submitEmailForm = async (e) => {
    e.preventDefault();

    if (isWaitingFormResponse || showFormSuccessMessage) {
      //Prevents multiple submissions in quick succession
      return false;
    }

    if (formEmail === '') {
      //Prevents empty form submissions
      return false;
    }

    //Displays loading spinner on form submission until success or error
    setIsWaitingFormResponse(true);

    const timestamp = Moment().format('MMMM Do YYYY, h:mm:ss a');
    const queryParam = window.location.search;

    try {
      const response = await fetch(
        'https://v1.nocodeapi.com/clubspoker/google_sheets/VrYuNUeArxrFkLjF?tabId=Sheet1',
        {
          method: 'post',
          // redirect: "follow",
          body: JSON.stringify([
            [
              timestamp,
              formEmail,
              formEventDate,
              formNumPlayers,
              formPackageSelect,
              queryParam,
            ],
          ]),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      await response.json();
      // console.log('Success:', JSON.stringify(json));
      setIsWaitingFormResponse(false);

      //Resets the form fields after submission
      setFormEmail('');
      setFormEventDate('');
      setFormNumPlayers('');
      setFormPackageSelect('');

      //Shows a form success message until page refresh
      setShowFormSuccessMessage(true);
    } catch (error) {
      // console.error('Error:', error);
      setIsWaitingFormResponse(false);
    }
  };

  const renderFormCtaText = () => {
    if (isWaitingFormResponse) {
      return <Spinner spinnerType="chase" style={{ width: 30, height: 30 }} />;
    } else if (showFormSuccessMessage) {
      return <p className="large-button-text">Request sent!</p>;
    } else {
      return <p className="large-button-text">{t('clBookNowCta')}</p>;
    }
  };

  return (
    <div
      className="landing company-landing"
      onClick={(e) => {
        closeNavIfOpen();
      }}
    >
      <Helmet>
        <title>{t('clSiteTitle')}</title>
      </Helmet>
      <div
        className={`header-container expanded-${smallScreenNavExpanded}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="header">
          {renderLogo(true)}
          <div className="header-right">
            <Link to="/" className="header-link">
              {t('clHeaderForFriends')}
            </Link>
            <a
              href="https://blog.clubspokernow.com"
              target="_blank"
              rel="noopener noreferrer"
              className="header-link"
            >
              {t('headerBlog')}
            </a>
            <p
              className="header-link"
              onClick={() => {
                setActiveModalIdent('login');
              }}
            >
              {t('headerLogin')}
            </p>
          </div>
          <div
            className="hamburger"
            onClick={(e) => {
              setSmallScreenNavExpanded(!smallScreenNavExpanded);
            }}
          >
            <i className="icofont-navigation-menu"></i>
          </div>
        </div>
        {renderNavItems('small-screen')}
      </div>
      {renderNavItems('large-screen')}
      <div className="hero-container">
        <div className="hero-text-container">
          <h1 className="hero-primary">{t('clHeroPrimary')}</h1>
          <p className="hero-secondary">{t('clHeroSecondary')}</p>
        </div>
        {renderHeroSignupCta()}
        <PokerTable
          canvasID={CANVAS_ID}
          tableState={activeTableState}
          playerUUID="000"
          joinTable={() => {}}
          processTableStateRender={() => {}}
          shotClock={30000}
          renderDimensions={renderDimensions}
          mouseEventsOffsets={{ x: 0, y: 0 }}
        />

        {/*<img src={heroTable} alt="" className="hero-table"/>*/}
      </div>
      <div className="testimonials-container">
        <div className="company-logos-container">
          <div className="row-1">
            <img src={geLogo} alt="" className="company-logo" />
            <img src={cwLogo} alt="" className="company-logo" />
          </div>
          <div className="row-2">
            <img src={onDeckLogo} alt="" className="company-logo" />
            <img src={branchLogo} alt="" className="company-logo" />
          </div>
        </div>
        <div className="testimonial">
          <div className="user-container">
            <img src={joe} alt="" />
            <p className="user-name">{t('clTestimonialName')}</p>
            <p className="title">{t('clTestimonialTitle')}</p>
          </div>
          <div className="quotation-container">
            <p className="quotation">{t('clTestimonialQuotation')}</p>
            {renderArrowCta(
              t('clTestimonialArrowCta'),
              'https://blog.clubspokernow.com/how-to-company-online-poker-night/',
            )}
          </div>
        </div>
      </div>
      <div className="how-it-works-container">
        <div className="anchor" id="how-it-works"></div>
        <div className="send-link-to-table content-row">
          <div className="text-container">
            <div className="label">{t('howItWorksLabel')}</div>
            <div className="heading">{t('sendLinkToTableHeading')}</div>
            <div className="description-1">
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('clHowItWorksBullet1')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('clHowItWorksBullet2')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('clHowItWorksBullet3')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('clHowItWorksBullet4')}
              </div>
            </div>
            {renderArrowCta(
              t('clHowItWorksArrowCta'),
              'https://www.loom.com/share/c7478596ad9e4ff59638e4443a218ec6',
            )}
          </div>
          <div className="send-link-to-table-img-container">
            <img
              src={sendLinkToTable}
              alt=""
              className="img-complement desktop-show"
            />
            <img
              src={sendLinkToTableMobile}
              alt=""
              className="img-complement mobile-show"
            />
          </div>
        </div>
      </div>
      <div className="features-container">
        <div className="anchor" id="features"></div>
        <div className="video-chatroom content-row">
          <div className="text-container">
            <div className="label">{t('featuresLabel')}</div>
            {renderTextSection(
              t('videoChatroomHeading'),
              t('videoChatroomDescription'),
            )}
          </div>
          <img src={videoChatroom} alt="" className="img-complement" />
        </div>
        <div className="custom-table content-row">
          <img src={brandedTable} alt="" className="img-complement" />
          <div className="text-container">
            {renderTextSection(
              t('clCustomBrandingHeading'),
              t('clCustomBrandingDescription'),
            )}
          </div>
        </div>
        <div className="post-game-summary content-row">
          <div className="text-container">
            {renderTextSection(
              t('clPostGameSummaryHeading'),
              t('clPostGameSummaryDescription'),
            )}
          </div>
          <img src={postGameSummary} alt="" className="img-complement" />
        </div>
        <div className="game-mode content-row">
          <img src={gameMode} alt="" className="img-complement" />
          <div className="text-container">
            {renderTextSection(
              t('clGameModeHeading'),
              t('clGameModeDescription'),
              [
                t('clGameModeArrowCta'),
                'https://blog.clubspokernow.com/tournaments-vs-cash-games/',
              ],
            )}
          </div>
        </div>
        <div className="features-cta">
          <div className="cta-container">
            <div className="copy-container">
              <p>{t('clFeaturesCtaLabel')}</p>
            </div>
            <div className="create-first-game-container">
              <a href="#book-now">
                <div className="create-first-game-button">
                  {t('clFeaturesCtaButton')}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="why-poker-container">
        <div className="anchor" id="why-poker"></div>
        <div className="why-poker content-row">
          <div className="text-container">
            <div className="label">{t('clWhyPokerLabel')}</div>
            <div className="heading">{t('clWhyPokerHeading')}</div>
            <div className="description-1">
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('clWhyPokerBullet1')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('clWhyPokerBullet2')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('clWhyPokerBullet3')}
              </div>
            </div>
          </div>
          <div className="youtube-video-container">
            <a
              href="https://youtu.be/PnTUTjbwqi4?t=49"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={annieDukeYoutube}
                alt="annie duke poker business video"
              />
            </a>
            {renderArrowCta(
              t('clWhyPokerArrowCta'),
              'https://youtu.be/PnTUTjbwqi4?t=49',
            )}
          </div>
        </div>
      </div>
      <div className="pricing-packages-container">
        <div className="anchor" id="pricing"></div>
        <div className="pricing-packages content-row">
          <div className="label">{t('clPricingLabel')}</div>
          <div className="packages-left-right-container">
            <div className="pricing-package package-left">
              <div className="package-title">{t('clPackageTitleLeft')}</div>
              <div className="price">{t('clPriceLeft')}</div>
              <div className="bullet">
                <span>&#10003;</span>
                {t('clPackageBulletLeft1')}
              </div>
              <div className="bullet">
                <span>&#10003;</span>
                {t('clPackageBulletLeft2')}
              </div>
            </div>
            <div className="pricing-package package-right">
              <div className="package-title">{t('clPackageTitleRight')}</div>
              <div className="price">{t('clPriceRight')}</div>
              <div className="bullet">
                <span>&#10003;</span>
                {t('clPackageBulletRight1')}
              </div>
              <div className="bullet">
                <span>&#10003;</span>
                {t('clPackageBulletRight2')}
              </div>
              <div className="bullet">
                <span>&#10003;</span>
                {t('clPackageBulletRight3')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="book-now-container">
        <div className="anchor" id="book-now"></div>
        <div className="book-now content-row">
          <div className="heading">Let's talk about your upcoming event.</div>
          <form className="clubs-form" onSubmit={submitEmailForm}>
            <div className="book-now-form">
              <input
                type="text"
                placeholder="Company email*"
                value={formEmail}
                onChange={(e) => {
                  setFormEmail(e.target.value);
                }}
              />
              <input
                type="text"
                placeholder="Desired event date(s)"
                value={formEventDate}
                onChange={(e) => {
                  setFormEventDate(e.target.value);
                }}
              />
              <input
                type="text"
                placeholder="# of players (approx)"
                value={formNumPlayers}
                onChange={(e) => {
                  setFormNumPlayers(e.target.value);
                }}
              />
              <Dropdown
                className="select-field extended book-now-dropdown"
                options={["Let's just play", 'Hosted event', 'Both / Other']}
                placeholder="Which package? (listed above)"
                value={formPackageSelect}
                onChange={({ value }) => setFormPackageSelect(value)}
              ></Dropdown>
            </div>
            <div className="submit-form-container">
              <div className="request-to-book-cta">
                <button
                  className={`cta-large-button form-success-message-${showFormSuccessMessage}`}
                >
                  {renderFormCtaText()}
                </button>
              </div>
              <p
                className={`success-message form-success-message-${showFormSuccessMessage}`}
              >
                <span>{t('clFormSuccessMessage')}</span>
                <Link to="/onboarding/host-signup" className="link">
                  {t('clFormSuccessMessageLink')}
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="contact-us-container">
        <div className="anchor" id="contact-us"></div>
        <div className="contact-us content-row">
          <div className="text-container">
            <div className="label">{t('contactUsLabel')}</div>
            <div>
              <div className="heading">{t('clContactUsHeading')}</div>
              <a
                href="https://blog.clubspokernow.com/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
                className="description-1"
              >
                {t('contactUsDescription')}
              </a>
              <div className="description-2">{t('contactUsDescription2')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="final-message-container">
        <h3 className="final-message-text">{t('clFinalMessageText')}</h3>
        <div className="hero-signup-cta">
          <Link to={'/onboarding/host-signup'}>
            <div className="cta-large-button">
              <p className="large-button-text">{t('heroCta')}</p>
            </div>
          </Link>
          <div className="sign-in-container">
            <p
              className="sign-in-link"
              onClick={() => {
                setActiveModalIdent('login');
              }}
            >
              {t('heroSignIn')}
            </p>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <div className="left">
          {renderLogo()}
          <pre className="spacer"> - </pre>
          <div className="made">
            Made with <i className="icofont-heart-alt heart"></i> in NYC
          </div>
        </div>
        <div className="right">
          <div className="links">
            <a
              href="https://blog.clubspokernow.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('headerBlog')}
            </a>
            <pre className="spacer"> | </pre>
            <a
              href="https://www.facebook.com/playonclubs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('facebook')}
            </a>
            <pre className="spacer"> | </pre>
            <a
              href="https://twitter.com/playonclubs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('twitter')}
            </a>
            <pre className="spacer"> | </pre>
            <a href="/privacy.html" target="_blank">
              {t('privacy')}
            </a>
            <pre className="spacer"> | </pre>
            <a href="/ClubsPokerNow-TOU-v012624.pdf" target="_blank">
              {t('terms')}
            </a>
          </div>
        </div>
      </div>

      {ActiveModal}
    </div>
  );
}
