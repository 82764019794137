import React from 'react';
import { RING_GAME_INFO } from '../../services/APIService';
import Spinner from '../Spinner';
import { numberDisplay, userNameDisplay } from '../../poker/helpers';
import moment from 'moment';
import { t } from '../../i18n';

export default function RingGameInfoModal({
  tableSessionUUID,
  playerUUID,
  playerIsHost,
  closeModal,
}) {
  const [isLoadingData, setIsLoadingData] = React.useState(true);
  const [gameInfo, setGameInfo] = React.useState(null);
  const elapsedTimeRef = React.useRef(0);

  React.useEffect(() => {
    RING_GAME_INFO({ tableSessionUUID }).then((response) => {
      setIsLoadingData(false);
      if (response.data.status === 'ok') {
        setGameInfo(response.data.table_session_info);
        elapsedTimeRef.current = response.data.table_session_info.elapsed_time;
        setInterval(() => {
          elapsedTimeRef.current += 1;
        }, 1000);
      }
    });
  }, [tableSessionUUID]);

  let renderPlayerRow = (playerData) => {
    return (
      <div className="player player-info-row" key={playerData.player_uuid}>
        <span className="player-name">
          {userNameDisplay(playerData.display)}
        </span>
        <span className="player-buy-in">
          {numberDisplay(
            playerData.buy_in_total / 100,
            gameInfo.decimal_display,
          )}
        </span>
        <span
          className={`player-winnings ${
            playerData.chip_delta < 0 ? 'negative' : ''
          }${playerData.chip_delta === 0 ? 'neutral' : ''}`}
        >{`${playerData.chip_delta > 0 ? '+' : ''}${numberDisplay(
          playerData.chip_delta / 100,
          gameInfo.decimal_display,
        )}`}</span>
      </div>
    );
  };

  let ModalContent = (
    <div
      className="modal-content"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Spinner />
      <h2 className="close-modal" onClick={closeModal}>
        X
      </h2>
    </div>
  );
  if (!isLoadingData && gameInfo) {
    let currentPlayer = gameInfo.players_data.find(
      (playerTableSession) => playerTableSession.player_uuid === playerUUID,
    );
    let activePlayers = gameInfo.players_data.filter(
      (playerTableSession) =>
        playerTableSession.player_uuid !== playerUUID &&
        playerTableSession.table_status !== 'inactive',
    );
    let inactivePlayers = gameInfo.players_data.filter(
      (playerTableSession) =>
        playerTableSession.player_uuid !== playerUUID &&
        playerTableSession.table_status === 'inactive',
    );

    ModalContent = (
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{`${numberDisplay(
          gameInfo.blinds[0] / 100,
          gameInfo.decimal_display,
        )}/${numberDisplay(
          gameInfo.blinds[1] / 100,
          gameInfo.decimal_display,
        )} No Limit Hold'em Ring Game`}</h1>
        <div className="meta-info">
          <p>
            {t('hostedBySimple')} <span>{gameInfo.host_display}</span>
          </p>
          <p className="divider">|</p>
          <p>
            {t('elapsedTime')}{' '}
            <span>
              {moment
                .utc(
                  moment
                    .duration(elapsedTimeRef.current, 'seconds')
                    .as('milliseconds'),
                )
                .format('HH:mm:ss')}
            </span>
          </p>
        </div>
        <div className="results">
          <div className="header">
            <p className="nickname">{t('nickname')}</p>
            <p className="buy-in">{t('ringHostBuyInTitle')}</p>
            <p className="winnings">{t('ringHostWinningsTitle')}</p>
          </div>
          {currentPlayer ? (
            <div className="players current-player">
              {renderPlayerRow(currentPlayer)}
              <div className="custom-border"></div>
            </div>
          ) : (
            <></>
          )}
          <div className="players active-players">
            {activePlayers.map((player) => renderPlayerRow(player))}
          </div>
          <div className="players inactive-players">
            {inactivePlayers.map((player) => renderPlayerRow(player))}
          </div>
        </div>
        {playerIsHost ? (
          <div className="host-controls-hint">
            <p>{t('hostControlsHint')}</p>
          </div>
        ) : (
          <div className="custom-spacer" style={{ height: 24 }} />
        )}
        <div className="submit-button" onClick={closeModal}>
          {t('ringHostGotItButton')}
        </div>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    );
  }

  return (
    <div className="modal ring-game-info-modal" onClick={closeModal}>
      {ModalContent}
    </div>
  );
}
