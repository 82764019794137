import React from 'react';
import { t } from '../i18n';
import { userNameDisplay, numberDisplay } from '../poker/helpers';
import speakerIcon from '../assets/icons/speaker-icon.png';
import _ from 'lodash';

export default function TournamentNonHostTableActions({
  tournament,
  player,
  playerIsSittingAtTable,
  eventEmitter,
  tableState,
  lastTableStateProcessed,
  setActiveModalIdent,
  sitInTournament,
  sitOutTournament,
}) {
  let processedLastHand =
    lastTableStateProcessed &&
    lastTableStateProcessed.last_hand &&
    lastTableStateProcessed.last_hand.uuid &&
    lastTableStateProcessed.last_hand.receipt;
  let tableStateLastHand =
    tableState.last_hand &&
    tableState.last_hand.uuid &&
    tableState.last_hand.receipt;

  let targetPlayer;
  if (
    lastTableStateProcessed &&
    lastTableStateProcessed.players &&
    !(
      _.includes(
        ['sit-out', 'sit-in', 'approve-request-add-chips'],
        tableState.last_table_session_event[0],
      ) && tableState.last_event_type === 'table-session'
    )
  ) {
    targetPlayer = _.compact(lastTableStateProcessed.players).find(
      (p) => p.player_uuid === player.uuid,
    );
  } else {
    targetPlayer = _.compact(tableState.players).find(
      (p) => p.player_uuid === player.uuid,
    );
  }

  let bottomActions = <></>;
  if (tournament.mode !== 'pending-start' && targetPlayer) {
    bottomActions = (
      <>
        {targetPlayer && targetPlayer.table_status === 'blinding-out' ? (
          <p onClick={sitInTournament} className="action highlight">
            <i className="icofont-ui-play" /> {t('sitInNextHand')}
          </p>
        ) : targetPlayer ? (
          <p onClick={sitOutTournament} className="action">
            <i className="icofont-ui-pause" /> {t('sitMeOut')}
          </p>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <div className="table-actions tournament-non-host-table-actions">
      <div className="top-actions">
        {player && player.guest ? (
          <div
            className="signup-while-you-play default-submit-button"
            onClick={(e) => {
              e.stopPropagation();
              eventEmitter.emit('open-sidebar-if-closed', {});
            }}
          >
            <p>{t('tableSessionShowSignupWhileYouPlay')}</p>
          </div>
        ) : (
          <></>
        )}
        {processedLastHand ? (
          <a
            className="action review-hand"
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.PUBLIC_URL}/hand-analyzer/${lastTableStateProcessed.last_hand.uuid}`}
          >
            <p className="action review-hand">
              <i className="icofont-loop" />{' '}
              {`Review hand: ${userNameDisplay(
                lastTableStateProcessed.last_hand.receipt.winners[0]
                  .player_user_name,
              )} (+${numberDisplay(
                lastTableStateProcessed.last_hand.receipt.winners[0]
                  .chip_delta / 100,
                lastTableStateProcessed.decimal_display,
              )})`}
            </p>
          </a>
        ) : tableStateLastHand ? (
          <a
            className="action review-hand"
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.PUBLIC_URL}/hand-analyzer/${tableState.last_hand.uuid}`}
          >
            <p className="action review-hand">
              <i className="icofont-loop" />{' '}
              {`Review hand: ${userNameDisplay(
                tableState.last_hand.receipt.winners[0].player_user_name,
              )} (+${numberDisplay(
                tableState.last_hand.receipt.winners[0].chip_delta / 100,
                tableState.decimal_display,
              )})`}
            </p>
          </a>
        ) : (
          <p className="action disabled review-hand">
            <i className="icofont-loop" /> {t('replayLastHand')}
          </p>
        )}
      </div>
      <div className="flex-spacer" />
      <div className="bottom-actions">
        <p
          onClick={() => {
            setActiveModalIdent('sounds');
          }}
          className={`action`}
        >
          <img src={speakerIcon} className="icon speaker-icon" alt="speaker" />{' '}
          {t('sounds')}
        </p>
        {bottomActions}
      </div>
    </div>
  );
}
