import React from 'react';
import { t } from '../i18n';
import { monthlyServerCosts } from './modals/TipTheDealerModalContent';

export default function TipTheDealerSummarySection({ openTipTheDealerModal }) {
  return (
    <div className="tip-the-dealer-summary-section">
      <p className="normal-paragraph">
        <span role="img" aria-label="emoji">
          💪💚
        </span>
        <span>{t('tipTheDealerTableOverlaySentence1')}</span>
        <button className="link-like-button" onClick={openTipTheDealerModal}>
          {t('tipTheDealerTableOverlayCTA1')}
        </button>
        <span>{t('tipTheDealerTableOverlaySentence2')}</span>
      </p>
      <p className="greyed-out-paragraph">
        {t('tipTheDealerHelpSupportCosts', monthlyServerCosts / 100)}
      </p>
    </div>
  );
}
