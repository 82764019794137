import React from 'react';
import { TOURNAMENT_INFO } from '../../services/APIService';
import Spinner from '../Spinner';
import { t } from '../../i18n';
import { numberDisplay, tournamentDurationDisplay } from '../../poker/helpers';
import moment from 'moment';

export default function TournamentInfoModal({
  tournamentUUID,
  playerUUID,
  playerIsHost,
  closeModal,
  openTournamentSettingsModal,
}) {
  const [isLoadingData, setIsLoadingData] = React.useState(true);
  const [tournamentInfo, setTournamentInfo] = React.useState({});
  const elapsedTimeRef = React.useRef(0);
  const timeUntilNextLevelRef = React.useRef(0);
  const [elapsedTime, setElapsedTime] = React.useState(0);

  React.useEffect(() => {
    TOURNAMENT_INFO({ tournamentUUID, playerUUID }).then((response) => {
      setIsLoadingData(false);
      if (response.data.status === 'ok') {
        setTournamentInfo(response.data);
        elapsedTimeRef.current = response.data.elapsed_time;
        timeUntilNextLevelRef.current =
          response.data.blinds.time_until_next_level;
        let tournamentMode = response.data.tournament.mode;
        setInterval(() => {
          if (tournamentMode !== 'paused') {
            elapsedTimeRef.current += 1;
            timeUntilNextLevelRef.current -= 1;
            setElapsedTime(elapsedTimeRef.current);
          }
        }, 1000);
      }
    });
  }, [tournamentUUID, playerUUID]);

  const renderTournamentBlinds = (blinds) => {
    let index0Display;
    if (
      tournamentInfo.tournament &&
      tournamentInfo.tournament.mode === 'pending-start'
    ) {
      index0Display = `${t('tournamentInfoModalLevel')} 1`;
    } else {
      index0Display = `${moment
        .utc(
          moment
            .duration(Math.max(0, timeUntilNextLevelRef.current), 'seconds')
            .as('milliseconds'),
        )
        .format('m:ss')} ${t('tournamentInfoModalLeft')}`;
    }

    return blinds.schedule_snapshot.map((blind, index) => {
      return (
        <div className="blind">
          <p className="blind-display">
            {`${numberDisplay(blind[1][0] / 100)} / ${numberDisplay(
              blind[1][1] / 100,
            )} ${t('tournamentInfoModalBlinds')}`}
          </p>
          <p className="middle-divider">-</p>
          <p className="level-display">
            {index === 0
              ? index0Display
              : `${t('tournamentInfoModalLevel')} ${blind[0] + 1}`}
          </p>
        </div>
      );
    });
  };

  let ModalContent = (
    <div
      className="modal-content"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Spinner />
      <h2 className="close-modal" onClick={closeModal}>
        X
      </h2>
    </div>
  );
  if (!isLoadingData && tournamentInfo.tournament) {
    ModalContent = (
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">No Limit Hold'em Tournament</h1>
        <div className="meta-info">
          <p>
            {t('hostedBySimple')}{' '}
            <span>{tournamentInfo.tournament.host_display}</span>
          </p>
          <p className="divider">|</p>
          <p>
            {t('elapsedTime')}{' '}
            <span>
              {moment
                .utc(moment.duration(elapsedTime, 'seconds').as('milliseconds'))
                .format('HH:mm:ss')}
            </span>
          </p>
        </div>
        <div className="tournament-standings-section">
          <p className="total-buyins">{`${
            tournamentInfo.standings.total_tournament_buyins
          } ${t('tournamentInfoModalBuyins')}`}</p>
          <p className="divider">|</p>
          <p className="current-place">
            {tournamentInfo.standings.current_player_standing
              ? t(
                  'tournamentInfoModalCurrentPlace',
                  tournamentInfo.standings.current_player_standing,
                  tournamentInfo.standings.total_tournament_players,
                )
              : t(
                  'tournamentInfoModalTotalTournamentPlayers',
                  tournamentInfo.standings.total_tournament_players,
                )}
          </p>
          <p className="divider">|</p>
          <p className="average-stack">
            {t(
              'tournamentAverageStack',
              numberDisplay(
                Math.round(tournamentInfo.standings.average_chip_stack / 100),
                false,
              ),
            )}
          </p>
        </div>
        <div className="blind-schedule-section">
          {renderTournamentBlinds(tournamentInfo.blinds)}
        </div>
        {tournamentInfo.tournament.mode !== 'pending-start' ? (
          <p className="estimated-time-remaining">
            {t(
              'tournamentInfoModalEstimatedTimeRemaining',
              tournamentDurationDisplay(
                tournamentInfo.tournament.estimated_time_remaining,
              ),
            )}
          </p>
        ) : (
          <></>
        )}
        <hr />
        <div className="host-config-section">
          <div className="config-row">
            <p className="starting-stack">
              {t(
                'tournamentInfoModalStartingStackSize',
                numberDisplay(
                  tournamentInfo.tournament.config.starting_stack / 100,
                ),
              )}
            </p>
            <p className="rebuys">
              {t(
                'tournamentInfoModalRebuys',
                tournamentInfo.tournament.config.rebuys,
              )}
            </p>
          </div>
          <div className="config-row">
            <p className="level-duration">
              {t(
                'tournamentInfoModalLevelDuration',
                tournamentInfo.tournament.config.level_duration,
              )}
            </p>
            <p className="late-registration">
              {t(
                'tournamentInfoModalLateRegistration',
                tournamentInfo.tournament.config.late_registration,
              )}
            </p>
          </div>
          <div className="config-row">
            <p className="blind-structure">
              {t(
                'tournamentInfoModalBlindStructure',
                tournamentInfo.tournament.config.blind_structure,
              )}
            </p>
            <p className="antes">
              {t(
                'tournamentInfoModalAntes',
                tournamentInfo.tournament.config.antes,
              )}
            </p>
          </div>
        </div>
        {playerIsHost ? (
          <p className="change-tournament-settings-prompt">
            {`${t('tournamentInfoModalChangeTournamentSettingsPrompt')} `}
            <span
              className="host-settings"
              onClick={openTournamentSettingsModal}
            >{`${t('tournamentInfoModalHostSettings')}`}</span>
          </p>
        ) : (
          <></>
        )}
        <div className="default-submit-button got-it" onClick={closeModal}>
          <p>{t('tournamentInfoModalGotIt')}</p>
        </div>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    );
  }

  return (
    <div className="modal tournament-info-modal" onClick={closeModal}>
      {ModalContent}
    </div>
  );
}
