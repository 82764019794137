import React from 'react';
import Spinner from '../Spinner';
import { t } from '../../i18n';

export default function VerifyAuthCodeModal({
  closeModal,
  signinPayload,
  verifyAuthCode,
  isFetchingData,
  codeResending,
  resendCode,
  formValidation,
}) {
  const [digit1, setDigit1] = React.useState('');
  const [digit2, setDigit2] = React.useState('');
  const [digit3, setDigit3] = React.useState('');
  const [digit4, setDigit4] = React.useState('');
  const [digit5, setDigit5] = React.useState('');
  const [digit6, setDigit6] = React.useState('');

  const digit1Ref = React.useRef();
  const digit2Ref = React.useRef();
  const digit3Ref = React.useRef();
  const digit4Ref = React.useRef();
  const digit5Ref = React.useRef();
  const digit6Ref = React.useRef();

  const submitForm = (e) => {
    e.preventDefault();
    const confirmationCode = [
      digit1,
      digit2,
      digit3,
      digit4,
      digit5,
      digit6,
    ].join('');
    if (confirmationCode.length === 6) {
      verifyAuthCode(confirmationCode);
    }
  };

  const { email, phone } = signinPayload;
  const userHandle = email ?? `+${phone.phoneCountryCode} ${phone.phoneNumber}`;
  const userHandleHost = email ? 'email' : 'phone';

  return (
    <div className="modal verify-auth-code-modal" onClick={closeModal}>
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">
          {t(
            'onboardingHostEmailConfirmationScreenPrimaryHeader',
            userHandleHost,
          )}
        </h1>
        <h2 className="modal-subtitle">
          {t('weveSentConfirmationCodeToRecipient', userHandle)}
        </h2>
        <form
          className="clubs-form verify-auth-code-form"
          onSubmit={submitForm}
        >
          <div
            className="code"
            onPaste={(e) => {
              e.preventDefault();
              let pasteText = e.clipboardData.getData('Text');
              if (pasteText.length === 6) {
                setDigit1(pasteText[0]);
                setDigit2(pasteText[1]);
                setDigit3(pasteText[2]);
                setDigit4(pasteText[3]);
                setDigit5(pasteText[4]);
                setDigit6(pasteText[5]);
                digit6Ref.current.focus();
              }
            }}
          >
            <input
              autoFocus={true}
              className={`digit-1 ${formValidation.otp ? 'error' : ''}`}
              maxLength="1"
              value={digit1}
              ref={digit1Ref}
              onKeyDown={(e) => {
                if (e.which >= 48 && e.which <= 57) {
                  setDigit1('');
                } else if (e.which === 8) {
                  setDigit1('');
                }
              }}
              onChange={(e) => {
                if (e.target.value !== '') {
                  setDigit1(e.target.value);
                  digit2Ref.current.focus();
                }
              }}
            />
            <input
              className={`digit-2 ${formValidation.otp ? 'error' : ''}`}
              maxLength="1"
              value={digit2}
              ref={digit2Ref}
              onKeyDown={(e) => {
                if (e.which >= 48 && e.which <= 57) {
                  setDigit2('');
                } else if (e.which === 8) {
                  setDigit2('');
                  digit1Ref.current.focus();
                }
              }}
              onChange={(e) => {
                if (e.target.value !== '') {
                  setDigit2(e.target.value);
                  digit3Ref.current.focus();
                }
              }}
            />
            <input
              className={`digit-3 ${formValidation.otp ? 'error' : ''}`}
              maxLength="1"
              value={digit3}
              ref={digit3Ref}
              onKeyDown={(e) => {
                if (e.which >= 48 && e.which <= 57) {
                  setDigit3('');
                } else if (e.which === 8) {
                  setDigit3('');
                  digit2Ref.current.focus();
                }
              }}
              onChange={(e) => {
                if (e.target.value !== '') {
                  setDigit3(e.target.value);
                  digit4Ref.current.focus();
                }
              }}
            />
            <input
              className={`digit-4 ${formValidation.otp ? 'error' : ''}`}
              maxLength="1"
              value={digit4}
              ref={digit4Ref}
              onKeyDown={(e) => {
                if (e.which >= 48 && e.which <= 57) {
                  setDigit4('');
                } else if (e.which === 8) {
                  setDigit4('');
                  digit3Ref.current.focus();
                }
              }}
              onChange={(e) => {
                if (e.target.value !== '') {
                  setDigit4(e.target.value);
                  digit5Ref.current.focus();
                }
              }}
            />
            <input
              className={`digit-5 ${formValidation.otp ? 'error' : ''}`}
              maxLength="1"
              value={digit5}
              ref={digit5Ref}
              onKeyDown={(e) => {
                if (e.which >= 48 && e.which <= 57) {
                  setDigit5('');
                } else if (e.which === 8) {
                  setDigit5('');
                  digit4Ref.current.focus();
                }
              }}
              onChange={(e) => {
                if (e.target.value !== '') {
                  setDigit5(e.target.value);
                  digit6Ref.current.focus();
                }
              }}
            />
            <input
              className={`digit-6 ${formValidation.otp ? 'error' : ''}`}
              maxLength="1"
              value={digit6}
              ref={digit6Ref}
              onKeyDown={(e) => {
                if (e.which >= 48 && e.which <= 57) {
                  setDigit6('');
                } else if (e.which === 8) {
                  setDigit6('');
                  digit5Ref.current.focus();
                }
              }}
              onKeyPress={(e) => {
                digit6Ref.current.focus();
              }}
              onChange={(e) => {
                if (e.target.value === '') {
                  setDigit6('');
                  digit5Ref.current.focus();
                } else {
                  setDigit6(e.target.value);
                }
              }}
            />
          </div>
          {formValidation.otp ? (
            <span className="form-validation">{formValidation.otp[0]}</span>
          ) : (
            <></>
          )}
          <div className="resend-code" onClick={resendCode}>
            <p>{codeResending ? t('codeSent') : t('resendCode')}</p>
          </div>
          <button type="submit" className="button submit-button">
            {isFetchingData ? (
              <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
            ) : (
              <p>{t('next')}</p>
            )}
          </button>
        </form>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    </div>
  );
}
