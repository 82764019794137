import React from 'react';
import Dropdown from 'react-dropdown';
import Spinner from '../Spinner';
import caratUp from '../../assets/icons/carat-up.png';
import { t } from '../../i18n';
import _ from 'lodash';
import { tournamentDurationDisplay } from '../../poker/helpers';

const defaultBlindSchedule = [
  { blinds: [500, 1000], ante: 100 },
  { blinds: [1000, 2000], ante: 200 },
  { blinds: [1500, 3000], ante: 300 },
  { blinds: [2000, 4000], ante: 400 },
  { blinds: [2500, 5000], ante: 500 },
  { blinds: [3000, 6000], ante: 600 },
  { blinds: [4000, 8000], ante: 800 },
  { blinds: [5000, 10000], ante: 1000 },
  { blinds: [7500, 15000], ante: 1500 },
  { blinds: [10000, 20000], ante: 2000 },
  { blinds: [12500, 25000], ante: 2500 },
  { blinds: [15000, 30000], ante: 3000 },
  { blinds: [20000, 40000], ante: 4000 },
  { blinds: [25000, 50000], ante: 5000 },
  { blinds: [30000, 60000], ante: 6000 },
  { blinds: [40000, 80000], ante: 8000 },
  { blinds: [50000, 100000], ante: 10000 },
  { blinds: [60000, 120000], ante: 12000 },
  { blinds: [80000, 160000], ante: 16000 },
  { blinds: [100000, 200000], ante: 20000 },
  { blinds: [120000, 240000], ante: 24000 },
  { blinds: [150000, 300000], ante: 30000 },
  { blinds: [200000, 400000], ante: 40000 },
  { blinds: [250000, 500000], ante: 50000 },
  { blinds: [300000, 600000], ante: 60000 },
  { blinds: [400000, 800000], ante: 80000 },
  { blinds: [500000, 1000000], ante: 100000 },
];

const calculateEstimatedDurationFor10Players = (
  startingStackSize,
  levelDuration,
  antes,
  rebuys,
) => {
  if (startingStackSize && levelDuration) {
    let totalBuyins = rebuys ? 15 : 10;
    let totalChipsOnTable =
      Number(startingStackSize.value) *
      defaultBlindSchedule[0].blinds[1] *
      totalBuyins;
    let endingLevel = _.findIndex(defaultBlindSchedule, (level) => {
      return (
        level.blinds[0] + level.blinds[1] + (antes ? level.ante : 0) >
        totalChipsOnTable * 0.05
      );
    });
    return (endingLevel + 1) * Number(levelDuration.value);
  } else {
    return undefined;
  }
};

export default function TournamentCreateFormAdvanced({
  createTournament,
  isFetchingData,
  toggleActiveForm,
  tournamentCreateFormValidation,
}) {
  const [startingStackSize, setStartingStackSize] = React.useState('');
  const [levelDuration, setLevelDuration] = React.useState('');
  const [shotClock, setShotClock] = React.useState('');
  const [antes, setAntes] = React.useState(true);
  const [rebuys, setRebuys] = React.useState(false);
  const [estimatedDurationFor10Players, setEstimatedDurationFor10Players] =
    React.useState(undefined);

  const startingStackSizeRef = React.useRef('');
  const levelDurationRef = React.useRef('');
  const shotClockRef = React.useRef('');
  const antesRef = React.useRef(antes);
  const rebuysRef = React.useRef(rebuys);

  React.useEffect(() => {
    startingStackSizeRef.current = startingStackSize;
    setEstimatedDurationFor10Players(
      calculateEstimatedDurationFor10Players(
        startingStackSize,
        levelDuration,
        antes,
        rebuys,
      ),
    );
  }, [startingStackSize]);

  React.useEffect(() => {
    levelDurationRef.current = levelDuration;
    setEstimatedDurationFor10Players(
      calculateEstimatedDurationFor10Players(
        startingStackSize,
        levelDuration,
        antes,
        rebuys,
      ),
    );
  }, [levelDuration]);

  React.useEffect(() => {
    shotClockRef.current = shotClock;
  }, [shotClock]);

  React.useEffect(() => {
    antesRef.current = antes;
    setEstimatedDurationFor10Players(
      calculateEstimatedDurationFor10Players(
        startingStackSize,
        levelDuration,
        antes,
        rebuys,
      ),
    );
  }, [antes]);

  React.useEffect(() => {
    rebuysRef.current = rebuys;
    setEstimatedDurationFor10Players(
      calculateEstimatedDurationFor10Players(
        startingStackSize,
        levelDuration,
        antes,
        rebuys,
      ),
    );
  }, [rebuys]);

  const startingStackSizeOptions = [
    { value: '20', label: 'Short (20 BB)' },
    { value: '50', label: 'Medium (50 BB)' },
    { value: '100', label: 'Large (100 BB)' },
    { value: '200', label: 'X-Large (200 BB)' },
    { value: '300', label: 'Mega (300 BB)' },
  ];

  const levelDurationOptions = [
    { value: '120', label: t('tournamentDurationMin', '2') },
    { value: '180', label: t('tournamentDurationMin', '3') },
    { value: '300', label: t('tournamentDurationMin', '5') },
    { value: '480', label: t('tournamentDurationMin', '8') },
    { value: '600', label: t('tournamentDurationMin', '10') },
    { value: '900', label: t('tournamentDurationMin', '15') },
    { value: '1200', label: t('tournamentDurationMin', '20') },
  ];

  const shotClockOptions = [
    { value: '20000', label: '20 seconds' },
    { value: '30000', label: '30 seconds' },
    { value: '60000', label: '60 seconds' },
    { value: '1000000000', label: 'Infinite' },
  ];

  const selectStartingStackSize = (data) => {
    setStartingStackSize(data);
  };

  const selectLevelDuration = (data) => {
    setLevelDuration(data);
  };

  const selectShotClock = (data) => {
    setShotClock(data);
  };

  const handleToggleForm = (event) => {
    toggleActiveForm('quick');
  };

  const submitForm = () => {
    createTournament({
      configType: 'advanced',
      startingStackSize: startingStackSizeRef.current.value,
      levelDuration: levelDurationRef.current.value,
      shotClock: shotClockRef.current.value,
      antes: antesRef.current,
      rebuys: rebuysRef.current,
    });
  };

  return (
    <div className="clubs-form tournament-create-form-advanced">
      <div className="toggle-active-form" onClick={handleToggleForm}>
        <img
          src={caratUp}
          className="toggle-active-form-icon"
          alt="arrow pointing up"
        />
        <p className="toggle-active-form-text">Quick Setup</p>
      </div>
      <h4 className="form-title">Advanced setup</h4>
      <div className="form-section starting-stack-section">
        <p className="label">Set the starting stack size</p>
        <div className="validated-input-fields">
          <Dropdown
            required
            className={`select-field starting-stack-size ${
              tournamentCreateFormValidation.starting_stack_size ? 'error' : ''
            }`}
            value={startingStackSize}
            options={startingStackSizeOptions}
            onChange={selectStartingStackSize}
            placeholder={t('tournamentStartingStackSize')}
          />
        </div>
      </div>
      <div className="form-section rebuys-antes-section">
        <p className="label"></p>
        <div className="validated-input-fields">
          <div className="rebuys-section">
            <p className="rebuys-text">Rebuys?</p>
            <input
              className="rebuys-checkbox"
              type="checkbox"
              onChange={(e) => {
                setRebuys(e.target.checked);
              }}
              checked={rebuys}
            />
          </div>
          <div className="antes-section">
            <p className="antes-text">Antes?</p>
            <input
              className="antes-checkbox"
              type="checkbox"
              onChange={(e) => {
                setAntes(e.target.checked);
              }}
              checked={antes}
            />
          </div>
        </div>
      </div>
      <div className="form-section level-duration-section">
        <p className="label">Manage blind increases</p>
        <div className="validated-input-fields">
          <Dropdown
            required
            className={`select-field level-duration ${
              tournamentCreateFormValidation.level_duration ? 'error' : ''
            }`}
            value={levelDuration}
            options={levelDurationOptions}
            onChange={selectLevelDuration}
            placeholder={t('tournamentLevelDuration')}
          />
        </div>
      </div>
      <div className="form-section shot-clock-section">
        <p className="label">Set the decision clock</p>
        <div className="validated-input-fields">
          <Dropdown
            className={`select-field shot-clock ${
              tournamentCreateFormValidation.shot_clock ? 'error' : ''
            }`}
            value={shotClock}
            options={shotClockOptions}
            onChange={selectShotClock}
            placeholder={t('hostSettingsDecisionClockPlaceholder')}
          />
        </div>
      </div>
      {estimatedDurationFor10Players ? (
        <div className="form-section estimated-duration-section">
          <p>{`${t(
            'tournamentEstimatedDurationFor10Players',
          )} ${tournamentDurationDisplay(estimatedDurationFor10Players)}`}</p>
        </div>
      ) : (
        <div className="form-section estimated-duration-section"></div>
      )}
      <div className="submit-button" onClick={submitForm}>
        {isFetchingData ? (
          <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
        ) : (
          <p className="submit-button-text">Create game</p>
        )}
      </div>
    </div>
  );
}
