import { reverseNumberDisplay } from '../poker/helpers';

export const setNumberValue =
  (stateSetter) =>
  ({ target: { value } }) =>
    stateSetter(
      value === undefined || value === ''
        ? undefined
        : reverseNumberDisplay(value) * 100,
    );

export const isFloatValuePositive = ({ floatValue }) =>
  floatValue === undefined || floatValue >= 0;
