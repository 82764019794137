import React from 'react';

export default function DarkModal({
  closeModal,
  title,
  modalClassName,
  modalContentClassName,
  children,
  contentStyle = {},
}) {
  return (
    <div
      className={['modal', 'dark-modal', modalClassName]
        .filter((s) => s)
        .join(' ')}
      onClick={closeModal}
    >
      <div
        className={['modal-content', modalContentClassName]
          .filter((s) => s)
          .join(' ')}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={contentStyle}
      >
        <h1 className="modal-title">{title}</h1>
        {children}
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    </div>
  );
}
