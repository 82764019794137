import React from 'react';
import { Helmet } from 'react-helmet';
import brandMark from '../assets/brand/brand-mark.svg';
import Cookies from 'js-cookie';
import { Link, useHistory } from 'react-router-dom';
import { PlayerContext } from '../contexts/PlayerContext';
import PlayerLoginModal from '../components/modals/PlayerLoginModal';
import VerifyAuthCodeModal from '../components/modals/VerifyAuthCodeModal';
import {
  PLAYER_REQUEST_AUTH_CODE,
  VERIFY_PHONE_NUMBER,
  RESEND_PHONE_CONFIRMATION_CODE,
} from '../services/APIService';
import image404 from '../assets/404-cards.png';
import { t } from '../i18n';

export default function NoMatchScreen() {
  let history = useHistory();
  const { player, setPlayer } = React.useContext(PlayerContext);
  const [activeModalIdent, setActiveModalIdent] = React.useState('');
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [playerLoginFormValidation, setPlayerLoginFormValidation] =
    React.useState({});
  const [playerAuthCodeFormValidation, setPlayerAuthCodeFormValidation] =
    React.useState({});
  const [phoneInformation, setPhoneInformation] = React.useState({});
  const [playerUUID, setPlayerUUID] = React.useState('');
  const [codeResending, setCodeResending] = React.useState(false);

  const resendCode = () => {
    setCodeResending(true);
    setTimeout(() => {
      setCodeResending(false);
    }, 3000);
    RESEND_PHONE_CONFIRMATION_CODE({ playerUUID });
  };

  const renderLogo = () => {
    return (
      <div className="logo">
        <img src={brandMark} alt="" className="brand-mark" />
        <p className="brand-name">
          <span className="clubs">Clubs</span> poker
        </p>
        <p className="now">now</p>
      </div>
    );
  };

  let ActiveModal = <></>;
  if (activeModalIdent === 'login') {
    ActiveModal = (
      <PlayerLoginModal
        closeModal={() => {
          setPlayerLoginFormValidation({});
          setActiveModalIdent('');
        }}
        isFetchingData={isFetchingData}
        formValidation={playerLoginFormValidation}
        playerRequestAuthCode={(phoneInformation) => {
          setIsFetchingData(true);
          setPhoneInformation(phoneInformation);
          PLAYER_REQUEST_AUTH_CODE(phoneInformation).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              setPlayerUUID(response.data.player_uuid);
              setActiveModalIdent('verify-auth-code');
            } else if (response.data.status === 'error') {
              setPlayerLoginFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  } else if (activeModalIdent === 'verify-auth-code') {
    ActiveModal = (
      <VerifyAuthCodeModal
        closeModal={() => {
          setActiveModalIdent('');
        }}
        phoneInformation={phoneInformation}
        codeResending={codeResending}
        isFetchingData={isFetchingData}
        resendCode={resendCode}
        formValidation={playerAuthCodeFormValidation}
        verifyAuthCode={(code) => {
          setIsFetchingData(true);
          VERIFY_PHONE_NUMBER({ playerUUID, code }).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              Cookies.set('player', JSON.stringify(response.data.player), {
                expires: 3650,
              });
              setPlayer(response.data.player);
              history.push('/lobby');
            } else if (response.data.status === 'error') {
              setPlayerAuthCodeFormValidation({
                message: response.data.message,
              });
            }
          });
        }}
      />
    );
  }

  const handleLogin = () => {
    if (player.email) {
      history.push('/lobby');
    } else {
      setActiveModalIdent('login');
    }
  };

  return (
    <div className="no-match-screen landing">
      <Helmet>
        <title>{t('noMatchPageTitle')}</title>
      </Helmet>
      <div
        className={`header-container`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="header">
          <Link to="/">{renderLogo()}</Link>
          <div className="login">
            {!player.email && (
              <p
                onClick={() => {
                  handleLogin();
                }}
              >
                {t('headerLogin')}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="no-match-container">
        <div className="info">
          <h1>{t('noMatchScreenTitle')}</h1>
          <p>{t('noMatchCtaTitle1')}</p>
          <p>{t('noMatchCtaTitle2')}</p>
          <Link className="submit-button" to="/">
            {t('noMatchHomeButton')}
          </Link>
          <p>
            {t('noMatchNeedHelp')}{' '}
            <a
              href="https://blog.clubspokernow.com/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('noMatchChatWithUs')}
            </a>
          </p>
        </div>
        <div className="graphics">
          <img src={image404} alt="404" />
        </div>
      </div>

      <div className="footer-container">
        <div className="left">
          {renderLogo()}
          <pre className="spacer"> - </pre>
          <div className="made">
            Made with <i className="icofont-heart-alt heart"></i> in NYC
          </div>
        </div>
        <div className="right">
          <div className="links">
            <a
              href="https://www.facebook.com/playonclubs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('facebook')}
            </a>
            <pre className="spacer"> | </pre>
            <a
              href="https://twitter.com/playonclubs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('twitter')}
            </a>
            <pre className="spacer"> | </pre>
            <a href="/privacy.html" target="_blank">
              {t('privacy')}
            </a>
            <pre className="spacer"> | </pre>
            <a href="/ClubsPokerNow-TOU-v012624.pdf" target="_blank">
              {t('terms')}
            </a>
          </div>
        </div>
      </div>

      {ActiveModal}
    </div>
  );
}
