import React from 'react';
import { Helmet } from 'react-helmet';
import { PlayerContext } from '../contexts/PlayerContext';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import { MULTI_TABLE_EVENT } from '../services/APIService';
import { numberDisplay, gameVariantDisplay } from '../poker/helpers';
import Spinner from '../components/Spinner';
import PokerTable from '../components/PokerTable';
import { t } from '../i18n';
import Cable from 'actioncable';

export default function MultiTableEventsShowScreen() {
  const { player } = React.useContext(PlayerContext);

  const [websocketConsumer, setWebsocketConsumer] = React.useState(null);
  const multiTableEventUUID = useParams().uuid;
  const [isLoading, setIsLoading] = React.useState(true);
  const [multiTableEvent, setMultiTableEvent] = React.useState(null);
  const [liveTableStates, setLiveTableStates] = React.useState({});

  const liveTableStatesRef = React.useRef(liveTableStates);

  React.useEffect(() => {
    liveTableStatesRef.current = liveTableStates;
  }, [liveTableStates]);

  React.useEffect(() => {
    MULTI_TABLE_EVENT({ uuid: multiTableEventUUID }).then((response) => {
      setMultiTableEvent(response.data);
      let nextLiveTableStates = {};
      response.data.table_sessions.forEach((tableSession) => {
        nextLiveTableStates[tableSession.uuid] = tableSession.state;
      });
      setLiveTableStates(nextLiveTableStates);
      setIsLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setWebsocketConsumer(
      Cable.createConsumer(
        `${process.env.REACT_APP_API_ENDPOINT}/cable?player_uuid=${player.uuid}`,
      ),
    );
  }, []);

  React.useEffect(() => {
    if (websocketConsumer) {
      websocketConsumer.subscriptions.create(
        {
          channel: 'MultiTableEventChannel',
          multi_table_event_uuid: multiTableEventUUID,
        },
        {
          connected: () => {},
          disconnected: () => {},
          received: (data) => {
            let nextLiveTableStates = JSON.parse(
              JSON.stringify(liveTableStatesRef.current),
            );
            nextLiveTableStates[data.table_session_uuid] = data.data;
            setLiveTableStates(nextLiveTableStates);
          },
        },
      );
    }
  }, [websocketConsumer]);

  function renderActiveTableSession(tableSession, index) {
    return (
      <div className="active-table" key={`active-table-${index}`}>
        <Link
          to={`/table-sessions/${tableSession.uuid}`}
          className="poker-table"
        >
          <PokerTable
            canvasID={`canvas-${index}`}
            tableState={liveTableStatesRef.current[tableSession.uuid]}
            playerUUID={player.uuid}
            joinTable={() => {}}
            processTableStateRender={() => {}}
            shotClock={999999999999}
            renderDimensions={{ width: 804, height: 417 }}
            mouseEventsOffsets={{ x: 0, y: 0 }}
          />
        </Link>
        <div className="active-table-info">
          <p className="primary-content">{tableSession.uuid}</p>
          <div className="secondary-content">
            {tableSession.mode === 'active' ? (
              <div className="table-mode">
                <div className="dot active" />
                <p>{t('inProgress')}</p>
              </div>
            ) : (
              <div className="table-mode">
                <span className="dot inactive" />
                <p>{t('waiting')}</p>
              </div>
            )}
            <p className="table-variant">{`${numberDisplay(
              tableSession.state.blinds[0] / 100,
              tableSession.decimal_display,
            )}/${numberDisplay(
              tableSession.state.blinds[1] / 100,
              tableSession.decimal_display,
            )} ${gameVariantDisplay(tableSession.game_variant)}`}</p>
            <p className="table-players-amount">{`${
              _.compact(tableSession.state.players).length
            } players`}</p>
          </div>
        </div>
      </div>
    );
  }

  let ActiveTables = <></>;
  if (player && multiTableEvent && multiTableEvent.table_sessions) {
    ActiveTables = multiTableEvent.table_sessions.map((tableSession, index) => {
      return renderActiveTableSession(tableSession, index);
    });
  }

  return (
    <div className="screen multi-table-event-screen">
      <Helmet>
        <title>{`Multi Table Event`}</title>
      </Helmet>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="table-section">
            <div className="tables">{ActiveTables}</div>
          </div>
        </>
      )}
    </div>
  );
}
