import React from 'react';
import { t } from '../i18n';
import _ from 'lodash';
import Spinner from './Spinner';

export default function SecondaryNotificationCenter({
  tournament,
  tableState,
  player,
  requestJoinTournament,
  requestRebuyTournament,
}) {
  const [isOptimisticallyUpdating, setIsOptimisticallyUpdating] =
    React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (isOptimisticallyUpdating) {
        setIsOptimisticallyUpdating(false);
      }
    }, 3000);
  }, [isOptimisticallyUpdating]);

  const renderSecondaryNotification = () => {
    if (tournament && player && tournament.mode !== 'completed') {
      let playerIsParticipantInTournament = _.includes(
        tournament.participants,
        player.uuid,
      );
      let playerIsSeatedAtTable = _.some(tableState.players, (p) => {
        return p && p.player_uuid === player.uuid;
      });
      let tableIsInProgress = _.includes(['active', 'paused'], tableState.mode);
      let numberOfPotentialPlayers =
        _.compact(tableState['players']).length +
        tournament['tournament_requests'].length;

      let playerIsCurrentlyRequestingRebuy = _.some(
        tournament.tournament_requests,
        (tr) => {
          return (
            tr.request_type === 'rebuy-tournament' &&
            tr.request_data.player_uuid === player.uuid
          );
        },
      );
      let playerIsCurrentlyRequestingJoin = _.some(
        tournament.tournament_requests,
        (tr) => {
          return (
            tr.request_type === 'join-tournament' &&
            tr.request_data.player_uuid === player.uuid
          );
        },
      );

      if (
        playerIsParticipantInTournament &&
        !playerIsSeatedAtTable &&
        tableIsInProgress &&
        !playerIsCurrentlyRequestingRebuy &&
        tournament.rebuys
      ) {
        return (
          <div className={`notification notification-0`} key={`notification-0`}>
            {numberOfPotentialPlayers >= 10 ? (
              <></>
            ) : (
              <p className="notification-text">
                {t('tournamentSecondaryNotificationInProgress')}
              </p>
            )}
            {numberOfPotentialPlayers >= 10 ? (
              <></>
            ) : (
              <div
                className="notification-approve request-rebuy"
                onClick={requestRebuyTournament}
              >
                <p>{t('tournamentSecondaryNotificationRequestRebuy')}</p>
              </div>
            )}
          </div>
        );
      } else if (
        playerIsParticipantInTournament &&
        !playerIsSeatedAtTable &&
        tableIsInProgress &&
        playerIsCurrentlyRequestingRebuy
      ) {
        return (
          <div className={`notification notification-0`} key={`notification-0`}>
            <p className="notification-text">
              {t('tournamentSecondaryNotificationInProgress')}
            </p>
            <div className="notification-approve default-submit-button action-in-progress">
              <p>
                {t('tournamentSecondaryNotificationRequestRebuyRequesting')}
              </p>
            </div>
          </div>
        );
      } else if (
        !playerIsParticipantInTournament &&
        !playerIsSeatedAtTable &&
        tableIsInProgress &&
        !playerIsCurrentlyRequestingJoin
      ) {
        return (
          <div className={`notification notification-0`} key={`notification-0`}>
            {numberOfPotentialPlayers >= 10 ? (
              <></>
            ) : (
              <p className="notification-text">
                {t('tournamentSecondaryNotificationInProgress')}
              </p>
            )}
            {numberOfPotentialPlayers >= 10 ? (
              <></>
            ) : (
              <div
                className="notification-approve default-submit-button"
                onClick={() => {
                  setIsOptimisticallyUpdating(true);
                  requestJoinTournament();
                }}
              >
                {isOptimisticallyUpdating ? (
                  <Spinner />
                ) : (
                  <p>{t('tournamentSecondaryNotificationRequestJoin')}</p>
                )}
              </div>
            )}
          </div>
        );
      } else if (
        !playerIsParticipantInTournament &&
        !playerIsSeatedAtTable &&
        tableIsInProgress &&
        playerIsCurrentlyRequestingJoin
      ) {
        return (
          <div className={`notification notification-0`} key={`notification-0`}>
            <p className="notification-text">
              {t('tournamentSecondaryNotificationInProgress')}
            </p>
            <div className="notification-approve default-submit-button action-in-progress">
              <p>
                {t('tournamentSecondaryNotificationRequestRebuyRequesting')}
              </p>
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  return (
    <div className="secondary-notification-center">
      {renderSecondaryNotification()}
    </div>
  );
}
