import React from 'react';
import ReactTooltip from 'react-tooltip-lite';

export const Tooltip = ({ content }) => {
  return (
    <ReactTooltip className="tooltip" content={content} distance={15}>
      <img src="/assets/question-mark.svg" alt="question mark" />
    </ReactTooltip>
  );
};
