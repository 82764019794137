import React from 'react';
import { t } from '../../i18n';

export default function ConfirmPlayerLogoutModal({ closeModal, logoutPlayer }) {
  return (
    <div className="modal confirm-player-logout-modal" onClick={closeModal}>
      <div
        className="modal-content inverted"
        style={{ left: '175px' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('logoutOfClubs')}</h1>
        <div
          className="button logout"
          onClick={() => {
            logoutPlayer();
            closeModal();
          }}
        >
          <p>{t('logout')}</p>
        </div>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    </div>
  );
}
