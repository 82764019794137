import React, { useState } from 'react';
import { numberDisplay } from '../../poker/helpers';
import NumberFormat from 'react-number-format';
import Spinner from '../Spinner';
import { t } from '../../i18n';
import { isFloatValuePositive, setNumberValue } from '../displayHelpers';
import { globalChipRequestLimit } from '../../poker/settings';

export default function AddChipsModal({
  closeModal,
  requestChips,
  isFetchingData,
  formValidation,
  player,
  tableState,
}) {
  const [requestedChipsAmount, setRequestedChipsAmount] = useState();

  function submitHandler(e) {
    e.preventDefault();
    return requestChips(requestedChipsAmount);
  }

  const chipStackAmount = tableState['players'].find(
    (p) => p && p.player_uuid === player.uuid,
  )['chip_stack_amount'];
  const minBuyInAmount = tableState.blinds[1] * tableState.min_buy_in;
  const maxBuyInAmount = tableState.blinds[1] * tableState.max_buy_in;

  const minDisplay = numberDisplay(
    Math.max(minBuyInAmount - chipStackAmount, 0) / 100,
    tableState.decimal_display,
  );
  const maxDisplay = numberDisplay(
    Math.min(maxBuyInAmount - chipStackAmount, globalChipRequestLimit) / 100,
    tableState.decimal_display,
  );
  const decimalScale = tableState.decimal_display ? 2 : 0;
  const requestedChipsAmountMaxLength = 6 + decimalScale + +!!decimalScale;

  return (
    <div
      className="modal add-chips-modal"
      onClick={() => {
        closeModal(false);
      }}
    >
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('addChipsModalTitle')}</h1>
        <h2 className="modal-subtitle">{t('addChipsModalSubTitle')}</h2>
        <form className="clubs-form" onSubmit={submitHandler}>
          <div className="validated-input-field">
            <NumberFormat
              className={`chip-amount ${formValidation.message ? 'error' : ''}`}
              placeholder={t('addChipsModalInputPlaceholder')}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={decimalScale}
              fixedDecimalScale="true"
              maxLength={requestedChipsAmountMaxLength}
              isAllowed={isFloatValuePositive}
              value={
                requestedChipsAmount === undefined
                  ? requestedChipsAmount
                  : requestedChipsAmount / 100
              }
              onChange={setNumberValue(setRequestedChipsAmount)}
            />
          </div>
          <div className="min-max-blinds">
            <p
              className={`min-blinds ${formValidation.message ? 'error' : ''}`}
            >
              {t('minAmountToRequest', minDisplay)}
            </p>
            <div className="flex-spacer" />
            <p
              className={`max-blinds ${formValidation.message ? 'error' : ''}`}
            >
              {t('maxAmountToRequest', maxDisplay)}
            </p>
          </div>
          <div className="flex-spacer" />
          <p className="chip-amount-annotation">
            {requestedChipsAmount && requestedChipsAmount >= 0
              ? t(
                  'chipAmountAfterApproval',
                  numberDisplay(
                    (chipStackAmount + requestedChipsAmount) / 100,
                    tableState.decimal_display,
                  ),
                )
              : t(
                  'currentChipAmountDisplay',
                  numberDisplay(
                    chipStackAmount / 100,
                    tableState.decimal_display,
                  ),
                )}
          </p>
          <button className="default-submit-button request-chips" type="submit">
            {isFetchingData ? (
              <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
            ) : (
              <p>
                {t(
                  'requestAmountOfChips',
                  requestedChipsAmount
                    ? numberDisplay(
                        requestedChipsAmount / 100,
                        tableState.decimal_display,
                      )
                    : '',
                )}
              </p>
            )}
          </button>
        </form>
        <h2
          className="close-modal"
          onClick={() => {
            closeModal(false);
          }}
        >
          X
        </h2>
      </div>
    </div>
  );
}
