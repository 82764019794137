import React, { useState } from 'react';
import Spinner from '../Spinner';
import LightModal from './LightModal';
import OTPFields from '../OTPFields';
import { t } from '../../i18n';

export default function UpdateEmailModal({
  closeModal,
  isFetchingData,
  formValidation,
  updateEmailStart,
  updateEmailConfirm,
}) {
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [activeContent, setActiveContent] = useState('field-input');
  const [codeResending, setCodeResending] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const submitUpdateEmailStart = async () => {
    const response = await updateEmailStart(email);
    if (response.data.status === 'ok') {
      setActiveContent('field-confirmation');
    }
    return response;
  };

  const submitUpdateEmailConfirm = async () => {
    const response = await updateEmailConfirm(email, otp);
    if (response.data.status === 'ok') {
      closeModal();
      setActiveContent('field-input');
      setEmail('');
    }
    return response;
  };

  const resendCode = async () => {
    setCodeResending(true);
    await submitUpdateEmailStart();
    setCodeResending(false);
    setCodeSent(true);
    setTimeout(() => {
      setCodeSent(false);
    }, 3000);
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (activeContent === 'field-input') {
      return submitUpdateEmailStart();
    }
    if (activeContent === 'field-confirmation') {
      return submitUpdateEmailConfirm();
    }
  };

  const modalTitle = {
    'field-input': t('updateEmail'),
    'field-confirmation': t('checkYourEmail'),
  }[activeContent];

  return (
    <LightModal title={modalTitle} closeModal={closeModal}>
      {activeContent === 'field-input' ? (
        <>
          <h2 className="modal-subtitle">
            {t('wellSendEmailConfirmationCode')}
          </h2>
          <form className="clubs-form" onSubmit={submitForm}>
            <div className="validated-input-field">
              <input
                className={
                  'email-input ' + (formValidation.email ? 'error' : '')
                }
                type="email"
                required
                placeholder="New email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {formValidation.email ? (
                <span className="form-validation">
                  {formValidation.email[0]}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="custom-spacer" style={{ height: 30 }} />
            <button
              className="button submit-button request-code-button"
              type="submit"
            >
              {isFetchingData ? (
                <Spinner
                  spinnerType="chase"
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                <p>{t('requestCode')}</p>
              )}
            </button>
          </form>
        </>
      ) : null}
      {activeContent === 'field-confirmation' ? (
        <>
          <h2 className="modal-subtitle">
            {t('weveSentConfirmationCodeToRecipient', email)}
          </h2>
          <form className="clubs-form" onSubmit={submitForm}>
            <OTPFields setValue={setOTP} error={formValidation.otp} />
            <span className="form-validation">
              {formValidation.otp ? formValidation.otp[0] : null}
            </span>
            {codeResending ? (
              <Spinner
                spinnerType="chase"
                invertedDots
                className="resend-code-spinner"
                style={{ width: 40, height: 40 }}
              />
            ) : codeSent ? (
              <p className="code-sent">{t('codeSent')}</p>
            ) : (
              <p className="resend-code" onClick={resendCode}>
                {t('resendCode')}
              </p>
            )}
            <button className="button submit-button" type="submit">
              {isFetchingData ? (
                <Spinner
                  spinnerType="chase"
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                <p>{t('updateEmail')}</p>
              )}
            </button>
          </form>
        </>
      ) : null}
    </LightModal>
  );
}
