import React from 'react';
import DarkModal from './DarkModal';
import TipTheDealerModalContent from './TipTheDealerModalContent';
import { t } from '../../i18n';

export default function TipTheDealerDarkModal({ closeModal }) {
  return (
    <DarkModal
      title={t('tipTheDealerTitle')}
      modalClassName="tip-the-dealer-modal"
      modalContentClassName="tip-the-dealer-modal-content-wrapper"
      closeModal={closeModal}
    >
      <TipTheDealerModalContent closeModal={closeModal} />
    </DarkModal>
  );
}
