import React from 'react';
import { t } from '../i18n';
import { userNameDisplay, numberDisplay } from '../poker/helpers';
import speakerIcon from '../assets/icons/speaker-icon.png';
import _ from 'lodash';

export default function RingHostTableActions({
  player,
  playerIsSittingAtTable,
  eventEmitter,
  tableState,
  lastTableStateProcessed,
  setActiveModalIdent,
  pauseGame,
  resumeGame,
  sitIn,
  sitOut,
}) {
  let processedLastHand =
    lastTableStateProcessed &&
    lastTableStateProcessed.last_hand &&
    lastTableStateProcessed.last_hand.uuid &&
    lastTableStateProcessed.last_hand.receipt;
  let tableStateLastHand =
    tableState.last_hand &&
    tableState.last_hand.uuid &&
    tableState.last_hand.receipt;

  let targetPlayer;
  if (
    lastTableStateProcessed &&
    lastTableStateProcessed.players &&
    !(
      _.includes(
        ['sit-out', 'sit-in', 'request-add-chips'],
        tableState.last_table_session_event[0],
      ) && tableState.last_event_type === 'table-session'
    )
  ) {
    targetPlayer = _.compact(lastTableStateProcessed.players).find(
      (p) => p.player_uuid === player.uuid,
    );
  } else {
    targetPlayer = _.compact(tableState.players).find(
      (p) => p.player_uuid === player.uuid,
    );
  }

  let displayAddChips = true;
  let displayLeaveTable = true;
  if (
    tableState.players.find((p) => p && p.player_uuid === player.uuid) &&
    tableState.players.find((p) => p && p.player_uuid === player.uuid)[
      'chip_stack_amount'
    ] >=
      tableState['blinds'][1] * tableState['max_buy_in']
  ) {
    displayAddChips = false;
  } else if (
    !tableState.players.find((p) => p && p.player_uuid === player.uuid)
  ) {
    displayAddChips = false;
    displayLeaveTable = false;
  }

  return (
    <div className="table-actions ring-host-table-actions">
      <div className="top-actions">
        {player && player.guest ? (
          <div
            className="signup-while-you-play default-submit-button"
            onClick={(e) => {
              e.stopPropagation();
              eventEmitter.emit('open-sidebar-if-closed', {});
            }}
          >
            <p>{t('tableSessionShowSignupWhileYouPlay')}</p>
          </div>
        ) : (
          <></>
        )}

        {processedLastHand ? (
          <a
            className="action review-hand"
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.PUBLIC_URL}/hand-analyzer/${lastTableStateProcessed.last_hand.uuid}`}
          >
            <p className="action review-hand">
              <i className="icofont-loop" />{' '}
              {`Review hand: ${userNameDisplay(
                lastTableStateProcessed.last_hand.receipt.winners[0]
                  .player_user_name,
              )} (+${numberDisplay(
                lastTableStateProcessed.last_hand.receipt.winners[0]
                  .chip_delta / 100,
                lastTableStateProcessed.decimal_display,
              )})`}
            </p>
          </a>
        ) : tableStateLastHand ? (
          <a
            className="action review-hand"
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.PUBLIC_URL}/hand-analyzer/${tableState.last_hand.uuid}`}
          >
            <p className="action review-hand">
              <i className="icofont-loop" />{' '}
              {`Review hand: ${userNameDisplay(
                tableState.last_hand.receipt.winners[0].player_user_name,
              )} (+${numberDisplay(
                tableState.last_hand.receipt.winners[0].chip_delta / 100,
                tableState.decimal_display,
              )})`}
            </p>
          </a>
        ) : (
          <p className="action disabled review-hand">
            <i className="icofont-loop" /> {t('replayLastHand')}
          </p>
        )}
        {/* {tableState && tableState.active_hand_state ? (<p className='action'>{`Canonical nonce: ${tableState.active_hand_state.nonce}`}</p>) : (<></>) }
        {lastTableStateProcessed && lastTableStateProcessed.active_hand_state ? (<p className='action'>{`Rendered nonce: ${lastTableStateProcessed.active_hand_state.nonce}`}</p>) : (<></>) } */}
      </div>
      <div className="flex-spacer" />
      <div className="bottom-actions">
        <p
          onClick={() => {
            setActiveModalIdent('sounds');
          }}
          className={`action`}
        >
          <img src={speakerIcon} className="icon speaker-icon" alt="speaker" />{' '}
          {t('sounds')}
        </p>
        <p
          onClick={() => {
            setActiveModalIdent('add-chips');
          }}
          className={`action ${displayAddChips ? '' : 'disabled'}`}
        >
          <i className="icofont-plus" /> {t('addChips')}
        </p>
        {targetPlayer && targetPlayer.table_status === 'sitting_out' ? (
          <p onClick={sitIn} className="action highlight">
            <i className="icofont-ui-play" /> {t('sitInNextHand')}
          </p>
        ) : targetPlayer ? (
          <p onClick={sitOut} className="action">
            <i className="icofont-ui-pause" /> {t('sitMeOut')}
          </p>
        ) : (
          <></>
        )}
        <p
          onClick={() => {
            setActiveModalIdent('leave-table');
          }}
          className={`action ${displayLeaveTable ? '' : 'disabled'}`}
        >
          <i className="icofont-exit" /> {t('leaveTable')}
        </p>
        <p
          onClick={() => {
            setActiveModalIdent('host-settings');
          }}
          className="action"
        >
          <i className="icofont-settings" /> {t('hostSettingsTableAction')}
        </p>
        {tableState.mode === 'paused' ? (
          <p onClick={resumeGame} className="action highlight">
            <i className="icofont-ui-play" /> {t('resumeGame')}
          </p>
        ) : (
          <p
            onClick={pauseGame}
            className={
              tableState.mode === 'pending-start' ? 'action disabled' : 'action'
            }
          >
            <i className="icofont-ui-pause" /> {t('pauseGame')}
          </p>
        )}
        <p
          onClick={() => {
            setActiveModalIdent('finish-game');
          }}
          className={
            _.includes(['pending-start', 'pending-complete'], tableState.mode)
              ? 'action disabled'
              : 'action'
          }
        >
          <i className="icofont-close" /> {t('finishGame')}
        </p>
      </div>
    </div>
  );
}
