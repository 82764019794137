import React from 'react';
import Dropdown from 'react-dropdown';
import Spinner from '../Spinner';
import caratDown from '../../assets/icons/carat-down.png';
import { tournamentDurationDisplay } from '../../poker/helpers';

export default function TournamentCreateFormQuick({
  shouldAnimate,
  createTournament,
  isFetchingData,
  toggleActiveForm,
}) {
  const [estimatedNumberOfPlayers, setEstimatedNumberOfPlayers] =
    React.useState({ value: '10', label: '10 players' });
  const [estimatedTournamentDuration, setEstimatedTournamentDuration] =
    React.useState('7200');

  const estimatedNumberOfPlayersRef = React.useRef(estimatedNumberOfPlayers);
  const estimatedTournamentDurationRef = React.useRef(
    estimatedTournamentDuration,
  );

  React.useEffect(() => {
    estimatedNumberOfPlayersRef.current = estimatedNumberOfPlayers;
  }, [estimatedNumberOfPlayers]);
  React.useEffect(() => {
    estimatedTournamentDurationRef.current = estimatedTournamentDuration;
  }, [estimatedTournamentDuration]);

  const estimatedNumberOfPlayersOptions = [
    { value: '2', label: '2 players' },
    { value: '3', label: '3 players' },
    { value: '4', label: '4 players' },
    { value: '5', label: '5 players' },
    { value: '6', label: '6 players' },
    { value: '7', label: '7 players' },
    { value: '8', label: '8 players' },
    { value: '9', label: '9 players' },
    { value: '10', label: '10 players' },
  ];

  const selectEstimatedNumberOfPlayers = (data) => {
    setEstimatedNumberOfPlayers(data);
  };

  const selectEstimatedTournamentDuration = (event) => {
    setEstimatedTournamentDuration(event.target.value);
  };

  const handleToggleForm = (event) => {
    toggleActiveForm('advanced');
  };

  const submitForm = () => {
    createTournament({
      configType: 'quick',
      estimatedNumberOfPlayers: estimatedNumberOfPlayersRef.current.value,
      estimatedTournamentDuration: estimatedTournamentDurationRef.current,
    });
  };

  return (
    <div
      className={`clubs-form tournament-create-form-quick ${
        shouldAnimate ? 'should-animate' : ''
      }`}
    >
      <h4 className="form-title">Quick setup</h4>
      <div className="form-section estimated-number-of-players-section">
        <p>About how many players?</p>
        <Dropdown
          required
          className="select-field estimated-number-of-players"
          value={estimatedNumberOfPlayers}
          options={estimatedNumberOfPlayersOptions}
          onChange={selectEstimatedNumberOfPlayers}
          placeholder="Estimated number of players"
        />
      </div>
      <div className="form-section estimated-tournament-duration-label-section">
        <p>How long do you want to play for?</p>
      </div>
      <div className="form-section estimated-tournament-duration-section">
        <input
          type="range"
          className="tournament-duration-slider"
          min="1800"
          max="14400"
          step="1800"
          value={estimatedTournamentDuration}
          onChange={selectEstimatedTournamentDuration}
        />
        <p>{tournamentDurationDisplay(estimatedTournamentDuration)}</p>
      </div>
      <div className="submit-button" onClick={submitForm}>
        {isFetchingData ? (
          <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
        ) : (
          <p className="submit-button-text">Create game</p>
        )}
      </div>
      <div className="toggle-active-form" onClick={handleToggleForm}>
        <img
          src={caratDown}
          className="toggle-active-form-icon"
          alt="arrow pointing down"
        />
        <p className="toggle-active-form-text">Advanced setup</p>
      </div>
    </div>
  );
}
