import axios from 'axios';

const endpoint = process.env.REACT_APP_API_ENDPOINT;
const client = axios.create({
  baseURL: endpoint,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});
export const trackingClient = axios.create({
  baseURL: [endpoint, 'kombucha'].join('/'),
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});

const sendSQSMessage = (
  producer,
  tableSessionUUID,
  tableStateNonce,
  eventData,
  creatorUUID,
  handUUID = null,
) => {
  // Stop trying to multi-send fisherman-fold/check events
  const eventType = handUUID ? 'hand' : 'table';
  const messageBody = JSON.stringify({
    table_session_uuid: tableSessionUUID,
    table_state_nonce: tableStateNonce,
    event_data: eventData,
    event_type: eventType,
    hand_uuid: handUUID,
    creator_uuid: creatorUUID,
  });
  return producer.send({
    message_group_id: tableSessionUUID,
    message_body: messageBody,
  });
};

const sendTournamentSQSMessage = (
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  eventData,
  creatorUUID,
) => {
  const eventType = 'tournament';
  const messageBody = JSON.stringify({
    tournament_uuid: tournamentUUID,
    tournament_nonce: tournamentNonce,
    event_data: eventData,
    event_type: eventType,
    creator_uuid: creatorUUID,
  });
  return producer.send({
    message_group_id: tableSessionUUID,
    message_body: messageBody,
  });
};

// Queries
// =====================
export function TOURNAMENTS_SHOW({ tournamentUUID }) {
  return client.get(`/tournaments/${tournamentUUID}`);
}

export function TABLE_SESSION_STATE({ uuid, jwt }) {
  return client.get(`/table-sessions/${uuid}/state`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });
}

export function TABLE_SESSION_SUMMARY({ tableSessionUUID, playerUUID, jwt }) {
  return client.get(
    `/table-sessions/${tableSessionUUID}/summary/${playerUUID}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

export function TOURNAMENT_SUMMARY({ tournamentUUID, playerUUID, jwt }) {
  return client.get(`/tournaments/${tournamentUUID}/summary/${playerUUID}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });
}

export function POKER_HAND_ANALYZER({ uuid, jwt }) {
  return client.get(`/poker-hands/${uuid}/analyzer`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });
}

// Players
export function PLAYER_LOBBY({ uuid, jwt }) {
  return client.get(`/players/${uuid}/lobby`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });
}

export function PLAYER_ACCOUNT({ uuid, jwt }) {
  return client.get(`/players/${uuid}/account`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });
}

export function PLAYER_ACTIVE_TABLE_SESSIONS({ uuid, jwt }) {
  return client.get(`/players/${uuid}/active_table_sessions`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });
}

// Active game management

export function CLOSE_ACTIVE_TABLE({ uuid, jwt }) {
  return client.post(
    '/table-sessions/close-table',
    { uuid },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

export function ARCHIVE_ACTIVE_TABLE({ uuid, jwt }) {
  return client.post(
    '/table-sessions/archive-table',
    { uuid },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

// Ring Games
export function RING_GAME_INFO({ tableSessionUUID }) {
  return client.get(`/table-sessions/${tableSessionUUID}/info`);
}

// Tournaments
export function TOURNAMENT_CONFIG({ tournamentUUID }) {
  return client.get(`/tournaments/${tournamentUUID}/config`);
}

export function TOURNAMENT_INFO({ tournamentUUID, playerUUID }) {
  return client.get(`/tournaments/${tournamentUUID}/info/${playerUUID}`);
}

export function TOURNAMENT_PLAYER_STANDINGS({ tournamentUUID }) {
  return client.get(`/tournaments/${tournamentUUID}/player-standings`);
}

// Table Sessions
export function TABLE_SESSION_CONFIG({ tableSessionUUID }) {
  return client.get(`/table-sessions/${tableSessionUUID}/config`);
}

// Multi Table Events
export function MULTI_TABLE_EVENT({ uuid }) {
  return client.get(`/multi-table-events/${uuid}`);
}

// Commands
// =====================

// POKER ROOMS
export function ADJUST_CHIPS({
  targetPlayerUUID,
  roomUUID,
  amount,
  sourcePlayerUUID,
}) {
  return client.post(`/poker-rooms/adjust-chips`, {
    targetPlayerUUID,
    roomUUID,
    amount,
    sourcePlayerUUID,
  });
}

// TOURNAMENTS
export function CREATE_TOURNAMENT_WITH_CONFIG({
  playerUUID,
  configType,
  estimatedNumberOfPlayers,
  estimatedTournamentDuration,
  startingStackSize,
  levelDuration,
  shotClock,
  antes,
  rebuys,
  gameVariant,
}) {
  return client.post(`/tournaments/create`, {
    playerUUID,
    configType,
    estimatedNumberOfPlayers,
    estimatedTournamentDuration,
    startingStackSize,
    levelDuration,
    shotClock,
    antes,
    rebuys,
    gameVariant,
  });
}

export function REQUEST_JOIN_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  creatorUUID,
  creatorUserName,
}) {
  let eventData = [
    'request-join-tournament',
    { player_uuid: creatorUUID, player_user_name: creatorUserName },
  ];
  sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-tournament-sessions/request-join`, {
    playerUUID: creatorUUID,
    tournamentUUID,
  });
}

export function REJECT_REQUEST_JOIN_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  requestIndex,
  creatorUUID,
}) {
  let eventData = [
    'reject-request-join-tournament',
    { request_index: requestIndex },
  ];
  sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-tournament-sessions/reject-request-join`, {
    tournamentUUID,
    requestIndex,
  });
}

export function APPROVE_REQUEST_JOIN_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  requestIndex,
  playerUUID,
  creatorUUID,
}) {
  let eventData = [
    'approve-request-join-tournament',
    { request_index: requestIndex },
  ];
  sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-tournament-sessions/approve-request-join`, {
    requestIndex,
    tournamentUUID,
    playerUUID,
  });
}

export function START_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  creatorUUID,
}) {
  let eventData = ['start-tournament', {}];
  sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/tournaments/start-tournament`, { tournamentUUID });
}

export function REQUEST_REBUY_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  creatorUUID,
  creatorUserName,
}) {
  let eventData = [
    'request-rebuy-tournament',
    { player_uuid: creatorUUID, player_user_name: creatorUserName },
  ];
  sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-tournament-sessions/request-rebuy`, {
    tournamentUUID,
    playerUUID: creatorUUID,
  });
}

export function REJECT_REQUEST_REBUY_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  requestIndex,
  creatorUUID,
}) {
  let eventData = [
    'reject-request-rebuy-tournament',
    { request_index: requestIndex },
  ];
  sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-tournament-sessions/reject-request-rebuy`, {
    tournamentUUID,
  });
}

export function APPROVE_REQUEST_REBUY_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  requestIndex,
  creatorUUID,
}) {
  let eventData = [
    'approve-request-rebuy-tournament',
    { request_index: requestIndex },
  ];
  sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-tournament-sessions/approve-request-rebuy`, {
    tournamentUUID,
  });
}

export function SIT_OUT_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  creatorUUID,
}) {
  let eventData = ['sit-out-tournament', { player_uuid: creatorUUID }];
  return sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
}

export function SIT_IN_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  creatorUUID,
}) {
  let eventData = ['sit-in-tournament', { player_uuid: creatorUUID }];
  return sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
}

export function PAUSE_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  creatorUUID,
}) {
  let eventData = ['pause-tournament', {}];
  return sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
}

export function RESUME_TOURNAMENT({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  creatorUUID,
}) {
  let eventData = ['resume-tournament', {}];
  return sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
}

export function UPDATE_TOURNAMENT_CONFIG({
  producer,
  tournamentUUID,
  tableSessionUUID,
  tournamentNonce,
  tournamentConfig,
  creatorUUID,
}) {
  let eventData = ['update-config', tournamentConfig];
  return sendTournamentSQSMessage(
    producer,
    tournamentUUID,
    tableSessionUUID,
    tournamentNonce,
    eventData,
    creatorUUID,
  );
}

// POKER HANDS
export function PLAYER_ACTION({
  producer,
  tableSessionUUID,
  tableStateNonce,
  handUUID,
  eventSequenceName,
  eventSequenceData,
  creatorUUID,
}) {
  let eventData = [eventSequenceName, eventSequenceData];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
    handUUID,
  );
}

export function FISHERMAN_FOLD({
  producer,
  tableSessionUUID,
  tableStateNonce,
  eventSequenceData,
  handUUID,
  creatorUUID,
}) {
  let eventData = ['fold', eventSequenceData];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
    handUUID,
  );
}

export function FISHERMAN_CHECK({
  producer,
  tableSessionUUID,
  tableStateNonce,
  eventSequenceData,
  handUUID,
  creatorUUID,
}) {
  let eventData = ['check', eventSequenceData];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
    handUUID,
  );
}

// TABLE SESSIONS
export function CREATE_TABLE_SESSION_WITH_CONFIG({
  blinds,
  gameVariant,
  startingChips,
  playerUUID,
  setupMode,
  minBuyIn,
  maxBuyIn,
  shotClock,
  showdownReveal,
}) {
  return client.post(`/table-sessions/create`, {
    blinds,
    gameVariant,
    startingChips,
    playerUUID,
    setupMode,
    minBuyIn,
    maxBuyIn,
    shotClock,
    showdownReveal,
  });
}

export function DEAL_CARDS({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
}) {
  let eventData = ['deal-cards', {}];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/table-sessions/deal-cards`, { tableSessionUUID });
}

export function PAUSE_GAME({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
}) {
  let eventData = ['pause-game', {}];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/table-sessions/pause-game`, { tableSessionUUID });
}

export function RESUME_GAME({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
}) {
  let eventData = ['resume-game', {}];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/table-sessions/resume-game`, { tableSessionUUID });
}

export function FINISH_GAME({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
}) {
  let eventData = ['finish-game', {}];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/table-sessions/finish-game`, { tableSessionUUID });
}

export function UPDATE_TABLE_SESSION_CONFIG({
  producer,
  tableSessionUUID,
  tableStateNonce,
  tableSessionConfig,
  creatorUUID,
}) {
  let nextBlinds = [
    Number(tableSessionConfig.blinds.split('-')[0]),
    Number(tableSessionConfig.blinds.split('-')[1]),
  ];
  let nextShotClock = tableSessionConfig.shot_clock;
  let nextMinBuyIn = tableSessionConfig.min_buy_in;
  let nextMaxBuyIn = tableSessionConfig.max_buy_in;
  let nextShowdownReveal = tableSessionConfig.showdown_reveal;
  let nextHostUUID = tableSessionConfig.host_uuid;
  let nextHostDisplay = tableSessionConfig.host_display;
  let nextNewPlayerJoinWithoutPosting =
    tableSessionConfig.new_player_join_without_posting;
  let eventData = [
    'update-config',
    {
      blinds: nextBlinds,
      shot_clock: nextShotClock,
      min_buy_in: nextMinBuyIn,
      max_buy_in: nextMaxBuyIn,
      showdown_reveal: nextShowdownReveal,
      host_uuid: nextHostUUID,
      host_display: nextHostDisplay,
      new_player_join_without_posting: nextNewPlayerJoinWithoutPosting,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/table-sessions/update-config`, {
    tableSessionUUID,
    tableSessionConfig,
  });
}

export function INIT_CHAT_ROOM({
  producer,
  tableSessionUUID,
  tableStateNonce,
  chatRoomConfig,
  creatorUUID,
}) {
  const eventData = ['init-chat-room', { new_values: chatRoomConfig }];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  // TODO: tracking
}

export function END_CHAT_ROOM({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
}) {
  const eventData = ['end-chat-room', {}];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  // TODO: tracking
}

export function UPDATE_CHAT_ROOM_CONFIG({
  producer,
  tableSessionUUID,
  tableStateNonce,
  chatRoomConfig,
  creatorUUID,
}) {
  const eventData = ['update-chat-room-config', { new_values: chatRoomConfig }];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  // TODO: tracking
}

// PLAYER TABLE SESSIONS
export function RESERVE_SEAT({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
  creatorUserName,
  seatIndex,
}) {
  let eventData = [
    'reserve-seat',
    {
      player_uuid: creatorUUID,
      player_user_name: creatorUserName,
      seat_number: seatIndex,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/reserve-seat`, {
    playerUUID: creatorUUID,
    tableSessionUUID,
    creatorUserName,
    seatIndex,
  });
}

export function UNRESERVE_SEAT({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
}) {
  let eventData = [
    'unreserve-seat',
    {
      player_uuid: creatorUUID,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  // return client.post(`/player-table-sessions/unreserve-seat`, {playerUUID, tableSessionUUID});
}

export function REQUEST_JOIN_TABLE_WITH_CHIPS({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
  chipAmount,
}) {
  let eventData = [
    'request-join',
    {
      player_uuid: creatorUUID,
      chip_amount: chipAmount,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/request-join`, {
    playerUUID: creatorUUID,
    tableSessionUUID,
    chipAmount,
  });
}

export function REJECT_REQUEST_JOIN_TABLE_WITH_CHIPS({
  producer,
  tableSessionUUID,
  tableStateNonce,
  requestIndex,
  playerTableSessionUUID,
  creatorUUID,
}) {
  let eventData = [
    'reject-request-join',
    {
      request_index: requestIndex,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/reject-request-join`, {
    requestIndex,
    playerTableSessionUUID,
  });
}

export function APPROVE_REQUEST_JOIN_TABLE_WITH_CHIPS({
  producer,
  tableSessionUUID,
  tableStateNonce,
  requestIndex,
  playerTableSessionUUID,
  creatorUUID,
}) {
  let eventData = [
    'approve-request-join',
    {
      request_index: requestIndex,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/approve-request-join`, {
    requestIndex,
    playerTableSessionUUID,
  });
}

export function REQUEST_ADD_CHIPS({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
  chipAmount,
}) {
  let eventData = [
    'request-add-chips',
    {
      player_uuid: creatorUUID,
      chip_amount: chipAmount,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/request-add-chips`, {
    playerUUID: creatorUUID,
    tableSessionUUID,
    chipAmount,
  });
}

export function REJECT_REQUEST_ADD_CHIPS({
  producer,
  tableSessionUUID,
  tableStateNonce,
  requestIndex,
  creatorUUID,
}) {
  let eventData = [
    'reject-request-add-chips',
    {
      request_index: requestIndex,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/reject-request-add-chips`, {
    requestIndex,
    tableSessionUUID,
  });
}

export function APPROVE_REQUEST_ADD_CHIPS({
  producer,
  tableSessionUUID,
  tableStateNonce,
  requestIndex,
  creatorUUID,
}) {
  let eventData = [
    'approve-request-add-chips',
    {
      request_index: requestIndex,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/approve-request-add-chips`, {
    requestIndex,
    tableSessionUUID,
  });
}

export function UPDATE_CHIPS({
  producer,
  tableSessionUUID,
  tableStateNonce,
  playerUUID,
  chipDelta,
  creatorUUID,
}) {
  let eventData = [
    'update-chips',
    {
      player_uuid: playerUUID,
      chip_delta: chipDelta,
    },
  ];
  return sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
}

export function SIT_OUT({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
}) {
  let eventData = [
    'sit-out',
    {
      player_uuid: creatorUUID,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/sit-out`, {
    playerUUID: creatorUUID,
    tableSessionUUID,
  });
}

export function SIT_IN({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
}) {
  let eventData = [
    'sit-in',
    {
      player_uuid: creatorUUID,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/sit-in`, {
    playerUUID: creatorUUID,
    tableSessionUUID,
  });
}

export function LEAVE_TABLE({
  producer,
  tableSessionUUID,
  tableStateNonce,
  creatorUUID,
  nextHostUUID,
}) {
  let eventData = [
    'leave-table-session',
    {
      player_uuid: creatorUUID,
      next_host_uuid: nextHostUUID,
    },
  ];
  sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
  return client.post(`/player-table-sessions/leave`, {
    playerUUID: creatorUUID,
    tableSessionUUID,
  });
}

export function REMOVE_PLAYER({
  producer,
  tableSessionUUID,
  tableStateNonce,
  playerUUID,
  creatorUUID,
}) {
  let eventData = [
    'remove-player',
    {
      player_uuid: playerUUID,
    },
  ];
  return sendSQSMessage(
    producer,
    tableSessionUUID,
    tableStateNonce,
    eventData,
    creatorUUID,
  );
}

// PLAYERS
export function UPDATE_PLAYER_ACCOUNT({ userName, jwt }) {
  return client.post(
    `/players/update-account`,
    {
      user_name: userName,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

export function CREATE_GUEST() {
  return client.post(`/guests/create`);
}

export function SIGNUP_GUEST({
  guestUUID,
  userName,
  email,
  phoneCountryCode,
  phoneNumber,
  referralUUID,
}) {
  return client.post(`/players/signup-guest`, {
    guestUUID,
    userName,
    email,
    phoneCountryCode,
    phoneNumber,
    referralUUID,
  });
}

// AUTH
export function CREATE_PLAYER({
  userName,
  email,
  phoneNumber,
  countryCode,
  referralUUID,
  ip,
}) {
  return client.post(`/players/create`, {
    userName,
    email,
    phoneNumber,
    countryCode,
    referralUUID,
    ip,
  });
}

export function CREATE_PLAYER_WITH_MEMBERSHIP({
  firstName,
  lastName,
  userName,
  email,
  password,
  passwordConfirmation,
  roomSlug,
}) {
  return client.post(`/players/create-with-membership`, {
    firstName,
    lastName,
    userName,
    email,
    password,
    passwordConfirmation,
    roomSlug,
  });
}

export function PLAYER_SIGNUP_INIT({
  userName,
  email,
  guestUUID,
  referralUUID,
}) {
  return client.post(`/auth/signup/init`, {
    user_name: userName,
    email,
    guestUUID,
    referralUUID,
  });
}

export function PLAYER_SIGNUP_CONFIRM({ email, otp }) {
  return client.post(`/auth/signup/confirm`, {
    email,
    otp,
  });
}

export function PLAYER_SIGNIN_INIT({ email, phone }) {
  const { phoneCountryCode, phoneNumber } = phone || {};
  return client.post(`/auth/signin/init`, {
    email,
    phone: phone
      ? {
          phone_country_code: phoneCountryCode,
          phone_number: phoneNumber,
        }
      : undefined,
  });
}

export function PLAYER_SIGNIN_OTP({ email, phone, otp }) {
  const { phoneCountryCode, phoneNumber } = phone || {};
  return client.post(`/auth/signin/otp`, {
    email,
    phone: phone
      ? {
          phone_country_code: phoneCountryCode,
          phone_number: phoneNumber,
        }
      : undefined,
    otp,
  });
}

export function UPDATE_EMAIL_START({ jwt, email }) {
  return client.post(
    `/players/update-account/email/start`,
    {
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

export function UPDATE_EMAIL_CONFIRM({ jwt, email, otp }) {
  return client.post(
    `/players/update-account/email/confirm`,
    {
      email,
      otp,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

export function UPDATE_PHONE_START({ jwt, phone }) {
  const { phoneCountryCode, phoneNumber } = phone || {};
  return client.post(
    `/players/update-account/phone/start`,
    {
      phone: phone
        ? {
            phone_country_code: phoneCountryCode,
            phone_number: phoneNumber,
          }
        : undefined,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

export function UPDATE_PHONE_CONFIRM({ jwt, phone, otp }) {
  const { phoneCountryCode, phoneNumber } = phone || {};
  return client.post(
    `/players/update-account/phone/confirm`,
    {
      phone: phone
        ? {
            phone_country_code: phoneCountryCode,
            phone_number: phoneNumber,
          }
        : undefined,
      otp,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

export function PLAYER_REQUEST_AUTH_CODE({ phoneCountryCode, phoneNumber }) {
  return client.post(`/players/request-auth-code`, {
    phoneCountryCode,
    phoneNumber,
  });
}

export function RESEND_PHONE_CONFIRMATION_CODE({ playerUUID }) {
  return client.post(`/players/resend-phone-confirmation-code`, {
    playerUUID,
  });
}

export function VERIFY_PHONE_NUMBER({ playerUUID, code }) {
  return client.post(`/players/verify-phone-number`, {
    playerUUID,
    code,
  });
}

export function INIT_PAYMENT({ jwt, tipAmount }) {
  return client.post(
    '/payments/init',
    { tip_amount: tipAmount },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    },
  );
}

// . NOT CURRENTLY BEING USED -> legacy
export function CREATE_HAND({ tableUUID }) {
  return client.post(`/poker-tables/create-hand`, { tableUUID });
}

// . NOT CURRENTLY BEING USED -> transitioned to websockets, keeping this route as a reminder for now
export function SEND_HAND_EVENT_SEQUENCE({
  handUUID,
  eventSequenceName,
  eventSequenceData,
}) {
  return client.post(`/poker-hands/send-event-sequence`, {
    handUUID,
    eventSequenceName,
    eventSequenceData,
  });
}
