import React from 'react';
import { PlayerContext } from '../contexts/PlayerContext';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import { useHistory, useParams } from 'react-router-dom';
import logo from '../assets/brand/logo-unicolor.png';
import chevronRight from '../assets/icons/chevron-right.png';
import { numberDisplay, userNameDisplay } from '../poker/helpers';
import SidebarFooter from './SidebarFooter';
import AnalyticsService from '../services/AnalyticsService';
import CARDS from '../poker/cards';
import { t } from '../i18n';

export default function HandAnalyzerSidebar({
  startClosed = true,
  isCollapsible = true,
  isExpandable = true,
  tableSessionHands,
  handleHandChange,
}) {
  let history = useHistory();
  let slugID = useParams();

  const eventEmitter = React.useContext(EventEmitterContext);
  const { player } = React.useContext(PlayerContext);
  const [isClosed, setIsClosed] = React.useState(startClosed);

  var classString = 'collapsible-sidebar';
  if (isClosed) {
    classString += ' is-closed';
  }

  const toggleSidebar = () => {
    if (isClosed && isExpandable) {
      setIsClosed(false);
      AnalyticsService.opensSidebarEvent(player);
    } else {
      setIsClosed(true);
    }
  };

  React.useEffect(() => {
    const closeSidebar = () => {
      if (!isClosed && isCollapsible) {
        setIsClosed(true);
      }
    };
    eventEmitter.on('close-sidebar-if-open', closeSidebar);
    return () => eventEmitter.off('close-sidebar-if-open', closeSidebar);
  });

  React.useEffect(() => {
    const openSidebar = () => {
      if (isClosed && isExpandable) {
        setIsClosed(false);
      }
    };
    eventEmitter.on('open-sidebar-if-closed', openSidebar);
    return () => eventEmitter.off('open-sidebar-if-closed', openSidebar);
  });

  const SidebarContent = isClosed ? (
    <>
      <img
        className="brand logo"
        src={logo}
        alt="clubs-poker"
        onClick={() => {
          history.push('/lobby');
        }}
      />
      {isExpandable ? (
        <div className="expand" onClick={toggleSidebar}>
          <img src={chevronRight} alt="expand-sidebar" />
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <>
      <div
        className="brand"
        onClick={() => {
          history.push('/lobby');
        }}
      >
        <img className="logo" src={logo} alt="clubs-poker" />
        <p className="clubs">
          <span className="bold">Clubs</span> poker
        </p>
        <p className="now">now</p>
      </div>
      <div className="expand" onClick={toggleSidebar}>
        <img src={chevronRight} alt="expand-sidebar" />
      </div>
      <div className="nav-items hand-analyzer-sidebar">
        <h2>
          {tableSessionHands.length}{' '}
          {tableSessionHands.length > 1 ? t('handsPlayed') : t('handPlayed')}
        </h2>
        {tableSessionHands.map((hand, index) => (
          <div
            className={`hand-row ${hand.uuid === slugID.uuid ? 'active' : ''}`}
            key={`hand-row-${index}`}
            onClick={() =>
              hand.uuid !== slugID.uuid && handleHandChange(hand.uuid)
            }
          >
            <div className="hand-info">
              <p className="hand-count">{`#${
                tableSessionHands.length - index
              }`}</p>
              {hand.cards.length > 0 ? (
                <div className="cards">
                  <img
                    src={
                      CARDS.find(
                        (card) =>
                          card.rank === hand.cards[0].rank &&
                          card.suit === hand.cards[0].suit,
                      ).img
                    }
                    alt={`${hand.cards[0].rank} of ${hand.cards[0].suit}`}
                  />
                  <img
                    src={
                      CARDS.find(
                        (card) =>
                          card.rank === hand.cards[1].rank &&
                          card.suit === hand.cards[1].suit,
                      ).img
                    }
                    alt={`${hand.cards[1].rank} of ${hand.cards[1].suit}`}
                  />
                </div>
              ) : (
                <div className="cards not-available">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/card-back.png`}
                    alt="card back"
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/card-back.png`}
                    alt="card back"
                  />
                </div>
              )}
            </div>
            <div className="meta-info">
              <p>
                {userNameDisplay(hand.receipt.winners[0].player_user_name, 16)}
              </p>
              <p>
                {t('pot')}:{' '}
                {numberDisplay(hand.pot_size / 100, hand.decimal_display)}
              </p>
            </div>
          </div>
        ))}
        <div className="flex-spacer" />
        <SidebarFooter player={player} />
      </div>
    </>
  );

  return <div className={classString}>{SidebarContent}</div>;
}
