import React from 'react';
import _ from 'lodash';
import CARDS from '../poker/cards';
import THEME from '../styles/themes/PokerTableTheme';
import { numberDisplay, userNameDisplay } from '../poker/helpers';
import { calculateTableDimensions } from '../poker-table/PokerTableDimensions';
import './RoundRect';
import { drawPlayerRunout } from './poker-table/PlayerRunout';

const CONTROLS_HEIGHT = 50;

let tableImage = new Image();
tableImage.src = `${process.env.PUBLIC_URL}/assets/table-replayer.png`;

let loadedCardBack = new Image();
loadedCardBack.src = `${process.env.PUBLIC_URL}/assets/card-back.png`;

let betDisk1 = new Image();
betDisk1.src = `${process.env.PUBLIC_URL}/assets/bet-disk-1.svg`;
let betDisk2 = new Image();
betDisk2.src = `${process.env.PUBLIC_URL}/assets/bet-disk-2.svg`;
let betDisk3 = new Image();
betDisk3.src = `${process.env.PUBLIC_URL}/assets/bet-disk-3.png`;

let potDiskImg = new Image();
potDiskImg.src = `${process.env.PUBLIC_URL}/assets/pot-disk-90.svg`;
let dealerButton = new Image();
dealerButton.src = `${process.env.PUBLIC_URL}/assets/dealer-button-v2.png`;

let loadedCardImagesCounter = 0;

let loadedCardImages = [];
loadedCardImages = CARDS.map((card) => {
  const loadedImg = new Image();
  loadedImg.onload = () => {
    loadedCardImagesCounter++;
  };
  loadedImg.src = card.img;
  return {
    suit: card.suit,
    rank: card.rank,
    img: loadedImg,
  };
});

let blankPeekCard = new Image();
blankPeekCard.src = `${process.env.PUBLIC_URL}/assets/blank-peek.svg`;
let flopPeekCard = new Image();
flopPeekCard.src = `${process.env.PUBLIC_URL}/assets/flop-peek.svg`;
let turnPeekCard = new Image();
turnPeekCard.src = `${process.env.PUBLIC_URL}/assets/turn-peek.svg`;
let riverPeekCard = new Image();
riverPeekCard.src = `${process.env.PUBLIC_URL}/assets/river-peek.svg`;

const DEG = Math.PI / 180;

function calculatePlayerCoordinates(numberOfSeats, dimensions) {
  if (numberOfSeats === 10) {
    let angles0 = { a: 50.15, b: 82.52, c: 47.33 };
    let angles1 = { a: 29.76, b: 108.88, c: 41.36, gx: 39.67 };
    let angles2 = { a: 21.56, b: 80.72, c: 77.72, gx: 9.63 };
    let angles3 = { a: 27.96, b: 40.27, c: 111.77, gx: 12.04 };
    let angles4 = { gx: 40.095 };
    let side0c1 =
      dimensions.tableHeight / 2 +
      dimensions.playerBoxHeight / 2 +
      0.25 * dimensions.playerBoxHeight;
    let side0c2 =
      dimensions.tableHeight / 2 +
      dimensions.playerBoxHeight / 2 +
      0.083 * dimensions.playerBoxHeight;
    let side0b =
      (side0c2 * Math.sin(angles0.b * DEG)) / Math.sin(angles0.c * DEG);
    let side1b =
      (side0b * Math.sin(angles1.b * DEG)) / Math.sin(angles1.c * DEG);
    let side2b =
      (side1b * Math.sin(angles2.b * DEG)) / Math.sin(angles2.c * DEG);
    let side3b =
      (side2b * Math.sin(angles3.b * DEG)) / Math.sin(angles3.c * DEG);
    return [
      { x: dimensions.canvasCenterX, y: dimensions.canvasCenterY + side0c1 },
      {
        x: dimensions.canvasCenterX - side0b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles1.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side1b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side2b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side2b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side3b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX,
        y:
          dimensions.canvasCenterY -
          dimensions.tableHeight / 2 -
          dimensions.playerBoxHeight / 2 +
          0.216 * dimensions.playerBoxHeight,
      },
      {
        x: dimensions.canvasCenterX + side3b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side2b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side2b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side1b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side0b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles1.gx * DEG),
      },
    ];
  }
}
function calculateBettingDiskCoordinates(numberOfSeats, dimensions) {
  if (numberOfSeats === 10) {
    let angles0 = { a: 66.52, b: 84.48, c: 29.0 };
    let angles1 = { a: 12.9, b: 140.36, c: 26.74, gx: 23.025 };
    let angles2 = { a: 22.96, b: 78.23, c: 78.81, gx: 10.282 };
    let angles3 = { a: 19.31, b: 43.19, c: 117.5, gx: 12.84 };
    let angles4 = { gx: 32.03 };
    let side0c = 0.301 * dimensions.tableHeight;
    let side0b =
      (side0c * Math.sin(angles0.b * DEG)) / Math.sin(angles0.c * DEG);
    let side1b =
      (side0b * Math.sin(angles1.b * DEG)) / Math.sin(angles1.c * DEG);
    let side2b =
      (side1b * Math.sin(angles2.b * DEG)) / Math.sin(angles2.c * DEG);
    let side3b =
      (side2b * Math.sin(angles3.b * DEG)) / Math.sin(angles3.c * DEG);
    return [
      {
        x: dimensions.canvasCenterX,
        y: dimensions.canvasCenterY + 0.301 * dimensions.tableHeight,
      },
      {
        x: dimensions.canvasCenterX - side0b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles1.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side1b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side2b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side2b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side3b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX,
        y: dimensions.canvasCenterY - 0.7197 * (dimensions.tableHeight / 2),
      },
      {
        x: dimensions.canvasCenterX + side3b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side2b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side2b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side1b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side0b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles1.gx * DEG),
      },
    ];
  }
}
function calculateButtonCoordinates(numberOfSeats, dimensions) {
  if (numberOfSeats === 10) {
    let angles0 = { a: 26.32, b: 90, c: 63.68, gx: 63.675 };
    let angles1 = { a: 42.34, b: 106.96, c: 30.7, gx: 21.34 };
    let angles2 = { a: 18.945, b: 86.257, c: 74.798, gx: 2.394 };
    let angles3 = { a: 20.47, b: 77.67, c: 81.86, gx: 18.36 };
    let angles4 = { a: 30.74, b: 34.97, c: 114.29, gx: 49.397 };
    let angles5 = { a: 71.04, b: 48.71, c: 60.25, gx: 60.78 };
    let angles6 = { a: 35.62, b: 112.04, c: 32.34, gx: 24.93 };
    let angles7 = { a: 19.29, b: 92.51, c: 68.2, gx: 5.65 };
    let angles8 = { a: 22.17, b: 77.97, c: 79.81, gx: 16.86 };
    let angles9 = { a: 32.28, b: 34.7, c: 113.02, gx: 49.247 };
    let side0c = 0.4456 * dimensions.tableHeight;
    let side0b =
      (side0c * Math.sin(angles0.b * DEG)) / Math.sin(angles0.c * DEG);
    let side1b =
      (side0b * Math.sin(angles1.b * DEG)) / Math.sin(angles1.c * DEG);
    let side2b =
      (side1b * Math.sin(angles2.b * DEG)) / Math.sin(angles2.c * DEG);
    let side3b =
      (side2b * Math.sin(angles3.b * DEG)) / Math.sin(angles3.c * DEG);
    let side4b =
      (side3b * Math.sin(angles4.b * DEG)) / Math.sin(angles4.c * DEG);
    let side5b =
      (side4b * Math.sin(angles5.b * DEG)) / Math.sin(angles5.c * DEG);
    let side6b =
      (side5b * Math.sin(angles6.b * DEG)) / Math.sin(angles6.c * DEG);
    let side7b =
      (side6b * Math.sin(angles7.b * DEG)) / Math.sin(angles7.c * DEG);
    let side8b =
      (side7b * Math.sin(angles8.b * DEG)) / Math.sin(angles8.c * DEG);
    let side9b =
      (side8b * Math.sin(angles9.b * DEG)) / Math.sin(angles9.c * DEG);
    return [
      {
        x: dimensions.canvasCenterX - side0b * Math.cos(angles0.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles0.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side1b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles1.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side2b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side2b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side3b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side4b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side4b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side5b * Math.cos(angles5.gx * DEG),
        y: dimensions.canvasCenterY - side5b * Math.sin(angles5.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side6b * Math.cos(angles6.gx * DEG),
        y: dimensions.canvasCenterY - side6b * Math.sin(angles6.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side7b * Math.cos(angles7.gx * DEG),
        y: dimensions.canvasCenterY - side7b * Math.sin(angles7.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side8b * Math.cos(angles8.gx * DEG),
        y: dimensions.canvasCenterY + side8b * Math.sin(angles8.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side9b * Math.cos(angles9.gx * DEG),
        y: dimensions.canvasCenterY + side9b * Math.sin(angles9.gx * DEG),
      },
    ];
  }
}

function drawTable(ctx, dimensions) {
  ctx.drawImage(
    tableImage,
    dimensions.tableStartX,
    dimensions.tableStartY,
    dimensions.tableWidth,
    dimensions.tableHeight,
  );
}

function drawCards(
  ctx,
  handState,
  finalHandState,
  playerUUID,
  handsFaceUp,
  coordinates,
  dimensions,
) {
  let playerIndex = _.findIndex(handState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let finalPlayerIndex = _.findIndex(finalHandState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let offsetPlayers = JSON.parse(JSON.stringify(handState.players));
  let finalOffsetPlayers = JSON.parse(JSON.stringify(finalHandState.players));
  let playerIndexCounter = JSON.parse(JSON.stringify(playerIndex));
  let finalPlayerIndexCounter = JSON.parse(JSON.stringify(finalPlayerIndex));
  if (playerIndex >= 0) {
    while (playerIndexCounter--) offsetPlayers.push(offsetPlayers.shift());
  }
  if (finalPlayerIndex >= 0) {
    while (finalPlayerIndexCounter--)
      finalOffsetPlayers.push(finalOffsetPlayers.shift());
  }
  coordinates.forEach(({ x, y }, index) => {
    ctx.globalAlpha = 1;
    let player = offsetPlayers[index];
    let finalPlayer = finalOffsetPlayers[index];
    if (player) {
      let cardOne, cardTwo;
      if (
        (player.player_uuid === playerUUID ||
          player.hand_status === 'revealed') &&
        player.hand.length > 0
      ) {
        cardOne = loadedCardImages.find(
          (c) =>
            c.rank === player.hand[0].rank && c.suit === player.hand[0].suit,
        ).img;
        cardTwo = loadedCardImages.find(
          (c) =>
            c.rank === player.hand[1].rank && c.suit === player.hand[1].suit,
        ).img;
        y = y - dimensions.cardHeight / 4;
      } else {
        if (handsFaceUp && finalPlayer.hand_status === 'revealed') {
          cardOne = loadedCardImages.find(
            (c) =>
              c.rank === player.hand[0].rank && c.suit === player.hand[0].suit,
          ).img;
          cardTwo = loadedCardImages.find(
            (c) =>
              c.rank === player.hand[1].rank && c.suit === player.hand[1].suit,
          ).img;
        } else {
          cardOne = loadedCardBack;
          cardTwo = loadedCardBack;
        }
      }
      if (player.hand_status === 'folded') {
        if (player.player_uuid === playerUUID) {
          ctx.globalAlpha = 0.2;
        } else {
          ctx.globalAlpha = 0;
        }
      }
      ctx.drawImage(
        cardOne,
        x - dimensions.cardWidth - 1,
        y - dimensions.cardHeight,
        dimensions.cardWidth,
        dimensions.cardHeight,
      );
      ctx.drawImage(
        cardTwo,
        x + 1,
        y - dimensions.cardHeight,
        dimensions.cardWidth,
        dimensions.cardHeight,
      );
    }
  });
  ctx.globalAlpha = 1;
}

const nonActiveEvents = [
  'consolidate-chips',
  'deal-flop',
  'deal-turn',
  'deal-river',
  'resolve-hand',
  'reveal-hand',
];

function drawPlayers(
  ctx,
  handState,
  finalHandState,
  playerUUID,
  coordinates,
  dimensions,
) {
  let currentPlayerIndex = _.findIndex(handState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let offsetHandPlayers = JSON.parse(JSON.stringify(handState.players));
  let currentPlayerIndexCounter = JSON.parse(
    JSON.stringify(currentPlayerIndex),
  );
  if (currentPlayerIndex >= 0) {
    while (currentPlayerIndexCounter--)
      offsetHandPlayers.push(offsetHandPlayers.shift());
  }

  coordinates.forEach(({ x, y }, index) => {
    let handPlayer = offsetHandPlayers[index];
    let isWinningPlayer =
      handState.receipt &&
      handPlayer &&
      _.some(handState.receipt.winners, (winner) => {
        return winner.player_uuid === handPlayer.player_uuid;
      });
    if (isWinningPlayer) {
      var winningPlayerPots = handState.receipt.winners.filter(
        (winner) => winner.player_uuid === handPlayer.player_uuid,
      );
      var winningPlayerChipDelta =
        winningPlayerPots[0].chip_delta +
        _.sum(winningPlayerPots.slice(1).map((x) => x.total_amount));
    }

    if (handPlayer) {
      const handPlayerIndex = _.findIndex(handState.players, (player) => {
        return player && player.player_uuid === handPlayer.player_uuid;
      });
      const isActivePlayer =
        handPlayerIndex === handState.actor_index &&
        !handState.receipt &&
        !_.includes(nonActiveEvents, handState.last_event);

      // Draw Right Annex Player Box Content;
      if (isWinningPlayer && handState.last_event === 'pay-out-winners') {
        ctx.fillStyle = THEME.playerDisplayBoxBackground;
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 4;
        ctx.shadowColor = THEME.playerDisplayBoxBorder;
        ctx.shadowBlur = dimensions.playerBoxWidth / 4;

        let chipDeltaText = numberDisplay(
          winningPlayerChipDelta / 100,
          handState.decimal_display,
        );
        let annexExtensionMultiple = Math.max(0, chipDeltaText.length - 1);
        let annexExtensionWidth =
          dimensions.playerBoxAnnexWidth +
          annexExtensionMultiple * (dimensions.playerBoxAnnexWidth / 7);

        ctx.roundRect(
          _.includes([2, 3], index)
            ? x - dimensions.playerBoxWidth / 2.05 - annexExtensionWidth
            : x + dimensions.playerBoxWidth / 2.05,
          y - dimensions.playerBoxHeight / 4,
          annexExtensionWidth,
          dimensions.playerBoxAnnexHeight,
          _.includes([2, 3], index)
            ? {
                upperLeft: 0.25 * dimensions.playerBoxAnnexWidth,
                upperRight: 0,
                lowerLeft: 0.25 * dimensions.playerBoxAnnexWidth,
                lowerRight: 0,
              }
            : {
                upperLeft: 0,
                upperRight: 0.25 * dimensions.playerBoxAnnexWidth,
                lowerLeft: 0,
                lowerRight: 0.25 * dimensions.playerBoxAnnexWidth,
              },
          true,
          true,
        );

        ctx.font = `${dimensions.tableWidth / 60}px Lato-Regular`;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = THEME.playerDisplayBoxText;
        ctx.fillText(
          `${winningPlayerChipDelta >= 0 ? '+' : '-'}${chipDeltaText}`,
          _.includes([2, 3], index)
            ? x -
                dimensions.playerBoxAnnexWidth *
                  (2.475 + annexExtensionMultiple * 0.155)
            : x + dimensions.playerBoxAnnexWidth * 2.05,
          y - dimensions.playerBoxHeight / 10,
        );
      }

      // Draw Player Box Shape
      if (isActivePlayer) {
        ctx.fillStyle = THEME.activePlayerDisplayBoxBackground;
        ctx.strokeStyle = THEME.activePlayerDisplayBoxBorder;
        ctx.lineWidth = THEME.activePlayerDisplayBoxBorderWidth;
        ctx.shadowBlur = 0;
      } else if (
        isWinningPlayer &&
        handState.last_event === 'pay-out-winners'
      ) {
        ctx.fillStyle = THEME.playerDisplayBoxBackground;
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 4;
        ctx.shadowColor = THEME.playerDisplayBoxBorder;
        ctx.shadowBlur = dimensions.playerBoxWidth / 4;
      } else if (handPlayer && handPlayer.hand_status !== 'folded') {
        ctx.fillStyle = THEME.playerDisplayBoxBackground;
        ctx.strokeStyle = '#b3b3b3';
        ctx.lineWidth = THEME.playerDisplayBoxBorderWidth;
        ctx.shadowBlur = 0;
      } else {
        ctx.fillStyle = THEME.playerDisplayBoxBackground;
        ctx.strokeStyle = THEME.playerDisplayBoxBorder;
        ctx.lineWidth = THEME.playerDisplayBoxBorderWidth;
        ctx.shadowBlur = 0;
      }

      ctx.roundRect(
        x - dimensions.playerBoxWidth / 2,
        y - dimensions.playerBoxHeight / 2,
        dimensions.playerBoxWidth,
        dimensions.playerBoxHeight,
        {
          upperLeft: dimensions.playerBoxHeight / 2,
          upperRight: dimensions.playerBoxHeight / 2,
          lowerLeft: dimensions.playerBoxHeight / 2,
          lowerRight: dimensions.playerBoxHeight / 2,
        },
        true,
        true,
      );

      // Draw Top Half Player Box Content
      ctx.fillStyle = isActivePlayer
        ? THEME.activePlayerDisplayBoxText
        : THEME.playerDisplayBoxText;
      if (handPlayer && handPlayer.hand_status !== 'folded') {
        ctx.fillStyle = THEME.playerDisplayBoxText;
      } else {
        ctx.fillStyle = 'rgba(255, 255, 255, 0.55)';
      }
      ctx.font = `${dimensions.tableWidth / 52}px Lato-Bold`;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';

      if (isActivePlayer && handState.last_event === 'fold') {
        ctx.fillText(
          'Fold',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (isActivePlayer && handState.last_event === 'check') {
        ctx.fillText(
          'Check',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (isActivePlayer && handState.last_event === 'bet') {
        ctx.fillText(
          'Bet',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (isActivePlayer && handState.last_event === 'raise') {
        ctx.fillText(
          'Raise',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (isActivePlayer && handState.last_event === 'call') {
        ctx.fillText(
          'Call',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else {
        ctx.fillText(
          userNameDisplay(handPlayer.display),
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      }

      // Draw Bottom Half Player Box Content;
      ctx.fillStyle = THEME.playerDisplayBoxText;
      if (handPlayer && handPlayer.hand_status !== 'folded') {
        ctx.fillStyle = THEME.playerDisplayBoxText;
      } else {
        ctx.fillStyle = 'rgba(255, 255, 255, 0.55)';
      }
      ctx.font = `${dimensions.tableWidth / 52}px Lato-Regular`;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      if (handPlayer && handPlayerIndex >= 0) {
        let chipStackText = numberDisplay(
          handPlayer.chip_stack_amount / 100,
          handState.decimal_display,
        );
        ctx.fillText(
          chipStackText,
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      }

      drawPlayerRunout(
        {
          ...handState,
          receipt: finalHandState.receipt,
        },
        ctx,
        dimensions,
        x,
        y,
        currentPlayerIndex,
        handState.last_event,
        index,
      );
    } else {
      // Empty Seat
    }
  });
  ctx.shadowBlur = 0;
}

function drawButton(ctx, handState, playerUUID, coordinates, dimensions) {
  let playerIndex = _.findIndex(handState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let buttonOffset =
    playerIndex >= 0
      ? (10 - playerIndex + handState.button_index) % 10
      : handState.button_index;
  if (handState.button_index !== null) {
    ctx.drawImage(
      dealerButton,
      coordinates[buttonOffset].x - dimensions.dealerButtonRadius / 2,
      coordinates[buttonOffset].y - dimensions.dealerButtonRadius / 2,
      dimensions.dealerButtonRadius,
      dimensions.dealerButtonRadius,
    );
  }
}

function drawBettingDisks(ctx, handState, playerUUID, coordinates, dimensions) {
  ctx.fillStyle = THEME.chipsInFrontText;
  ctx.font = `${dimensions.tableWidth / 56}px Lato-Regular`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  let playerIndex = _.findIndex(handState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let offsetPlayers = JSON.parse(JSON.stringify(handState.players));
  let playerIndexCounter = JSON.parse(JSON.stringify(playerIndex));
  if (playerIndex >= 0) {
    while (playerIndexCounter--) offsetPlayers.push(offsetPlayers.shift());
  }

  coordinates.forEach(({ x, y }, index) => {
    let renderX = x;
    let renderY = y;

    let player = offsetPlayers[index];
    if (player && player.chip_front_amount > 0) {
      let betDisk = betDisk1;
      let betDiskWidth = dimensions.betDisk1Width;
      let betDiskHeight = dimensions.betDisk1Height;
      if (player.bet_level >= 3) {
        betDisk = betDisk3;
        betDiskWidth = dimensions.betDisk3Width;
        betDiskHeight = dimensions.betDisk3Height;
      } else if (player.bet_level === 2) {
        betDisk = betDisk2;
        betDiskWidth = dimensions.betDisk2Width;
        betDiskHeight = dimensions.betDisk2Height;
      }

      if (player.hand_status === 'active') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'covered-by-blind') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'bet') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'called') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'raised') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'checked') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'folded') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      }

      let displayText = numberDisplay(
        player.chip_front_amount / 100,
        handState.decimal_display,
      );
      ctx.fillText(displayText, renderX, renderY);
    }
  });
}

function drawCommunityCards(ctx, handState, dimensions) {
  let startPoint = {
    x:
      dimensions.canvasCenterX -
      dimensions.cardWidth / 2 -
      2 * (dimensions.cardWidth + dimensions.communityCardMargin),
    y: dimensions.canvasCenterY - dimensions.cardHeight / 2,
  };
  handState.community_cards.forEach((card, index) => {
    let communityCard = loadedCardImages.find(
      (c) => c.rank === card.rank && c.suit === card.suit,
    );
    ctx.drawImage(
      communityCard.img,
      startPoint.x +
        index * (dimensions.cardWidth + dimensions.communityCardMargin),
      startPoint.y,
      dimensions.cardWidth,
      dimensions.cardHeight,
    );
  });
  // if(handState.last_event === 'pay-out-winners' && handState.community_cards.length === 4) {
  //   ctx.drawImage(riverPeekCard, (startPoint.x + (4 * (dimensions.cardWidth + dimensions.communityCardMargin))), startPoint.y, dimensions.cardWidth, dimensions.cardHeight);
  // }
  // if(handState.last_event === 'pay-out-winners' && handState.community_cards.length === 3) {
  //   ctx.drawImage(turnPeekCard, (startPoint.x + (3 * (dimensions.cardWidth + dimensions.communityCardMargin))), startPoint.y, dimensions.cardWidth, dimensions.cardHeight);
  // }
  // if(handState.last_event === 'pay-out-winners' && handState.community_cards.length === 0) {
  //   ctx.drawImage(blankPeekCard, startPoint.x, startPoint.y, dimensions.cardWidth, dimensions.cardHeight);
  //   ctx.drawImage(flopPeekCard, (startPoint.x + (1 * (dimensions.cardWidth + dimensions.communityCardMargin))), startPoint.y, dimensions.cardWidth, dimensions.cardHeight);
  //   ctx.drawImage(blankPeekCard, (startPoint.x + (2 * (dimensions.cardWidth + dimensions.communityCardMargin))), startPoint.y, dimensions.cardWidth, dimensions.cardHeight);
  // }
}

function drawPot(ctx, handState, playerUUID, dimensions) {
  ctx.fillStyle = THEME.potAmountText;
  ctx.font = `${dimensions.tableWidth / 56}px Lato-Regular`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  let sourceX = dimensions.canvasCenterX;
  let sourceY = dimensions.canvasCenterY - dimensions.potOffsetY;

  let renderPoints = handState.pots.map((pot, index) => {
    return {
      renderX: sourceX + dimensions.potDiskMargin * index,
      renderY: sourceY,
    };
  });

  handState.pots.forEach((potDisk, index) => {
    if (potDisk.amount > 0) {
      ctx.drawImage(
        potDiskImg,
        renderPoints[index].renderX - dimensions.potDiskWidth / 2,
        renderPoints[index].renderY - dimensions.potDiskHeight / 2,
        dimensions.potDiskWidth,
        dimensions.potDiskHeight,
      );
      let potText = numberDisplay(
        potDisk.amount / 100,
        handState.decimal_display,
      );
      ctx.fillText(
        potText,
        renderPoints[index].renderX + dimensions.betDiskTextOffset,
        renderPoints[index].renderY + 1,
      );
    }
  });

  ctx.font = `${dimensions.tableWidth / 56}px Lato-Regular`;
  let totalText = numberDisplay(
    handState.pot_size / 100,
    handState.decimal_display,
  );
  ctx.fillText(
    `Total: ${totalText}`,
    dimensions.canvasCenterX,
    dimensions.canvasCenterY - dimensions.potTextOffsetY,
  );
}

function renderTable(
  ctx,
  handState,
  finalHandState,
  playerUUID,
  handsFaceUp,
  windowDimensions,
) {
  ctx.clearRect(0, 0, windowDimensions.width, windowDimensions.height);

  let dimensions = calculateTableDimensions(windowDimensions);

  let playerCoordinates = calculatePlayerCoordinates(10, dimensions);
  let buttonCoordinates = calculateButtonCoordinates(10, dimensions);
  let bettingDiskCoordinates = calculateBettingDiskCoordinates(10, dimensions);

  if (handState) {
    drawTable(ctx, dimensions);
    drawCards(
      ctx,
      handState,
      finalHandState,
      playerUUID,
      handsFaceUp,
      playerCoordinates,
      dimensions,
    );
    drawPlayers(
      ctx,
      handState,
      finalHandState,
      playerUUID,
      playerCoordinates,
      dimensions,
    );
    drawButton(ctx, handState, playerUUID, buttonCoordinates, dimensions);
    drawBettingDisks(
      ctx,
      handState,
      playerUUID,
      bettingDiskCoordinates,
      dimensions,
    );
    drawCommunityCards(ctx, handState, dimensions);
    drawPot(ctx, handState, playerUUID, dimensions);
  }
}

function getDimensions(windowDimensionsRef) {
  return {
    width: windowDimensionsRef.current.width,
    height: windowDimensionsRef.current.height - CONTROLS_HEIGHT,
  };
}

export default function PokerTable({
  handState,
  finalHandState,
  playerUUID,
  handsFaceUp,
  renderDimensions,
}) {
  const windowDimensionsRef = React.useRef(renderDimensions);

  const handStateRef = React.useRef(null);
  const finalHandStateRef = React.useRef(null);
  const playerUUIDRef = React.useRef(null);
  const handsFaceUpRef = React.useRef(null);

  React.useEffect(() => {
    handStateRef.current = handState;
  }, [handState]);
  React.useEffect(() => {
    finalHandStateRef.current = finalHandState;
  }, [finalHandState]);
  React.useEffect(() => {
    playerUUIDRef.current = playerUUID;
  }, [playerUUID]);
  React.useEffect(() => {
    handsFaceUpRef.current = handsFaceUp;
  }, [handsFaceUp]);

  let canvas = document.querySelector('canvas.poker-table');

  if (canvas) {
    const dimensions = getDimensions(windowDimensionsRef);
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;
    let ctx = canvas.getContext('2d');
    renderTable(
      ctx,
      handState,
      finalHandState,
      playerUUID,
      handsFaceUp,
      dimensions,
    );
  }

  React.useEffect(() => {
    let interval = setInterval(() => {
      if (loadedCardImagesCounter === 52) {
        let canvas = document.querySelector('canvas.poker-table');
        if (canvas) {
          const dimensions = getDimensions(windowDimensionsRef);
          canvas.width = dimensions.width;
          canvas.height = dimensions.height;
          let ctx = canvas.getContext('2d');
          renderTable(
            ctx,
            handStateRef.current,
            finalHandStateRef.current,
            playerUUIDRef.current,
            handsFaceUpRef.current,
            dimensions,
          );
          clearInterval(interval);
        }
      }
    }, 100);
  }, []);

  React.useEffect(() => {
    windowDimensionsRef.current = {
      width: renderDimensions.width,
      height: renderDimensions.height,
    };
    let canvas = document.querySelector('canvas.poker-table');
    const dimensions = getDimensions(windowDimensionsRef);
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;
    let ctx = canvas.getContext('2d');
    if (handState) {
      renderTable(
        ctx,
        handState,
        finalHandState,
        playerUUID,
        handsFaceUp,
        dimensions,
      );
    }
  }, [renderDimensions]);

  return <canvas className="poker-table"></canvas>;
}
