export function toOrdinal(input) {
  const uniques = { 1: 'st', 2: 'nd', 3: 'rd' };
  const exceptions = [11, 12, 13];
  const teenPortion = input % 100;
  const lastDigit = input % 10;

  if (lastDigit in uniques && !exceptions.includes(teenPortion)) {
    return `${input}${uniques[lastDigit]}`;
  }
  return `${input}th`;
}
