export default {
  'en-US': {
    // Home Screen
    // =====================
    homeSiteTitle: `Clubs Poker | Play poker with friends`,
    headerHiring: `We're hiring`,
    headerBlog: `Our blog`,
    headerLogin: `Login`,

    heroPrimary: `Start a poker game with your friends in 30 seconds`,
    heroSecondary: `Clubs is the new home for weekly home games, poker nights, or virtual company outings. We even have the video and audio chat covered, too.`,
    heroCta: `Start playing now`,
    heroSignIn: `Already a member?`,
    signInWithEmail: `Sign in with email?`,
    signInWithPhone: `Sign in with phone number?`,
    createAccountInstead: `Create an account instead?`,

    testimonialLeftName: `Tamara H.`,
    testimonialRightName: `Drew C.`,
    testimonialLeftQuotation: `"This is our favorite night of the week. Thank you for building the platform that brings so much joy."`,
    testimonialRightQuotation: `"We're using Clubs instead of Pokerstars for our online poker nights now, so keep up the good work!"`,

    howItWorksLabel: `How it works`,
    sendLinkToTableHeading: `Just share a link to the table and start playing`,
    sendLinkToTableDescription: `After you create the table, just send out the unique link. When a player joins, you’ll be asked to approve their request for chips before they can play. The best part - players don’t even need to create their own account.`,
    howItWorksBullet1: `Play with a browser, no downloads`,
    howItWorksBullet2: `Your friends don’t need to sign up for an account`,
    howItWorksBullet3: `Approve player requests before they can play`,
    howItWorksBullet4: `Allow players/spectators to join the video and audio chat`,

    featuresLabel: `Features`,

    videoChatroomHeading: `Video, audio, and text chat built right in`,
    videoChatroomDescription: `Start a video chatroom, or an audio chatroom for everyone at the poker table. You can even allow non-playing spectators to join in too. But no smack-talking, okay!?`,
    postGameSummaryHeading: `Track results in the post-game summary`,
    postGameSummaryDescription: `Get the full report after the game is over. Results for every player, stats to make you better, and the top hands of the game. Each report is saved to your account so you can track progress over time.`,

    handReplayerHeading: `Replay and analyze all your past hands`,
    handReplayerDescription: `Did you miss the action? Want to review the most important hands of the night? Open the hand replayer to see how any hand played out.`,

    gameModeHeading: `Play a ring game or a tournament`,
    gameModeDescription: `Using play money, you can play in a friendly ring game a.k.a "cash game", or play a tournament for some added competition. Both styles support up to 10 players.`,
    gameModeArrowCta: 'Read about tournaments vs. ring games',

    featuresCtaLabel: `Set up a game for now or for later.`,
    featuresCtaButton: `Create my first game`,

    betaProgramLabel: `Free beta program`,
    betaProgramHeading: `Get early access through our free open beta`,
    betaProgramDescription: `Join our beta program for early access to Clubs poker, entirely for free. As we expand the product, we'll offer advanced features with different pricing options. Stay tuned!`,

    forCompaniesLabel: `Clubs for companies`,
    forCompaniesHeading: `Make your next company event an online poker night`,
    forCompaniesBullet1: `Your company logo, right on the table`,
    forCompaniesBullet2: `Dedicated account manager`,
    forCompaniesBullet3: `“Poker tips 101” live presentation (optional)`,
    forCompaniesArrowCta: `Learn more about Clubs for companies`,

    meetTheTeamLabel: `Meet the founder`,
    meetTheTeamHeading: `Busy creating the new home for poker with friends`,
    meetTheTeamDescription: `Taylor was a poker player for 4 years, a PM for 10 years, and now the founder of Clubs Poker. He’s got a big vision for the future of poker, and it starts with making poker more social, approachable, and… dare we say it, more fun again.`,

    taylorName: `Taylor Crane`,
    yourName: `Is this you?`,
    taylorHandle: `@taykcrane`,
    viewRoles: `View open roles`,

    contactUsLabel: `Contact us`,
    contactUsLabelLowercase: `contact us`,
    contactUsHeading: `Have any questions for us?`,
    contactUsDescription: `Contact us`,
    contactUsDescription2: `Shoot us an email at hello@clubspoker.com`,

    finalMessageText: `Take your poker night to the next level.`,

    emailInputPlaceholder: `Your email, please!`,
    emailButtonText: `Request access`,
    emailReceivedMessage: `Got it! We'll be in touch soon.`,

    // Companies Screen copy overrides below, any copy shared between screens
    // is defined above
    // (cl stands for "company landing")
    // =====================
    clSiteTitle: `Clubs Poker | for companies, corporate events, charities`,

    clCompaniesLabel: `for companies`,
    clHeaderForFriends: `Clubs for friends`,

    clHeroPrimary: `Make your next company event an online poker night`,
    clHeroSecondary: `Clubs is the online destination for company poker nights, corporate outings, and charity events`,
    clHeroCta: `Book an event now`,
    clHeroSignIn: `Already a member?`,

    clTestimonialName: `Joe E.`,
    clTestimonialTitle: `CTO @ Branch`,
    clTestimonialQuotation: `"The poker night on Clubs was a fantastic conclusion to our remote executive retreat. Everyone had a blast. We want to do it again soon!"`,
    clTestimonialArrowCta: `Read the case study`,

    clHowItWorksBullet1: `Play with a browser, no downloads`,
    clHowItWorksBullet2: `Your colleagues don’t need to sign up for an account`,
    clHowItWorksBullet3: `Customize the game with different settings`,
    clHowItWorksBullet4: `The whole setup process takes 30 seconds!`,
    clHowItWorksArrowCta: `Watch the how-to video`,

    clPostGameSummaryHeading: `Full event recap in the Post-game Summary`,
    clPostGameSummaryDescription: `Get the full report after the game is over. Results for every player, who won the most hands, who got the luckiest cards, links to the top hands of the night, and more!`,

    clCustomBrandingHeading: `Your company logo, right on the table`,
    clCustomBrandingDescription: `For any corporate event you’ll receive a custom-designed table and cards with your company logo or other custom branding`,

    clGameModeHeading: `Play a tournament or a ring game (we’ll help you choose)`,
    clGameModeDescription: `Choose between friendly ring game or competitive tournament modes. Can’t decide? We’ll walk you through what’s best given your goals.`,
    clGameModeArrowCta: 'Read about tournaments vs. ring games',

    clFeaturesCtaLabel: `Host a one-time event or make it recurring`,
    clFeaturesCtaButton: `Request a demo`,

    clWhyPokerLabel: `Why a poker night?`,
    clWhyPokerHeading: `Simply put, poker is great for business`,
    clWhyPokerBullet1: `Most already know how to play poker (or we’ll teach you)`,
    clWhyPokerBullet2: `Friendly competition & great conversations at the same time`,
    clWhyPokerBullet3: `Poker strategy is highly relevant to business, from profitable investing to quick decision-making`,
    clWhyPokerArrowCta: `Listen to poker pro Annie Duke`,

    clPricingLabel: `Pricing & packages`,
    clPackageTitleLeft: `Let's just play`,
    clPackageTitleRight: `Hosted event`,
    clPriceLeft: `$50+`,
    clPriceRight: `$250+`,
    clPackageBulletLeft1: `Custom branding`,
    clPackageBulletLeft2: `Dedicated account manager`,
    clPackageBulletRight1: `Custom branding`,
    clPackageBulletRight2: `Dedicated event host`,
    clPackageBulletRight3: `“Poker tips 101” (live presentation)`,

    clContactUsHeading: `Have any questions for us before you book?`,

    clFormSuccessMessage: `Awesome! You'll hear from us soon. In the meantime, you can `,
    clFormSuccessMessageLink: `sign up for Clubs here.`,
    clBookNowCta: `Request to book`,

    clFinalMessageText: `You can play with friends for free, too.`,

    // Footer
    hiring: `Work with us`,
    facebook: `Facebook`,
    twitter: `Twitter`,
    privacy: `Privacy`,
    terms: `Terms`,

    // Onboarding Screen
    // =====================
    onboardingHostSignupScreenPrimaryHeader: `Welcome! Let's get you set up as game host.`,
    onboardingHostSignupScreenSecondaryHeader: `We'll send you a confirmation code to your email. No more passwords!`,
    onboardingHostEmailConfirmationScreenPrimaryHeader: `Check your %{0}!`,
    onboardingTableSessionCreateScreenPrimaryHeader: `Nice, a %{gameVariant} ring game!`,
    onboardingTableSessionCreateScreenSecondaryHeader: `You can change these settings during the game, too.`,

    // Onboarding Host Email Confirmation Screen
    // =====================
    next: `Next`,

    // Onboarding Successful on Mobile
    // =====================
    onboardingSignupSuccessful: `Perfect! All done for now.`,
    onboardingYouNeedAComputer: `To play poker on Clubs, you’ll want to use a laptop or tablet (in landscape mode).`,
    onboardingInTheMeantime: `In the meantime...`,
    sendToFriend: `Send to a friend`,
    wantMobileApp: `Want a mobile app?`,
    addNameToWaitlist: `Add name to waitlist`,
    nameAddedToWaitlist: `Name added!`,
    readOurBlog: `Read our blog`,
    takeMeToLobbyAnyway: `Take me to the lobby anyway`,

    // Onboarding Table Session Create Form
    // =====================
    createGame: `Create game`,

    // New Table Selection Screen
    // =====================
    newTableSelectionScreenPrimaryHeader: `What kind of poker do you want to play?`,
    newTableSelectionScreenSecondaryHeader: `You can also set your poker table up later.`,
    newTableSelectionScreenTableVariantRingGame: `Ring game`,
    newTableSelectionScreenTableVariantRingGameDescription1: `Choose the blinds`,
    newTableSelectionScreenTableVariantRingGameDescription2: `Players come and go`,
    newTableSelectionScreenTableVariantRingGameDescription3: `Play until you want to stop`,
    newTableSelectionScreenTableVariantRingGameButtonText: `Ring game`,
    newTableSelectionScreenTableVariantSingleTableTournament: `Single table tournament`,
    newTableSelectionScreenTableVariantSingleTableTournamentDescription1: `Blinds increase regularly`,
    newTableSelectionScreenTableVariantSingleTableTournamentDescription2: `Players start at the same time`,
    newTableSelectionScreenTableVariantSingleTableTournamentDescription3: `Play until one winner`,
    newTableSelectionScreenTableVariantSingleTableTournamentButtonText: `Tournament`,
    newTableSelectionScreenTableVariantNotSureWhichToChoose: `Not sure which to choose?`,

    // TableSession Create Forms
    // =====================
    tableSessionCreateFormQuickSetup: `Quick setup`,
    tableSessionCreateFormAdvancedSetup: `Advanced setup`,
    tableSessionCreateFormChooseBlinds: `Choose the blinds`,
    tableSessionCreateFormBlindOptions: `Small / Big`,
    tableSessionCreateFormRecommendStack: `Recommend a starting stack`,
    tableSessionCreateFormNumberOfChips: `# of chips`,
    tableSessionCreateFormCreateGame: `Create game`,
    tableSessionCreateFormSmallBlind: `Small blind`,
    tableSessionCreateFormBigBlind: `Big blind`,
    tableSessionCreateFormRecommendedBuyIn: `Recommended buy-in`,
    tableSessionCreateFormAdvancedSmallBlind: `Small`,
    tableSessionCreateFormAdvancedBigBlind: `Big`,
    tableSessionCreateFormAdvancedMinBuyIn: `Min`,
    tableSessionCreateFormAdvancedMaxBuyIn: `Max`,
    tableSessionCreateFormAdvancedShowdownReveal: `Showdown reveal`,

    // Player Create Table Session
    // =====================
    playerTableSessionCreateScreenPrimary: `You can change these settings during the game, too.`,

    // Player Login Form
    // =====================
    createAnAccount: `Create an Account`,

    // Player Signup Form
    // =====================
    creatYourAccount: `Create your account`,
    firstName: `First Name`,
    lastName: `Last Name`,
    password: `Password`,
    confirmPassword: `Confirm Password`,

    // Guest Signup Left Sidebar
    // =====================
    signUpToAccessPostSummary: `Sign up to access your post-game`,
    statsToKeepTrack: `statistics, keep track of your results,`,
    andHostYourOwnGame: `and host your own game.`,
    guestSignupOnboardingText: `Sign up to access your post-game statistics, keep track of your results, and host your own game.`,
    guestSignupLearnMoreText: `Learn more about Clubs`,
    signUp: `Sign up`,
    guestSignupInvalidEmail: `That email doesn't look right...`,
    guestSignupInvalidPhone: `That phone doesn't look right...`,

    // Player Confirm Phone Number Sidebar
    // =====================
    checkYourPhone: `Check your phone!`,
    checkYourEmail: `Check your email!`,
    weJustSentConfirmationCode: `Check your email! We just sent a confirmation code to`,
    playerConfirmEmailSidebarRenterEmail: `Change email address`,
    codeSent: `Code sent!`,
    resendCode: `Re-send code`,
    submitCode: `Submit Code`,

    // Table Session Show Summary Screen
    // =====================
    summaryScreenTitle: `Table Session Summary`,
    hostedBySimple: `hosted by`,
    playedOn: `played on`,
    handsDealt: `hands dealt`,
    biggestWinner: `Biggest winner`,
    mostHandsWon: `Most hands won`,
    largestPot: `Largest pot`,
    viewHand: `view hand`,
    players: `Players`,
    total: `total`,
    handsWon: `hands won`,
    handStrength: `hand strength`,
    totalBuyin: `total buyin`,
    totalEntries: `entries`,
    chipsLeft: `chips left`,
    requestForFeedback: `How was your game experience? We’d love to hear any feedback you have so we can make the app better.`,
    requestForFeedbackCta: `Shoot us a message.`,
    anytime: 'anytime',
    yourSummary: 'Your summary',
    vpip: 'VPIP',
    preflopRaise: 'preflop raise',
    winnings: 'winnings',
    tournamentStanding: 'place',
    allInResults: 'All-in results',
    allInResultsTooltip:
      'Your re-calculated winnings after accounting for the expected value (EV) of your all-in situations before the river.',
    featuredHands: 'Featured hands',
    preflopAggression: 'Preflop aggression',
    preflopAggressionTooltip:
      'The % of hands you chose to play preflop (VPIP), and the % of hands you chose to raise preflop (PFR)',
    startingHandStrength: 'Starting hand strength',
    startingHandStrengthTooltip:
      'How lucky or unlucky the starting hands you were dealt were when compared to the average of 50%',
    playerHadChipsAddedByTheHost: `This player had a total of %{n} chips added by the host`,
    playerHadChipsRemovedByTheHost: `This player had a total of %{n} chips removed by the host`,
    playerRemovedByTheHost: `This player was removed by the host %{n} time(s) during this session`,

    // Tournament Show Summary Screen
    // =====================
    totalBuyins: `total buyins`,
    startingStackSize: `starting stack size`,
    levelDuration: `level duration`,
    bigBlinds: `BB`,
    minutes: `min`,
    blindStructure: `blind structure`,
    tournamentWinner: `Winner`,

    // Tournament Create Screen
    // =====================
    tournamentCreateScreenSetUpLater: `I'll set this up later`,
    tournamentCreateScreenPrimaryHeader: `A no-limit hold'em tournament, great!`,
    tournamentCreateScreenSecondaryHeader: `You can change these settings during the game too.`,

    // Tournament Create Form
    // =====================
    tournamentSpeedStructure: `Tournament speed`,
    tournamentSpeedStandard: `Standard (2 hours)`,
    tournamentSpeedTurbo: `Turbo (1 hour)`,
    tournamentSpeedHyper: `Hyper (30 minutes)`,
    tournamentStartingStackSize: `# of chips`,
    tournamentStandardStack: `Standard (5,000)`,
    tournamentDeepStack: `Deep (10,000)`,
    tournamentMegaStack: `Mega (20,000)`,
    tournamentLevelDuration: `Level duration`,
    tournamentDurationMin: `%{minutes} minutes`,
    tournamentBlindStructure: `Blind structure`,
    tournamentBlindStandard: `Standard`,
    tournamentBlindTurbo: `Turbo`,
    tournamentBlindHyper: `Hyper`,
    tournamentEstimatedDurationFor10Players: `Estimated duration for 10 players:`,
    hours: `hours`,

    // Tournaments
    // =====================
    tournamentBlinds: `Blinds`,

    // Table Session Show Screen
    // =====================
    tableSessionShowApproveNotification: `Approve`,
    tableSessionShowSignupWhileYouPlay: `Signup while you play`,
    tableSessionShowTournamentInfo: `Tournament info`,
    tableSessionShowTournamentPlayerStandings: `Player standings`,
    tableSessionShowRingGameInfo: `Player standings`,
    tableSessionShowNotificationCenterLeavingTable: `%{userName} will be removed from this table at the end of the current hand.`,
    tableSessionChipUpdateNotification: `Your chips will be updated at the start of the next hand.`,
    hostTableSessionChipUpdateNotification: `The chips for %{userName} will be updated at the start of the next hand.`,
    userIsRequestingSeatAndChips: `%{userName} is requesting a seat and %{numberOfChips} chips`,
    userIsRequestingChips: `%{userName} is requesting %{numberOfChips} chips`,
    tournamentRequestingJoin: `%{userName} is requesting a seat in the tournament`,
    tournamentRequestingRebuy: `%{userName} is requesting a rebuy in the tournament`,
    tournamentSecondaryNotificationInProgress: `The tournament is already in progress.`,
    tournamentSecondaryNotificationRequestRebuy: `Request a rebuy`,
    tournamentSecondaryNotificationRequestRebuyRequesting: `Requesting...`,
    tournamentSecondaryNotificationRequestJoin: `Request a seat`,
    blindsDisplayMinLeft: `min left`,
    hostControlsHint: `To adjust a player's chips or remove them entirely, click on their name at the table.`,
    hostControlsTournamentHint: `To remove a player from the tournament, click on their name at the table.`,

    // Betting Actions
    betOneQuarterPotAmount: `1/4 pot`,
    betHalfPotAmount: `1/2 pot`,
    betThreeQuarterPotAmount: `3/4 pot`,
    betPotAmount: `Pot`,
    betAllInAmount: `All-in`,
    betTwoTimesAmount: `2x`,
    betThreeTimesAmount: `3x`,
    betFourTimesAmount: `4x`,
    betFiveTimesAmount: `5x`,
    showCardsAction: `Show Cards`,
    foldCardsAction: `Fold`,
    callBetAction: `Call`,
    raiseToAmountAction: `Raise to`,
    checkAction: `Check`,
    betAction: `Bet`,
    checkOrFoldPreAction: `Check/fold`,
    copiedLink: `Copied!`,
    copyInviteLink: `Copy invite link`,

    // Table Overlay Content
    // =====================
    dealCardsAction: `Deal cards`,
    inviteOtherPlayersToGameLink: `Invite other players with a unique link to the game.`,
    numberOfPlayersReadyToPlay: `%{numberOfPlayers} players are ready to play.`,
    waitingForHostNameToStartGame: `Waiting for %{hostDisplay} to start the game.`,
    waitingForYouToResumeGame: `Waiting for you to resume the game.`,
    notEnoughPlayersToContinueGame: `Not enough players to continue. You can invite more, or finish the game.`,
    exploreGameSummary: `Explore game summary`,
    exploreTournamentSummary: `Explore tournament summary`,
    tableOverlayWaitingForPlayersToJoinTournament: `Waiting for players to join the tournament.`,
    tableOverlayWaitingForHostToStartTournament: `Waiting for the host to start the tournament.`,
    tableOverlayRequestJoinTournament: `Request a seat`,
    tableOverlayHostRequestJoinTournament: `Take my seat`,
    tableOverlayWaitingToStartTournament: `%{numberOfPlayers} players are ready to play.`,
    tableOverlayStartTournament: `Start tournament`,
    tableOverlayWaitingForYouToResumeTournament: `Waiting for you to resume the tournament.`,
    tableOverlayWaitingForHostToResumeTournament: `Waiting for the host to resume the tournament.`,
    tableOverlayPendingTournamentApproval: `Waiting for approval to join tournament`,
    tableOverlayRequestingApproval: `Requesting...`,

    // Ring Host Table Actions
    // =====================
    replayLastHand: `Replay last hand`,
    sounds: `Sounds`,
    addChips: `Add chips`,
    sitInNextHand: `Sit in next hand`,
    sitMeOut: `Sit me out`,
    leaveTable: `Leave table`,
    hostSettings: `Host Settings`,
    tournamentSettings: `Tournament Settings`,
    hostSettingsTableAction: `Host settings`,
    tournamentSettingsTableAction: `Tournament settings`,
    resumeGame: `Resume game`,
    pauseGame: `Pause game`,
    finishGame: `Finish game`,

    // Tournament Host Table Actions
    // =====================
    pauseTournament: `Pause tournament`,
    resumeTournament: `Resume tournament`,

    // Host Player Controls
    // =====================
    updateChips: `Update chips`,
    updateChipsInstructions: `Enter the total number of chips you want "%{playerDisplay}" to have.`,
    updateChipsInfo: `They currently have %{currentChipAmount} chips`,
    newChipsTotal: `New chips total`,
    removePlayer: `Remove player`,
    removePlayerInstructions: `This will remove "%{playerDisplay}" from the table after the current hand.`,
    removePlayerConfirm: `Yes, remove`,

    // Lobby Screen
    // =====================
    lobbySiteTitle: `Clubs Poker`,
    lobbyScreenHistoryZeroState: `A history of your past poker games will appear here. Good luck!`,
    hands: `hands`,
    handsPlayed: `hands played`,
    hostedByGameHost: `Hosted by %{gameHost}`,
    inProgress: `In progress`,
    waiting: `Waiting...`,
    joined: `joined`,
    active: `Active`,
    history: `History`,
    lobbyTournament: `Tournament`,
    createNewTable: `+ Create new table`,
    gameWillFinishAfterThisHand: `The game will finish after this hand`,
    thanksForPlayingClubsPoker: `Thanks for playing on Clubs poker!`,
    openTable: `Open table`,
    closeTable: `Close table`,
    closeTableConfirmationText: `Your in-progress game will be finished and the results will be available in the "History" section`,
    closeTableConfirmationCTA: `Yes, close it`,
    deleteTable: `Delete table`,
    deleteTableConfirmationText: `Your table will be deleted and removed from your list of active tables`,
    deleteTableConfirmationCTA: `Yes, delete it`,

    // Seasonal CTAs
    // =====================
    wantToGiveSwag: `We want to give you some swag for playing in the month of January.`,
    seeHowToQualify: `See how to qualify here.`,

    // Player Table Session Disconnected Modal
    // =====================
    reconnectingToGame: `One moment while we try to reconnect to the game...`,
    havingTroubleReconnecting: `Having trouble? Email hello@clubspoker.com`,

    // Sounds Modal
    // =====================
    soundsModalTitle: `Sound controls`,
    soundsModalSubtitle: `Customize the sounds to your needs.`,
    soundFrequencyLabelTextNone: `Mute sounds`,
    soundFrequencyLabelTextImportant: `Important sounds`,
    soundFrequencyLabelTextAll: `Play all sounds`,
    soundVolumeLabelText: `Volume - %{volume}%`,
    imDoneText: `I'm done`,

    // Tournament Info Modal
    // =====================
    tournamentInfoModalBuyins: `buyins`,
    tournamentInfoModalCurrentPlace: `%{currentPlayerStandings} out of %{totalTournamentPlayers}`,
    tournamentInfoModalTotalTournamentPlayers: `%{totalTournamentPlayers} players`,
    tournamentAverageStack: `%{averageChipStack} average stack`,
    tournamentInfoModalBlinds: `blinds`,
    tournamentInfoModalLeft: `left`,
    tournamentInfoModalLevel: `Level`,
    tournamentInfoModalEstimatedTimeRemaining: `Estimated %{estimatedTimeRemaining} remaining`,
    tournamentInfoModalChangeTournamentSettingsPrompt: `You can change these settings in`,
    tournamentInfoModalHostSettings: `Tournament settings`,
    tournamentInfoModalStartingStackSize: `Starting stack: %{startingStackSize} chips`,
    tournamentInfoModalRebuys: `Rebuys: %{rebuys}`,
    tournamentInfoModalLevelDuration: `Level duration: %{levelDuration}`,
    tournamentInfoModalLateRegistration: `Late registration: %{lateRegistration}`,
    tournamentInfoModalBlindStructure: `Blind structure: %{blindStructure}`,
    tournamentInfoModalAntes: `Antes: %{antes}`,
    tournamentInfoModalGotIt: `Got it!`,

    // Tournament Player Standings Modal
    // =====================
    tournamentPlayerStandingsCurrentChips: 'Current chips',
    tournamentPlayerStandingsBuyIns: `Buy-ins`,

    // 404 Page
    // =====================
    noMatchPageTitle: `Clubs 404`,
    noMatchScreenTitle: `Oops!`,
    noMatchCtaTitle1: `It looks like this page doesn’t exist.`,
    noMatchCtaTitle2: `If you’re trying to join a poker game, double check that the link is correct.`,
    noMatchHomeButton: `Go home`,
    noMatchNeedHelp: `Need help?`,
    noMatchChatWithUs: `Contact us`,

    // Stripe success page
    // =====================
    stripeSuccessPageTitle: `Clubs Poker`,
    stripeSuccessScreenTitle: `Thank you!`,
    stripeSuccessCtaTitle1: `Your support is what helps us keep Clubs Poker up and running. We couldn’t do it without you`,
    stripeSuccessCtaTitle2: ``,
    stripeSuccessHomeButton: `Go home`,
    stripeSuccessNeedHelp: `Need help?`,
    stripeSuccessChatWithUs: `Contact us`,

    // Stripe cancel page
    // =====================
    stripeCancelPageTitle: `Clubs Poker`,
    stripeCancelScreenTitle: `Error occurred`,
    stripeCancelCtaTitle1: `For some reason the payment was not successful. Please try again, and if the problem continues, contact us.`,
    stripeCancelCtaTitle2: ``,
    stripeCancelHomeButton: `Go home`,
    stripeCancelNeedHelp: `Need help?`,
    stripeCancelChatWithUs: `Contact us`,

    // Account Screen
    // =====================
    logout: `Log out`,
    nickname: `Nickname`,
    emailAddress: `Email address`,
    phoneNumber: `Phone number`,
    email: `Email`,
    updateEmail: `Update email`,
    updatePhone: `Update phone`,
    changesSaved: `Changes saved!`,
    noPhoneNumberEntered: `None added`,

    // Sidebar Footer
    // =====================
    contactUs: `Contact us`,
    privacyPolicy: `Privacy policy`,
    termsOfService: `Terms of service`,

    // Summary Blocking Screen
    // =====================
    almostDoneUser: `Almost done, %{userName}!`,
    finishAccountToAccessSummary: `Finish your account setup to access the post-game summary`,
    getAllTheGameStats: `Get all the game statistics, including your own performance and your top hands`,
    saveResultsToGameHistory: `Save the results to your Game History`,
    becomeAHost: `Become the host of your own game`,
    youCan: `You can`,
    learnMoreAboutClubs: `learn more about Clubs here`,
    wedLoveToHearFeedback: `We'd also love to hear any feedback or questions you have, feel free to`,

    // Welcome Back Sidebar
    // =====================
    youveBeenInvitedToJoinGame: `You’ve been invited to join a poker game`,
    hostedBy: `hosted by %{hostDisplay}.`,
    sidebarTournament: `tournament`,
    sidebarChipsStartingStack: `%{startingStack} chips starting stack`,
    sidebarLevelDuration: `%{levelDuration} minute levels`,

    // Add Chips Modal
    // =====================
    addChipsModalTitle: `Add Chips`,
    addChipsModalSubTitle: `How many additional chips would you like?`,
    addChipsModalInputPlaceholder: `Chip amount`,
    currentChipAmountDisplay: `You have %{chipStackAmount} chips on the table`,
    chipAmountAfterApproval: `You'll have %{updatedChipCount} chips total`,
    requestAmountOfChips: `Request %{chipAmount} chips`,
    addNChips: `Add %{n} chips`,
    removeNChips: `Remove %{n} chips`,
    currentChipCountInfo: `They currently have %{n} chips`,

    // Confirm Player Logout Modal
    // =====================
    logoutOfClubs: `Log out of Clubs?`,

    // Finish Game Modal
    // =====================
    gameWillEndAndPlayersCanViewSummary: `The game will end after this hand and all players will be able to view the game summary`,
    confirmFinishGame: `Yes, finish`,

    // Host Settings Modal
    // =====================
    changesWillTakeEffectNextHand: `Any changes you make will start to take effect in the next hand.`,
    hostSettingsBlindDisplay: `Small blind / Big Blind`,
    hostSettingsDecisionClock: `Decision clock (seconds)`,
    hostSettingsDecisionClockPlaceholder: `Decision clock`,
    hostSettingsBuyInLimit: `Buy-in limit (big blinds)`,
    hostSettingsMinBuyIn: `Min buy-in (big blinds)`,
    hostSettingsMinBuyInPlaceholder: `Min buy-in`,
    hostSettingsMaxBuyIn: `Max buy-in (big blinds)`,
    hostSettingsMaxBuyInPlaceholder: `Max buy-in`,
    hostSettingsAtShowDownReveal: `Showdown reveal`,
    hostSettingsAtShowDownRevealPlaceholder: `Showdown reveal...`,
    hostSettingsGameHost: `Game host`,
    hostSettingsSave: `Save`,

    // Tournament Settings Modal
    // =====================
    changesWillTakeEffectNextLevel: `Any changes you make will start to take effect in the next level.`,
    tournamentSettingsStartingStackSize: `Starting stack size`,
    tournamentSettingsRebuyRequests: `Re-buy requests`,
    tournamentSettingsLevelDuration: `Level duration`,
    tournamentSettingsDecisionClock: `Decision clock`,
    tournamentSettingsTournamentHost: `Tournament host`,
    tournamentSettingsRebuyOptionsTrue: `Host approval`,
    tournamentSettingsRebuyOptionsFalse: `Not allowed`,

    // Leave Poker Table Modal
    // =====================
    dontWorryChipBalanceWillSave: `Don't worry, your chip balance will be saved`,
    changeGameHost: `Change game host`,
    confirmLeaveTable: `Yes, leave`,

    // Player Login Modal
    // =====================
    signIn: `Sign in`,
    wellSendEmailConfirmationCode: `We'll send a confirmation code to your email`,
    wellSendPhoneConfirmationCode: `We'll send a confirmation code to your phone`,
    requestCode: `Request Code`,

    // Request Chips Modal
    // =====================
    requestChips: `Request Chips`,
    requestChipsSubHeader: `How many chips do you want to play with?`,
    requestChipsInputPlaceholder: `Chip amount`,
    minAmountToRequest: `Min: %{chipAmount}`,
    maxAmountToRequest: `Max: %{chipAmount}`,
    requestChipAmountButton: `Request %{numberOfChips} chips`,

    // Ring Game Info Modal
    // =====================
    elapsedTime: `elapsed`,
    ringHostBuyInTitle: `Buy-in`,
    ringHostWinningsTitle: `Winnings`,
    ringHostGotItButton: `Got it!`,

    // Verify Auth Code Modal
    // =====================
    weveSentConfirmationCodeToRecipient: `We've sent a confirmation code to %{recipient}.`,
    thisIsHowYouLogIn: `This is how you'll log in each time.`,

    // Header Tags Content
    // =====================
    metaContentInfo: `Clubs is the new home for weekly home games, for poker nights, or virtual company outings. Start a poker game in just 30 seconds and invite your friends with a link.`,

    // Hand Analyzer Screen
    // =====================
    analyzerActionPreFlop: `Pre-flop`,
    analyzerActionFlop: `Flop`,
    analyzerActionTurn: `Turn`,
    analyzerActionRiver: `River`,
    analyzerActionFinal: `Final`,
    analyzerActionHandsUp: `Hands face up?`,

    // Hand Analyzer Sidebar
    // =====================
    handPlayed: `hand played`,
    pot: `pot`,

    // Player Collapsible Sidebar
    // =====================
    home: `Home`,
    myAccount: `My account`,
    pokerTableWithBlindsDisplay: `Poker table (%{smallBlind} / %{bigBlind})`,

    // Sidebar Signin Form
    // =====================
    welcome: `Welcome!`,
    noLimitHoldem: `No Limit Hold'em`,
    playAsGuest: `Play as guest`,
    alreadyAMember: `Already a member?`,

    // Welcome Back Player Sidebar
    // =====================
    welcomeBackDisplay: `Welcome back, %{userName}!`,
    youveBeenInvitedToAPokerGame: `You've been invited to join a poker game`,
    blinds: `blinds`,
    chooseMySeat: `Choose my seat`,
    gotIt: `Got it!`,

    // Video Room
    // =====================
    joinVideoRoom: `Join the Video Room`,

    // Tip the dealer
    // =====================
    tipTheDealerTitle: `Tip the dealer`,
    tipTheDealerParagraph1: `Clubs wouldn’t be possible without your support. Thank you for helping us keep the site up and running 💪💚`,
    tipTheDealerParagraph2: `Current server costs are about`,
    tipTheDealerServerMonthlyCost: `$%{monthlyServerCost}/month`,
    tipTheDealerOptionTemplate: `tip %{timeAmount} of server time`,
    tipTheDealerCustomOption: `Custom amount`,
    tipTheDealerButtonCTA: `Tip $%{tipAmount}`,
    tipTheDealerGoBackCTA: `< Go back`,

    tipTheDealerTableOverlaySentence1: `Did you have a good time?`,
    tipTheDealerTableOverlaySentence2: `(that's us!)`,
    tipTheDealerTableOverlayCTA1: `You can tip the dealer`,
    tipTheDealerTableOverlaySkewedMessage: `This helps us keep the site running`,

    tipTheDealerHelpSupportCosts: `Help support our server costs of about $%{monthlyServerCost}/month`,

    // Mobile Accessibility
    // =====================
    mobileAccessibilityMessage: `Heads up! We recommend using a laptop or tablet (in landscape mode) to play on Clubs.`,
    dismiss: `Dismiss`,

    siteNoticeMessage: `We’ll be performing scheduled maintenance on our site today to make some necessary upgrades. On %{startTime}, Clubs Poker will stop working for about an hour. We expect the site and any in-progress games to resume around %{endTime}. Thanks for your patience!`,
  },
  nl: {
    heroPrimary: `Start een pokerspel met je vrienden in 30 seconden`,
    heroSecondary: `Clubs is de nieuwe thuisbasis voor wekelijkse homegames, pokeravonden of virtuele bedrijfsuitjes`,
  },
};
