import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import Spinner from '../Spinner';
import LightModal from './LightModal';
import OTPFields from '../OTPFields';
import { t } from '../../i18n';
import { phoneNumberDisplay } from '../../poker/helpers';

export default function UpdatePhoneModal({
  closeModal,
  isFetchingData,
  formValidation,
  updatePhoneStart,
  updatePhoneConfirm,
}) {
  const [phone, setPhone] = useState({});
  const [country, setCountry] = React.useState({});
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [otp, setOTP] = useState('');
  const [activeContent, setActiveContent] = useState('field-input');
  const [codeResending, setCodeResending] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const submitUpdatePhoneStart = async () => {
    if (!country.dialCode || !phoneNumber) {
      return;
    }
    const _phone = {
      phoneCountryCode: country.dialCode,
      phoneNumber: phoneNumber.slice(country.dialCode.length),
    };
    setPhone(_phone);
    const response = await updatePhoneStart(_phone);
    if (response.data.status === 'ok') {
      setActiveContent('field-confirmation');
    }
    return response;
  };

  const submitUpdatePhoneConfirm = async () => {
    const response = await updatePhoneConfirm(phone, otp);
    if (response.data.status === 'ok') {
      closeModal();
      setActiveContent('field-input');
      setPhone('');
    }
    return response;
  };

  const resendCode = async () => {
    setCodeResending(true);
    await submitUpdatePhoneStart();
    setCodeResending(false);
    setCodeSent(true);
    setTimeout(() => {
      setCodeSent(false);
    }, 3000);
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (activeContent === 'field-input') {
      return submitUpdatePhoneStart();
    }
    if (activeContent === 'field-confirmation') {
      return submitUpdatePhoneConfirm();
    }
  };

  const modalTitle = {
    'field-input': t('updatePhone'),
    'field-confirmation': t('checkYourPhone'),
  }[activeContent];

  return (
    <LightModal title={modalTitle} closeModal={closeModal}>
      {activeContent === 'field-input' ? (
        <>
          <h2 className="modal-subtitle">
            {t('wellSendPhoneConfirmationCode')}
          </h2>
          <form className="clubs-form" onSubmit={submitForm}>
            <div className="validated-input-field">
              <PhoneInput
                inputProps={{
                  required: true,
                  autoFocus: true,
                }}
                placeholder={t('phoneNumber')}
                onFocus={() => {
                  if (isEmpty(country)) {
                    setCountry({ countryCode: 'us' });
                  }
                }}
                country={country.countryCode}
                value={phoneNumber}
                onChange={(phoneNumber, country) => {
                  setPhoneNumber(phoneNumber);
                  if (country.countryCode !== 'ca') {
                    setCountry(country);
                  }
                }}
                // This is needed because of an unaddressed issue in react-phone-input-2, more info in:
                // https://github.com/bl00mber/react-phone-input-2/issues/298
                onEnterKeyPress={submitForm}
              />
              {formValidation.phone ? (
                <span className="form-validation">
                  {formValidation.phone[0]}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="custom-spacer" style={{ height: 30 }} />
            <button
              className="button submit-button request-code-button"
              type="submit"
            >
              {isFetchingData ? (
                <Spinner
                  spinnerType="chase"
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                <p>{t('requestCode')}</p>
              )}
            </button>
          </form>
        </>
      ) : null}
      {activeContent === 'field-confirmation' ? (
        <>
          <h2 className="modal-subtitle">
            {t(
              'weveSentConfirmationCodeToRecipient',
              phoneNumberDisplay(phone.phoneCountryCode, phone.phoneNumber),
            )}
          </h2>
          <form className="clubs-form" onSubmit={submitForm}>
            <OTPFields setValue={setOTP} error={formValidation.otp} />
            <span className="form-validation">
              {formValidation.otp ? formValidation.otp[0] : null}
            </span>
            {codeResending ? (
              <Spinner
                spinnerType="chase"
                invertedDots
                className="resend-code-spinner"
                style={{ width: 40, height: 40 }}
              />
            ) : codeSent ? (
              <p className="code-sent">{t('codeSent')}</p>
            ) : (
              <p className="resend-code" onClick={resendCode}>
                {t('resendCode')}
              </p>
            )}
            <button className="button submit-button" type="submit">
              {isFetchingData ? (
                <Spinner
                  spinnerType="chase"
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                <p>{t('updatePhone')}</p>
              )}
            </button>
          </form>
        </>
      ) : null}
    </LightModal>
  );
}
