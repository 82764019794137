const TABLE_ASPECT_RATIO = 1.6;

export function calculateTableDimensions(windowDimensions) {
  const canvasCenterX = windowDimensions.width / 2;
  const canvasCenterY = windowDimensions.height / 2;
  let tableWidth = Math.min(
    windowDimensions.width / 1.65,
    (windowDimensions.height * TABLE_ASPECT_RATIO) / 1.65,
    // 1280
  );
  let tableHeight = tableWidth / 2.15;
  let tableStartX = (windowDimensions.width - tableWidth) / 2;
  let tableStartY = windowDimensions.height / 2 - tableHeight / 2;
  let playerBoxWidth = 0.184 * tableWidth;
  let playerBoxHeight = 0.213 * tableHeight;
  let playerBoxAnnexWidth = 0.275 * playerBoxWidth;
  let playerBoxAnnexHeight = 0.3 * playerBoxHeight;
  let cardWidth = tableWidth * 0.0759;
  let cardHeight = tableHeight * 0.235;
  let betDisk1Width = tableWidth * 0.0826;
  let betDisk1Height = tableHeight * 0.07111;
  let betDisk2Width = tableWidth * 0.0826;
  let betDisk2Height = tableHeight * 0.07111;
  let betDisk3Width = tableWidth * 0.0914;
  let betDisk3Height = tableHeight * 0.09;
  let betDisk4Width = tableWidth * 0.1;
  let betDisk4Height = tableHeight * 0.109;
  let betDiskTextOffset = betDisk1Height / 2.5;
  let dealerButtonRadius = tableWidth * 0.0297;
  let communityCardMargin = tableWidth * 0.0132;
  let potOffsetY = tableHeight * 0.1754;
  let potTextOffsetY = tableHeight * 0.26;
  let potDiskMargin = tableWidth * 0.11;
  let potDiskWidth = tableWidth * 0.09912;
  let potDiskHeight = tableHeight * 0.07111;
  let activePlayerBoxMinBorder = tableWidth * 0.0045;
  let activePlayerBoxMaxBorder = tableWidth * 0.013;
  let tableShadowOffsetX = tableWidth * 0.00546875;
  let tableShadowOffsetY = tableWidth * 0.0140625;
  let tableShadowBlur = tableWidth * 0.0140625;
  let playerBoxRunoutEquityWidth = 0.1079 * tableWidth;
  let playerBoxRunoutEquityHeight = 0.045 * tableWidth;

  return {
    canvasCenterX,
    canvasCenterY,
    tableWidth,
    tableHeight,
    tableStartX,
    tableStartY,
    cardWidth,
    cardHeight,
    playerBoxWidth,
    playerBoxHeight,
    playerBoxAnnexWidth,
    playerBoxAnnexHeight,
    betDisk1Width,
    betDisk1Height,
    betDisk2Width,
    betDisk2Height,
    betDisk3Width,
    betDisk3Height,
    betDisk4Width,
    betDisk4Height,
    betDiskTextOffset,
    dealerButtonRadius,
    communityCardMargin,
    potOffsetY,
    potTextOffsetY,
    potDiskMargin,
    potDiskWidth,
    potDiskHeight,
    activePlayerBoxMinBorder,
    activePlayerBoxMaxBorder,
    tableShadowOffsetX,
    tableShadowOffsetY,
    tableShadowBlur,
    playerBoxRunoutEquityWidth,
    playerBoxRunoutEquityHeight,
  };
}

export function calculateTableOverlayDimensions(pokerTableContainerHeight) {
  return {
    aboveLogoComponent: {
      fontSize: pokerTableContainerHeight * 0.02667,
      bottom: `calc(50% + ${pokerTableContainerHeight * 0.0525}px)`,
    },
    aboveLogoTwoPartTopComponent: {
      fontSize: pokerTableContainerHeight * 0.02667,
      bottom: `calc(50% + ${pokerTableContainerHeight * 0.0725}px)`,
    },
    aboveLogoTwoPartBottomComponent: {
      fontSize: pokerTableContainerHeight * 0.01778,
      bottom: `calc(50% + ${pokerTableContainerHeight * 0.0475}px)`,
    },
    belowLogoComponent: {
      top: `calc(50% + ${pokerTableContainerHeight * 0.035}px`,
      fontSize: pokerTableContainerHeight * 0.02067,
    },
    skewedMessageImageComponent: {
      marginLeft: `calc(50% + ${pokerTableContainerHeight * 0.0155}px`,
      width: pokerTableContainerHeight * 0.14,
    },
    belowCardsComponent: {
      fontSize: pokerTableContainerHeight * 0.02667,
      top: `calc(50% + ${pokerTableContainerHeight * 0.075}px)`,
    },
    winningHandDescriptionComponent: {
      fontSize: pokerTableContainerHeight * 0.02667,
      top: `calc(50% + ${pokerTableContainerHeight * 0.0725}px)`,
      textShadow: `0px 0px ${
        pokerTableContainerHeight * 0.0222
      }px rgba(255, 255, 255, 0.8)`,
    },
    buttonComponent: {
      top: `calc(50% + ${pokerTableContainerHeight * 0.0525}px`,
      height: pokerTableContainerHeight * 0.0467,
      borderRadius: pokerTableContainerHeight * 0.0222,
      borderWidth: pokerTableContainerHeight * 0.00222,
    },
    buttonComponentSecondary: {
      top: `calc(50% + ${pokerTableContainerHeight * 0.095}px`,
      height: pokerTableContainerHeight * 0.0467,
      borderRadius: pokerTableContainerHeight * 0.0222,
      borderWidth: pokerTableContainerHeight * 0.00222,
    },
    exploreSummaryButtonComponent: {
      top: `calc(50% + ${-pokerTableContainerHeight * 0.0205}px`,
    },
    buttonText: {
      fontSize: pokerTableContainerHeight * 0.02,
      paddingTop: pokerTableContainerHeight * 0.00889,
      paddingBottom: pokerTableContainerHeight * 0.00889,
      paddingLeft: pokerTableContainerHeight * 0.01333,
      paddingRight: pokerTableContainerHeight * 0.01333,
    },
    copyInviteLinkComponent: {
      top: `calc(50% + ${pokerTableContainerHeight * 0.045}px`,
      fontSize: pokerTableContainerHeight * 0.025,
      textAlign: 'center',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    urlInputComponent: {
      top: `calc(50% + ${pokerTableContainerHeight * 0.0525}px`,
      width: pokerTableContainerHeight * 0.3811,
      height: pokerTableContainerHeight * 0.04778,
    },
    urlInputComponentInput: {
      borderWidth: pokerTableContainerHeight * 0.00222,
      fontSize: pokerTableContainerHeight * 0.02,
    },
    urlInputComponentButton: {
      width: pokerTableContainerHeight * 0.0889,
      borderTopRightRadius: pokerTableContainerHeight * 0.0222,
      borderBottomRightRadius: pokerTableContainerHeight * 0.0222,
      borderWidth: pokerTableContainerHeight * 0.00222,
      paddingTop: pokerTableContainerHeight * 0.00889,
      paddingBottom: pokerTableContainerHeight * 0.00889,
      paddingLeft: pokerTableContainerHeight * 0.01333,
      paddingRight: pokerTableContainerHeight * 0.01333,
    },
  };
}

export function calculateTableActionDimensions(pokerTableContainerHeight) {
  return {
    betActionsContainer: {
      bottom: pokerTableContainerHeight * 0.0867,
      right: pokerTableContainerHeight * 0.0278,
      height: pokerTableContainerHeight * 0.056,
    },
    potIncrementSection: {
      height: pokerTableContainerHeight * 0.0389,
      marginRight: pokerTableContainerHeight * 0.00444,
      borderWidth: pokerTableContainerHeight * 0.00222,
      borderRadius: pokerTableContainerHeight * 0.0111,
      width: pokerTableContainerHeight * 0.3933,
      paddingLeft: pokerTableContainerHeight * 0.00222,
      paddingRight: pokerTableContainerHeight * 0.00222,
    },
    potIncrementSectionBetActionButton: {
      minWidth: pokerTableContainerHeight * 0.07778,
    },
    potIncrementSectionText: {
      fontSize: pokerTableContainerHeight * 0.0178,
    },
    blindIncrementSection: {
      marginRight: pokerTableContainerHeight * 0.00444,
    },
    blindIncrementSectionBetActionButtonFirstChild: {
      height: pokerTableContainerHeight * 0.0389,
      width: pokerTableContainerHeight * 0.0389,
      borderWidth: pokerTableContainerHeight * 0.00222,
      marginRight: pokerTableContainerHeight * 0.00444,
    },
    blindIncrementSectionBetActionButtonLastChild: {
      height: pokerTableContainerHeight * 0.0389,
      width: pokerTableContainerHeight * 0.0389,
      borderWidth: pokerTableContainerHeight * 0.00222,
    },
    blindIncrementSectionBetActionButtonText: {
      fontSize: pokerTableContainerHeight * 0.0222,
    },
    customIncrementSectionInput: {
      borderWidth: pokerTableContainerHeight * 0.00222,
      borderRadius: pokerTableContainerHeight * 0.0111,
      width: pokerTableContainerHeight * 0.0833,
      height: pokerTableContainerHeight * 0.0411,
      paddingLeft: pokerTableContainerHeight * 0.00889,
      paddingRight: pokerTableContainerHeight * 0.00889,
      fontSize: pokerTableContainerHeight * 0.02,
    },
    handActionsContainer: {
      bottom: pokerTableContainerHeight * 0.01667,
      right: pokerTableContainerHeight * 0.0278,
    },
    handActionButton: {
      width: pokerTableContainerHeight * 0.17,
      height: pokerTableContainerHeight * 0.0689,
      borderWidth: pokerTableContainerHeight * 0.00222,
      borderRadius: pokerTableContainerHeight * 0.0111,
      paddingTop: pokerTableContainerHeight * 0.0089,
      paddingBottom: pokerTableContainerHeight * 0.0089,
      paddingLeft: pokerTableContainerHeight * 0.0133,
      paddingRight: pokerTableContainerHeight * 0.0133,
    },
    handActionText: {
      fontSize: pokerTableContainerHeight * 0.0267,
    },
    revealHandActionText: {
      fontSize: pokerTableContainerHeight * 0.0178,
    },
    preActionsContainer: {
      bottom: pokerTableContainerHeight * 0.01667,
      right: pokerTableContainerHeight * 0.2133,
    },
    preActionButton: {
      width: pokerTableContainerHeight * 0.17,
      height: pokerTableContainerHeight * 0.0689,
      borderWidth: pokerTableContainerHeight * 0.00222,
      borderRadius: pokerTableContainerHeight * 0.0111,
      paddingTop: pokerTableContainerHeight * 0.0089,
      paddingBottom: pokerTableContainerHeight * 0.0089,
      paddingLeft: pokerTableContainerHeight * 0.0133,
      paddingRight: pokerTableContainerHeight * 0.0133,
    },
    preActionButtonText: {
      fontSize: pokerTableContainerHeight * 0.0267,
    },
  };
}
