import React, { useState } from 'react';
import Spinner from '../Spinner';
import { t } from '../../i18n';
import { phoneNumberDisplay } from '../../poker/helpers';

export default function PlayerAccountForm({
  player,
  updatePlayerAccount,
  fieldIsUpdating,
  checkMarkField,
  setCheckMarkField,
  fieldFailedValidation,
  openUpdateEmailModal,
  openUpdatePhoneModal,
}) {
  const [playerUserName, setPlayerUserName] = useState(player.user_name);

  const [lastBlurredField, setLastBlurredField] = useState('');

  React.useEffect(() => {
    setPlayerUserName(player.user_name);
  }, [player]);

  const updateAccount = (formComponent) => {
    if (formComponent === 'userName' && playerUserName !== player.user_name) {
      setLastBlurredField(formComponent);
      updatePlayerAccount({
        userName: playerUserName,
      });
    }
  };

  React.useEffect(() => {
    if (!fieldIsUpdating) {
      if (lastBlurredField === 'userName') {
        setCheckMarkField('userName');
      }
    }
  }, [fieldIsUpdating]);

  return (
    <div className="player-account-form">
      <div className="form-component">
        <div className="label-component">
          <p className="component-label">{t('nickname')}</p>
          {fieldIsUpdating && lastBlurredField === 'userName' ? (
            <Spinner
              spinnerType="chase"
              invertedDots={true}
              style={{ height: 20, width: 20, color: 'black' }}
            />
          ) : checkMarkField === 'userName' && !fieldFailedValidation ? (
            <p className="approved">✓ {t('changesSaved')}</p>
          ) : checkMarkField === 'userName' && fieldFailedValidation ? (
            <p className="failed">
              <span role="img" aria-label="failed">
                ❌
              </span>
            </p>
          ) : (
            <></>
          )}
        </div>
        <input
          className="component-input"
          value={playerUserName}
          onChange={(e) => {
            setPlayerUserName(e.target.value);
          }}
          onBlur={() => {
            updateAccount('userName');
          }}
        />
        <div className="field-footer" />
      </div>
      <div className="form-component">
        <div className="label-component">
          <p className="component-label">{t('emailAddress')}</p>
          {fieldIsUpdating && lastBlurredField === 'email' ? (
            <Spinner
              spinnerType="chase"
              invertedDots={true}
              style={{ height: 20, width: 20, color: 'black' }}
            />
          ) : checkMarkField === 'email' && !fieldFailedValidation ? (
            <p className="approved">✓ {t('changesSaved')}</p>
          ) : checkMarkField === 'email' && fieldFailedValidation ? (
            <p className="failed">
              <span role="img" aria-label="failed">
                ❌
              </span>
            </p>
          ) : (
            <></>
          )}
        </div>
        <input
          className="component-input"
          disabled
          type="email"
          value={player.email}
        />
        <div className="field-footer">
          <button
            type="button"
            className="update-property-in-modal-button"
            onClick={openUpdateEmailModal}
          >
            {t('updateEmail')}
          </button>
        </div>
      </div>
      <div className="form-component">
        <div className="label-component">
          <p className="component-label">{t('phoneNumber')}</p>
          {fieldIsUpdating && lastBlurredField === 'phone' ? (
            <Spinner
              spinnerType="chase"
              invertedDots={true}
              style={{ height: 20, width: 20, color: 'black' }}
            />
          ) : checkMarkField === 'phone' && !fieldFailedValidation ? (
            <p className="approved">✓ {t('changesSaved')}</p>
          ) : checkMarkField === 'phone' && fieldFailedValidation ? (
            <p className="failed">
              <span role="img" aria-label="failed">
                ❌
              </span>
            </p>
          ) : (
            <></>
          )}
        </div>
        <input
          className="component-input"
          disabled
          type="text"
          value={
            player.phone_country_code && player.phone_number
              ? phoneNumberDisplay(
                  player.phone_country_code,
                  player.phone_number,
                )
              : ''
          }
          placeholder={t('noPhoneNumberEntered')}
        />
        <div className="field-footer">
          <button
            type="button"
            className="update-property-in-modal-button"
            onClick={openUpdatePhoneModal}
          >
            {t('updatePhone')}
          </button>
        </div>
      </div>
    </div>
  );
}
