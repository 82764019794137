import React from 'react';
import Spinner from '../Spinner';
import { TOURNAMENT_PLAYER_STANDINGS } from '../../services/APIService';
import { t } from '../../i18n';
import moment from 'moment';
import { numberDisplay, userNameDisplay } from '../../poker/helpers';

export default function TournamentPlayerStandingsModal({
  tournamentUUID,
  playerUUID,
  playerIsHost,
  closeModal,
}) {
  const [isLoadingData, setIsLoadingData] = React.useState(true);
  const [tournamentInfo, setTournamentInfo] = React.useState({});
  const elapsedTimeRef = React.useRef(0);

  React.useEffect(() => {
    TOURNAMENT_PLAYER_STANDINGS({ tournamentUUID }).then((response) => {
      setIsLoadingData(false);
      if (response.data.status === 'ok') {
        setTournamentInfo(response.data);
        elapsedTimeRef.current = response.data.elapsed_time;
        setInterval(() => {
          elapsedTimeRef.current += 1;
        }, 1000);
      }
    });
  }, [tournamentUUID, playerUUID]);

  let renderPlayerRow = (playerData) => {
    return (
      <div className="player player-info-row" key={playerData.player_uuid}>
        <span className="player-name">
          {userNameDisplay(playerData.display)}
        </span>
        <span className="player-current-chips">
          {numberDisplay(playerData.current_chips / 100)}
        </span>
        <span className="player-total-buy-ins">{playerData.total_buy_ins}</span>
      </div>
    );
  };

  let ModalContent = (
    <div
      className="modal-content"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Spinner />
      <h2 className="close-modal" onClick={closeModal}>
        X
      </h2>
    </div>
  );
  if (!isLoadingData && tournamentInfo.standings) {
    let currentPlayer = tournamentInfo.standings.find(
      (playerTournamentSession) =>
        playerTournamentSession.player_uuid === playerUUID,
    );
    let activePlayers = tournamentInfo.standings.filter(
      (playerTournamentSession) =>
        playerTournamentSession.player_uuid !== playerUUID &&
        playerTournamentSession.tournament_status !== 'busted',
    );
    let bustedPlayers = tournamentInfo.standings.filter(
      (playerTournamentSession) =>
        playerTournamentSession.player_uuid !== playerUUID &&
        playerTournamentSession.tournament_status === 'busted',
    );

    ModalContent = (
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">No Limit Hold'em Tournament</h1>
        <div className="meta-info">
          <p>
            {t('hostedBySimple')} <span>{tournamentInfo.host_display}</span>
          </p>
          <p className="divider">|</p>
          <p>
            {t('elapsedTime')}{' '}
            <span>
              {moment
                .utc(
                  moment
                    .duration(elapsedTimeRef.current, 'seconds')
                    .as('milliseconds'),
                )
                .format('HH:mm:ss')}
            </span>
          </p>
        </div>
        <div className="results">
          <div className="header">
            <p className="nickname">{t('nickname')}</p>
            <p className="current-chips">
              {t('tournamentPlayerStandingsCurrentChips')}
            </p>
            <p className="buy-ins">{t('tournamentPlayerStandingsBuyIns')}</p>
          </div>
          {currentPlayer ? (
            <div className="players current-player">
              {renderPlayerRow(currentPlayer)}
              <div className="custom-border"></div>
            </div>
          ) : (
            <></>
          )}
          <div className="players active-players">
            {activePlayers.map((player) => renderPlayerRow(player))}
          </div>
          <div className="players busted-players">
            {bustedPlayers.map((player) => renderPlayerRow(player))}
          </div>
        </div>
        {playerIsHost ? (
          <>
            <div className="custom-spacer" style={{ height: 24 }} />
            <div className="host-controls-hint">
              <p>{t('hostControlsTournamentHint')}</p>
            </div>
          </>
        ) : null}
        <div className="submit-button" onClick={closeModal}>
          {t('ringHostGotItButton')}
        </div>
      </div>
    );
  }

  return (
    <div
      className="modal tournament-player-standings-modal"
      onClick={closeModal}
    >
      {ModalContent}
    </div>
  );
}
