import React, { useContext, useEffect, useRef, useState } from 'react';
import { t } from '../i18n';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import { useHistory } from 'react-router-dom';
import logo from '../assets/brand/logo-unicolor.png';
import chevronRight from '../assets/icons/chevron-right.png';
import 'react-phone-input-2/lib/style.css';
import Spinner from './Spinner';
import { PLAYER_SIGNUP_INIT } from '../services/APIService';
import SidebarFooter from './SidebarFooter';
import AnalyticsService from '../services/AnalyticsService';

export default function PlayerConfirmEmailSidebar({
  player,
  startClosed,
  isCollapsible = true,
  isExpandable = true,
  reEnterSignupDetails,
  verifyAuthCode,
  formValidation,
  isFetchingData,
  resetFormValidation,
}) {
  let history = useHistory();
  const eventEmitter = useContext(EventEmitterContext);
  const [isClosed, setIsClosed] = useState(startClosed);
  const [codeResending, setCodeResending] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const [digit1, setDigit1] = useState('');
  const [digit2, setDigit2] = useState('');
  const [digit3, setDigit3] = useState('');
  const [digit4, setDigit4] = useState('');
  const [digit5, setDigit5] = useState('');
  const [digit6, setDigit6] = useState('');
  const digit1Ref = useRef();
  const digit2Ref = useRef();
  const digit3Ref = useRef();
  const digit4Ref = useRef();
  const digit5Ref = useRef();
  const digit6Ref = useRef();

  useEffect(() => {
    const closeSidebar = () => {
      if (!isClosed && isCollapsible) {
        setIsClosed(true);
      }
    };
    eventEmitter.on('close-sidebar-if-open', closeSidebar);
    return () => eventEmitter.off('close-sidebar-if-open', closeSidebar);
  });

  useEffect(() => {
    const openSidebar = () => {
      if (isClosed && isExpandable) {
        setIsClosed(false);
      }
    };
    eventEmitter.on('open-sidebar-if-closed', openSidebar);
    return () => eventEmitter.off('open-sidebar-if-closed', openSidebar);
  });

  useEffect(() => {
    if (!isClosed) {
      resetFormValidation();
    }
  }, [isClosed]);

  const resendCode = async () => {
    setCodeResending(true);
    await PLAYER_SIGNUP_INIT({
      guestUUID: player.uuid,
      email: player.email,
      userName: player.display,
    });
    setCodeResending(false);
    setCodeSent(true);
    setTimeout(() => {
      setCodeSent(false);
    }, 3000);
  };

  var classString = 'player-confirm-email-sidebar';
  if (isClosed) {
    classString += ' is-closed';
  }

  const toggleSidebar = () => {
    if (isClosed) {
      setIsClosed(false);
      AnalyticsService.opensSidebarEvent(player);
    } else {
      setIsClosed(true);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const confirmationCode = [
      digit1,
      digit2,
      digit3,
      digit4,
      digit5,
      digit6,
    ].join('');
    if (confirmationCode.length === 6) {
      verifyAuthCode(confirmationCode);
    }
  };

  const SidebarContent = isClosed ? (
    <>
      <img
        className="logo"
        src={logo}
        alt="clubs-poker"
        onClick={() => {
          history.push('/');
        }}
      />
      {isExpandable ? (
        <div className="expand" onClick={toggleSidebar}>
          <img src={chevronRight} alt="expand-sidebar" />
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <div className="sidebar-content">
      <div
        className="brand"
        onClick={() => {
          history.push('/');
        }}
      >
        <img className="logo" src={logo} alt="clubs-poker" />
        <p className="clubs">
          <span className="bold">Clubs</span> poker
        </p>
        <p className="now">now</p>
      </div>
      <div className="expand">
        <img src={chevronRight} alt="expand-sidebar" />
      </div>
      <p className="onboarding-text">{t('weJustSentConfirmationCode')}</p>
      <p className="onboarding-text ellipsis">{player.email}</p>
      {codeResending ? (
        <Spinner spinnerType="chase" className="resend-code-spinner" />
      ) : codeSent ? (
        <p className="code-sent">{t('codeSent')}</p>
      ) : (
        <p className="resend-code" onClick={resendCode}>
          {t('resendCode')}
        </p>
      )}
      <p className="reenter-email" onClick={reEnterSignupDetails}>
        {t('playerConfirmEmailSidebarRenterEmail')}
      </p>
      <form onSubmit={submitForm} className="clubs-form">
        <div
          className="code"
          onPaste={(e) => {
            let pasteText = e.clipboardData.getData('Text');
            if (pasteText.length === 6) {
              setDigit1(pasteText[0]);
              setDigit2(pasteText[1]);
              setDigit3(pasteText[2]);
              setDigit4(pasteText[3]);
              setDigit5(pasteText[4]);
              setDigit6(pasteText[5]);
              digit6Ref.current.focus();
            }
          }}
        >
          <input
            autoFocus={true}
            className={`digit-1 ${formValidation.otp ? 'error' : ''}`}
            maxLength="1"
            value={digit1}
            ref={digit1Ref}
            onKeyDown={(e) => {
              if (e.which >= 48 && e.which <= 57) {
                setDigit1('');
              } else if (e.which === 8) {
                setDigit1('');
              }
            }}
            onChange={(e) => {
              if (e.target.value !== '') {
                setDigit1(e.target.value);
                digit2Ref.current.focus();
              }
            }}
          />
          <input
            className={`digit-2 ${formValidation.otp ? 'error' : ''}`}
            maxLength="1"
            value={digit2}
            ref={digit2Ref}
            onKeyDown={(e) => {
              if (e.which >= 48 && e.which <= 57) {
                setDigit2('');
              } else if (e.which === 8) {
                setDigit2('');
                digit1Ref.current.focus();
              }
            }}
            onChange={(e) => {
              if (e.target.value !== '') {
                setDigit2(e.target.value);
                digit3Ref.current.focus();
              }
            }}
          />
          <input
            className={`digit-3 ${formValidation.otp ? 'error' : ''}`}
            maxLength="1"
            value={digit3}
            ref={digit3Ref}
            onKeyDown={(e) => {
              if (e.which >= 48 && e.which <= 57) {
                setDigit3('');
              } else if (e.which === 8) {
                setDigit3('');
                digit2Ref.current.focus();
              }
            }}
            onChange={(e) => {
              if (e.target.value !== '') {
                setDigit3(e.target.value);
                digit4Ref.current.focus();
              }
            }}
          />
          <input
            className={`digit-4 ${formValidation.otp ? 'error' : ''}`}
            maxLength="1"
            value={digit4}
            ref={digit4Ref}
            onKeyDown={(e) => {
              if (e.which >= 48 && e.which <= 57) {
                setDigit4('');
              } else if (e.which === 8) {
                setDigit4('');
                digit3Ref.current.focus();
              }
            }}
            onChange={(e) => {
              if (e.target.value !== '') {
                setDigit4(e.target.value);
                digit5Ref.current.focus();
              }
            }}
          />
          <input
            className={`digit-5 ${formValidation.otp ? 'error' : ''}`}
            maxLength="1"
            value={digit5}
            ref={digit5Ref}
            onKeyDown={(e) => {
              if (e.which >= 48 && e.which <= 57) {
                setDigit5('');
              } else if (e.which === 8) {
                setDigit5('');
                digit4Ref.current.focus();
              }
            }}
            onChange={(e) => {
              if (e.target.value !== '') {
                setDigit5(e.target.value);
                digit6Ref.current.focus();
              }
            }}
          />
          <input
            className={`digit-6 ${formValidation.otp ? 'error' : ''}`}
            maxLength="1"
            value={digit6}
            ref={digit6Ref}
            onKeyDown={(e) => {
              if (e.which >= 48 && e.which <= 57) {
                setDigit6('');
              } else if (e.which === 8) {
                setDigit6('');
                digit5Ref.current.focus();
              }
            }}
            onKeyPress={(e) => {
              digit6Ref.current.focus();
            }}
            onChange={(e) => {
              if (e.target.value === '') {
                setDigit6('');
                digit5Ref.current.focus();
              } else {
                setDigit6(e.target.value);
              }
            }}
          />
          {formValidation.otp ? (
            <span className="form-validation">{formValidation.otp[0]}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="form-actions">
          <button className="submit-button" type="submit">
            {isFetchingData ? (
              <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
            ) : (
              <p>{t('submitCode')}</p>
            )}
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <div className={classString}>
      {SidebarContent}
      {isClosed ? (
        <></>
      ) : (
        <>
          <div className="flex-spacer" />
          <SidebarFooter player={player} />
        </>
      )}
    </div>
  );
}
