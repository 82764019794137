import React from 'react';
import _ from 'lodash';
import CARDS from '../poker/cards';
import THEME from '../styles/themes/PokerTableTheme';
import { numberDisplay, userNameDisplay } from '../poker/helpers';
import { calculateTableDimensions } from '../poker-table/PokerTableDimensions';
import { usePageVisibility } from 'react-page-visibility';
import { useContextMenu } from 'react-contexify';
import './RoundRect';
import { drawPlayerRunout } from './poker-table/PlayerRunout';
import { HOST_PLAYER_ACTIONS_CONTEXT_MENU_ID } from './poker-table/HostPlayerActionsContextMenu';

// Preload Images
let tableImage = new Image();
let loadedCardBack = new Image();

let betDisk1 = new Image();
betDisk1.src = `${process.env.PUBLIC_URL}/assets/bet-disk.svg`;
let betDisk2 = new Image();
betDisk2.src = `${process.env.PUBLIC_URL}/assets/raise-disk.svg`;
let betDisk3 = new Image();
betDisk3.src = `${process.env.PUBLIC_URL}/assets/3bet-disk.svg`;
let betDisk4 = new Image();
betDisk4.src = `${process.env.PUBLIC_URL}/assets/4bet-disk.svg`;
let potDiskImg = new Image();
potDiskImg.src = `${process.env.PUBLIC_URL}/assets/pot-disk-90.svg`;
let dealerButton = new Image();
dealerButton.src = `${process.env.PUBLIC_URL}/assets/dealer-button-v2.png`;

let loadedCardImages = [];
loadedCardImages = CARDS.map((card) => {
  const loadedImg = new Image();
  loadedImg.src = card.img;
  return {
    suit: card.suit,
    rank: card.rank,
    img: loadedImg,
  };
});

function easeInOutSine(x) {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

let pulseAnimationDirection = 'increasing';
let pulseAnimationTargetAnimationFrame = undefined;

const pulseActivePlayerBorder = (minSize, maxSize, timeToMax, easeFunction) => {
  if (!pulseAnimationTargetAnimationFrame) {
    pulseAnimationTargetAnimationFrame = window.animationFrame + timeToMax;
  }

  let pulseAnimationPercentage =
    (timeToMax -
      Math.max(0, pulseAnimationTargetAnimationFrame - window.animationFrame)) /
    timeToMax;
  pulseAnimationPercentage = easeInOutSine(pulseAnimationPercentage);

  if (pulseAnimationDirection === 'increasing') {
    let borderSize = minSize + (maxSize - minSize) * pulseAnimationPercentage;
    if (pulseAnimationPercentage === 1) {
      pulseAnimationTargetAnimationFrame = window.animationFrame + timeToMax;
      pulseAnimationDirection = 'decreasing';
    }
    return borderSize;
  } else {
    let borderSize = maxSize - (maxSize - minSize) * pulseAnimationPercentage;
    if (pulseAnimationPercentage === 1) {
      pulseAnimationTargetAnimationFrame = window.animationFrame + timeToMax;
      pulseAnimationDirection = 'increasing';
    }
    return borderSize;
  }
};

// Define Constants
const DEG = Math.PI / 180;

// Tick Duration
function tickDurationForEvent(nextTableState) {
  switch (nextTableState.last_event[0]) {
    case 'start-hand':
      return 750;
    case 'complete-hand':
      return 750;
    case 'resolve-hand':
      return 0;
    case 'muck-hand':
      return 750;
    case 'assign-button':
      return 750;
    case 'post-antes':
      return 0;
    case 'post-blind':
      return 750;
    case 'deal-hands':
      return 750;
    case 'deal-flop':
      if (
        nextTableState.active_hand_state.receipt &&
        nextTableState.active_hand_state.receipt.all_in
      ) {
        return 2000;
      } else {
        return 200;
      }
    case 'deal-turn':
      if (
        nextTableState.active_hand_state.receipt &&
        nextTableState.active_hand_state.receipt.all_in
      ) {
        return 2000;
      } else {
        return 200;
      }
    case 'deal-river':
      if (
        nextTableState.active_hand_state.receipt &&
        nextTableState.active_hand_state.receipt.all_in
      ) {
        return 2000;
      } else {
        return 200;
      }
    case 'assign-actor':
      return 0;
    case 'bet':
      return 750;
    case 'raise':
      return 750;
    case 'call':
      return 750;
    case 'check':
      return 750;
    case 'fold':
      return 750;
    case 'return-uncalled-bet':
      return 0;
    case 'consolidate-chips':
      return 750;
    case 'pay-out-winners':
      if (
        nextTableState.active_hand_state.receipt &&
        nextTableState.active_hand_state.receipt.showdown
      ) {
        return 5000;
      } else {
        return 2000;
      }
    case 'reveal-hand':
      if (
        nextTableState.active_hand_state.receipt &&
        nextTableState.active_hand_state.receipt.win_probabilities &&
        nextTableState.active_hand_state.receipt.win_probabilities.river &&
        nextTableState.active_hand_state.receipt.win_probabilities.river
          .length ===
          nextTableState.active_hand_state.players.filter((p) => {
            return p && p['hand_status'] === 'revealed';
          }).length
      ) {
        return 3000;
      } else {
        return 2000;
      }
    default:
      return 750;
  }
}

// Calculating Coordinate Functions
function calculatePlayerCoordinates(numberOfSeats, dimensions) {
  if (numberOfSeats === 10) {
    let angles0 = { a: 50.15, b: 82.52, c: 47.33 };
    let angles1 = { a: 29.76, b: 108.88, c: 41.36, gx: 39.67 };
    let angles2 = { a: 21.56, b: 80.72, c: 77.72, gx: 9.63 };
    let angles3 = { a: 27.96, b: 40.27, c: 111.77, gx: 12.04 };
    let angles4 = { gx: 40.095 };
    let side0c1 =
      dimensions.tableHeight / 2 +
      dimensions.playerBoxHeight / 2 +
      0.25 * dimensions.playerBoxHeight;
    let side0c2 =
      dimensions.tableHeight / 2 +
      dimensions.playerBoxHeight / 2 +
      0.083 * dimensions.playerBoxHeight;
    let side0b =
      (side0c2 * Math.sin(angles0.b * DEG)) / Math.sin(angles0.c * DEG);
    let side1b =
      (side0b * Math.sin(angles1.b * DEG)) / Math.sin(angles1.c * DEG);
    let side2b =
      (side1b * Math.sin(angles2.b * DEG)) / Math.sin(angles2.c * DEG);
    let side3b =
      (side2b * Math.sin(angles3.b * DEG)) / Math.sin(angles3.c * DEG);
    return [
      { x: dimensions.canvasCenterX, y: dimensions.canvasCenterY + side0c1 },
      {
        x: dimensions.canvasCenterX - side0b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles1.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side1b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side2b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side2b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side3b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX,
        y:
          dimensions.canvasCenterY -
          dimensions.tableHeight / 2 -
          dimensions.playerBoxHeight / 2 +
          0.216 * dimensions.playerBoxHeight,
      },
      {
        x: dimensions.canvasCenterX + side3b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side2b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side2b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side1b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side0b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles1.gx * DEG),
      },
    ];
  }
}
function calculateBettingDiskCoordinates(numberOfSeats, dimensions) {
  if (numberOfSeats === 10) {
    let angles0 = { a: 66.52, b: 84.48, c: 29.0 };
    let angles1 = { a: 12.9, b: 140.36, c: 26.74, gx: 23.025 };
    let angles2 = { a: 22.96, b: 78.23, c: 78.81, gx: 10.282 };
    let angles3 = { a: 19.31, b: 43.19, c: 117.5, gx: 12.84 };
    let angles4 = { gx: 32.03 };
    let side0c = 0.301 * dimensions.tableHeight;
    let side0b =
      (side0c * Math.sin(angles0.b * DEG)) / Math.sin(angles0.c * DEG);
    let side1b =
      (side0b * Math.sin(angles1.b * DEG)) / Math.sin(angles1.c * DEG);
    let side2b =
      (side1b * Math.sin(angles2.b * DEG)) / Math.sin(angles2.c * DEG);
    let side3b =
      (side2b * Math.sin(angles3.b * DEG)) / Math.sin(angles3.c * DEG);
    return [
      {
        x: dimensions.canvasCenterX,
        y: dimensions.canvasCenterY + 0.301 * dimensions.tableHeight,
      },
      {
        x: dimensions.canvasCenterX - side0b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles1.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side1b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side2b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side2b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side3b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX,
        y: dimensions.canvasCenterY - 0.7197 * (dimensions.tableHeight / 2),
      },
      {
        x: dimensions.canvasCenterX + side3b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side2b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side2b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side1b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side0b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles1.gx * DEG),
      },
    ];
  }
}
function calculateButtonCoordinates(numberOfSeats, dimensions) {
  if (numberOfSeats === 10) {
    let angles0 = { a: 26.32, b: 90, c: 63.68, gx: 63.675 };
    let angles1 = { a: 42.34, b: 106.96, c: 30.7, gx: 21.34 };
    let angles2 = { a: 18.945, b: 86.257, c: 74.798, gx: 2.394 };
    let angles3 = { a: 20.47, b: 77.67, c: 81.86, gx: 18.36 };
    let angles4 = { a: 30.74, b: 34.97, c: 114.29, gx: 49.397 };
    let angles5 = { a: 71.04, b: 48.71, c: 60.25, gx: 60.78 };
    let angles6 = { a: 35.62, b: 112.04, c: 32.34, gx: 24.93 };
    let angles7 = { a: 19.29, b: 92.51, c: 68.2, gx: 5.65 };
    let angles8 = { a: 22.17, b: 77.97, c: 79.81, gx: 16.86 };
    let angles9 = { a: 32.28, b: 34.7, c: 113.02, gx: 49.247 };
    let side0c = 0.4456 * dimensions.tableHeight;
    let side0b =
      (side0c * Math.sin(angles0.b * DEG)) / Math.sin(angles0.c * DEG);
    let side1b =
      (side0b * Math.sin(angles1.b * DEG)) / Math.sin(angles1.c * DEG);
    let side2b =
      (side1b * Math.sin(angles2.b * DEG)) / Math.sin(angles2.c * DEG);
    let side3b =
      (side2b * Math.sin(angles3.b * DEG)) / Math.sin(angles3.c * DEG);
    let side4b =
      (side3b * Math.sin(angles4.b * DEG)) / Math.sin(angles4.c * DEG);
    let side5b =
      (side4b * Math.sin(angles5.b * DEG)) / Math.sin(angles5.c * DEG);
    let side6b =
      (side5b * Math.sin(angles6.b * DEG)) / Math.sin(angles6.c * DEG);
    let side7b =
      (side6b * Math.sin(angles7.b * DEG)) / Math.sin(angles7.c * DEG);
    let side8b =
      (side7b * Math.sin(angles8.b * DEG)) / Math.sin(angles8.c * DEG);
    let side9b =
      (side8b * Math.sin(angles9.b * DEG)) / Math.sin(angles9.c * DEG);
    return [
      {
        x: dimensions.canvasCenterX - side0b * Math.cos(angles0.gx * DEG),
        y: dimensions.canvasCenterY + side0b * Math.sin(angles0.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side1b * Math.cos(angles1.gx * DEG),
        y: dimensions.canvasCenterY + side1b * Math.sin(angles1.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side2b * Math.cos(angles2.gx * DEG),
        y: dimensions.canvasCenterY + side2b * Math.sin(angles2.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side3b * Math.cos(angles3.gx * DEG),
        y: dimensions.canvasCenterY - side3b * Math.sin(angles3.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX - side4b * Math.cos(angles4.gx * DEG),
        y: dimensions.canvasCenterY - side4b * Math.sin(angles4.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side5b * Math.cos(angles5.gx * DEG),
        y: dimensions.canvasCenterY - side5b * Math.sin(angles5.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side6b * Math.cos(angles6.gx * DEG),
        y: dimensions.canvasCenterY - side6b * Math.sin(angles6.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side7b * Math.cos(angles7.gx * DEG),
        y: dimensions.canvasCenterY - side7b * Math.sin(angles7.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side8b * Math.cos(angles8.gx * DEG),
        y: dimensions.canvasCenterY + side8b * Math.sin(angles8.gx * DEG),
      },
      {
        x: dimensions.canvasCenterX + side9b * Math.cos(angles9.gx * DEG),
        y: dimensions.canvasCenterY + side9b * Math.sin(angles9.gx * DEG),
      },
    ];
  }
}

// Drawing Functions
function drawTable(ctx, dimensions) {
  ctx.shadowOffsetX = dimensions.tableShadowOffsetX;
  ctx.shadowOffsetY = dimensions.tableShadowOffsetY;
  ctx.shadowBlur = dimensions.tableShadowBlur;
  ctx.shadowColor = 'black';
  ctx.drawImage(
    tableImage,
    dimensions.tableStartX,
    dimensions.tableStartY,
    dimensions.tableWidth,
    dimensions.tableHeight,
  );
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.shadowBlur = 0;
}

function drawCards(ctx, tableState, playerUUID, coordinates, dimensions) {
  let playerIndex = _.findIndex(tableState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let offsetPlayers = JSON.parse(
    JSON.stringify(tableState.active_hand_state.players),
  );
  let playerIndexCounter = JSON.parse(JSON.stringify(playerIndex));
  if (playerIndex >= 0) {
    while (playerIndexCounter--) offsetPlayers.push(offsetPlayers.shift());
  }
  coordinates.forEach(({ x, y }, index) => {
    ctx.globalAlpha = 1;
    let player = offsetPlayers[index];
    if (player) {
      let cardOne, cardTwo;
      if (
        (player.player_uuid === playerUUID ||
          player.hand_status === 'revealed') &&
        player.hand.length > 0
      ) {
        cardOne = loadedCardImages.find(
          (c) =>
            c.rank === player.hand[0].rank && c.suit === player.hand[0].suit,
        ).img;
        cardTwo = loadedCardImages.find(
          (c) =>
            c.rank === player.hand[1].rank && c.suit === player.hand[1].suit,
        ).img;
        y = y - dimensions.cardHeight / 4;
      } else {
        cardOne = loadedCardBack;
        cardTwo = loadedCardBack;
      }
      if (player.hand_status === 'folded') {
        if (player.player_uuid === playerUUID) {
          ctx.globalAlpha = 0.2;
        } else {
          ctx.globalAlpha = 0;
        }
      }
      ctx.drawImage(
        cardOne,
        x - dimensions.cardWidth - 1,
        y - dimensions.cardHeight,
        dimensions.cardWidth,
        dimensions.cardHeight,
      );
      ctx.drawImage(
        cardTwo,
        x + 1,
        y - dimensions.cardHeight,
        dimensions.cardWidth,
        dimensions.cardHeight,
      );
    }
  });
  ctx.globalAlpha = 1;
}

function drawPlayers(
  ctx,
  tableState,
  playerUUID,
  isJoinable,
  coordinates,
  lastEvent,
  shotClock,
  dimensions,
) {
  let tablePlayerIndex = _.findIndex(tableState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let handPlayerIndex = _.findIndex(
    tableState.active_hand_state.players,
    (player) => {
      return player && player.player_uuid === playerUUID;
    },
  );
  let offsetTablePlayers = JSON.parse(JSON.stringify(tableState.players));
  let offsetHandPlayers = JSON.parse(
    JSON.stringify(tableState.active_hand_state.players),
  );
  let tablePlayerIndexCounter =
    tablePlayerIndex >= 0 ? JSON.parse(JSON.stringify(tablePlayerIndex)) : 0;
  let handPlayerIndexCounter =
    handPlayerIndex >= 0
      ? JSON.parse(JSON.stringify(handPlayerIndex))
      : tablePlayerIndex >= 0
      ? JSON.parse(JSON.stringify(tablePlayerIndex))
      : 0;
  while (tablePlayerIndexCounter--)
    offsetTablePlayers.push(offsetTablePlayers.shift());
  while (handPlayerIndexCounter--)
    offsetHandPlayers.push(offsetHandPlayers.shift());
  coordinates.forEach(({ x, y }, index) => {
    let tablePlayer = offsetTablePlayers[index];
    let handPlayer = offsetHandPlayers[index];
    if (handPlayer && !tablePlayer) {
      // situation occurs when player busts from tournament
      if (tablePlayerIndex > -1) {
        // from other players perspective
        tablePlayer = handPlayer;
      } else {
        // from the player who busts perspective
        offsetHandPlayers = JSON.parse(
          JSON.stringify(tableState.active_hand_state.players),
        );
        handPlayer = offsetHandPlayers[index];
        tablePlayer = handPlayer;
      }
    }
    let playerSittingAtTable =
      offsetTablePlayers.findIndex(
        (player) => player && player.player_uuid === playerUUID,
      ) > -1;
    let isActivePlayer =
      (index +
        (handPlayerIndex >= 0
          ? handPlayerIndex
          : tablePlayerIndex >= 0
          ? tablePlayerIndex
          : 0)) %
        10 ===
        tableState.active_hand_state.actor_index &&
      !tableState.active_hand_state.receipt;
    let isWinningPlayer =
      tableState.active_hand_state.receipt &&
      handPlayer &&
      _.some(tableState.active_hand_state.receipt.winners, (winner) => {
        return winner.player_uuid === handPlayer.player_uuid;
      });
    if (isWinningPlayer) {
      var winningPlayerPots =
        tableState.active_hand_state.receipt.winners.filter(
          (winner) => winner.player_uuid === handPlayer.player_uuid,
        );
      var winningPlayerChipDelta =
        winningPlayerPots[0].chip_delta +
        _.sum(winningPlayerPots.slice(1).map((x) => x.total_amount));
    }

    if (tablePlayer) {
      if (isWinningPlayer && lastEvent === 'pay-out-winners') {
        ctx.fillStyle = THEME.playerDisplayBoxBackground;
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 4;
        ctx.shadowColor = THEME.playerDisplayBoxBorder;
        ctx.shadowBlur = dimensions.playerBoxWidth / 4;

        let chipDeltaText = numberDisplay(
          winningPlayerChipDelta / 100,
          tableState.decimal_display,
        );
        let annexExtensionMultiple = Math.max(0, chipDeltaText.length - 1);
        let annexExtensionWidth =
          dimensions.playerBoxAnnexWidth +
          annexExtensionMultiple * (dimensions.playerBoxAnnexWidth / 7);

        ctx.roundRect(
          _.includes([2, 3], index)
            ? x - dimensions.playerBoxWidth / 2.05 - annexExtensionWidth
            : x + dimensions.playerBoxWidth / 2.05,
          y - dimensions.playerBoxHeight / 4,
          annexExtensionWidth,
          dimensions.playerBoxAnnexHeight,
          _.includes([2, 3], index)
            ? {
                upperLeft: 0.25 * dimensions.playerBoxAnnexWidth,
                upperRight: 0,
                lowerLeft: 0.25 * dimensions.playerBoxAnnexWidth,
                lowerRight: 0,
              }
            : {
                upperLeft: 0,
                upperRight: 0.25 * dimensions.playerBoxAnnexWidth,
                lowerLeft: 0,
                lowerRight: 0.25 * dimensions.playerBoxAnnexWidth,
              },
          true,
          true,
        );

        ctx.font = `${dimensions.tableWidth / 60}px Lato-Regular`;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = THEME.playerDisplayBoxText;
        ctx.fillText(
          `${winningPlayerChipDelta >= 0 ? '+' : '-'}${chipDeltaText}`,
          _.includes([2, 3], index)
            ? x -
                dimensions.playerBoxAnnexWidth *
                  (2.475 + annexExtensionMultiple * 0.155)
            : x + dimensions.playerBoxAnnexWidth * 2.05,
          y - dimensions.playerBoxHeight / 10,
        );
      }

      let activePlayerBorderWidth = pulseActivePlayerBorder(
        dimensions.activePlayerBoxMinBorder,
        dimensions.activePlayerBoxMaxBorder,
        1000,
        'linear',
      );

      // Draw Player Box Shape
      if (isActivePlayer) {
        ctx.fillStyle = THEME.activePlayerDisplayBoxBackground;
        ctx.strokeStyle = THEME.activePlayerDisplayBoxBorder;
        ctx.lineWidth = activePlayerBorderWidth;
        ctx.shadowColor = 'rgba(40, 207, 117, 0.75)';
        ctx.shadowBlur = activePlayerBorderWidth / 2;
      } else if (isWinningPlayer && lastEvent === 'pay-out-winners') {
        ctx.fillStyle = THEME.playerDisplayBoxBackground;
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 4;
        ctx.shadowColor = THEME.playerDisplayBoxBorder;
        ctx.shadowBlur = dimensions.playerBoxWidth / 4;
      } else if (handPlayer && handPlayer.hand_status !== 'folded') {
        ctx.fillStyle = THEME.playerDisplayBoxBackground;
        ctx.strokeStyle = '#b3b3b3';
        ctx.lineWidth = THEME.playerDisplayBoxBorderWidth;
        ctx.shadowBlur = 0;
      } else {
        ctx.fillStyle = THEME.playerDisplayBoxBackground;
        ctx.strokeStyle = THEME.playerDisplayBoxBorder;
        ctx.lineWidth = THEME.playerDisplayBoxBorderWidth;
        ctx.shadowBlur = 0;
      }
      if (
        isActivePlayer &&
        lastEvent === 'assign-actor' &&
        shotClock <= 10000
      ) {
        ctx.fillStyle = THEME.shotClockLowPlayerDisplayBoxBackground;
        ctx.strokeStyle = THEME.shotClockLowPlayerDisplayBoxBorder;
        ctx.lineWidth = activePlayerBorderWidth;
        ctx.shadowColor = THEME.shotClockLowPlayerDisplayBoxBorder;
        ctx.shadowBlur = activePlayerBorderWidth / 2;
      }

      ctx.roundRect(
        x - dimensions.playerBoxWidth / 2,
        y - dimensions.playerBoxHeight / 2,
        dimensions.playerBoxWidth,
        dimensions.playerBoxHeight,
        {
          upperLeft: dimensions.playerBoxHeight / 2,
          upperRight: dimensions.playerBoxHeight / 2,
          lowerLeft: dimensions.playerBoxHeight / 2,
          lowerRight: dimensions.playerBoxHeight / 2,
        },
        true,
        true,
      );

      // Draw Top Half Player Box Content
      ctx.fillStyle = isActivePlayer
        ? THEME.activePlayerDisplayBoxText
        : THEME.playerDisplayBoxText;
      if (handPlayer && handPlayer.hand_status !== 'folded') {
        ctx.fillStyle = THEME.playerDisplayBoxText;
      } else {
        ctx.fillStyle = 'rgba(255, 255, 255, 0.55)';
      }
      ctx.font = `${dimensions.tableWidth / 52}px Lato-Bold`;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.shadowBlur = 0;
      if (isActivePlayer && lastEvent === 'fold') {
        ctx.fillText(
          'Fold',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (isActivePlayer && lastEvent === 'check') {
        ctx.fillText(
          'Check',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (isActivePlayer && lastEvent === 'bet') {
        ctx.fillText(
          'Bet',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (isActivePlayer && lastEvent === 'raise') {
        ctx.fillText(
          'Raise',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (isActivePlayer && lastEvent === 'call') {
        ctx.fillText(
          'Call',
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (
        isActivePlayer &&
        lastEvent === 'assign-actor' &&
        shotClock <= 1000
      ) {
        ctx.fillStyle = THEME.shotClockLowPlayerDisplayBoxText;
        ctx.fillText(
          `1 second...`,
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else if (
        isActivePlayer &&
        lastEvent === 'assign-actor' &&
        shotClock <= 10000
      ) {
        ctx.fillStyle = THEME.shotClockLowPlayerDisplayBoxText;
        ctx.fillText(
          `${Math.ceil(shotClock / 1000)} seconds...`,
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      } else {
        ctx.fillText(
          userNameDisplay(tablePlayer.display),
          x - dimensions.playerBoxWidth / 2.5,
          y - dimensions.playerBoxHeight / 7,
        );
      }

      // Draw Bottom Half Player Box Content
      ctx.fillStyle = THEME.playerDisplayBoxText;
      if (handPlayer && handPlayer.hand_status !== 'folded') {
        ctx.fillStyle = THEME.playerDisplayBoxText;
      } else {
        ctx.fillStyle = 'rgba(255, 255, 255, 0.55)';
      }
      ctx.font = `${dimensions.tableWidth / 52}px Lato-Regular`;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.shadowBlur = 0;
      if (handPlayer && tablePlayer.table_status === 'blinding-out') {
        ctx.fillText(
          '(Sitting Out)',
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      } else if (
        handPlayer &&
        handPlayer.hand_status === 'folded' &&
        tablePlayer.table_status === 'sitting_out'
      ) {
        ctx.fillText(
          '(Sitting Out)',
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      } else if (handPlayer) {
        let chipStackText = numberDisplay(
          handPlayer.chip_stack_amount / 100,
          tableState.decimal_display,
        );
        ctx.fillText(
          chipStackText,
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      } else if (tablePlayer.table_status === 'reserved') {
        ctx.fillText(
          '(Reserved)',
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      } else if (
        _.includes(['sitting_out', 'blinding-out'], tablePlayer.table_status)
      ) {
        ctx.fillText(
          '(Sitting Out)',
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      } else if (tablePlayer.table_status === 'pending_join') {
        ctx.fillText(
          'Requesting...',
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      } else if (tablePlayer.leaving_table) {
        ctx.fillText(
          '(Leaving)',
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      } else {
        let chipStackText = numberDisplay(
          tablePlayer.chip_stack_amount / 100,
          tableState.decimal_display,
        );
        ctx.fillText(
          chipStackText,
          x - dimensions.playerBoxWidth / 2.5,
          y + dimensions.playerBoxHeight / 7,
        );
      }

      ctx.font = `${dimensions.tableWidth / 62}px Lato-Regular`;
      ctx.textAlign = 'right';
      ctx.shadowBlur = 0;

      let handsWon = 0;
      if (tablePlayer.hands_won) {
        handsWon = tablePlayer.hands_won;
      }
      ctx.fillText(
        `🏆 ${handsWon}`,
        x + dimensions.playerBoxWidth / 2.8,
        y + dimensions.playerBoxHeight / 7,
      );

      drawPlayerRunout(
        tableState.active_hand_state,
        ctx,
        dimensions,
        x,
        y,
        tablePlayerIndex,
        lastEvent,
        index,
      );

      if (
        handPlayer &&
        handPlayer.player_uuid === playerUUID &&
        handPlayer.hand_emoji_description
      ) {
        ctx.textAlign = 'center';
        ctx.font = `${dimensions.tableWidth / 62}px Lato-Regular`;
        ctx.fillStyle = THEME.playerHandRank;

        if (handPlayer.hand_status === 'folded') {
          ctx.fillStyle = THEME.playerHandRankFolded;
        }

        ctx.fillText(
          handPlayer.hand_emoji_description,
          x,
          y - dimensions.playerBoxHeight / 4 + dimensions.playerBoxHeight,
        );
      }
    } else {
      // Empty Seat
      if (!playerSittingAtTable && isJoinable) {
        ctx.fillStyle = THEME.emptySeatPlayerDisplayBoxBackground;
        ctx.strokeStyle = THEME.emptySeatPlayerDisplayBoxBorder;
        ctx.lineWidth = THEME.emptySeatPlayerDisplayBoxBorderWidth;
        ctx.shadowBlur = 0;
        ctx.roundRect(
          x - dimensions.playerBoxWidth / 2,
          y - dimensions.playerBoxHeight / 2,
          dimensions.playerBoxWidth,
          dimensions.playerBoxHeight,
          {
            upperLeft: dimensions.playerBoxHeight / 2,
            upperRight: dimensions.playerBoxHeight / 2,
            lowerLeft: dimensions.playerBoxHeight / 2,
            lowerRight: dimensions.playerBoxHeight / 2,
          },
          true,
          true,
        );
        ctx.fillStyle = THEME.emptySeatPlayerDisplayBoxText;
        ctx.font = `${dimensions.tableWidth / 48}px Lato-Bold`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('+ Join table', x, y);
      }
    }
  });
  ctx.shadowBlur = 0;
}

function drawButton(ctx, tableState, playerUUID, coordinates, dimensions) {
  let playerIndex = _.findIndex(tableState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let buttonOffset =
    playerIndex >= 0
      ? (10 - playerIndex + tableState.current_button_index) % 10
      : tableState.current_button_index;
  if (tableState.current_button_index !== null) {
    ctx.drawImage(
      dealerButton,
      coordinates[buttonOffset].x - dimensions.dealerButtonRadius / 2,
      coordinates[buttonOffset].y - dimensions.dealerButtonRadius / 2,
      dimensions.dealerButtonRadius,
      dimensions.dealerButtonRadius,
    );
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 0;
  }
}

function drawBettingDisks(
  ctx,
  tableState,
  playerUUID,
  coordinates,
  dimensions,
  animationProgress,
) {
  ctx.fillStyle = THEME.chipsInFrontText;
  ctx.font = `${dimensions.tableWidth / 56}px Lato-Regular`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  let targetX = dimensions.canvasCenterX;
  let targetY = dimensions.canvasCenterY - dimensions.potOffsetY;

  let playerIndex = _.findIndex(tableState.players, (player) => {
    return player && player.player_uuid === playerUUID;
  });
  let offsetPlayers = JSON.parse(
    JSON.stringify(tableState.active_hand_state.players),
  );
  let playerIndexCounter = JSON.parse(JSON.stringify(playerIndex));
  if (playerIndex >= 0) {
    while (playerIndexCounter--) offsetPlayers.push(offsetPlayers.shift());
  }

  coordinates.forEach(({ x, y }, index) => {
    let renderX = x;
    let renderY = y;
    if (animationProgress > 0 && animationProgress <= 1) {
      if (renderX >= targetX) {
        renderX = renderX - (renderX - targetX) * animationProgress;
      } else {
        renderX = renderX + (targetX - renderX) * animationProgress;
      }
      if (renderY >= targetY) {
        renderY = renderY - (renderY - targetY) * animationProgress;
      } else {
        renderY = renderY + (targetY - renderY) * animationProgress;
      }
    }

    let player = offsetPlayers[index];
    if (player && player.chip_front_amount > 0) {
      let betDisk = betDisk1;
      let betDiskWidth = dimensions.betDisk1Width;
      let betDiskHeight = dimensions.betDisk1Height;
      if (player.bet_level >= 4) {
        betDisk = betDisk4;
        betDiskWidth = dimensions.betDisk4Width;
        betDiskHeight = dimensions.betDisk4Height;
      } else if (player.bet_level === 3) {
        betDisk = betDisk3;
        betDiskWidth = dimensions.betDisk3Width;
        betDiskHeight = dimensions.betDisk3Height;
      } else if (player.bet_level === 2) {
        betDisk = betDisk2;
        betDiskWidth = dimensions.betDisk2Width;
        betDiskHeight = dimensions.betDisk2Height;
      }

      if (player.hand_status === 'active') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'covered-by-blind') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'bet') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'called') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'raised') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'checked') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      } else if (player.hand_status === 'folded') {
        ctx.drawImage(
          betDisk,
          renderX - betDiskWidth / 2,
          renderY - betDiskHeight / 2,
          betDiskWidth,
          betDiskHeight,
        );
      }
      let displayText = numberDisplay(
        player.chip_front_amount / 100,
        tableState.decimal_display,
      );
      ctx.fillText(displayText, renderX, renderY);
    }
  });
}

function drawCommunityCards(
  ctx,
  tableState,
  lastEvent,
  dimensions,
  animationProgress,
) {
  let startPoint = {
    x:
      dimensions.canvasCenterX -
      dimensions.cardWidth / 2 -
      2 * (dimensions.cardWidth + dimensions.communityCardMargin),
    y: dimensions.canvasCenterY - dimensions.cardHeight / 2,
  };
  let renderPoint = { x: startPoint.x, y: startPoint.y };
  tableState.active_hand_state.community_cards.forEach((card, index) => {
    let communityCard = loadedCardImages.find(
      (c) => c.rank === card.rank && c.suit === card.suit,
    );
    if (
      lastEvent === 'pay-out-winners' &&
      tableState.active_hand_state &&
      tableState.active_hand_state.receipt &&
      !_.isEmpty(tableState.active_hand_state.receipt.winning_cards)
    ) {
      if (
        tableState.active_hand_state.receipt.winning_cards.find((card) => {
          return (
            card.rank === communityCard.rank && card.suit === communityCard.suit
          );
        })
      ) {
        ctx.globalAlpha = 1;
        renderPoint.y =
          startPoint.y -
          (dimensions.cardHeight / 8) * Math.min(1, animationProgress);
      } else {
        renderPoint.y = startPoint.y;
        ctx.globalAlpha = 0.4;
      }
    }
    ctx.drawImage(
      communityCard.img,
      renderPoint.x +
        index * (dimensions.cardWidth + dimensions.communityCardMargin),
      renderPoint.y,
      dimensions.cardWidth,
      dimensions.cardHeight,
    );
    ctx.globalAlpha = 1;
  });
}

function drawPot(
  ctx,
  tableState,
  playerUUID,
  coordinates,
  lastEvent,
  dimensions,
  animationProgress,
) {
  ctx.fillStyle = THEME.potAmountText;
  ctx.font = `${dimensions.tableWidth / 56}px Lato-Regular`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  let sourceX = dimensions.canvasCenterX;
  let sourceY = dimensions.canvasCenterY - dimensions.potOffsetY;

  let renderPoints = tableState.active_hand_state.pots.map((pot, index) => {
    return {
      renderX: sourceX + dimensions.potDiskMargin * index,
      renderY: sourceY,
    };
  });
  let targetPoints = [];

  if (lastEvent === 'pay-out-winners') {
    if (animationProgress > 0) {
      tableState.last_event[1]['payouts'].forEach((payout, index) => {
        let playerIndex = _.findIndex(tableState.players, (player) => {
          return player && player.player_uuid === playerUUID;
        });
        let offset =
          playerIndex >= 0
            ? (10 - playerIndex + payout['seat_index']) % 10
            : payout['seat_index'];
        targetPoints[index] = {
          targetX: coordinates[offset].x,
          targetY: coordinates[offset].y,
        };
        tableState.active_hand_state.pots[index] = { amount: payout['amount'] };
        renderPoints[index] = { renderX: sourceX, renderY: sourceY };
      });
    }
    if (animationProgress > 0 && animationProgress <= 1) {
      tableState.last_event[1]['payouts'].forEach((payout, index) => {
        if (renderPoints[index].renderX >= targetPoints[index].targetX) {
          renderPoints[index].renderX =
            renderPoints[index].renderX -
            (renderPoints[index].renderX - targetPoints[index].targetX) *
              animationProgress;
        } else {
          renderPoints[index].renderX =
            renderPoints[index].renderX +
            (targetPoints[index].targetX - renderPoints[index].renderX) *
              animationProgress;
        }
        if (renderPoints[index].renderY >= targetPoints[index].targetY) {
          renderPoints[index].renderY =
            renderPoints[index].renderY -
            (renderPoints[index].renderY - targetPoints[index].targetY) *
              animationProgress;
        } else {
          renderPoints[index].renderY =
            renderPoints[index].renderY +
            (targetPoints[index].targetY - renderPoints[index].renderY) *
              animationProgress;
        }
      });
    } else if (animationProgress > 1) {
      tableState.last_event[1]['payouts'].forEach((payout, index) => {
        renderPoints[index].renderX = targetPoints[index].targetX;
        renderPoints[index].renderY = targetPoints[index].targetY;
      });
    }
  }

  if (
    lastEvent === 'reveal-hand' &&
    tableState.active_hand_state.receipt &&
    tableState.active_hand_state.hand_state === 'completed'
  ) {
    tableState.active_hand_state.receipt['winners'].forEach((winner, index) => {
      let playerIndex = _.findIndex(tableState.players, (player) => {
        return player && player.player_uuid === playerUUID;
      });
      let winnerIndex = _.findIndex(tableState.players, (player) => {
        return player && player.player_uuid === winner.player_uuid;
      });
      let offset =
        playerIndex >= 0 ? (10 - playerIndex + winnerIndex) % 10 : winnerIndex;
      targetPoints[index] = {
        targetX: coordinates[offset].x,
        targetY: coordinates[offset].y,
      };
      tableState.active_hand_state.pots[index] = {
        amount: winner['total_amount'],
      };
      renderPoints[index] = { renderX: sourceX, renderY: sourceY };
    });

    tableState.active_hand_state.receipt['winners'].forEach((winner, index) => {
      renderPoints[index].renderX = targetPoints[index].targetX;
      renderPoints[index].renderY = targetPoints[index].targetY;
    });
  }

  tableState.active_hand_state.pots.forEach((potDisk, index) => {
    if (potDisk.amount > 0) {
      ctx.drawImage(
        potDiskImg,
        renderPoints[index].renderX - dimensions.potDiskWidth / 2,
        renderPoints[index].renderY - dimensions.potDiskHeight / 2,
        dimensions.potDiskWidth,
        dimensions.potDiskHeight,
      );
      let potText = numberDisplay(
        potDisk.amount / 100,
        tableState.decimal_display,
      );
      ctx.fillText(
        potText,
        renderPoints[index].renderX + dimensions.betDiskTextOffset,
        renderPoints[index].renderY + 1,
      );
    }
  });

  ctx.font = `${dimensions.tableWidth / 56}px Lato-Regular`;
  let totalText = numberDisplay(
    tableState.active_hand_state.pot_size / 100,
    tableState.decimal_display,
  );
  ctx.fillText(
    `Total: ${totalText}`,
    dimensions.canvasCenterX,
    dimensions.canvasCenterY - dimensions.potTextOffsetY,
  );
}

function renderTable(
  ctx,
  tableState,
  playerUUID,
  isJoinable,
  lastEvent,
  shotClock,
  animationProgress,
  windowDimensions,
) {
  // Clear Canvas
  ctx.clearRect(0, 0, windowDimensions.width, windowDimensions.height);

  // Define Dimensions
  let dimensions = calculateTableDimensions(windowDimensions);

  // Define Variables
  let playerCoordinates = calculatePlayerCoordinates(10, dimensions);
  let buttonCoordinates = calculateButtonCoordinates(10, dimensions);
  let bettingDiskCoordinates = calculateBettingDiskCoordinates(10, dimensions);

  if (tableState.mode === 'pending-start') {
    drawTable(ctx, dimensions);
    drawPlayers(
      ctx,
      tableState,
      playerUUID,
      isJoinable,
      playerCoordinates,
      lastEvent,
      shotClock,
      dimensions,
    );
  }

  if (tableState.mode === 'paused') {
    drawTable(ctx, dimensions);
    drawCards(ctx, tableState, playerUUID, playerCoordinates, dimensions);
    drawPlayers(
      ctx,
      tableState,
      playerUUID,
      isJoinable,
      playerCoordinates,
      lastEvent,
      shotClock,
      dimensions,
    );
    drawButton(ctx, tableState, playerUUID, buttonCoordinates, dimensions);
    drawBettingDisks(
      ctx,
      tableState,
      playerUUID,
      bettingDiskCoordinates,
      dimensions,
      lastEvent === 'consolidate-chips' ? animationProgress : 0,
    );
    drawCommunityCards(
      ctx,
      tableState,
      lastEvent,
      dimensions,
      lastEvent === 'pay-out-winners' ? animationProgress : 0,
    );
    drawPot(
      ctx,
      tableState,
      playerUUID,
      bettingDiskCoordinates,
      lastEvent,
      dimensions,
      _.includes(['pay-out-winners', 'reveal-hand'], lastEvent)
        ? animationProgress
        : 0,
    );
  }

  if (tableState.mode === 'pending-player-join') {
    drawTable(ctx, dimensions);
    drawPlayers(
      ctx,
      tableState,
      playerUUID,
      isJoinable,
      playerCoordinates,
      lastEvent,
      shotClock,
      dimensions,
    );
  }

  if (tableState.mode === 'pending-complete') {
    drawTable(ctx, dimensions);
    drawCards(ctx, tableState, playerUUID, playerCoordinates, dimensions);
    drawPlayers(
      ctx,
      tableState,
      playerUUID,
      isJoinable,
      playerCoordinates,
      lastEvent,
      shotClock,
      dimensions,
    );
    drawButton(ctx, tableState, playerUUID, buttonCoordinates, dimensions);
    drawBettingDisks(
      ctx,
      tableState,
      playerUUID,
      bettingDiskCoordinates,
      dimensions,
      lastEvent === 'consolidate-chips' ? animationProgress : 0,
    );
    drawCommunityCards(
      ctx,
      tableState,
      lastEvent,
      dimensions,
      lastEvent === 'pay-out-winners' ? animationProgress : 0,
    );
    drawPot(
      ctx,
      tableState,
      playerUUID,
      bettingDiskCoordinates,
      lastEvent,
      dimensions,
      _.includes(['pay-out-winners', 'reveal-hand'], lastEvent)
        ? animationProgress
        : 0,
    );
  }

  if (tableState.mode === 'completed') {
    drawTable(ctx, dimensions);
    drawPlayers(
      ctx,
      tableState,
      playerUUID,
      isJoinable,
      playerCoordinates,
      lastEvent,
      shotClock,
      dimensions,
    );
  }

  if (tableState.mode === 'active') {
    drawTable(ctx, dimensions);
    drawCards(ctx, tableState, playerUUID, playerCoordinates, dimensions);
    drawPlayers(
      ctx,
      tableState,
      playerUUID,
      isJoinable,
      playerCoordinates,
      lastEvent,
      shotClock,
      dimensions,
    );
    drawButton(ctx, tableState, playerUUID, buttonCoordinates, dimensions);
    drawBettingDisks(
      ctx,
      tableState,
      playerUUID,
      bettingDiskCoordinates,
      dimensions,
      lastEvent === 'consolidate-chips' ? animationProgress : 0,
    );
    drawCommunityCards(
      ctx,
      tableState,
      lastEvent,
      dimensions,
      lastEvent === 'pay-out-winners' ? animationProgress : 0,
    );
    drawPot(
      ctx,
      tableState,
      playerUUID,
      bettingDiskCoordinates,
      lastEvent,
      dimensions,
      _.includes(['pay-out-winners', 'reveal-hand'], lastEvent)
        ? animationProgress
        : 0,
    );
  }
}

export default function PokerTable({
  canvasID,
  tableState,
  playerUUID,
  isJoinable,
  reserveSeat,
  shotClock,
  processTableStateRender,
  renderDimensions,
  playerIsHost,
  setHostActionTargetPlayer,
}) {
  tableImage.src = tableState.table_image_uri;
  loadedCardBack.src = tableState.card_back_image_uri;

  // Define Refs
  const shouldAnimate = React.useRef(false);
  const startAnimationFrameRef = React.useRef(0);
  const endTickFrameRef = React.useRef(0);
  const lastEventRef = React.useRef('');
  const prevTableStateRef = React.useRef(null);
  const tableStateRef = React.useRef(tableState);
  const shotClockRef = React.useRef(shotClock);
  const playerUUIDRef = React.useRef(playerUUID);
  const isJoinableRef = React.useRef(isJoinable);
  const windowDimensionsRef = React.useRef(renderDimensions);
  const tableStateAnimationQueueRef = React.useRef([]);
  const canvasIsVisible = usePageVisibility();
  const { show: showContextMenu } = useContextMenu({
    id: HOST_PLAYER_ACTIONS_CONTEXT_MENU_ID,
  });

  const advanceNextState = () => {
    let nextTableState = tableStateAnimationQueueRef.current.shift();
    if (
      nextTableState.last_event &&
      !(
        tableStateRef.current.last_event &&
        tableStateRef.current.last_event[0] === 'pay-out-winners' &&
        nextTableState.last_event[0] === 'pay-out-winners'
      )
    ) {
      prevTableStateRef.current = tableStateRef.current;
      tableStateRef.current = nextTableState;
      if (
        _.includes(
          ['consolidate-chips', 'pay-out-winners'],
          nextTableState.last_event[0],
        )
      ) {
        shouldAnimate.current = true;
        startAnimationFrameRef.current = window.animationFrame;
      }
      lastEventRef.current = nextTableState.last_event[0];
      endTickFrameRef.current =
        window.animationFrame + tickDurationForEvent(nextTableState);
      processTableStateRender(nextTableState);
    }
    if (
      _.includes(
        ['pending-start', 'completed', 'pending-player-join'],
        nextTableState.mode,
      )
    ) {
      prevTableStateRef.current = tableStateRef.current;
      tableStateRef.current = nextTableState;
    }
  };

  // 1. Set Animation Loop
  const animationLoop = (timestamp) => {
    let canvas = document.querySelector(`canvas#${canvasID}`);

    if (canvas) {
      canvas.width = windowDimensionsRef.current.width;
      canvas.height = windowDimensionsRef.current.height;
      let ctx = canvas.getContext('2d');

      // Initial Render
      if (
        !tableStateRef.current &&
        tableStateAnimationQueueRef.current.length > 0
      ) {
        let nextTableState = tableStateAnimationQueueRef.current.shift();
        prevTableStateRef.current = nextTableState;
        tableStateRef.current = nextTableState;
      }

      // Advance Next State
      if (
        tableStateRef.current &&
        tableStateAnimationQueueRef.current.length > 0 &&
        window.animationFrame >= endTickFrameRef.current
      ) {
        advanceNextState();
      }

      // Render Current Table State
      if (tableStateRef.current) {
        if (shouldAnimate.current === true) {
          let tickDuration = 400;
          let animationProgress =
            (tickDuration -
              (startAnimationFrameRef.current +
                tickDuration -
                window.animationFrame)) /
            tickDuration;
          if (animationProgress <= 1) {
            if (lastEventRef.current === 'consolidate-chips') {
              renderTable(
                ctx,
                prevTableStateRef.current,
                playerUUIDRef.current,
                isJoinableRef.current,
                lastEventRef.current,
                shotClockRef.current,
                animationProgress,
                windowDimensionsRef.current,
              );
            } else if (lastEventRef.current === 'pay-out-winners') {
              renderTable(
                ctx,
                tableStateRef.current,
                playerUUIDRef.current,
                isJoinableRef.current,
                lastEventRef.current,
                shotClockRef.current,
                animationProgress,
                windowDimensionsRef.current,
              );
            } else {
              renderTable(
                ctx,
                tableStateRef.current,
                playerUUIDRef.current,
                isJoinableRef.current,
                lastEventRef.current,
                shotClockRef.current,
                animationProgress,
                windowDimensionsRef.current,
              );
            }
          } else {
            if (
              lastEventRef.current === 'pay-out-winners' ||
              lastEventRef.current === 'reveal-hand'
            ) {
              renderTable(
                ctx,
                tableStateRef.current,
                playerUUIDRef.current,
                isJoinableRef.current,
                lastEventRef.current,
                shotClockRef.current,
                animationProgress,
                windowDimensionsRef.current,
              );
            } else {
              shouldAnimate.current = false;
              lastEventRef.current = '';
              renderTable(
                ctx,
                tableStateRef.current,
                playerUUIDRef.current,
                isJoinableRef.current,
                lastEventRef.current,
                shotClockRef.current,
                animationProgress,
                windowDimensionsRef.current,
              );
            }
          }
        } else {
          renderTable(
            ctx,
            tableStateRef.current,
            playerUUIDRef.current,
            isJoinableRef.current,
            lastEventRef.current,
            shotClockRef.current,
            0,
            windowDimensionsRef.current,
          );
        }
      }
    }

    window.animationFrame = timestamp;
    window.requestAnimationFrame(animationLoop);
  };

  React.useEffect(() => {
    window.animationFrame = 0;
    animationLoop(0);
  }, []);

  // 2. Set windowDimensions on renderDimensions change
  React.useEffect(() => {
    windowDimensionsRef.current = {
      width: renderDimensions.width,
      height: renderDimensions.height,
    };
    let canvas = document.querySelector(`canvas#${canvasID}`);
    canvas.width = windowDimensionsRef.current.width;
    canvas.height = windowDimensionsRef.current.height;
    let ctx = canvas.getContext('2d');
    if (tableStateRef.current) {
      renderTable(
        ctx,
        tableStateRef.current,
        playerUUIDRef.current,
        isJoinableRef.current,
        lastEventRef.current,
        0,
        0,
        windowDimensionsRef.current,
      );
    }
  }, [renderDimensions]);

  // 3. Set the tableStateRef whenever a new table state is passed from the parent screen
  React.useEffect(() => {
    if (canvasIsVisible) {
      tableStateAnimationQueueRef.current.push(tableState);
    } else {
      if (tableState.last_event) {
        tableStateAnimationQueueRef.current = [];
        prevTableStateRef.current = tableStateRef.current;
        tableStateRef.current = tableState;
        if (tableState.last_event) {
          lastEventRef.current = tableState.last_event[0];
        }
        processTableStateRender(tableState);
      }
    }
  }, [tableState]);

  // 4. Set shotClockRef when shotClock is updated from the parent component
  React.useEffect(() => {
    shotClockRef.current = shotClock;
  }, [shotClock]);

  React.useEffect(() => {
    playerUUIDRef.current = playerUUID;
  }, [playerUUID]);

  React.useEffect(() => {
    isJoinableRef.current = isJoinable;
  }, [isJoinable]);

  // 5. Handle ReserveSeat click, context menu for hosts click
  const handleClick = React.useCallback(
    (event) => {
      const x = event.offsetX;
      const y = event.offsetY;
      const windowDimensions = { ...windowDimensionsRef.current };
      const dimensions = calculateTableDimensions(windowDimensions);
      const coordinates = calculatePlayerCoordinates(10, dimensions);
      const playerIndex = tableState.players.findIndex((player) => {
        return player && player.player_uuid === playerUUID;
      });
      const playerIsAtTable = playerIndex > -1;

      const chosenPosition = coordinates.findIndex((position) => {
        return (
          y > position.y - dimensions.playerBoxHeight / 2 &&
          y < position.y + dimensions.playerBoxHeight / 2 &&
          x > position.x - dimensions.playerBoxWidth / 2 &&
          x < position.x + dimensions.playerBoxWidth / 2
        );
      });
      const chosenPositionIsJoinable =
        chosenPosition >= 0
          ? tableState.players[chosenPosition] === null
          : false;

      if (
        chosenPositionIsJoinable &&
        !playerIsAtTable &&
        isJoinableRef.current
      ) {
        return reserveSeat(chosenPosition);
      }

      if (chosenPosition === -1) {
        return;
      }
      const targetOffset = playerIndex >= 0 ? playerIndex : 0;

      const targetPlayer =
        tableState.players[
          (chosenPosition + targetOffset) % tableState.players.length
        ];

      if (playerIsHost && targetPlayer && tableState.mode !== 'completed') {
        setHostActionTargetPlayer(targetPlayer);
        return showContextMenu(event, { props: { targetPlayer } });
      }
    },
    [playerIsHost, tableState, playerUUID],
  );

  // 6. Handle Cursor Pointer delegation on mousemove
  const handleMouseMove = React.useCallback(
    (event) => {
      const x = event.offsetX;
      const y = event.offsetY;
      const windowDimensions = { ...windowDimensionsRef.current };
      const dimensions = calculateTableDimensions(windowDimensions);
      const coordinates = calculatePlayerCoordinates(10, dimensions);
      const playerIsAtTable =
        tableState.players.findIndex(
          (player) => player && player.player_uuid === playerUUID,
        ) > -1;

      const chosenPosition = coordinates.findIndex((position) => {
        return (
          y > position.y - dimensions.playerBoxHeight / 2 &&
          y < position.y + dimensions.playerBoxHeight / 2 &&
          x > position.x - dimensions.playerBoxWidth / 2 &&
          x < position.x + dimensions.playerBoxWidth / 2
        );
      });

      if (
        chosenPosition > -1 &&
        !playerIsAtTable &&
        isJoinableRef.current &&
        !tableState.players[chosenPosition]
      ) {
        document.querySelector(`canvas#${canvasID}`).style.cursor = 'pointer';
      } else {
        document.querySelector(`canvas#${canvasID}`).style.cursor = 'auto';
      }
    },
    [playerIsHost, tableState, playerUUID],
  );

  React.useEffect(() => {
    const canvas = document.querySelector(`canvas#${canvasID}`);
    canvas.addEventListener('click', handleClick, false);
    return () => {
      canvas.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  React.useEffect(() => {
    const canvas = document.querySelector(`canvas#${canvasID}`);
    canvas.addEventListener('mousemove', handleMouseMove, false);
    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  return <canvas id={canvasID} className="poker-table" />;
}
