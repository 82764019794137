import { trackingClient } from './APIService';
import mixpanel from 'mixpanel-browser';

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
if (mixpanelToken) {
  mixpanel.init(mixpanelToken);
}

export default class AnalyticsService {
  static viewsPostGameSummary = (player, pokerSessionType, routeSource) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track(`Views post game summary`, {
        id: player.uuid,
        poker_session_type: pokerSessionType,
        user_name: player.display,
        email: player.email,
        phone: player.phone_number,
        phone_confirmed: player.phone_number_confirmation,
        click_source: routeSource,
      });
    }
  };

  static logUserOutEvent = (player) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track('User confirms logout', {
        id: player.uuid,
        user_name: player.display,
        email: player.email,
        phone: player.phone_number,
        phone_confirmed: player.phone_number_confirmation,
      });
    }
  };

  static setupGameLaterEvent = (player, source) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track('Set up game later', {
        id: player.uuid,
        user_name: player.display,
        email: player.email,
        phone: player.phone_number,
        phone_confirmed: player.phone_number_confirmation,
        source: source,
      });
    }
  };

  static copyInviteLinkEvent = (player) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track('Copies invite link', {
        id: player.uuid,
        user_name: player.display,
        email: player.email,
        phone: player.phone_number,
        phone_confirmed: player.phone_number_confirmation,
      });
    }
  };

  static opensSidebarEvent = (player) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track('User opens sidebar', {
        id: player.uuid,
        user_name: player.display,
        email: player.email,
        phone: player.phone_number,
        phone_confirmed: player.phone_number_confirmation,
      });
    }
  };

  static viewsActivePokerTable = (
    player,
    pokerSessionType,
    playerTableSession,
  ) => {
    if (mixpanelToken) {
      let playerCount = playerTableSession.state.players.filter(
        (spot) => spot !== null,
      ).length;
      mixpanel.identify(player.uuid);
      mixpanel.track('Views active poker table', {
        id: player.uuid,
        poker_session_type: pokerSessionType,
        user_name: player.display,
        email: player.email,
        phone: player.phone_number,
        phone_confirmed: player.phone_number_confirmation,
        player_count: playerCount,
      });
    }
  };

  static clicksToContactUsEvent = (player) => {
    if (mixpanelToken) {
      if (player.uuid) {
        if (player.guest) {
          mixpanel.identify(player.uuid);
          mixpanel.track('Clicks to contact us', {
            id: player.uuid,
            user_name: player.display,
          });
        } else {
          mixpanel.identify(player.uuid);
          mixpanel.track('Clicks to contact us', {
            id: player.uuid,
            user_name: player.display,
            email: player.email,
            phone: player.phone_number,
            phone_confirmed: player.phone_number_confirmation,
          });
        }
      }
    }
  };

  static playAsGuestEvent = (guestNickName) => {
    if (mixpanelToken) {
      mixpanel.track('Plays as guest', {
        user_name: guestNickName,
      });
    }
  };

  static opensTournamentInfoModal = (player) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track('Opens tournament info popup', {
        id: player.uuid,
      });
    }
  };

  static opensPlayerStandingsModal = (player) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track('Opens player standings popup', {
        id: player.uuid,
      });
    }
  };

  static changesSoundMode = (player, nextSoundMode) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track('Changes sound mode', {
        id: player.uuid,
        next_sound_mode: nextSoundMode,
      });
    }
  };

  static changesSoundVolume = (player, nextSoundVolume) => {
    if (mixpanelToken) {
      mixpanel.identify(player.uuid);
      mixpanel.track('Changes sounds volume', {
        id: player.uuid,
        next_sound_volume: nextSoundVolume,
      });
    }
  };

  // New way of tracking events server-side

  static identify = (options) => {
    const {
      userId,
      anonymousId,
      traits,
      context,
      integrations,
      timestamp,
      messageId,
    } = options;

    return trackingClient.post(
      '/id',
      JSON.stringify({
        user_id: userId,
        anonymous_id: anonymousId,
        traits,
        context,
        integrations,
        timestamp,
        message_id: messageId,
      }),
    );
  };

  static track = (options) => {
    const {
      userId,
      anonymousId,
      event,
      properties = {},
      context = {},
      integrations,
      timestamp,
      messageId,
    } = options;
    const userAgent = navigator.userAgent;

    return trackingClient.post(
      '/check',
      JSON.stringify({
        user_id: userId,
        anonymous_id: anonymousId,
        event,
        properties: Object.assign(properties, { user_agent: userAgent }),
        context: Object.assign(context, { user_agent: userAgent }),
        integrations,
        timestamp,
        message_id: messageId,
      }),
    );
  };

  static addNameToMobileWaitlist = (player) => {
    return this.track({
      userId: player.uuid,
      event: 'Adds name to mobile app waitlist',
      properties: {
        user_name: player.display,
        email: player.email,
        phone: player.phone_number,
      },
    });
  };

  static opensBlog = (player, blogUrl) => {
    return this.track({
      userId: player.uuid,
      event: 'Opens blog',
      properties: {
        user_name: player.display,
        email: player.email,
        phone: player.phone_number,
        blogUrl,
      },
    });
  };

  // Host actions
  // ============

  static removesPlayerFromTable = (
    hostUUID,
    hostDisplay,
    player,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    const pokerSessionType = tournamentUUID ? 'tournament' : 'table-session';
    return this.track({
      userId: hostUUID,
      event: 'Removes a player from the table',
      properties: {
        host_uuid: hostUUID,
        host_display: hostDisplay,
        target_player_uuid: player.uuid || player.player_uuid,
        target_player_display: player.display,
        table_session_uuid: tableSessionUUID,
        tournament_uuid: tournamentUUID,
        poker_session_type: pokerSessionType,
      },
    });
  };

  static updatesPlayerChip = (
    hostUUID,
    hostDisplay,
    player,
    chipDelta,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    const pokerSessionType = tournamentUUID ? 'tournament' : 'table-session';
    const updateType = chipDelta >= 0 ? 'addition' : 'substraction';

    return this.track({
      userId: hostUUID,
      event: "Updates a player's chip stack",
      properties: {
        host_uuid: hostUUID,
        host_display: hostDisplay,
        target_player_uuid: player.uuid || player.player_uuid,
        target_player_display: player.display,
        table_session_uuid: tableSessionUUID,
        tournament_uuid: tournamentUUID,
        poker_session_type: pokerSessionType,
        update_type: updateType,
        chip_delta: chipDelta,
      },
    });
  };

  // Chatrooms
  // ============

  static startsChatroom = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
    allowedParticipants,
    video,
    audio,
  ) => {
    const pokerSessionType = tournamentUUID ? 'tournament' : 'table-session';

    return this.track({
      userId,
      event: 'Starts a chatroom',
      properties: {
        user_name: userDisplay,
        table_session_uuid: tableSessionUUID,
        tournament_uuid: tournamentUUID,
        poker_session_type: pokerSessionType,
        allowed_participants: allowedParticipants,
        video: !!video,
        audio: !!audio,
      },
    });
  };

  static joinsChatroom = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
    allowedParticipants,
    video,
    audio,
  ) => {
    const pokerSessionType = tournamentUUID ? 'tournament' : 'table-session';

    return this.track({
      userId,
      event: 'Joins a chatroom',
      properties: {
        user_name: userDisplay,
        table_session_uuid: tableSessionUUID,
        tournament_uuid: tournamentUUID,
        poker_session_type: pokerSessionType,
        allowed_participants: allowedParticipants,
        video: !!video,
        audio: !!audio,
      },
    });
  };

  static chatroomGenericEvent = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
    event,
  ) => {
    const pokerSessionType = tournamentUUID ? 'tournament' : 'table-session';

    return this.track({
      userId,
      event,
      properties: {
        user_name: userDisplay,
        table_session_uuid: tableSessionUUID,
        tournament_uuid: tournamentUUID,
        poker_session_type: pokerSessionType,
      },
    });
  };

  static chatroomVideoIsEnabled = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    return this.chatroomGenericEvent(
      userId,
      userDisplay,
      tableSessionUUID,
      tournamentUUID,
      'Chatroom video is enabled',
    );
  };

  static chatroomVideoIsDisabled = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    return this.chatroomGenericEvent(
      userId,
      userDisplay,
      tableSessionUUID,
      tournamentUUID,
      'Chatroom video is disabled',
    );
  };

  static chatroomAudioIsEnabled = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    return this.chatroomGenericEvent(
      userId,
      userDisplay,
      tableSessionUUID,
      tournamentUUID,
      'Chatroom audio is enabled',
    );
  };

  static chatroomAudioIsDisabled = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    return this.chatroomGenericEvent(
      userId,
      userDisplay,
      tableSessionUUID,
      tournamentUUID,
      'Chatroom audio is disabled',
    );
  };

  static sendsTextMessageInChatroom = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    return this.chatroomGenericEvent(
      userId,
      userDisplay,
      tableSessionUUID,
      tournamentUUID,
      'Sends a text message in chatroom',
    );
  };

  static updatesChatroomSettings = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
    nextAllowedParticipants,
    nextVideo,
    nextAudio,
  ) => {
    const pokerSessionType = tournamentUUID ? 'tournament' : 'table-session';

    return this.track({
      userId,
      event: 'Updates chatroom settings',
      properties: {
        user_name: userDisplay,
        table_session_uuid: tableSessionUUID,
        tournament_uuid: tournamentUUID,
        poker_session_type: pokerSessionType,
        next_allowed_participants: nextAllowedParticipants,
        next_video: !!nextVideo,
        next_audio: !!nextAudio,
      },
    });
  };

  static endsChatroomForEveryone = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    return this.chatroomGenericEvent(
      userId,
      userDisplay,
      tableSessionUUID,
      tournamentUUID,
      'Ends chatroom for everyone',
    );
  };

  static leavesChatroom = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    return this.chatroomGenericEvent(
      userId,
      userDisplay,
      tableSessionUUID,
      tournamentUUID,
      'Leaves chatroom',
    );
  };

  static minimizesChatroomPane = (
    userId,
    userDisplay,
    tableSessionUUID,
    tournamentUUID,
  ) => {
    return this.chatroomGenericEvent(
      userId,
      userDisplay,
      tableSessionUUID,
      tournamentUUID,
      'Minimizes chatroom pane',
    );
  };

  // Tip the dealer
  // ==============

  static opensTipTheDealerPopup = (userId, userDisplay) => {
    return this.track({
      userId,
      event: 'Opens tip the dealer popup',
      properties: {
        user_name: userDisplay,
      },
    });
  };

  static tipsTheDealer = (userId, userDisplay, tipAmount) => {
    return this.track({
      userId,
      event: 'Tips the dealer',
      properties: {
        user_name: userDisplay,
        tip_amount: tipAmount,
      },
    });
  };
}
