import React from 'react';
import { Helmet } from 'react-helmet';
import { PlayerContext } from '../contexts/PlayerContext';
import { useHistory, useLocation } from 'react-router-dom';
import { CREATE_TOURNAMENT_WITH_CONFIG } from '../services/APIService';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import TournamentCreateFormQuick from '../components/forms/TournamentCreateFormQuick';
import TournamentCreateFormAdvanced from '../components/forms/TournamentCreateFormAdvanced';
import table from '../assets/table3.png';
import AnalyticsService from '../services/AnalyticsService';
import { t } from '../i18n';
import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';

export default function TournamentCreateScreen() {
  let history = useHistory();
  let location = useLocation();
  const { player } = React.useContext(PlayerContext);
  const [activeFormIdent, setActiveFormIdent] = React.useState('quick');
  const [initialFormShouldAnimate, setInitialFormShouldAnimate] =
    React.useState(false);
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [tournamentCreateFormValidation, setTournamentCreateFormValidation] =
    React.useState({});

  const activeFormIdentRef = React.useRef(activeFormIdent);

  let gameVariantIdent =
    new URLSearchParams(location.search).get('gv') || 'no-limit-holdem';

  React.useEffect(() => {
    activeFormIdentRef.current = activeFormIdent;
    if (activeFormIdent === 'advanced') {
      setInitialFormShouldAnimate(true);
    }
  }, [activeFormIdent]);

  const createTournament = (tournamentData) => {
    setIsFetchingData(true);
    CREATE_TOURNAMENT_WITH_CONFIG(
      Object.assign(tournamentData, {
        playerUUID: player.uuid,
        gameVariant: gameVariantIdent,
      }),
    )
      .then((response) => {
        setIsFetchingData(false);
        if (response.data.status === 'ok') {
          history.replace(
            `/tournaments/${response.data.tournament.uuid}/table-sessions/${response.data.table_session.uuid}`,
          );
          history.go();
        } else if (response.data.status === 'error') {
          setTournamentCreateFormValidation(response.data.details);
        }
      })
      .catch((error) => {
        // handler server error
      });
  };

  const handleSetupLater = () => {
    AnalyticsService.setupGameLaterEvent(player, 'tournament-create');
    history.push('/lobby');
  };

  const toggleActiveForm = (nextFormIdent) => {
    setActiveFormIdent(nextFormIdent);
  };

  const renderTournamentForm = () => {
    if (activeFormIdent === 'quick') {
      return (
        <TournamentCreateFormQuick
          shouldAnimate={initialFormShouldAnimate}
          createTournament={createTournament}
          isFetchingData={isFetchingData}
          toggleActiveForm={toggleActiveForm}
        />
      );
    } else if (activeFormIdent === 'advanced') {
      return (
        <TournamentCreateFormAdvanced
          createTournament={createTournament}
          isFetchingData={isFetchingData}
          toggleActiveForm={toggleActiveForm}
          tournamentCreateFormValidation={tournamentCreateFormValidation}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="screen tournament-create-screen">
      <Helmet>
        <title>Clubs Poker</title>
      </Helmet>
      <MobileAccessibilityBanner />
      <SiteNoticeBanner />

      <div className="collapsible-sidebar-content-container">
        <PlayerCollapsibleSidebar startClosed={true} />
        <div className="content">
          <div className="poker-table">
            <img src={table} alt="poker-table" className="poker-table-image" />
            <p className="set-up-later" onClick={handleSetupLater}>
              {t('tournamentCreateScreenSetUpLater')}
            </p>
          </div>
          <h1 className="primary-header">
            {t('tournamentCreateScreenPrimaryHeader')}
          </h1>
          <h2 className="secondary-header">
            {t('tournamentCreateScreenSecondaryHeader')}
          </h2>
          {renderTournamentForm()}
        </div>
      </div>
    </div>
  );
}
