import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../i18n';
import GenericInfoPageShell from './GenericInfoPageShell';
import animation from '../assets/animations/tip-success.webp';

export default function StripeSuccessPage() {
  return (
    <GenericInfoPageShell title={t('stripeSuccessPageTitle')}>
      <div className="info">
        <h1>{t('stripeSuccessScreenTitle')}</h1>
        <p>
          {t('stripeSuccessCtaTitle1')}{' '}
          <span role="img" aria-label="emoji">
            💪💚
          </span>
        </p>
        <p>{t('stripeSuccessCtaTitle2')}</p>
        <Link className="submit-button" to="/">
          {t('stripeSuccessHomeButton')}
        </Link>
        <p>
          {t('stripeSuccessNeedHelp')}{' '}
          <a
            href="https://blog.clubspokernow.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('stripeSuccessChatWithUs')}
          </a>
        </p>
      </div>
      <div className="graphics">
        <img className="animation" src={animation} alt="animation" />
      </div>
    </GenericInfoPageShell>
  );
}
