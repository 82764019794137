import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../i18n';

const renderModalActions = ({
  tournament,
  tournamentFinish,
  requestRebuyTournament,
  closeModal,
}) => {
  if (tournament.mode === 'completed') {
    return (
      <Link
        to={`/tournaments/${tournament.uuid}/summary?ref=table`}
        target="_blank"
      >
        <div
          className="default-submit-button explore-tournament-summary"
          onClick={() => {
            closeModal();
          }}
        >
          <p>{t('exploreTournamentSummary')}</p>
        </div>
      </Link>
    );
  } else if (tournament.rebuys) {
    return (
      <>
        <div
          className="default-submit-button request-rebuy"
          onClick={() => {
            requestRebuyTournament();
            closeModal();
          }}
        >
          <p>{`Request rebuy`}</p>
        </div>
        <p
          className="close-modal-text"
          onClick={closeModal}
        >{`Continue watching`}</p>
      </>
    );
  } else {
    return (
      <div
        className="default-submit-button continue-watching"
        onClick={() => {
          closeModal();
        }}
      >
        <p>{`Continue watching`}</p>
      </div>
    );
  }
};

export default function TournamentBustedModal({
  tournament,
  tournamentFinish,
  gifURI,
  requestRebuyTournament,
  closeModal,
}) {
  var ModalContent = (
    <div
      className="modal-content"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <h1 className="modal-title">{`You've finished the tournament in ${tournamentFinish} place!`}</h1>
      <img className="tournament-busted-gif" src={gifURI} alt="busted" />
      {renderModalActions({
        tournament,
        tournamentFinish,
        requestRebuyTournament,
        closeModal,
      })}
      <h2 className="close-modal" onClick={closeModal}>
        X
      </h2>
    </div>
  );

  return (
    <div className="modal tournament-busted-modal" onClick={closeModal}>
      {ModalContent}
    </div>
  );
}
