import React from 'react';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import TournamentHostTableActions from './TournamentHostTableActions';
import TournamentNonHostTableActions from './TournamentNonHostTableActions';
import RingHostTableActions from './RingHostTableActions';
import RingNonHostTableActions from './RingNonHostTableActions';

export default function PokerTableActions({
  tournamentUUID,
  tournament,
  playerIsHost,
  player,
  playerIsSittingAtTable,
  tableState,
  lastTableStateProcessed,
  setActiveModalIdent,
  pauseGame,
  resumeGame,
  sitIn,
  sitOut,
  sitInTournament,
  sitOutTournament,
  pauseTournament,
  resumeTournament,
}) {
  const eventEmitter = React.useContext(EventEmitterContext);
  if (tableState) {
    if (tournamentUUID) {
      if (!tournament) {
        return <></>;
      } else if (playerIsHost) {
        return (
          <TournamentHostTableActions
            tournament={tournament}
            player={player}
            playerIsSittingAtTable={playerIsSittingAtTable}
            eventEmitter={eventEmitter}
            tableState={tableState}
            lastTableStateProcessed={lastTableStateProcessed}
            setActiveModalIdent={setActiveModalIdent}
            sitInTournament={sitInTournament}
            sitOutTournament={sitOutTournament}
            pauseTournament={pauseTournament}
            resumeTournament={resumeTournament}
          />
        );
      } else {
        return (
          <TournamentNonHostTableActions
            tournament={tournament}
            player={player}
            playerIsSittingAtTable={playerIsSittingAtTable}
            eventEmitter={eventEmitter}
            tableState={tableState}
            lastTableStateProcessed={lastTableStateProcessed}
            setActiveModalIdent={setActiveModalIdent}
            sitInTournament={sitInTournament}
            sitOutTournament={sitOutTournament}
          />
        );
      }
    } else {
      if (playerIsHost) {
        return (
          <RingHostTableActions
            player={player}
            playerIsSittingAtTable={playerIsSittingAtTable}
            eventEmitter={eventEmitter}
            tableState={tableState}
            lastTableStateProcessed={lastTableStateProcessed}
            setActiveModalIdent={setActiveModalIdent}
            pauseGame={pauseGame}
            resumeGame={resumeGame}
            sitIn={sitIn}
            sitOut={sitOut}
          />
        );
      } else {
        return (
          <RingNonHostTableActions
            player={player}
            playerIsSittingAtTable={playerIsSittingAtTable}
            eventEmitter={eventEmitter}
            tableState={tableState}
            lastTableStateProcessed={lastTableStateProcessed}
            setActiveModalIdent={setActiveModalIdent}
            sitIn={sitIn}
            sitOut={sitOut}
          />
        );
      }
    }
  } else {
    return <></>;
  }
}
