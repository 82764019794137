import React from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { t } from '../i18n';
import OnboardingHostEmailConfirmationForm from '../components/forms/OnboardingHostEmailConfirmationForm';
import Cookies from 'js-cookie';
import { PlayerContext } from '../contexts/PlayerContext';
import { useHistory } from 'react-router-dom';
import {
  PLAYER_SIGNIN_INIT, // Used to resend the OTP
  PLAYER_SIGNUP_CONFIRM,
} from '../services/APIService';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import table from '../assets/table2.png';
import { screenWidthBreakpoint } from '../poker/settings';

export default function OnboardingHostEmailConfirmationScreen() {
  const playerUUID = Cookies.get('playerUUID');
  const email = Cookies.get('email');
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [codeResending, setCodeResending] = React.useState(false);
  const [
    onboardingHostEmailConfirmationFormValidation,
    setOnboardingHostEmailConfirmationFormValidation,
  ] = React.useState({});
  let history = useHistory();
  const { setPlayer } = React.useContext(PlayerContext);

  const resendCode = () => {
    setCodeResending(true);
    setTimeout(() => {
      setCodeResending(false);
    }, 3000);
    PLAYER_SIGNIN_INIT({ email });
  };

  const verifyAuthCode = (otp) => {
    setIsFetchingData(true);
    PLAYER_SIGNUP_CONFIRM({ email, otp }).then((response) => {
      setIsFetchingData(false);
      if (response.data.status === 'ok') {
        const _player = response.data.player;
        Cookies.set('player', JSON.stringify(_player), {
          expires: 3650,
        });
        setPlayer(_player);
        Cookies.remove('email');
        Cookies.remove('playerUUID');
        if (window.innerWidth >= screenWidthBreakpoint) {
          history.push('/new-table-selection');
        } else {
          history.push('/onboarding/signup-successful');
        }
      } else {
        setOnboardingHostEmailConfirmationFormValidation(response.data.details);
      }
    });
  };

  if (!playerUUID || !email) {
    return <Redirect to="/onboarding/host-signup" />;
  }

  return (
    <div className="screen onboarding-host-email-confirmation-screen">
      <Helmet>
        <title>Clubs Poker</title>
      </Helmet>

      <div className="collapsible-sidebar-content-container">
        <PlayerCollapsibleSidebar startClosed={true} isExpandable={false} />
        <div className="content">
          <div className="poker-table">
            <img src={table} alt="poker-table" className="poker-table-image" />
          </div>
          <h1 className="primary-header">
            {t('onboardingHostEmailConfirmationScreenPrimaryHeader', 'email')}
          </h1>
          <h2 className="secondary-header">
            <p>{t('weveSentConfirmationCodeToRecipient', email)}</p>
            <p>{t('thisIsHowYouLogIn')}</p>
          </h2>
          <OnboardingHostEmailConfirmationForm
            isFetchingData={isFetchingData}
            codeResending={codeResending}
            resendCode={resendCode}
            verifyAuthCode={verifyAuthCode}
            email={email}
            formValidation={onboardingHostEmailConfirmationFormValidation}
          />
          <div className="external-links">
            <a href="/privacy.html" target="_blank" className="external-link">
              {t('privacyPolicy')}
            </a>
            <span className="divider">|</span>
            <a
              href="/ClubsPokerNow-TOU-v012624.pdf"
              target="_blank"
              className="external-link"
            >
              {t('termsOfService')}
            </a>
            <span className="divider">|</span>
            <a
              className="external-link"
              href="https://blog.clubspokernow.com/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('contactUsLabel')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
