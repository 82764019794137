import React from 'react';
import { TABLE_SESSION_CONFIG } from '../../services/APIService';
import Spinner from '../Spinner';
import Dropdown from 'react-dropdown';
import NumberFormat from 'react-number-format';
import { isFloatValuePositive, setNumberValue } from '../displayHelpers';
import { t } from '../../i18n';
import { numberDisplay } from '../../poker/helpers';

const shotClockOptions = [
  { value: '20000', label: '20 seconds' },
  { value: '30000', label: '30 seconds' },
  { value: '60000', label: '60 seconds' },
  { value: '1000000000', label: 'Infinite' },
];

const minBuyInOptions = [
  { value: '1', label: '1BB' },
  { value: '10', label: '10BB' },
  { value: '20', label: '20BB' },
  { value: '50', label: '50BB' },
  { value: '100', label: '100BB' },
];

const maxBuyInOptions = [
  { value: '50', label: '50BB' },
  { value: '100', label: '100BB' },
  { value: '200', label: '200BB' },
  { value: '1000', label: '1,000BB' },
];

const showdownRevealOptions = [
  { value: 'all-hands', label: 'Show all hands' },
  { value: 'winning-hand', label: 'Show winning hand' },
];

export default function HostSettingsModal({
  tableSessionUUID,
  currentHost,
  activePlayers,
  closeModal,
  updateSettings,
}) {
  const [isLoadingData, setIsLoadingData] = React.useState(true);

  const [smallBlind, setSmallBlind] = React.useState();
  const [bigBlind, setBigBlind] = React.useState();
  const [shotClock, setShotClock] = React.useState({});
  const [minBuyIn, setMinBuyIn] = React.useState({});
  const [maxBuyIn, setMaxBuyIn] = React.useState({});
  const [showdownReveal, setShowdownReveal] = React.useState({});
  const [gameHost, setGameHost] = React.useState(currentHost);

  React.useEffect(() => {
    TABLE_SESSION_CONFIG({ tableSessionUUID }).then((response) => {
      setSmallBlind(response.data.config.blinds[0]);
      setBigBlind(response.data.config.blinds[1]);
      setShotClock(response.data.config.shot_clock.toString());
      setMinBuyIn(response.data.config.min_buy_in.toString());
      setMaxBuyIn(response.data.config.max_buy_in.toString());
      setShowdownReveal(response.data.config.showdown_reveal.toString());
      setIsLoadingData(false);
    });
  }, [tableSessionUUID]);

  let ModalContent = (
    <div
      className="modal-content"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Spinner />
    </div>
  );
  if (!isLoadingData) {
    ModalContent = (
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('hostSettings')}</h1>
        <h2 className="modal-subtitle">{t('changesWillTakeEffectNextHand')}</h2>
        <div className="clubs-form settings">
          <div className="setting blinds-setting">
            <p className="label">{t('hostSettingsBlindDisplay')}</p>
            <div className="validated-input-fields">
              <NumberFormat
                className="input-field small-blind"
                placeholder={t('tableSessionCreateFormAdvancedSmallBlind')}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale="true"
                maxLength={6}
                isAllowed={isFloatValuePositive}
                value={numberDisplay(smallBlind / 100)}
                onChange={setNumberValue(setSmallBlind)}
              />
              <NumberFormat
                className="input-field big-blind"
                placeholder={t('tableSessionCreateFormAdvancedBigBlind')}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale="true"
                maxLength={6}
                isAllowed={isFloatValuePositive}
                value={numberDisplay(bigBlind / 100)}
                onChange={setNumberValue(setBigBlind)}
              />
            </div>
          </div>
          <div className="setting shot-clock-setting">
            <p className="label">{t('hostSettingsDecisionClock')}</p>
            <div className="validated-input-field">
              <Dropdown
                className="select-field shot-clock"
                value={shotClock}
                options={shotClockOptions}
                onChange={({ value }) => setShotClock(value)}
                placeholder={t('hostSettingsDecisionClockPlaceholder')}
              />
            </div>
          </div>
          <div className="setting buy-in-setting">
            <p className="label">{t('hostSettingsBuyInLimit')}</p>
            <div className="validated-input-fields">
              <Dropdown
                className="select-field min-buy-in"
                value={minBuyIn}
                options={minBuyInOptions}
                onChange={({ value }) => setMinBuyIn(value)}
                placeholder={t('hostSettingsMinBuyInPlaceholder')}
              />
              <Dropdown
                className="select-field max-buy-in"
                value={maxBuyIn}
                options={maxBuyInOptions}
                onChange={({ value }) => setMaxBuyIn(value)}
                placeholder={t('hostSettingsMaxBuyInPlaceholder')}
              />
            </div>
          </div>
          <div className="setting showdown-reveal-setting">
            <p className="label">{t('hostSettingsAtShowDownReveal')}</p>
            <div className="validated-input-field">
              <Dropdown
                className="select-field showdown-reveal"
                value={showdownReveal}
                options={showdownRevealOptions}
                onChange={({ value }) => setShowdownReveal(value)}
                placeholder={t('hostSettingsAtShowDownRevealPlaceholder')}
              />
            </div>
          </div>
          <div className="setting game-host-setting">
            <p className="label">{t('hostSettingsGameHost')}</p>
            <div className="validated-input-field">
              <Dropdown
                className="select-field game-host"
                value={gameHost}
                options={activePlayers}
                onChange={setGameHost}
                placeholder={t('hostSettingsGameHost')}
              />
            </div>
          </div>
        </div>
        <div
          className="default-submit-button update-settings"
          onClick={() => {
            updateSettings({
              blinds: `${smallBlind}-${bigBlind}`,
              min_buy_in: Number(minBuyIn),
              max_buy_in: Number(maxBuyIn),
              shot_clock: Number(shotClock),
              showdown_reveal: showdownReveal,
              host_uuid: gameHost.value,
              host_display: gameHost.label,
            });
            closeModal();
          }}
        >
          <p>{t('hostSettingsSave')}</p>
        </div>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    );
  }

  return (
    <div className="modal host-settings-modal" onClick={closeModal}>
      {ModalContent}
    </div>
  );
}
