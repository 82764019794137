import React from 'react';
import LightModal from './LightModal';
import TipTheDealerModalContent from './TipTheDealerModalContent';
import { t } from '../../i18n';

export default function TipTheDealerLightModal({ closeModal }) {
  return (
    <LightModal
      title={t('tipTheDealerTitle')}
      modalClassName="tip-the-dealer-modal"
      modalContentClassName="tip-the-dealer-modal-content-wrapper"
      closeModal={closeModal}
    >
      <TipTheDealerModalContent closeModal={closeModal} />
    </LightModal>
  );
}
