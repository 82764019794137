import Copy from './Copy';
import { getUserLocale } from 'get-user-locale';

export const t = (key, ...args) => {
  try {
    let language = getUserLocale();

    const tokenRegex = /(%{\S*})/g;
    let targetString = Copy[language][key];
    let tokens = targetString.match(tokenRegex);

    args.forEach((arg, index) => {
      targetString = targetString.replace(tokens[index], arg);
    });

    return targetString;
  } catch (error) {
    let language = 'en-US';

    const tokenRegex = /(%{\S*})/g;
    let targetString = Copy[language][key];
    let tokens = targetString.match(tokenRegex);

    args.forEach((arg, index) => {
      targetString = targetString.replace(tokens[index], arg);
    });

    return targetString;
  }
};
