const HAND_STATES = [
  // {
  //   "uuid": "12345",
  //   "blinds": [5,10],
  //   "pot_size": 0,
  //   "target_raise_amount": 20,
  //   "current_bet_level": 1,
  //   "pots": [],
  //   "community_cards": [],
  //   "players": [
  //     {
  //       "display": "Archer",
  //       "player_uuid": "000",
  //       "player_table_session_uuid": "000-pts",
  //       "guest": false,
  //       "chip_stack_amount": 5025,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "LANA",
  //       "player_uuid": "001",
  //       "player_table_session_uuid": "001-pts",
  //       "guest": false,
  //       "chip_stack_amount": 898,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Cyril Figgis",
  //       "player_uuid": "002",
  //       "player_table_session_uuid": "002-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4450,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Malory",
  //       "player_uuid": "003",
  //       "player_table_session_uuid": "003-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4088,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Ray G",
  //       "player_uuid": "004",
  //       "player_table_session_uuid": "004-pts",
  //       "guest": false,
  //       "chip_stack_amount": 1785,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Barry",
  //       "player_uuid": "005",
  //       "player_table_session_uuid": "005-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2350,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Cheryl",
  //       "player_uuid": "006",
  //       "player_table_session_uuid": "006-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4555,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Pam",
  //       "player_uuid": "007",
  //       "player_table_session_uuid": "007-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2012,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Dr. Krieger",
  //       "player_uuid": "008",
  //       "player_table_session_uuid": "008-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2899,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Woodhouse",
  //       "player_uuid": "009",
  //       "player_table_session_uuid": "009-pts",
  //       "guest": false,
  //       "chip_stack_amount": 3475,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     }
  //   ],
  //   "actor_index": null,
  //   "button_index": 0,
  //   "small_blind_index": 1,
  //   "big_blind_index": 2,
  //   "first_to_act_index": 3,
  //   "hand_state": "pending",
  //   "deck": []
  // },
  // {
  //   "uuid": "12345",
  //   "blinds": [5,10],
  //   "pot_size": 0,
  //   "target_raise_amount": 20,
  //   "current_bet_level": 1,
  //   "pots": [],
  //   "community_cards": [],
  //   "players": [
  //     {
  //       "display": "Archer",
  //       "player_uuid": "000",
  //       "player_table_session_uuid": "000-pts",
  //       "guest": false,
  //       "chip_stack_amount": 5025,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "LANA",
  //       "player_uuid": "001",
  //       "player_table_session_uuid": "001-pts",
  //       "guest": false,
  //       "chip_stack_amount": 898,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Cyril Figgis",
  //       "player_uuid": "002",
  //       "player_table_session_uuid": "002-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4450,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Malory",
  //       "player_uuid": "003",
  //       "player_table_session_uuid": "003-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4088,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Ray G",
  //       "player_uuid": "004",
  //       "player_table_session_uuid": "004-pts",
  //       "guest": false,
  //       "chip_stack_amount": 1785,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Barry",
  //       "player_uuid": "005",
  //       "player_table_session_uuid": "005-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2350,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Cheryl",
  //       "player_uuid": "006",
  //       "player_table_session_uuid": "006-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4555,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Pam",
  //       "player_uuid": "007",
  //       "player_table_session_uuid": "007-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2012,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Dr. Krieger",
  //       "player_uuid": "008",
  //       "player_table_session_uuid": "008-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2899,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Woodhouse",
  //       "player_uuid": "009",
  //       "player_table_session_uuid": "009-pts",
  //       "guest": false,
  //       "chip_stack_amount": 3475,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     }
  //   ],
  //   "actor_index": null,
  //   "button_index": 0,
  //   "small_blind_index": 1,
  //   "big_blind_index": 2,
  //   "first_to_act_index": 3,
  //   "hand_state": "active",
  //   "deck": []
  // },
  // {
  //   "uuid": "12345",
  //   "blinds": [5,10],
  //   "pot_size": 5,
  //   "target_raise_amount": 20,
  //   "current_bet_level": 1,
  //   "pots": [],
  //   "community_cards": [],
  //   "players": [
  //     {
  //       "display": "Archer",
  //       "player_uuid": "000",
  //       "player_table_session_uuid": "000-pts",
  //       "guest": false,
  //       "chip_stack_amount": 5025,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "LANA",
  //       "player_uuid": "001",
  //       "player_table_session_uuid": "001-pts",
  //       "guest": false,
  //       "chip_stack_amount": 893,
  //       "chip_hand_amount": 5,
  //       "chip_front_amount": 5,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Cyril Figgis",
  //       "player_uuid": "002",
  //       "player_table_session_uuid": "002-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4450,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Malory",
  //       "player_uuid": "003",
  //       "player_table_session_uuid": "003-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4088,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Ray G",
  //       "player_uuid": "004",
  //       "player_table_session_uuid": "004-pts",
  //       "guest": false,
  //       "chip_stack_amount": 1785,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Barry",
  //       "player_uuid": "005",
  //       "player_table_session_uuid": "005-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2350,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Cheryl",
  //       "player_uuid": "006",
  //       "player_table_session_uuid": "006-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4555,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Pam",
  //       "player_uuid": "007",
  //       "player_table_session_uuid": "007-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2012,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Dr. Krieger",
  //       "player_uuid": "008",
  //       "player_table_session_uuid": "008-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2899,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Woodhouse",
  //       "player_uuid": "009",
  //       "player_table_session_uuid": "009-pts",
  //       "guest": false,
  //       "chip_stack_amount": 3475,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     }
  //   ],
  //   "actor_index": null,
  //   "button_index": 0,
  //   "small_blind_index": 1,
  //   "big_blind_index": 2,
  //   "first_to_act_index": 3,
  //   "hand_state": "active",
  //   "deck": []
  // },
  // {
  //   "uuid": "12345",
  //   "blinds": [5,10],
  //   "pot_size": 15,
  //   "target_raise_amount": 20,
  //   "current_bet_level": 1,
  //   "pots": [],
  //   "community_cards": [],
  //   "players": [
  //     {
  //       "display": "Archer",
  //       "player_uuid": "000",
  //       "player_table_session_uuid": "000-pts",
  //       "guest": false,
  //       "chip_stack_amount": 5025,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "LANA",
  //       "player_uuid": "001",
  //       "player_table_session_uuid": "001-pts",
  //       "guest": false,
  //       "chip_stack_amount": 893,
  //       "chip_hand_amount": 5,
  //       "chip_front_amount": 5,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Cyril Figgis",
  //       "player_uuid": "002",
  //       "player_table_session_uuid": "002-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4440,
  //       "chip_hand_amount": 10,
  //       "chip_front_amount": 10,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Malory",
  //       "player_uuid": "003",
  //       "player_table_session_uuid": "003-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4088,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Ray G",
  //       "player_uuid": "004",
  //       "player_table_session_uuid": "004-pts",
  //       "guest": false,
  //       "chip_stack_amount": 1785,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Barry",
  //       "player_uuid": "005",
  //       "player_table_session_uuid": "005-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2350,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Cheryl",
  //       "player_uuid": "006",
  //       "player_table_session_uuid": "006-pts",
  //       "guest": false,
  //       "chip_stack_amount": 4555,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Pam",
  //       "player_uuid": "007",
  //       "player_table_session_uuid": "007-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2012,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Dr. Krieger",
  //       "player_uuid": "008",
  //       "player_table_session_uuid": "008-pts",
  //       "guest": false,
  //       "chip_stack_amount": 2899,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     },
  //     {
  //       "display": "Woodhouse",
  //       "player_uuid": "009",
  //       "player_table_session_uuid": "009-pts",
  //       "guest": false,
  //       "chip_stack_amount": 3475,
  //       "chip_hand_amount": 0,
  //       "chip_front_amount": 0,
  //       "hand": [],
  //       "table_status": "active",
  //       "hand_status": "active"
  //     }
  //   ],
  //   "actor_index": null,
  //   "button_index": 0,
  //   "small_blind_index": 1,
  //   "big_blind_index": 2,
  //   "first_to_act_index": 3,
  //   "hand_state": "active",
  //   "deck": []
  // },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 1500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 235000,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 455500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: null,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 1500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 235000,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 455500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 3,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 1500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 235000,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 455500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 3,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 1500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 235000,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 455500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 4,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 1500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 235000,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 455500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 4,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 1500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 235000,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 455500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 2500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 455500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 2500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 455500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 3500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 3500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 7,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 3500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 7,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 3500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 8,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 3500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 8,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 3500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
    ],
    actor_index: 9,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 3500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 9,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 3500,
    target_raise_amount: 2000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 502500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 6500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 6500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 1,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 6500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 1,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 6500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 2,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 6500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 2,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 6500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 234000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 8500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 8500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 454500,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 10500,
    target_raise_amount: 5000,
    current_bet_level: 1,
    pots: [],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 500,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 1000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 3000,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 10500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 10500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 10500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 10500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 10500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 10500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 10500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 499500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 16500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 493500,
        chip_hand_amount: 9000,
        chip_front_amount: 6000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 16500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 493500,
        chip_hand_amount: 9000,
        chip_front_amount: 6000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 232000,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 34500,
    target_raise_amount: 30000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 493500,
        chip_hand_amount: 9000,
        chip_front_amount: 6000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 214000,
        chip_hand_amount: 21000,
        chip_front_amount: 18000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 34500,
    target_raise_amount: 30000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 493500,
        chip_hand_amount: 9000,
        chip_front_amount: 6000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 214000,
        chip_hand_amount: 21000,
        chip_front_amount: 18000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'checked',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 34500,
    target_raise_amount: 30000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 493500,
        chip_hand_amount: 9000,
        chip_front_amount: 6000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 214000,
        chip_hand_amount: 21000,
        chip_front_amount: 18000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 6,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 34500,
    target_raise_amount: 30000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 493500,
        chip_hand_amount: 9000,
        chip_front_amount: 6000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 214000,
        chip_hand_amount: 21000,
        chip_front_amount: 18000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 46500,
    target_raise_amount: 30000,
    current_bet_level: 1,
    pots: [{ amount: 10500, player_indexes: [0, 5, 6] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 481500,
        chip_hand_amount: 21000,
        chip_front_amount: 18000,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 214000,
        chip_hand_amount: 21000,
        chip_front_amount: 18000,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 46500,
    target_raise_amount: 30000,
    current_bet_level: 1,
    pots: [{ amount: 46500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 481500,
        chip_hand_amount: 21000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 214000,
        chip_hand_amount: 21000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'checked',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 46500,
    target_raise_amount: 30000,
    current_bet_level: 1,
    pots: [{ amount: 46500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 481500,
        chip_hand_amount: 21000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 214000,
        chip_hand_amount: 21000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 46500,
    target_raise_amount: 30000,
    current_bet_level: 1,
    pots: [{ amount: 46500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 481500,
        chip_hand_amount: 21000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 214000,
        chip_hand_amount: 21000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 79000,
    target_raise_amount: 65000,
    current_bet_level: 1,
    pots: [{ amount: 46500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 481500,
        chip_hand_amount: 21000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 181500,
        chip_hand_amount: 53500,
        chip_front_amount: 32500,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 79000,
    target_raise_amount: 65000,
    current_bet_level: 1,
    pots: [{ amount: 46500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 481500,
        chip_hand_amount: 21000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 181500,
        chip_hand_amount: 53500,
        chip_front_amount: 32500,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 111500,
    target_raise_amount: 65000,
    current_bet_level: 1,
    pots: [{ amount: 46500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 449000,
        chip_hand_amount: 53500,
        chip_front_amount: 32500,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 181500,
        chip_hand_amount: 53500,
        chip_front_amount: 32500,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 111500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 111500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 449000,
        chip_hand_amount: 53500,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 181500,
        chip_hand_amount: 53500,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 111500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 111500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 449000,
        chip_hand_amount: 53500,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 181500,
        chip_hand_amount: 53500,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 111500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 111500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 449000,
        chip_hand_amount: 53500,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 181500,
        chip_hand_amount: 535,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 30,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 159000,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 111500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 449000,
        chip_hand_amount: 53500,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 134000,
        chip_hand_amount: 101000,
        chip_front_amount: 47500,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 159000,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 111500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 449000,
        chip_hand_amount: 53500,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'active',
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 134000,
        chip_hand_amount: 101000,
        chip_front_amount: 47500,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 326500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 111500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 281500,
        chip_hand_amount: 221000,
        chip_front_amount: 167500,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 134000,
        chip_hand_amount: 101000,
        chip_front_amount: 47500,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 0,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 326500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 111500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 281500,
        chip_hand_amount: 221000,
        chip_front_amount: 167500,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 134000,
        chip_hand_amount: 101000,
        chip_front_amount: 47500,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'bet',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 446500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 111500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 281500,
        chip_hand_amount: 221000,
        chip_front_amount: 167500,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 14000,
        chip_hand_amount: 221000,
        chip_front_amount: 167500,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 446500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 446500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 281500,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 14000,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'active',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 446500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 446500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 281500,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 14000,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'called',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'resolving',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 446500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 446500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 281500,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'raised',
        bet_level: 2,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 14000,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'revealed',
        bet_level: 2,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'resolving',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 446500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 446500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 281500,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'revealed',
        hands_won: 0,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 14000,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'revealed',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'resolving',
    deck: [],
  },
  {
    uuid: '12345',
    blinds: [500, 1000],
    pot_size: 446500,
    target_raise_amount: 1000,
    current_bet_level: 1,
    pots: [{ amount: 446500, player_indexes: [0, 5] }],
    community_cards: [
      { rank: 'queen', suit: 'spades' },
      { rank: 'jack', suit: 'clubs' },
      { rank: 'ten', suit: 'diamonds' },
      { rank: 'nine', suit: 'hearts' },
      { rank: 'two', suit: 'clubs' },
    ],
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 728000,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'ace' },
          { suit: 'hearts', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'revealed',
        hands_won: 0,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 89300,
        chip_hand_amount: 500,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 444000,
        chip_hand_amount: 1000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 408800,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 178500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 14000,
        chip_hand_amount: 221000,
        chip_front_amount: 0,
        hand: [
          { suit: 'hearts', rank: 'queen' },
          { suit: 'spades', rank: 'king' },
        ],
        table_status: 'active',
        hand_status: 'revealed',
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 452500,
        chip_hand_amount: 3000,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 201200,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 289900,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 347500,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [{}, {}],
        table_status: 'active',
        hand_status: 'folded',
      },
    ],
    actor_index: 5,
    button_index: 0,
    small_blind_index: 1,
    big_blind_index: 2,
    first_to_act_index: 3,
    hand_state: 'resolving',
    deck: [],
    receipt: {
      winners: [
        { player_uuid: '000', player_user_name: 'Archer', chip_delta: 225500 },
      ],
    },
  },
];

const HAND_EVENTS = [
  // null,
  // ["start-hand", {}],
  // ["post-blind", {"seat_index": 1, "amount": 5}],
  // ["post-blind", {"seat_index": 2, "amount": 10}],
  ['deal-hands', {}],
  ['assign-actor', { seat_index: 3 }],
  ['fold', { seat_index: 3 }],
  ['assign-actor', { seat_index: 4 }],
  ['fold', { seat_index: 4 }],
  ['assign-actor', { seat_index: 5 }],
  ['call', { seat_index: 5 }],
  ['assign-actor', { seat_index: 6 }],
  ['call', { seat_index: 6 }],
  ['assign-actor', { seat_index: 7 }],
  ['fold', { seat_index: 7 }],
  ['assign-actor', { seat_index: 8 }],
  ['fold', { seat_index: 8 }],
  ['assign-actor', { seat_index: 9 }],
  ['fold', { seat_index: 9 }],
  ['assign-actor', { seat_index: 0 }],
  ['raise', { seat_index: 0, amount: 3000 }],
  ['assign-actor', { seat_index: 1 }],
  ['fold', { seat_index: 1 }],
  ['assign-actor', { seat_index: 2 }],
  ['fold', { seat_index: 2 }],
  ['assign-actor', { seat_index: 5 }],
  ['call', { seat_index: 5 }],
  ['assign-actor', { seat_index: 6 }],
  ['call', { seat_index: 6 }],
  [
    'consolidate-chips',
    { pot_index: 0, amount: 10500, player_indexes: [0, 5, 6] },
  ],
  ['deal-flop', {}],
  ['assign-actor', { seat_index: 5 }],
  ['check', { seat_index: 5 }],
  ['assign-actor', { seat_index: 6 }],
  ['check', { seat_index: 6 }],
  ['assign-actor', { seat_index: 0 }],
  ['bet', { seat_index: 0, amount: 6000 }],
  ['assign-actor', { seat_index: 5 }],
  ['raise', { seat_index: 5, amount: 18000 }],
  ['assign-actor', { seat_index: 6 }],
  ['fold', { seat_index: 6 }],
  ['assign-actor', { seat_index: 0 }],
  ['call', { seat_index: 0 }],
  [
    'consolidate-chips',
    { pot_index: 0, amount: 36000, player_indexes: [0, 5] },
  ],
  ['deal-turn', {}],
  ['assign-actor', { seat_index: 5 }],
  ['bet', { seat_index: 5, amount: 32500 }],
  ['assign-actor', { seat_index: 0 }],
  ['call', { seat_index: 0 }],
  [
    'consolidate-chips',
    { pot_index: 0, amount: 65000, player_indexes: [0, 5] },
  ],
  ['deal-river', {}],
  ['assign-actor', { seat_index: 5 }],
  ['bet', { seat_index: 5, amount: 47500 }],
  ['assign-actor', { seat_index: 0 }],
  ['raise', { seat_index: 0, amount: 167500 }],
  ['assign-actor', { seat_index: 5 }],
  ['call', { seat_index: 5 }],
  [
    'consolidate-chips',
    { pot_index: 0, amount: 335000, player_indexes: [0, 5] },
  ],
  ['resolve-hand', {}],
  ['reveal-hand', { seat_index: 5 }],
  ['reveal-hand', { seat_index: 0 }],
  [
    'pay-out-winners',
    { payouts: [{ pot_index: 0, seat_index: 0, amount: 446500 }] },
  ],
];

export const TABLE_STATES = HAND_STATES.map((handState, index) => {
  return {
    players: [
      {
        display: 'Archer',
        player_uuid: '000',
        player_table_session_uuid: '000-pts',
        guest: false,
        chip_stack_amount: 5025,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 12,
      },
      {
        display: 'LANA',
        player_uuid: '001',
        player_table_session_uuid: '001-pts',
        guest: false,
        chip_stack_amount: 898,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 8,
      },
      {
        display: 'Cyril Figgis',
        player_uuid: '002',
        player_table_session_uuid: '002-pts',
        guest: false,
        chip_stack_amount: 4450,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 8,
      },
      {
        display: 'Malory',
        player_uuid: '003',
        player_table_session_uuid: '003-pts',
        guest: false,
        chip_stack_amount: 4088,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 11,
      },
      {
        display: 'Ray G',
        player_uuid: '004',
        player_table_session_uuid: '004-pts',
        guest: false,
        chip_stack_amount: 1785,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 4,
      },
      {
        display: 'Barry',
        player_uuid: '005',
        player_table_session_uuid: '005-pts',
        guest: false,
        chip_stack_amount: 2350,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 9,
      },
      {
        display: 'Cheryl',
        player_uuid: '006',
        player_table_session_uuid: '006-pts',
        guest: false,
        chip_stack_amount: 4555,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 6,
      },
      {
        display: 'Pam',
        player_uuid: '007',
        player_table_session_uuid: '007-pts',
        guest: false,
        chip_stack_amount: 2012,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 1,
      },
      {
        display: 'Dr. Krieger',
        player_uuid: '008',
        player_table_session_uuid: '008-pts',
        guest: false,
        chip_stack_amount: 2899,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 0,
      },
      {
        display: 'Woodhouse',
        player_uuid: '009',
        player_table_session_uuid: '009-pts',
        guest: false,
        chip_stack_amount: 3475,
        chip_hand_amount: 0,
        chip_front_amount: 0,
        hand: [],
        table_status: 'active',
        hand_status: 'active',
        hands_won: 5,
      },
    ],
    mode: 'active',
    table_image_uri:
      'https://d3895wx6m1m62f.cloudfront.net/poker-tables/default.png',
    card_back_image_uri:
      'https://d3895wx6m1m62f.cloudfront.net/card-backs/default.png',
    blinds: [20, 40],
    host_uuid: '000',
    current_button_index: 0,
    last_event: HAND_EVENTS[index],
    updated_at: 1592967748,
    last_hand: '1522db18-c220-46d5-837f-2d98fc372fa4',
    active_hand_state: handState,
  };
});
