import React from 'react';
import Dropdown from 'react-dropdown';
import NumberFormat from 'react-number-format';
import { isFloatValuePositive, setNumberValue } from '../displayHelpers';
import Spinner from '../Spinner';
import caratDown from '../../assets/icons/carat-down.png';
import { t } from '../../i18n';

export default function TableSessionCreateFormQuick({
  shouldAnimate,
  createTableSession,
  isFetchingData,
  toggleActiveForm,
  tableSessionCreateFormValidation,
}) {
  const [blinds, setBlinds] = React.useState('');
  const [startingChips, setStartingChips] = React.useState();

  const blindOptions = [
    { value: '1-2', label: '0.01 / 0.02' },
    { value: '2-5', label: '0.02 / 0.05' },
    { value: '5-10', label: '0.05 / 0.10' },
    { value: '10-20', label: '0.10 / 0.20' },
    { value: '25-50', label: '0.25 / 0.50' },
    { value: '50-100', label: '0.50 / 1.00' },
    { value: '100-200', label: '1 / 2' },
    { value: '200-500', label: '2 / 5' },
    { value: '500-1000', label: '5 / 10' },
    { value: '1000-2000', label: '10 / 20' },
    { value: '2500-5000', label: '25 / 50' },
    { value: '5000-10000', label: '50 / 100' },
  ];

  const handleToggleForm = (event) => {
    toggleActiveForm('advanced');
  };

  const submitForm = (e) => {
    e.preventDefault();
    createTableSession({
      setupMode: 'quick',
      blinds: blinds,
      startingChips: startingChips,
    });
  };

  return (
    <form
      onSubmit={submitForm}
      className={`clubs-form table-session-create-form-quick ${
        shouldAnimate ? 'should-animate' : ''
      }`}
    >
      <h4 className="form-title">{t('tableSessionCreateFormQuickSetup')}</h4>
      <div className="form-section blinds-section">
        <p>{t('tableSessionCreateFormChooseBlinds')}</p>
        <div className="validated-input-field">
          <Dropdown
            required
            className="select-field blinds extended"
            value={blinds}
            options={blindOptions}
            onChange={({ value }) => setBlinds(value)}
            placeholder={t('tableSessionCreateFormBlindOptions')}
          />
          {tableSessionCreateFormValidation.blinds ? (
            <span className="form-validation">
              {tableSessionCreateFormValidation.blinds[0]}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="form-section recommended-starting-stack-section">
        <p>{t('tableSessionCreateFormRecommendStack')}</p>
        <div className="validated-input-field">
          <NumberFormat
            className="input-field starting-chips"
            placeholder={t('tableSessionCreateFormNumberOfChips')}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale="true"
            maxLength={9}
            isAllowed={isFloatValuePositive}
            value={
              startingChips === undefined ? startingChips : startingChips / 100
            }
            onChange={setNumberValue(setStartingChips)}
          />
          {tableSessionCreateFormValidation.recommended_starting_chips ? (
            <span className="form-validation">
              {tableSessionCreateFormValidation.recommended_starting_chips[0]}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      <button className="submit-button" type="submit">
        {isFetchingData ? (
          <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
        ) : (
          <p className="submit-button-text">
            {t('tableSessionCreateFormCreateGame')}
          </p>
        )}
      </button>
      <div className="toggle-active-form" onClick={handleToggleForm}>
        <img
          src={caratDown}
          className="toggle-active-form-icon"
          alt="arrow pointing down"
        />
        <p className="toggle-active-form-text">
          {t('tableSessionCreateFormAdvancedSetup')}
        </p>
      </div>
    </form>
  );
}
