import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import { t } from '../../i18n';

export default function LeavePokerTableModal({
  closeModal,
  remainingPlayers,
  playerIsHost,
  leaveTable,
}) {
  const firstHostCandidate = remainingPlayers[0];
  const [nextHost, setNextHost] = useState(firstHostCandidate);

  return (
    <div className="modal leave-poker-table-modal" onClick={closeModal}>
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('leaveTable')}</h1>
        <h2 className="modal-subtitle">{t('dontWorryChipBalanceWillSave')}</h2>
        <div className="host-leave-settings">
          {playerIsHost && (
            <div className="setting game-host-setting">
              <p className="label">{t('changeGameHost')}</p>
              <div className="validated-input-field">
                <Dropdown
                  className="select-field game-host"
                  value={nextHost}
                  options={remainingPlayers}
                  onChange={setNextHost}
                  placeholder={t('hostSettingsGameHost')}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="default-submit-button leave-table"
          onClick={() => {
            leaveTable(playerIsHost ? nextHost.value : undefined);
            closeModal();
          }}
        >
          <p>{t('confirmLeaveTable')}</p>
        </div>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    </div>
  );
}
