import React from 'react';
import { t } from '../i18n';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner';
import TipTheDealerHeadsUp from './TipTheDealerHeadsUp';

export default function TournamentOverlayContent({
  dimensions,
  tournamentUUID,
  tableState,
  lastTableStateProcessed,
  sidebarAcknowledgement,
  player,
  playerIsHost,
  tournament,
  requestJoinTournament,
  startTournament,
  copyURL,
  copyingURL,
  openTipTheDealerModal,
}) {
  const [isOptimisticallyUpdating, setIsOptimisticallyUpdating] =
    React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (isOptimisticallyUpdating) {
        setIsOptimisticallyUpdating(false);
      }
    }, 3000);
  }, [isOptimisticallyUpdating]);

  if (tournament && tableState && sidebarAcknowledgement) {
    let tablePlayer = tableState['players'].find(
      (p) => p && p['player_uuid'] === player.uuid,
    );
    let tournamentHasStarted =
      tournament && tournament.mode !== 'pending-start';
    let playerIsSeated = player && tablePlayer;
    let playerTournamentRequests = tournament['tournament_requests'].filter(
      (request) => request.request_data.player_uuid === player.uuid,
    );
    let playerTournamentRequestJoin = playerTournamentRequests.find(
      (request) => request.request_type === 'join-tournament',
    );
    let tournamentIsPaused = tournament && tournament.mode === 'paused';
    let numberOfPotentialPlayers =
      _.compact(tableState['players']).length +
      tournament['tournament_requests'].length;

    if (
      !tournamentHasStarted &&
      playerIsHost &&
      _.compact(tableState['players']).length >= 2
    ) {
      if (playerIsSeated) {
        return (
          <>
            <p
              className="table-overlay-component"
              style={dimensions.aboveLogoComponent}
            >
              {t(
                'tableOverlayWaitingToStartTournament',
                _.compact(tableState['players']).length,
              )}
            </p>
            <div
              className="table-overlay-component default-submit-button take-my-seat-button"
              style={dimensions.buttonComponent}
              onClick={startTournament}
            >
              <p style={dimensions.buttonText}>
                {t('tableOverlayStartTournament')}
              </p>
            </div>
          </>
        );
      } else {
        return (
          <>
            <p
              className="table-overlay-component"
              style={dimensions.aboveLogoComponent}
            >
              {t(
                'tableOverlayWaitingToStartTournament',
                _.compact(tableState['players']).length,
              )}
            </p>
            {numberOfPotentialPlayers >= 10 ? (
              <></>
            ) : (
              <p
                className="table-overlay-component"
                style={dimensions.copyInviteLinkComponent}
                onClick={requestJoinTournament}
              >
                {t('tableOverlayHostRequestJoinTournament')}
              </p>
            )}
            <div
              className="table-overlay-component default-submit-button take-my-seat-button"
              style={dimensions.buttonComponentSecondary}
              onClick={startTournament}
            >
              <p style={dimensions.buttonText}>
                {t('tableOverlayStartTournament')}
              </p>
            </div>
          </>
        );
      }
    } else if (
      !tournamentHasStarted &&
      playerIsHost &&
      playerIsSeated &&
      _.compact(tableState['players']).length === 1
    ) {
      return (
        <>
          <p
            className="table-overlay-component"
            style={dimensions.aboveLogoComponent}
          >
            {t('tableOverlayWaitingForPlayersToJoinTournament')}
          </p>
          <p
            className="table-overlay-component"
            style={dimensions.copyInviteLinkComponent}
            onClick={copyURL}
          >
            {copyingURL ? t('copiedLink') : t('copyInviteLink')}
          </p>
        </>
      );
    } else if (
      !tournamentHasStarted &&
      !playerIsSeated &&
      !playerTournamentRequestJoin
    ) {
      if (playerIsHost) {
        return (
          <>
            <p
              className="table-overlay-component"
              style={dimensions.aboveLogoComponent}
            >
              {t('tableOverlayWaitingForPlayersToJoinTournament')}
            </p>
            <p
              className="table-overlay-component"
              style={dimensions.copyInviteLinkComponent}
              onClick={copyURL}
            >
              {copyingURL ? t('copiedLink') : t('copyInviteLink')}
            </p>
            {numberOfPotentialPlayers >= 10 ? (
              <></>
            ) : (
              <div
                className="table-overlay-component default-submit-button take-my-seat-button"
                style={dimensions.buttonComponentSecondary}
                onClick={() => {
                  setIsOptimisticallyUpdating(true);
                  requestJoinTournament();
                }}
              >
                {isOptimisticallyUpdating ? (
                  <Spinner />
                ) : (
                  <p style={dimensions.buttonText}>
                    {t('tableOverlayHostRequestJoinTournament')}
                  </p>
                )}
              </div>
            )}
          </>
        );
      } else {
        return (
          <>
            <p
              className="table-overlay-component"
              style={dimensions.aboveLogoComponent}
            >
              {t('tableOverlayWaitingForPlayersToJoinTournament')}
            </p>
            <p
              className="table-overlay-component"
              style={dimensions.copyInviteLinkComponent}
              onClick={copyURL}
            >
              {copyingURL ? t('copiedLink') : t('copyInviteLink')}
            </p>
            {numberOfPotentialPlayers >= 10 ? (
              <></>
            ) : (
              <div
                className="table-overlay-component default-submit-button take-my-seat-button"
                style={dimensions.buttonComponentSecondary}
                onClick={() => {
                  setIsOptimisticallyUpdating(true);
                  requestJoinTournament();
                }}
              >
                {isOptimisticallyUpdating ? (
                  <Spinner />
                ) : (
                  <p style={dimensions.buttonText}>
                    {t('tableOverlayRequestJoinTournament')}
                  </p>
                )}
              </div>
            )}
          </>
        );
      }
    } else if (
      !tournamentHasStarted &&
      !playerIsSeated &&
      playerTournamentRequestJoin
    ) {
      return (
        <>
          <p
            className="table-overlay-component"
            style={dimensions.aboveLogoComponent}
          >
            {t('tableOverlayPendingTournamentApproval')}
          </p>
          <p
            className="table-overlay-component"
            style={dimensions.copyInviteLinkComponent}
            onClick={copyURL}
          >
            {copyingURL ? t('copiedLink') : t('copyInviteLink')}
          </p>
          <div
            className="table-overlay-component default-submit-button action-in-progress"
            style={dimensions.buttonComponentSecondary}
          >
            <p style={dimensions.buttonText}>
              {t('tableOverlayRequestingApproval')}
            </p>
          </div>
        </>
      );
    } else if (!tournamentHasStarted && playerIsSeated && !playerIsHost) {
      return (
        <>
          <p
            className="table-overlay-component"
            style={dimensions.aboveLogoComponent}
          >
            {t('tableOverlayWaitingForHostToStartTournament')}
          </p>
          <p
            className="table-overlay-component"
            style={dimensions.copyInviteLinkComponent}
            onClick={copyURL}
          >
            {copyingURL ? t('copiedLink') : t('copyInviteLink')}
          </p>
        </>
      );
    } else if (tournamentIsPaused && playerIsHost) {
      return (
        <>
          <p
            className="table-overlay-component"
            style={dimensions.aboveLogoComponent}
          >
            {t('tableOverlayWaitingForYouToResumeTournament')}
          </p>
        </>
      );
    } else if (tournamentIsPaused && !playerIsHost) {
      return (
        <>
          <p
            className="table-overlay-component"
            style={dimensions.aboveLogoComponent}
          >
            {t('tableOverlayWaitingForHostToResumeTournament')}
          </p>
        </>
      );
    } else if (
      // tableState
      tableState &&
      tableState.mode === 'completed' &&
      (!lastTableStateProcessed ||
        (lastTableStateProcessed &&
          lastTableStateProcessed.last_event[0] === 'pay-out-winners'))
    ) {
      return (
        <>
          <p
            className="table-overlay-component"
            style={dimensions.aboveLogoTwoPartTopComponent}
          >
            {t('thanksForPlayingClubsPoker')}
          </p>
          <Link
            to={`/tournaments/${tournamentUUID}/summary?ref=table`}
            target="_blank"
            className="table-overlay-component"
            style={dimensions.exploreSummaryButtonComponent}
          >
            <div
              className="default-submit-button explore-game-summary"
              style={Object.assign({}, dimensions.buttonComponent, {
                marginTop: 0,
              })}
            >
              <p style={dimensions.buttonText}>
                {t('exploreTournamentSummary')}
              </p>
            </div>
          </Link>
          <TipTheDealerHeadsUp
            dimensions={dimensions}
            openTipTheDealerModal={openTipTheDealerModal}
          />
        </>
      );
    } else if (
      tableState &&
      tableState.mode === 'active' &&
      lastTableStateProcessed &&
      lastTableStateProcessed.last_event &&
      lastTableStateProcessed.last_event[0] === 'pay-out-winners' &&
      lastTableStateProcessed.active_hand_state &&
      lastTableStateProcessed.active_hand_state.receipt &&
      !_.isEmpty(
        lastTableStateProcessed.active_hand_state.receipt.winning_description,
      )
    ) {
      return (
        <p
          className="table-overlay-component winning-hand-description"
          style={dimensions.winningHandDescriptionComponent}
        >
          {
            lastTableStateProcessed.active_hand_state.receipt
              .winning_description
          }
        </p>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
}
