export default {
  // Colors
  // =====================
  actionButtonBackground: '#FFFFFF',
  actionButtonText: '#000000',
  activePlayerActionBoxBackground: 'gold',
  activePlayerActionBoxText: '#000000',
  activePlayerDisplayBoxBackground: 'rgba(28, 28, 28, 0.90)',
  activePlayerDisplayBoxBorder: 'rgba(40, 207, 117, 1)',
  activePlayerDisplayBoxText: '#FFFFFF',
  bettingDiskBackground: 'rgba(255, 255, 255, 0.2)',
  chipsInFrontText: '#FFFFFF',
  communityCardsCardBorder: '#999999',
  dealerButton: 'rgb(196, 196, 196)',
  emptySeatPlayerDisplayBoxBackground: 'rgba(28, 28, 28, 0.90)',
  emptySeatPlayerDisplayBoxBorder: '#FFFFFF',
  emptySeatPlayerDisplayBoxText: '#FFFFFF',
  playerActionBoxBackground: '#000000',
  playerActionBoxText: '#FFFFFF',
  playerDisplayBoxBackground: 'rgba(28, 28, 28, 0.90)',
  playerDisplayBoxBorder: '#666666',
  playerDisplayBoxText: '#FFFFFF',
  playerHandCardBorder: '#999999',
  playerRunoutEquityBoxBackground: 'rgba(28, 28, 28, 1)',
  potAmountText: '#FFFFFF',
  seatDot: '#FF0000',
  sittingOutPlayerDisplayBoxBackground: 'rgba(28, 28, 28, 0.70)',
  sittingOutPlayerDisplayBoxBorder: 'rgba(102,102,102,0)',
  shotClockLowPlayerDisplayBoxBackground: 'rgba(28, 28, 28, 0.90)',
  shotClockLowPlayerDisplayBoxBorder: 'rgb(234,82,100)',
  shotClockLowPlayerDisplayBoxText: 'rgb(234,82,100)',
  playerHandRank: 'rgb(255, 255, 255, 1)',
  playerHandRankFolded: 'rgb(255, 255, 255, 0.55)',

  // Fonts
  // =====================
  actionButtonFont: '800 24px helvetica',
  chipsInFrontFont: '15px Lato-Bold',
  emptySeatPlayerDisplayBoxFont: '18px Lato-Bold',
  playerActionBoxFont: '600 10px helvetica',
  playerDisplayBoxFont: '15px Lato-Bold',
  playerDisplayBoxChipAmountFont: '15px Lato-Regular',
  potAmountFont: '18px Lato-Bold',

  // Borders
  // =====================
  activePlayerDisplayBoxBorderWidth: 6,
  communityCardsCardBorderWidth: 0,
  emptySeatPlayerDisplayBoxBorderWidth: 3,
  playerDisplayBoxBorderWidth: 3,
  playerHandCardBorderWidth: 0,

  // Text
  // =====================
  emptySeatDisplayBoxEmptyText: 'Empty Seat',
  emptySeatDisplayBoxJoinText: '+ Join Table',
};

// radial-gradient(73.25% 73.25% at 50% 50%, #343434 0%, #000000 100%)
