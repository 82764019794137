import React from 'react';
import { numberDisplay, gameVariantDisplay } from '../poker/helpers';
import { t } from '../i18n';

export default function BlindsDisplay({ tableState, tournament }) {
  let tournamentBlinds;
  if (tableState.tournament_id) {
    if (tableState.ante && tableState.ante > 0) {
      tournamentBlinds = `${t('tournamentBlinds')} ${
        tableState.ante / 100
      }/${numberDisplay(
        tableState.blinds[0] / 100,
        tableState.decimal_display,
      )}/${numberDisplay(
        tableState.blinds[1] / 100,
        tableState.decimal_display,
      )}`;
    } else {
      tournamentBlinds = `${t('tournamentBlinds')} ${numberDisplay(
        tableState.blinds[0] / 100,
        tableState.decimal_display,
      )}/${numberDisplay(
        tableState.blinds[1] / 100,
        tableState.decimal_display,
      )}`;
    }
    if (tournament) {
      if (tournament.mode === 'pending-start') {
        let nextBlindsTimeDisplay = tournament.level_duration / 60;
        return (
          <div className="blinds-display">
            {`${tournamentBlinds}, ${nextBlindsTimeDisplay} ${t(
              'blindsDisplayMinLeft',
            )}`}
          </div>
        );
      } else {
        let nextBlindsTimeDisplay = Math.max(
          0,
          Math.ceil(
            (tournament.next_blind_timestamp - Math.round(Date.now() / 1000)) /
              60,
          ),
        );
        return (
          <div className="blinds-display">
            {`${tournamentBlinds}, ${nextBlindsTimeDisplay} ${t(
              'blindsDisplayMinLeft',
            )}`}
          </div>
        );
      }
    } else {
      return <div className="blinds-display">{`${tournamentBlinds}`}</div>;
    }
  } else {
    return (
      <div className="blinds-display">
        {`${numberDisplay(
          tableState.blinds[0] / 100,
          tableState.decimal_display,
        )}/${numberDisplay(
          tableState.blinds[1] / 100,
          tableState.decimal_display,
        )} ${gameVariantDisplay(tableState.game_variant)}`}
      </div>
    );
  }
}
