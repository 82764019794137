import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { t } from '../i18n';

import TournamentOverlayContent from './TournamentOverlayContent';
import TipTheDealerHeadsUp from './TipTheDealerHeadsUp';

export default function TableOverlayContent({
  dimensions,
  tableState,
  lastTableStateProcessed,
  sidebarAcknowledgement,
  player,
  playerIsHost,
  startTournament,
  requestJoinTournament,
  dealCards,
  finishGame,
  copyURL,
  copyingURL,
  tournamentUUID,
  tournament,
  tableSessionUUID,
  openTipTheDealerModal,
}) {
  // LEGACY
  // =====================
  const [showAsyncOverlay, setShowAsyncOverlay] = React.useState(false);

  let gameHost = tableState.players.find(
    (p) => p && p.player_uuid === tableState.host_uuid,
  );

  React.useEffect(() => {
    if (lastTableStateProcessed) {
      setShowAsyncOverlay(false);
      let timeoutLength = 2000;
      if (
        lastTableStateProcessed.active_hand_state.receipt &&
        lastTableStateProcessed.active_hand_state.receipt.showdown
      ) {
        let reveal_hand_events = tableState.active_hand_state.events.filter(
          (event) => event[0] === 'reveal-hand',
        );
        timeoutLength = 5000 + 2000 * reveal_hand_events.length;
      }
      setTimeout(() => {
        setShowAsyncOverlay(true);
      }, timeoutLength);
    } else {
      setShowAsyncOverlay(true);
    }
  }, [lastTableStateProcessed]);

  if (tournamentUUID) {
    return (
      <TournamentOverlayContent
        dimensions={dimensions}
        tournamentUUID={tournamentUUID}
        tableState={tableState}
        lastTableStateProcessed={lastTableStateProcessed}
        sidebarAcknowledgement={sidebarAcknowledgement}
        player={player}
        playerIsHost={playerIsHost}
        tournament={tournament}
        requestJoinTournament={requestJoinTournament}
        startTournament={startTournament}
        copyURL={copyURL}
        copyingURL={copyingURL}
        openTipTheDealerModal={openTipTheDealerModal}
      />
    );
  } else {
    if (tableState && tableState.mode === 'pending-start') {
      // deal-cards
      let numberOfPlayers = _.compact(tableState.players).filter(
        (p) => p.table_status === 'active',
      ).length;
      if (playerIsHost && numberOfPlayers >= 2) {
        return (
          <>
            <p
              className="number-of-players table-overlay-component"
              style={dimensions.aboveLogoComponent}
            >
              {t('numberOfPlayersReadyToPlay', numberOfPlayers)}
            </p>
            <div
              className="deal-cards-button default-submit-button table-overlay-component"
              style={dimensions.buttonComponent}
              onClick={dealCards}
            >
              <p style={dimensions.buttonText}>{t('dealCardsAction')}</p>
            </div>
          </>
        );
      } else if (playerIsHost) {
        // invite-url
        return (
          <>
            <p
              className="table-overlay-component"
              style={dimensions.aboveLogoComponent}
            >
              {t('inviteOtherPlayersToGameLink')}
            </p>
            <div
              className="url-input table-overlay-component"
              style={dimensions.urlInputComponent}
            >
              <input
                type="text"
                style={dimensions.urlInputComponentInput}
                readOnly
                value={window.location}
              />
              <div
                className="copy-button default-submit-button"
                style={dimensions.urlInputComponentButton}
                onClick={copyURL}
              >
                <p style={dimensions.buttonText}>
                  {copyingURL ? 'Copied!' : 'Copy'}
                </p>
              </div>
            </div>
          </>
        );
      } else {
        // start-game
        return (
          <p
            className="table-overlay-component"
            style={dimensions.aboveLogoComponent}
          >
            {t(
              'waitingForHostNameToStartGame',
              gameHost ? gameHost.display : 'the host',
            )}
          </p>
        );
      }
    } else if (tableState && tableState.mode === 'paused') {
      // resume-game
      if (playerIsHost) {
        return (
          <p
            className="table-overlay-component"
            style={dimensions.belowCardsComponent}
          >
            {t('waitingForYouToResumeGame')}
          </p>
        );
      } else {
        return (
          <p
            className="table-overlay-component"
            style={dimensions.belowCardsComponent}
          >
            {t(
              'waitingForHostNameToStartGame',
              gameHost ? gameHost.display : 'the host',
            )}
          </p>
        );
      }
    } else if (tableState && tableState.mode === 'pending-player-join') {
      let numberOfPlayers = _.compact(tableState.players).filter(
        (p) => p.table_status === 'active',
      ).length;
      if (showAsyncOverlay) {
        if (playerIsHost) {
          if (numberOfPlayers >= 2) {
            // deal-cards
            return (
              <>
                <p
                  className="table-overlay-component number-of-players"
                  style={dimensions.aboveLogoComponent}
                >
                  {t('numberOfPlayersReadyToPlay', numberOfPlayers)}
                </p>
                <div
                  className="table-overlay-component deal-cards-button default-submit-button"
                  style={dimensions.buttonComponent}
                  onClick={dealCards}
                >
                  <p>{t('dealCardsAction')}</p>
                </div>
              </>
            );
          } else {
            // pending-player-join
            return (
              <>
                <p
                  className="table-overlay-component"
                  style={dimensions.aboveLogoComponent}
                >
                  {t('notEnoughPlayersToContinueGame')}
                </p>
                <div
                  className="table-overlay-component finish-game-button default-submit-button"
                  style={dimensions.buttonComponent}
                  onClick={finishGame}
                >
                  <p style={dimensions.buttonText}>{t('finishGame')}</p>
                </div>
              </>
            );
          }
        } else {
          return (
            <p
              className="table-overlay-component resume-game"
              style={dimensions.aboveLogoComponent}
            >
              {t(
                'waitingForHostNameToStartGame',
                gameHost ? gameHost.display : 'the host',
              )}
            </p>
          );
        }
      } else {
        return <></>;
      }
    } else if (tableState && tableState.mode === 'pending-complete') {
      // finish-game
      return (
        <p
          className="table-overlay-component"
          style={dimensions.belowCardsComponent}
        >
          {t('gameWillFinishAfterThisHand')}
        </p>
      );
    } else if (
      tableState &&
      tableState.mode === 'completed' &&
      lastTableStateProcessed
    ) {
      if (showAsyncOverlay) {
        return (
          <>
            <p
              className="table-overlay-component"
              style={dimensions.aboveLogoTwoPartTopComponent}
            >
              {t('thanksForPlayingClubsPoker')}
            </p>
            <Link
              to={`/table-sessions/${tableSessionUUID}/summary?ref=table`}
              target="_blank"
              className="table-overlay-component"
              style={dimensions.exploreSummaryButtonComponent}
            >
              <div
                className="default-submit-button explore-game-summary"
                style={Object.assign({}, dimensions.buttonComponent, {
                  marginTop: 0,
                })}
              >
                <p style={dimensions.buttonText}>{t('exploreGameSummary')}</p>
              </div>
            </Link>
            <TipTheDealerHeadsUp
              dimensions={dimensions}
              openTipTheDealerModal={openTipTheDealerModal}
            />
          </>
        );
      } else {
        return <></>;
      }
    } else if (
      tableState &&
      tableState.mode === 'completed' &&
      !lastTableStateProcessed
    ) {
      // completed
      return (
        <>
          <p
            className="table-overlay-component"
            style={dimensions.aboveLogoTwoPartTopComponent}
          >
            {t('thanksForPlayingClubsPoker')}
          </p>
          <Link
            to={`/table-sessions/${tableSessionUUID}/summary?ref=table`}
            target="_blank"
            className="table-overlay-component"
            style={dimensions.exploreSummaryButtonComponent}
          >
            <div
              className="default-submit-button explore-game-summary"
              style={Object.assign({}, dimensions.buttonComponent, {
                marginTop: 0,
              })}
            >
              <p style={dimensions.buttonText}>{t('exploreGameSummary')}</p>
            </div>
          </Link>
          <TipTheDealerHeadsUp
            dimensions={dimensions}
            openTipTheDealerModal={openTipTheDealerModal}
          />
        </>
      );
    } else if (
      tableState &&
      tableState.mode === 'active' &&
      lastTableStateProcessed &&
      lastTableStateProcessed.last_event &&
      lastTableStateProcessed.last_event[0] === 'pay-out-winners' &&
      lastTableStateProcessed.active_hand_state &&
      lastTableStateProcessed.active_hand_state.receipt &&
      !_.isEmpty(
        lastTableStateProcessed.active_hand_state.receipt.winning_description,
      )
    ) {
      // winning-hand-description
      return (
        <p
          className="table-overlay-component winning-hand-description"
          style={dimensions.winningHandDescriptionComponent}
        >
          {
            lastTableStateProcessed.active_hand_state.receipt
              .winning_description
          }
        </p>
      );
    } else {
      return <></>;
    }
  }
}
