import React, { useState } from 'react';
import { t } from '../i18n';
import TipTheDealerLightModal from './modals/TipTheDealerLightModal';

export default function SidebarFooter() {
  const [activeModal, setActiveModal] = useState();

  return (
    <>
      <div className="footer-items">
        <div className="footer-item tip-the-dealer-footer-item">
          <button onClick={() => setActiveModal('tip-the-dealer')}>
            {t('tipTheDealerTitle')}
          </button>
        </div>
        <div className="footer-item contact-us">
          <a
            href="https://blog.clubspokernow.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('contactUs')}
          </a>
        </div>
        <div className="footer-item privacy-policy">
          <a href="/privacy.html" target="_blank">
            {t('privacyPolicy')}
          </a>
        </div>
        <div className="footer-item terms-of-service">
          <a href="/ClubsPokerNow-TOU-v012624.pdf" target="_blank">
            {t('termsOfService')}
          </a>
        </div>
      </div>
      {activeModal === 'tip-the-dealer' ? (
        <TipTheDealerLightModal closeModal={() => setActiveModal(null)} />
      ) : null}
    </>
  );
}
