import 'react-contexify/dist/ReactContexify.css';
import './styles/App.css';
import React, { useState, useMemo } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import _ from 'lodash';

import { PlayerContext } from './contexts/PlayerContext';
import { PlayerTableSessionsContext } from './contexts/PlayerTableSessionsContext';
import { PLAYER_ACTIVE_TABLE_SESSIONS } from './services/APIService';
import EventEmitter from 'events';
import { EventEmitterContext } from './contexts/EventEmitterContext';

import HandAnalyzerScreen from './screens/HandAnalyzerScreen';
import HomeScreen from './screens/HomeScreen';
import CompanyLandingScreen from './screens/CompanyLandingScreen';
import OnboardingHostSignupScreen from './screens/OnboardingHostSignupScreen';
import OnboardingHostEmailConfirmationScreen from './screens/OnboardingHostEmailConfirmationScreen';
import NewTableSelectionScreen from './screens/NewTableSelectionScreen';
import TableSessionCreateScreen from './screens/TableSessionCreateScreen';
import TournamentCreateScreen from './screens/TournamentCreateScreen';
import TournamentsShowScreen from './screens/TournamentsShowScreen';
import TableSessionsShowScreen from './screens/TableSessionsShowScreen';
import TableSessionsDebugScreen from './screens/TableSessionsDebugScreen';
import TableSessionsShowSummaryScreen from './screens/TableSessionsShowSummaryScreen';
import TournamentsShowSummaryScreen from './screens/TournamentsShowSummaryScreen';
import MultiTableEventsShowScreen from './screens/MultiTableEventsShowScreen';
import LobbyScreen from './screens/LobbyScreen';
import AccountScreen from './screens/AccountScreen';
import NoMatchScreen from './screens/NoMatchScreen';
import OnboardingSignupSuccessfulScreen from './screens/OnboardingSignupSuccessfulScreen';
import StripeSuccessPage from './screens/StripeSuccessPage';
import StripeCancelPage from './screens/StripeCancelPage';

// TODO: Remove the following WebSocket monkey-patched close function once
// ActionCable's fix for Safari's NSURLSession WebSocket is released.
// More info:
//    - https://linear.app/clubs-poker/issue/CLBD-552/cant-load-tables-in-safari-151
//    - https://github.com/rails/rails/issues/43835
//    - https://github.com/rails/rails/pull/44304
const originalWebSocketClose = WebSocket.prototype.close;
WebSocket.prototype.close = function () {
  if (this.readyState !== WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments);
  }
};

export default function App() {
  var playerExists;
  var playerData = {};

  playerExists = Cookies.get('player');
  if (playerExists) {
    playerData = JSON.parse(Cookies.get('player'));
    let browserCookieMinorVersion = Number(
      playerData.cookie_version.split('.')[1],
    );
    let envCookieMinorVersion = Number(
      process.env.REACT_APP_COOKIE_VERSION.split('.')[1],
    );

    if (
      _.isNumber(envCookieMinorVersion) &&
      _.isNumber(browserCookieMinorVersion) &&
      envCookieMinorVersion > browserCookieMinorVersion
    ) {
      Cookies.remove('player');
      playerData = {};
    }
  }

  let urlSearchParams = new URLSearchParams(window.location.search);
  let referralUUID = urlSearchParams.get('ref');
  if (referralUUID) {
    Cookies.set('referralUUID', referralUUID, { expires: 3650 });
  }

  // Providers
  const [player, setPlayer] = useState(playerData);
  const playerProviderValue = useMemo(
    () => ({ player, setPlayer }),
    [player, setPlayer],
  );
  const [playerTableSessions, setPlayerTableSessions] = useState([]);
  const playerTableSessionsProviderValue = useMemo(
    () => ({ playerTableSessions, setPlayerTableSessions }),
    [playerTableSessions, setPlayerTableSessions],
  );
  const eventEmitter = new EventEmitter();

  React.useEffect(() => {
    if (player && !player.guest) {
      PLAYER_ACTIVE_TABLE_SESSIONS({ uuid: player.uuid, jwt: player.jwt }).then(
        (response) => {
          if (response.data.status === 'ok') {
            setPlayerTableSessions(response.data.table_sessions);
          }
        },
      );
    }
  }, [player]);

  return (
    <BrowserRouter>
      <div className="App">
        <EventEmitterContext.Provider value={eventEmitter}>
          <PlayerContext.Provider value={playerProviderValue}>
            <PlayerTableSessionsContext.Provider
              value={playerTableSessionsProviderValue}
            >
              <Switch>
                <Route
                  exact={true}
                  path="/"
                  render={(props) => {
                    if (!_.isEmpty(player) && !player.guest) {
                      return <Redirect to={`/lobby`} />;
                    } else {
                      return <HomeScreen />;
                    }
                  }}
                />

                <Route
                  exact={true}
                  path="/for-companies"
                  component={CompanyLandingScreen}
                />

                <Route
                  exact={true}
                  path="/lobby"
                  render={(props) => {
                    if (!_.isEmpty(player) && !player.guest) {
                      return <LobbyScreen />;
                    } else {
                      return <Redirect to={`/`} />;
                    }
                  }}
                ></Route>

                <Route
                  exact={true}
                  path="/account"
                  render={(props) => {
                    if (!_.isEmpty(player) && !player.guest) {
                      return <AccountScreen />;
                    } else {
                      return <Redirect to={`/`} />;
                    }
                  }}
                ></Route>

                <Route
                  exact
                  path="/onboarding/host-signup"
                  component={OnboardingHostSignupScreen}
                />
                <Route
                  exact
                  path="/onboarding/host-email-confirmation"
                  component={OnboardingHostEmailConfirmationScreen}
                />
                <Route
                  exact
                  path="/onboarding/signup-successful"
                  component={OnboardingSignupSuccessfulScreen}
                />

                <Route
                  exact
                  path="/new-table-selection"
                  render={(props) => {
                    if (!_.isEmpty(player) && !player.guest) {
                      return <NewTableSelectionScreen />;
                    } else {
                      return <Redirect to={`/onboarding/host-signup`} />;
                    }
                  }}
                ></Route>

                <Route
                  exact
                  path="/table-session-create"
                  render={(props) => {
                    if (!_.isEmpty(player) && !player.guest) {
                      return <TableSessionCreateScreen />;
                    } else {
                      return <Redirect to={`/onboarding/host-signup`} />;
                    }
                  }}
                ></Route>

                <Route
                  exact
                  path="/tournament-create"
                  render={(props) => {
                    if (!_.isEmpty(player) && !player.guest) {
                      return <TournamentCreateScreen />;
                    } else {
                      return <Redirect to={`/onboarding/host-signup`} />;
                    }
                  }}
                ></Route>

                <Route exact path="/tournaments/:tournamentUUID">
                  <TournamentsShowScreen />
                </Route>

                <Route path="/table-sessions/:uuid/debug">
                  <TableSessionsDebugScreen />
                </Route>

                <Route path="/table-sessions/:uuid/summary">
                  <TableSessionsShowSummaryScreen />
                </Route>

                <Route path="/tournaments/:tournamentUUID/summary">
                  <TournamentsShowSummaryScreen />
                </Route>

                <Route path="/tournaments/:tournamentUUID/table-sessions/:tableSessionUUID">
                  <TableSessionsShowScreen />
                </Route>

                <Route path="/table-sessions/:tableSessionUUID">
                  <TableSessionsShowScreen />
                </Route>

                <Route path="/hand-analyzer/:uuid">
                  <HandAnalyzerScreen />
                </Route>

                <Route path="/multi-table-events/:uuid">
                  <MultiTableEventsShowScreen />
                </Route>

                <Route path="/404">
                  <NoMatchScreen />
                </Route>

                <Route exact path="/tip-the-dealer/success">
                  <StripeSuccessPage />
                </Route>

                <Route exact path="/tip-the-dealer/cancel">
                  <StripeCancelPage />
                </Route>

                <Route>
                  <NoMatchScreen />
                </Route>
              </Switch>
            </PlayerTableSessionsContext.Provider>
          </PlayerContext.Provider>
        </EventEmitterContext.Provider>
      </div>
    </BrowserRouter>
  );
}
