import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import Cable from 'actioncable';
import { useHistory, useParams } from 'react-router-dom';
import _, { compact } from 'lodash';
import { numberDisplay, reverseNumberDisplay } from '../poker/helpers';
import NumberFormat from 'react-number-format';
import HostPlayerActionsContextMenu from '../components/poker-table/HostPlayerActionsContextMenu';
import VideoRoomContainer from '../components/VideoRoomContainer';
import { PlayerContext } from '../contexts/PlayerContext';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import { PlayerTableSessionsContext } from '../contexts/PlayerTableSessionsContext';
import { t } from '../i18n';
import AnalyticsService from '../services/AnalyticsService';
import { buildURL } from '../utils/httpUtils';

// API Calls
import {
  TABLE_SESSION_STATE,
  PLAYER_ACTION,
  FISHERMAN_FOLD,
  FISHERMAN_CHECK,
  RESERVE_SEAT,
  UNRESERVE_SEAT,
  REQUEST_JOIN_TABLE_WITH_CHIPS,
  APPROVE_REQUEST_JOIN_TABLE_WITH_CHIPS,
  REJECT_REQUEST_JOIN_TABLE_WITH_CHIPS,
  DEAL_CARDS,
  SIT_OUT,
  SIT_IN,
  REQUEST_ADD_CHIPS,
  APPROVE_REQUEST_ADD_CHIPS,
  REJECT_REQUEST_ADD_CHIPS,
  LEAVE_TABLE,
  PAUSE_GAME,
  RESUME_GAME,
  FINISH_GAME,
  UPDATE_TABLE_SESSION_CONFIG,
  UPDATE_TOURNAMENT_CONFIG,
  // TOURNAMENTS
  TOURNAMENTS_SHOW,
  REQUEST_JOIN_TOURNAMENT,
  REJECT_REQUEST_JOIN_TOURNAMENT,
  APPROVE_REQUEST_JOIN_TOURNAMENT,
  START_TOURNAMENT,
  REQUEST_REBUY_TOURNAMENT,
  REJECT_REQUEST_REBUY_TOURNAMENT,
  APPROVE_REQUEST_REBUY_TOURNAMENT,
  SIT_OUT_TOURNAMENT,
  SIT_IN_TOURNAMENT,
  PAUSE_TOURNAMENT,
  RESUME_TOURNAMENT,

  // Auth
  PLAYER_SIGNUP_INIT,
  PLAYER_SIGNUP_CONFIRM,
  PLAYER_SIGNIN_INIT,
  PLAYER_SIGNIN_OTP,

  // Chatroom
  INIT_CHAT_ROOM,
  END_CHAT_ROOM,
  UPDATE_CHAT_ROOM_CONFIG,
} from '../services/APIService';

// Components
import Spinner from '../components/Spinner';
import NotificationCenter from '../components/NotificationCenter';
import SecondaryNotificationCenter from '../components/SecondaryNotificationCenter';
import BlindsDisplay from '../components/BlindsDisplay';
import PokerTable from '../components/PokerTable';
import PokerTableLeftSidebar from '../poker-table/PokerTableLeftSidebar';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import TableOverlayContent from '../poker-table/TableOverlayContent';
import HeaderTags from '../components/table-session-components/HeaderTags';
import PokerTableActions from '../poker-table/PokerTableActions';
import {
  calculateTableActionDimensions,
  calculateTableOverlayDimensions,
} from '../poker-table/PokerTableDimensions';

// Modals
import RequestChipsModal from '../components/modals/RequestChipsModal';
import AddChipsModal from '../components/modals/AddChipsModal';
import LeavePokerTableModal from '../components/modals/LeavePokerTableModal';
import FinishGameModal from '../components/modals/FinishGameModal';
import PlayerTableSessionDisconnectedModal from '../components/modals/PlayerTableSessionDisconnectedModal';
import HostSettingsModal from '../components/modals/HostSettingsModal';
import TournamentSettingsModal from '../components/modals/TournamentSettingsModal';
import TournamentInfoModal from '../components/modals/TournamentInfoModal';
import TournamentPlayerStandingsModal from '../components/modals/TournamentPlayerStandingsModal';
import RingGameInfoModal from '../components/modals/RingGameInfoModal';
import PlayerLoginModal from '../components/modals/PlayerLoginModal';
import VerifyAuthCodeModal from '../components/modals/VerifyAuthCodeModal';
import TournamentBustedModal from '../components/modals/TournamentBustedModal';
import SoundsModal from '../components/modals/SoundsModal';
import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';
import TipTheDealerDarkModal from '../components/modals/TipTheDealerDarkModal';

const nextHostCandidateTableStatus = ['active'];

// Sound Effects
const soundEffectCheck = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/check-6.mp3`,
);
soundEffectCheck.volume = 0.5;
const soundEffectFold = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/fold-4.mp3`,
);
soundEffectFold.volume = 0.5;
const soundEffectRaise = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/raise-4.mp3`,
);
soundEffectRaise.volume = 0.5;
const soundEffectPost = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/bet-3.mp3`,
);
soundEffectPost.volume = 0.5;
const soundEffectBet = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/bet-3.mp3`,
);
soundEffectBet.volume = 0.5;
const soundEffectDeal = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/deal-4.mp3`,
);
soundEffectDeal.volume = 0.5;
const soundEffectDeal2 = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/deal-4.mp3`,
);
soundEffectDeal2.volume = 0.5;
const soundEffectDeal3 = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/deal-4.mp3`,
);
soundEffectDeal3.volume = 0.5;
const soundEffectWinHand = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/win-hand-2.mp3`,
);
soundEffectWinHand.volume = 0.5;
const soundEffectYourTurn = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/your-turn.mp3`,
);
soundEffectYourTurn.volume = 0.5;
const soundEffectTimeLow = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/time-low.mp3`,
);
soundEffectTimeLow.volume = 0.5;
const soundEffectRevealHand = new Audio(
  `${process.env.PUBLIC_URL}/assets/sound-effects/deal.mp3`,
);
soundEffectRevealHand.volume = 0.4;

// Pre-Component Setup
const CANVAS_ID = Math.random().toString(36).slice(2).replace(/[0-9]/g, '');
const TABLE_ASPECT_RATIO = 1.6;
const VIDEO_ROOM_WIDTH = 325;

// Play Sound Effect
const playSoundEffectForEvent = (tableState, player, soundEffectFrequency) => {
  if (
    soundEffectFrequency === 'all-sounds' ||
    soundEffectFrequency === 'important-sounds'
  ) {
    if (tableState.last_event[0] === 'assign-actor') {
      if (
        tableState.players[tableState.last_event[1]['seat_index']]
          .player_uuid === player.uuid
      ) {
        soundEffectYourTurn.play();
      }
    }

    if (soundEffectFrequency === 'all-sounds') {
      if (tableState.last_event[0] === 'check') {
        soundEffectCheck.play();
      }
      if (tableState.last_event[0] === 'raise') {
        soundEffectRaise.play();
      }
      if (tableState.last_event[0] === 'fold') {
        soundEffectFold.play();
      }
      if (tableState.last_event[0] === 'bet') {
        soundEffectBet.play();
      }
      if (tableState.last_event[0] === 'call') {
        soundEffectBet.play();
      }
      if (tableState.last_event[0] === 'post-blind') {
        soundEffectPost.play();
      }
      if (tableState.last_event[0] === 'deal-hands') {
        soundEffectDeal.play();
        setTimeout(() => {
          soundEffectDeal2.play();
        }, 250);
      }
      if (tableState.last_event[0] === 'deal-flop') {
        soundEffectDeal.play();
        setTimeout(() => {
          soundEffectDeal2.play();
        }, 170);
        setTimeout(() => {
          soundEffectDeal3.play();
        }, 340);
      }
      if (tableState.last_event[0] === 'deal-turn') {
        soundEffectDeal.play();
      }
      if (tableState.last_event[0] === 'deal-river') {
        soundEffectDeal.play();
      }
      if (tableState.last_event[0] === 'reveal-hand') {
        soundEffectRevealHand.play();
      }
      if (tableState.last_event[0] === 'pay-out-winners') {
        if (
          tableState.players[
            tableState.last_event[1]['payouts'][0]['seat_index']
          ] &&
          tableState.players[
            tableState.last_event[1]['payouts'][0]['seat_index']
          ].player_uuid === player.uuid
        ) {
          soundEffectWinHand.play();
        }
      }
    }
  }
};

// Poker Logic Helpers
function calculateAmountToCall(handState, playerUUID) {
  if (handState) {
    let players = _.compact(handState.players);
    let max_chip_front = Math.max(
      ...players
        .filter((player) => {
          return player;
        })
        .map((player) => player['chip_front_amount']),
    );
    let currentPlayer = players.find(
      (player) => player.player_uuid === playerUUID,
    );
    if (!currentPlayer) {
      return null;
    }
    return Math.min(
      Math.max(0, max_chip_front - currentPlayer['chip_front_amount']),
      currentPlayer['chip_stack_amount'],
    );
  }
}

// React Component
export default function TableSessionsShowScreen() {
  let history = useHistory();
  let nonceHead = 0;

  const eventEmitter = React.useContext(EventEmitterContext);

  const [websocketConsumer, setWebsocketConsumer] = React.useState(null);
  const [subscription, setSubscription] = React.useState(null);
  const { playerTableSessions, setPlayerTableSessions } = React.useContext(
    PlayerTableSessionsContext,
  );
  const { player, setPlayer } = React.useContext(PlayerContext);
  const [jwt, setJwt] = React.useState(player?.jwt);
  const [playerUuid, setPlayerUuid] = React.useState(player?.player_uuid);
  const [playerDisplay, setPlayerDisplay] = React.useState(player?.display);
  const [pokerTableContainerHeight, setPokerTableContainerHeight] =
    React.useState(0);
  const [pokerTableContainerXOffset, setPokerTableContainerXOffset] =
    React.useState(0);
  const [actionDimensions, setActionDimensions] = React.useState({});
  const [tableOverlayDimensions, setTableOverlayDimensions] = React.useState(
    {},
  );
  const [videoRoomWidth, setVideoRoomWidth] = useState(VIDEO_ROOM_WIDTH);
  const [videoRoomContainerIsOpen, setVideoRoomContainerIsOpen] =
    useState(false);
  const [isInVideoRoom, setIsInVideoRoom] = useState(false);
  const renderedVideoRoomWidth = videoRoomContainerIsOpen ? videoRoomWidth : 0;

  // Authentication
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const playerRef = React.useRef({});
  const [playerIsHost, setPlayerIsHost] = React.useState(false);
  const [sidebarAcknowledgement, setSidebarAcknowledgment] =
    React.useState(false);
  const [recommendedStartingChips, setRecommendedStartingChips] =
    React.useState(0);
  const [guestSignupFormValidation, setGuestSignupFormValidation] =
    React.useState({});
  const [
    playerConfirmEmailFormValidation,
    setPlayerConfirmEmailFormValidation,
  ] = React.useState({});
  const [requestChipsFormValidation, setRequestChipsFormValidation] =
    React.useState({});
  const [addChipsFormValidation, setAddChipsFormValidation] = React.useState(
    {},
  );
  const [playerLoginFormValidation, setPlayerLoginFormValidation] =
    React.useState({});
  const [playerAuthCodeFormValidation, setPlayerAuthCodeFormValidation] =
    React.useState({});
  const [codeResending, setCodeResending] = React.useState(false);
  const [signinPayload, setSigninPayload] = React.useState({});
  const [copyingURL, setCopyingURL] = React.useState(false);
  const [tableIsJoinable, setTableIsJoinable] = React.useState(false);

  // Tournament
  const [tournament, setTournament] = React.useState(null);

  // Table Session State
  const [tableState, setTableState] = React.useState(null);
  const tableStateRef = React.useRef(tableState);
  const clockOffsetRef = React.useRef(0);
  let tableStateWebsocketReceiptCache = {};
  const tournamentUUID = useParams().tournamentUUID;
  const tableSessionUUID = useParams().tableSessionUUID;
  const [activePlayerUUID, setActivePlayerUUID] = React.useState(null);
  const activePlayerUUIDRef = React.useRef(activePlayerUUID);
  const lastTableStateProcessedRef = React.useRef(null);

  // Gameplay
  const [renderDimensions, setRenderDimensions] = React.useState({
    width: window.innerWidth - renderedVideoRoomWidth,
    height: window.innerHeight,
  });
  const [shotClock, setShotClock] = React.useState(100000);
  const [shouldHideBetActions, setShouldHideBetActions] = React.useState(false);
  const [shouldHideHandActions, setShouldHideHandActions] =
    React.useState(false);
  const [shouldHidePreActions, setShouldHidePreActions] = React.useState(false);
  const [targetRaiseAmount, setTargetRaiseAmount] = React.useState(0);
  const targetRaiseAmountRef = React.useRef(0);
  const targetRaiseAmountInputRef = React.useRef(null);
  const [amountToCall, setAmountToCall] = React.useState(0);
  const amountToCallRef = React.useRef(0);
  const [preAction, setPreAction] = React.useState('');
  const preActionRef = React.useRef('');
  const enterActionRef = React.useRef();

  const activePlayers = tableState?.players
    ? tableState.players
        .filter(
          (p) => p && nextHostCandidateTableStatus.includes(p.table_status),
        )
        .map((player) => ({
          label: player.display,
          value: player.player_uuid,
        }))
    : [];
  const remainingPlayers = activePlayers.filter(
    (p) => p.value !== tableState['host_uuid'],
  );

  // Modals
  const [activeModalIdent, setActiveModalIdent] = React.useState('');
  const [activeModalParams, setActiveModalParams] = React.useState({});
  const [tipTheDealerModalOpenedOnce, setTipTheDealerModalOpenedOnce] =
    useState(false);
  const activeModalIdentRef = React.useRef('');
  const activeModalParamsRef = React.useRef({});

  function openTipTheDealerModal() {
    setActiveModalIdent('tip-the-dealer');
    if (!tipTheDealerModalOpenedOnce) {
      setTipTheDealerModalOpenedOnce(true);
    }
  }

  const [tipTheDealerAutoOpenTimer, setTipTheDealerAutoOpenTimer] = useState(0);
  const tipTheDealerAutoOpenTimerLimit = 60;
  useEffect(() => {
    if (tableState?.mode === 'completed' && !tipTheDealerModalOpenedOnce) {
      setTimeout(() => {
        if (
          tipTheDealerAutoOpenTimer < tipTheDealerAutoOpenTimerLimit &&
          !tipTheDealerModalOpenedOnce
        ) {
          setTipTheDealerAutoOpenTimer(tipTheDealerAutoOpenTimer + 1);
          return;
        }
        if (
          tipTheDealerAutoOpenTimer === tipTheDealerAutoOpenTimerLimit &&
          !tipTheDealerModalOpenedOnce
        ) {
          openTipTheDealerModal();
        }
      }, 1000);
    }
  }, [tableState, tipTheDealerAutoOpenTimer, tipTheDealerModalOpenedOnce]);

  // Host controls
  const [hostActionTargetPlayer, setHostActionTargetPlayer] = useState(null);

  // Sound Effects
  const [soundEffectFrequency, setSoundEffectFrequency] =
    React.useState('all-sounds');
  const soundEffectFrequencyRef = React.useRef(soundEffectFrequency);
  const [soundEffectVolume, setSoundEffectVolume] = React.useState(0.5);

  const producer = React.useRef(subscription);
  React.useEffect(() => {
    producer.current = subscription;
  }, [subscription]);

  React.useEffect(() => {
    soundEffectFrequencyRef.current = soundEffectFrequency;
  }, [soundEffectFrequency]);

  React.useEffect(() => {
    soundEffectCheck.volume = soundEffectVolume;
    soundEffectFold.volume = soundEffectVolume;
    soundEffectRaise.volume = soundEffectVolume;
    soundEffectPost.volume = soundEffectVolume;
    soundEffectBet.volume = soundEffectVolume;
    soundEffectDeal.volume = soundEffectVolume;
    soundEffectDeal2.volume = soundEffectVolume;
    soundEffectDeal3.volume = soundEffectVolume;
    soundEffectWinHand.volume = soundEffectVolume;
    soundEffectYourTurn.volume = soundEffectVolume;
    soundEffectTimeLow.volume = soundEffectVolume;
    soundEffectRevealHand.volume = Math.max(soundEffectVolume - 0.1, 0);
  }, [soundEffectVolume]);

  const unlockAudio = () => {
    soundEffectCheck.play();
    soundEffectCheck.pause();
    soundEffectCheck.currentTime = 0;
    soundEffectFold.play();
    soundEffectFold.pause();
    soundEffectFold.currentTime = 0;
    soundEffectRaise.play();
    soundEffectRaise.pause();
    soundEffectRaise.currentTime = 0;
    soundEffectPost.play();
    soundEffectPost.pause();
    soundEffectPost.currentTime = 0;
    soundEffectBet.play();
    soundEffectBet.pause();
    soundEffectBet.currentTime = 0;
    soundEffectDeal.play();
    soundEffectDeal.pause();
    soundEffectDeal.currentTime = 0;
    soundEffectDeal2.play();
    soundEffectDeal2.pause();
    soundEffectDeal2.currentTime = 0;
    soundEffectDeal3.play();
    soundEffectDeal3.pause();
    soundEffectDeal3.currentTime = 0;
    soundEffectWinHand.play();
    soundEffectWinHand.pause();
    soundEffectWinHand.currentTime = 0;
    soundEffectYourTurn.play();
    soundEffectYourTurn.pause();
    soundEffectYourTurn.currentTime = 0;
    soundEffectTimeLow.play();
    soundEffectTimeLow.pause();
    soundEffectTimeLow.currentTime = 0;
    soundEffectRevealHand.play();
    soundEffectRevealHand.pause();
    soundEffectRevealHand.currentTime = 0;
    document.body.removeEventListener('click', unlockAudio);
  };

  // Audio shim for Safari
  React.useEffect(() => {
    document.body.addEventListener('click', unlockAudio);
  }, []);

  // Websocket initialization
  React.useEffect(() => {
    const cableParamsPairs = [
      { key: 'jwt', value: jwt },
      { key: 'player_uuid', value: playerUuid },
      { key: 'player_display', value: playerDisplay },
      { key: 'table_session_uuid', value: tableSessionUUID },
    ];
    const _consumer = Cable.createConsumer(
      buildURL(
        `${process.env.REACT_APP_API_ENDPOINT}/cable`,
        cableParamsPairs,
      ).toString(),
    );
    setWebsocketConsumer(_consumer);
    return () => {
      _consumer.subscriptions.subscriptions.forEach((s) => s.unsubscribe());
      _consumer.disconnect();
    };
  }, [jwt, playerUuid, playerDisplay, tableSessionUUID]);

  // Ref Watchers
  React.useEffect(() => {
    // 0.
    tableStateRef.current = tableState;

    // 1.
    let targetActivePlayerUUID = '';
    if (
      tableState &&
      tableState.active_hand_state &&
      Number.isInteger(tableState.active_hand_state.actor_index)
    ) {
      targetActivePlayerUUID =
        tableState.active_hand_state.players[
          tableState.active_hand_state.actor_index
        ].player_uuid;
      setActivePlayerUUID(targetActivePlayerUUID);
    }

    // 2.
    if (tableState && tableState.active_hand_state) {
      let previousAmountToCall = amountToCall;
      let targetAmountToCall = calculateAmountToCall(
        tableState.active_hand_state,
        player.uuid,
      );
      if (targetAmountToCall > previousAmountToCall && preAction === 'call') {
        setPreAction('');
      }
      setAmountToCall(targetAmountToCall);
    }

    // 3.
    if (
      tableState &&
      tableState.active_hand_state &&
      (tableState.last_event_type === 'poker-hand' ||
        targetRaiseAmountRef.current === 0)
    ) {
      setTargetRaiseAmount(tableState.active_hand_state.target_raise_amount);
    }
    if (targetRaiseAmountInputRef.current) {
      targetRaiseAmountInputRef.current.focus();
    }

    // 4.
    if (tableState && player && player.uuid === tableState.host_uuid) {
      setPlayerIsHost(true);
    } else if (
      tableState &&
      player &&
      player.uuid !== tableState.host_uuid &&
      playerIsHost
    ) {
      setPlayerIsHost(false);
    }

    // 5.
    if (
      tableState &&
      tableState.last_event &&
      tableState.last_event[0] === 'assign-actor'
    ) {
      setShotClock(100000);
    }
  }, [tableState]);

  React.useEffect(() => {
    setActionDimensions(
      calculateTableActionDimensions(pokerTableContainerHeight),
    );
    setTableOverlayDimensions(
      calculateTableOverlayDimensions(pokerTableContainerHeight),
    );
  }, [pokerTableContainerHeight]);

  React.useEffect(() => {
    targetRaiseAmountRef.current = targetRaiseAmount;
  }, [targetRaiseAmount]);
  React.useEffect(() => {
    activePlayerUUIDRef.current = activePlayerUUID;
  }, [activePlayerUUID]);
  React.useEffect(() => {
    amountToCallRef.current = amountToCall;
  }, [amountToCall]);
  React.useEffect(() => {
    playerRef.current = player;
  }, [player]);
  React.useEffect(() => {
    preActionRef.current = preAction;
  }, [preAction]);
  React.useEffect(() => {
    activeModalIdentRef.current = activeModalIdent;
  }, [activeModalIdent]);
  React.useEffect(() => {
    activeModalParamsRef.current = activeModalParams;
  }, [activeModalParams]);

  // Websockets
  React.useEffect(() => {
    if (websocketConsumer) {
      const _subscription = websocketConsumer.subscriptions.create(
        {
          channel: 'PlayerTableSessionChannel',
          table_session_uuid: tableSessionUUID,
          player_uuid: playerUuid,
          player_display: playerDisplay,
        },
        {
          connected: () => {
            TABLE_SESSION_STATE({ uuid: tableSessionUUID, jwt: player.jwt })
              .then((response) => {
                if (!tournamentUUID && response.data.tournament_uuid) {
                  history.push(
                    `/tournaments/${response.data.tournament_uuid}/table-sessions/${tableSessionUUID}`,
                  );
                }
                clockOffsetRef.current = Date.now() - response.data.timestamp;
                nonceHead = response.data.state.nonce;
                tableStateWebsocketReceiptCache = {};
                setTableState(response.data.state);
                if (player && player.uuid === response.data.state.host_uuid) {
                  setPlayerIsHost(true);
                }
                setRecommendedStartingChips(
                  response.data.recommended_starting_chips,
                );
                setIsLoading(false);

                if (
                  player &&
                  response.data.state.players.find(
                    (p) => p && p.player_uuid === player.uuid,
                  )
                ) {
                  acknowledgeSidebar();
                  if (
                    response.data.state.players.find(
                      (p) => p && p.player_uuid === player.uuid,
                    ).table_status === 'reserved'
                  ) {
                    setActiveModalIdent('request-join');
                  }
                }
              })
              .catch((error) => {
                history.push('/404');
              });

            if (
              activeModalIdentRef.current ===
              'player-table-session-disconnected'
            ) {
              setActiveModalIdent('');
            }
          },
          disconnected: () => {
            setActiveModalIdent('player-table-session-disconnected');
          },
          received: (data) => {
            if (data.event === 'next-table-state') {
              if (nonceHead) {
                let nextNonce = nonceHead + 1;
                if (data.data.nonce === nextNonce) {
                  setTableState(data.data);
                  nonceHead = data.data.nonce;
                  var shouldCheckTableStateWebsocketReceiptCache = true;
                  var nextNextNonce = nextNonce + 1;
                  while (shouldCheckTableStateWebsocketReceiptCache) {
                    let tableStateFromCache =
                      tableStateWebsocketReceiptCache[nextNextNonce];
                    if (tableStateFromCache) {
                      setTableState(tableStateFromCache);
                      nonceHead = nextNextNonce;
                      tableStateWebsocketReceiptCache[nextNextNonce] =
                        undefined;
                      nextNextNonce += 1;
                    } else {
                      shouldCheckTableStateWebsocketReceiptCache = false;
                    }
                  }
                } else if (
                  data.data.nonce > nextNonce &&
                  data.data.mode === 'completed'
                ) {
                  setTableState(data.data);
                  lastTableStateProcessedRef.current = data.data;
                } else if (data.data.nonce - nextNonce >= 2) {
                  setTableState(data.data);
                  nonceHead = data.data.nonce;
                } else if (data.data.nonce > nextNonce) {
                  tableStateWebsocketReceiptCache[data.data.nonce] = data.data;
                }
              } else {
                setTableState(data.data);
                nonceHead = data.data.nonce;
              }

              if (!data.data.last_event) {
                setShouldHideHandActions(true);
              }
            }
          },
        },
      );
      setSubscription(_subscription);

      if (tournamentUUID) {
        websocketConsumer.subscriptions.create(
          {
            channel: 'PlayerTournamentSessionChannel',
            tournament_uuid: tournamentUUID,
          },
          {
            connected: () => {
              TOURNAMENTS_SHOW({ tournamentUUID: tournamentUUID }).then(
                (response) => {
                  setTournament(response.data.tournament);
                },
              );
            },
            disconnected: () => {},
            received: (data) => {
              let targetModalNonce, intervalID;
              if (data.event === 'next-tournament-state') {
                setTournament(data.tournament);
              } else if (data.event === 'tournament-bust') {
                if (data.data.player_uuid === playerRef.current.uuid) {
                  targetModalNonce = tableStateRef.current.nonce;
                  intervalID = setInterval(() => {
                    if (
                      lastTableStateProcessedRef.current &&
                      lastTableStateProcessedRef.current.nonce >
                        targetModalNonce
                    ) {
                      setActiveModalIdent('tournament-busted');
                      setActiveModalParams(data.data);
                      clearInterval(intervalID);
                    }
                  }, 200);
                }
              } else if (data.event === 'tournament-win') {
                if (data.data.player_uuid === playerRef.current.uuid) {
                  targetModalNonce = tableStateRef.current.nonce;
                  intervalID = setInterval(() => {
                    if (
                      lastTableStateProcessedRef.current &&
                      lastTableStateProcessedRef.current.nonce >
                        targetModalNonce - 2
                    ) {
                      setActiveModalIdent('tournament-busted');
                      setActiveModalParams(data.data);
                      clearInterval(intervalID);
                    }
                  }, 200);
                }
              }
            },
          },
        );
      }

      if (Cookies.get('player')) {
        const playerData = JSON.parse(Cookies.get('player'));
        setPlayer(playerData);
        setJwt(playerData.jwt);
        setPlayerUuid(playerData.uuid);
        setPlayerDisplay(playerData.display);
      }
    }
    return () => {
      if (websocketConsumer) {
        websocketConsumer.subscriptions.subscriptions.forEach((s) =>
          s.unsubscribe(),
        );
        websocketConsumer.disconnect();
      }
    };
  }, [websocketConsumer, playerRef.current?.uuid]);

  // Window Resize handler
  React.useEffect(() => {
    function handleResize() {
      setRenderDimensions({
        width: window.innerWidth - renderedVideoRoomWidth,
        height: window.innerHeight,
      });
      setPokerTableContainerHeight(
        Math.min(
          window.innerHeight,
          (window.innerWidth - renderedVideoRoomWidth) / TABLE_ASPECT_RATIO,
        ),
      );
      setPokerTableContainerXOffset(-Math.floor(renderedVideoRoomWidth / 2));
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [renderedVideoRoomWidth]);

  // Table State Render Handler
  function processTableStateRender(nextTableState) {
    let targetActivePlayerUUID = '';
    if (
      nextTableState.active_hand_state &&
      _.isNumber(nextTableState.active_hand_state.actor_index)
    ) {
      targetActivePlayerUUID =
        nextTableState.active_hand_state.players[
          nextTableState.active_hand_state.actor_index
        ].player_uuid;
    }

    let shouldProcessPreAction =
      nextTableState &&
      nextTableState.last_event &&
      nextTableState.last_event[0] === 'assign-actor' &&
      playerRef.current.uuid === targetActivePlayerUUID &&
      preActionRef.current !== '';

    // Sound Effects
    if (
      lastTableStateProcessedRef.current &&
      lastTableStateProcessedRef.current.last_event &&
      nextTableState.last_event !==
        lastTableStateProcessedRef.current.last_event &&
      !shouldProcessPreAction &&
      (lastTableStateProcessedRef.current.last_event[0] !==
        nextTableState.last_event[0] ||
        nextTableState.last_event[0] === 'post-blind' ||
        nextTableState.last_event[0] === 'reveal-hand')
    ) {
      playSoundEffectForEvent(
        nextTableState,
        playerRef.current,
        soundEffectFrequencyRef.current,
      );
    }

    // cache last table state
    if (
      !lastTableStateProcessedRef.current ||
      (nextTableState &&
        nextTableState.nonce > lastTableStateProcessedRef.current.nonce)
    ) {
      lastTableStateProcessedRef.current = nextTableState;
    }

    // Hide Hand/Pre Actions
    if (shouldProcessPreAction) {
      processPreAction(nextTableState);
    } else if (
      nextTableState &&
      nextTableState.last_event &&
      (nextTableState.last_event[0] === 'assign-actor' ||
        nextTableState.last_event[0] === 'pay-out-winners' ||
        nextTableState.last_event[0] === 'reveal-hand')
    ) {
      setShouldHideHandActions(false);
    } else {
      setShouldHideHandActions(true);
    }

    if (
      nextTableState &&
      nextTableState.last_event &&
      (nextTableState.last_event[0] === 'consolidate-chips' ||
        nextTableState.last_event[0] === 'deal-flop' ||
        nextTableState.last_event[0] === 'deal-turn' ||
        nextTableState.last_event[0] === 'deal-river' ||
        nextTableState.last_event[0] === 'pay-out-winners' ||
        nextTableState.last_event[0] === 'reveal-hand')
    ) {
      setShouldHidePreActions(true);
      setPreAction('');
    } else {
      setShouldHidePreActions(false);
    }

    if (
      nextTableState &&
      nextTableState.last_event &&
      nextTableState.last_event[0] === 'assign-actor'
    ) {
      setShouldHideBetActions(false);
    } else {
      setShouldHideBetActions(true);
    }

    if (
      nextTableState &&
      nextTableState.last_event &&
      (nextTableState.last_event[0] === 'bet' ||
        nextTableState.last_event[0] === 'raise')
    ) {
      if (preActionRef.current === 'check-fold') {
        setPreAction('fold');
      }
      if (preActionRef.current === 'check') {
        setPreAction('');
      }
    }
  }

  // Start ShotClock
  React.useEffect(() => {
    const interval = setInterval(() => {
      let nextShotClock = 100000;
      if (
        tableStateRef.current &&
        tableStateRef.current.updated_at &&
        tableStateRef.current.last_event &&
        _.includes(['active', 'pending-complete'], tableStateRef.current.mode)
      ) {
        nextShotClock =
          tableStateRef.current.shot_clock -
          (Date.now() -
            clockOffsetRef.current -
            tableStateRef.current.updated_at);
        var isActivePlayer =
          tableStateRef.current.last_event[0] === 'assign-actor' &&
          tableStateRef.current.players[
            tableStateRef.current.last_event[1]['seat_index']
          ].player_uuid === playerRef.current.uuid;

        if (nextShotClock >= 9000 && nextShotClock < 10000 && isActivePlayer) {
          if (
            soundEffectFrequency === 'all-sounds' ||
            soundEffectFrequency === 'important-sounds'
          ) {
            soundEffectTimeLow.play();
          }
        }
      }

      if (
        tableStateRef.current &&
        tableStateRef.current.last_event &&
        _.includes(['active', 'pending-complete'], tableStateRef.current.mode)
      ) {
        if (
          nextShotClock <= 0 ||
          (tableStateRef.current.players[
            tableStateRef.current.active_hand_state.actor_index
          ] &&
            tableStateRef.current.players[
              tableStateRef.current.active_hand_state.actor_index
            ].table_status === 'blinding-out')
        ) {
          let targetAmountToCall = calculateAmountToCall(
            tableStateRef.current.active_hand_state,
            tableStateRef.current.players[
              tableStateRef.current.active_hand_state.actor_index
            ].player_uuid,
          );
          if (targetAmountToCall > 0) {
            FISHERMAN_FOLD({
              producer: producer.current,
              tableSessionUUID,
              tableStateNonce: tableStateRef.current.nonce,
              eventSequenceData: {
                seat_index: tableStateRef.current.active_hand_state.actor_index,
                fisherman: true,
                auto:
                  tableStateRef.current.players[
                    tableStateRef.current.active_hand_state.actor_index
                  ].table_status === 'blinding-out',
              },
              handUUID: tableStateRef.current.active_hand_state.uuid,
              creatorUUID: playerRef.current.uuid,
            });
            setShotClock(100000);
          } else {
            FISHERMAN_CHECK({
              producer: producer.current,
              tableSessionUUID,
              tableStateNonce: tableStateRef.current.nonce,
              eventSequenceData: {
                seat_index: tableStateRef.current.active_hand_state.actor_index,
                fisherman: true,
                auto:
                  tableStateRef.current.players[
                    tableStateRef.current.active_hand_state.actor_index
                  ].table_status === 'blinding-out',
              },
              handUUID: tableStateRef.current.active_hand_state.uuid,
              creatorUUID: playerRef.current.uuid,
            });
            setShotClock(100000);
          }
        }
        setShotClock(nextShotClock);
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  function copyURL() {
    setCopyingURL(true);
    setTimeout(() => {
      setCopyingURL(false);
    }, 1750);
    let copyText = document.querySelector('input.invite-link');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText.blur();
    AnalyticsService.copyInviteLinkEvent(player);
  }

  function unlockTable() {
    if (!tournamentUUID) {
      setTableIsJoinable(true);
    }
  }

  function acknowledgeSidebar() {
    setSidebarAcknowledgment(true);
    unlockTable();
  }

  function onGuestCreated(guest) {
    Cookies.set('player', JSON.stringify(guest), { expires: 1 });
    setSidebarAcknowledgment(true);
    unlockTable();
    setPlayer(guest);
    setJwt(guest.jwt);
    setPlayerUuid(guest.uuid);
    setPlayerDisplay(guest.display);
  }

  async function signupGuest(signupPayload) {
    setIsFetchingData(true);
    let playerCreateData = Object.assign(signupPayload, {
      referralUUID: Cookies.get('referralUUID'),
    });
    const response = await PLAYER_SIGNUP_INIT(playerCreateData);
    setIsFetchingData(false);
    if (response.data.status === 'ok') {
      const _player = response.data.player;
      Cookies.set('player', JSON.stringify(_player), {
        expires: 3650,
      });
      Cookies.remove('referralUUID');
      setPlayer(_player);
      setPlayerTableSessions([
        { uuid: tableSessionUUID, state: tableStateRef.current },
      ]);
      websocketConsumer.subscriptions.create(
        {
          channel: 'PlayerTableSessionChannel',
          table_session_uuid: tableSessionUUID,
        },
        {
          received: (data_1) => {
            let pts = playerTableSessions;
            pts[0] = { uuid: tableSessionUUID, state: data_1 };
            setPlayerTableSessions(pts);
          },
        },
      );
      return true;
    } else {
      setGuestSignupFormValidation(response.data.details);
      return false;
    }
  }

  async function signupVerifyAuthCode(otp) {
    setIsFetchingData(true);
    const response = await PLAYER_SIGNUP_CONFIRM({ email: player.email, otp });
    setIsFetchingData(false);
    if (response.data.status === 'ok') {
      const _player = response.data.player;
      Cookies.set('player', JSON.stringify(_player), {
        expires: 3650,
      });
      setPlayer(_player);
      Cookies.remove('email');
      Cookies.remove('playerUUID');
    } else {
      setPlayerConfirmEmailFormValidation(response.data.details);
    }
  }

  const resendCode = () => {
    setCodeResending(true);
    setTimeout(() => {
      setCodeResending(false);
    }, 3000);
    return PLAYER_SIGNIN_INIT({ email: signinPayload.email });
  };

  // Tournament API Calls
  // =====================
  function requestJoinTournament() {
    // reset the 1-day timeout on a guest session cookie
    if (player && player.guest) {
      Cookies.set('player', JSON.stringify(player), { expires: 1 });
    }

    REQUEST_JOIN_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      creatorUUID: playerRef.current.uuid,
      creatorUserName: player.display,
    });
  }

  function rejectRequestJoinTournament(requestIndex) {
    REJECT_REQUEST_JOIN_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      requestIndex,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function approveRequestJoinTournament(requestIndex, playerUUID) {
    APPROVE_REQUEST_JOIN_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      requestIndex,
      playerUUID,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function startTournament() {
    START_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function requestRebuyTournament() {
    REQUEST_REBUY_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      creatorUUID: playerRef.current.uuid,
      creatorUserName: player.display,
    });
  }

  function rejectRequestRebuyTournament(requestIndex) {
    REJECT_REQUEST_REBUY_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      requestIndex,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function approveRequestRebuyTournament(requestIndex) {
    APPROVE_REQUEST_REBUY_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      requestIndex,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function sitOutTournament() {
    SIT_OUT_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function sitInTournament() {
    SIT_IN_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function pauseTournament() {
    PAUSE_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function resumeTournament() {
    RESUME_TOURNAMENT({
      producer: producer.current,
      tournamentUUID,
      tableSessionUUID,
      tournamentNonce: tournament.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  // Table Session API Calls
  // =====================
  function reserveSeat(seatIndex) {
    // reset the 1-day timeout on a guest session cookie
    if (player && player.guest) {
      Cookies.set('player', JSON.stringify(player), { expires: 1 });
    }

    RESERVE_SEAT({
      producer: producer.current,
      tableSessionUUID: tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
      creatorUserName: playerRef.current.display,
      seatIndex: seatIndex,
    }).then((response) => {
      if (response.data.status === 'ok') {
        if (response.data.action === 'reserve') {
          setActiveModalIdent('request-join');
        }
      }
    });
  }

  function unreserveSeat() {
    UNRESERVE_SEAT({
      producer: producer.current,
      tableSessionUUID: tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function requestJoin(chipAmount) {
    setIsFetchingData(true);
    REQUEST_JOIN_TABLE_WITH_CHIPS({
      producer: producer.current,
      tableSessionUUID: tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
      chipAmount: chipAmount,
    }).then((response) => {
      setIsFetchingData(false);
      if (response.data.status === 'ok') {
        setActiveModalIdent('');
      } else if (response.data.status === 'error') {
        setRequestChipsFormValidation({ message: response.data.message });
      }
    });
  }

  function approveRequestJoinTable(requestIndex, playerTableSessionUUID) {
    APPROVE_REQUEST_JOIN_TABLE_WITH_CHIPS({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      requestIndex,
      playerTableSessionUUID,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function rejectRequestJoinTable(requestIndex, playerTableSessionUUID) {
    REJECT_REQUEST_JOIN_TABLE_WITH_CHIPS({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      requestIndex,
      playerTableSessionUUID,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function approveRequestAddChips(requestIndex) {
    APPROVE_REQUEST_ADD_CHIPS({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      requestIndex,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function rejectRequestAddChips(requestIndex) {
    REJECT_REQUEST_ADD_CHIPS({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      requestIndex,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function requestAddChips(chipAmount) {
    if (isFetchingData) {
      return;
    }
    setIsFetchingData(true);
    REQUEST_ADD_CHIPS({
      producer: producer.current,
      tableSessionUUID: tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
      chipAmount: chipAmount,
    }).then((response) => {
      setIsFetchingData(false);
      if (response.data.status === 'ok') {
        setActiveModalIdent('');
      } else if (response.data.status === 'error') {
        setAddChipsFormValidation({ message: response.data.message });
      }
    });
  }

  function leaveTable(nextHostUUID) {
    LEAVE_TABLE({
      producer: producer.current,
      tableSessionUUID: tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
      nextHostUUID: nextHostUUID,
    });
  }

  function dealCards() {
    let tableOverlay = document.querySelector('.table-overlay');
    if (tableOverlay) {
      tableOverlay.classList.add('fade-out');
    }
    DEAL_CARDS({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function finishGame() {
    FINISH_GAME({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function sitOut() {
    SIT_OUT({
      producer: producer.current,
      tableSessionUUID: tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function sitIn() {
    if (tableState) {
      var tablePlayer = tableState.players.find(
        (p) => p && p.player_uuid === player.uuid,
      );
    }

    if (
      tablePlayer &&
      tablePlayer['chip_stack_amount'] < tableState.blinds[1]
    ) {
      setActiveModalIdent('add-chips');
    } else {
      SIT_IN({
        producer: producer.current,
        tableSessionUUID: tableSessionUUID,
        tableStateNonce: tableState.nonce,
        creatorUUID: playerRef.current.uuid,
      });
    }
  }

  function pauseGame() {
    PAUSE_GAME({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function resumeGame() {
    RESUME_GAME({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function sendPlayerAction(type, data) {
    setShouldHideHandActions(true);
    setShouldHidePreActions(true);
    const handUUID = lastTableStateProcessedRef.current
      ? lastTableStateProcessedRef.current.active_hand_state.uuid
      : tableStateRef.current.active_hand_state.uuid;
    PLAYER_ACTION({
      producer: producer.current,
      tableSessionUUID,
      tableStateNonce: tableStateRef.current.nonce,
      handUUID,
      eventSequenceName: type,
      eventSequenceData: data,
      creatorUUID: playerRef.current.uuid,
    });
  }

  function processPreAction(nextTableState) {
    let targetAmountToCall = calculateAmountToCall(
      nextTableState.active_hand_state,
      playerRef.current.uuid,
    );
    if (preActionRef.current === 'fold') {
      sendPlayerAction('fold', {
        seat_index: nextTableState.active_hand_state.actor_index,
      });
    }
    if (preActionRef.current === 'check') {
      if (targetAmountToCall === 0) {
        sendPlayerAction('check', {
          seat_index: nextTableState.active_hand_state.actor_index,
        });
      }
    }
    if (preActionRef.current === 'check-fold') {
      if (targetAmountToCall === 0) {
        sendPlayerAction('check', {
          seat_index: nextTableState.active_hand_state.actor_index,
        });
      } else {
        sendPlayerAction('fold', {
          seat_index: nextTableState.active_hand_state.actor_index,
        });
      }
    }
    if (preActionRef.current === 'call') {
      sendPlayerAction('call', {
        seat_index: nextTableState.active_hand_state.actor_index,
      });
    }
    setPreAction('');
  }

  const setPotTargetRaiseAmount = (potFraction) => {
    const potRaise =
      tableState.active_hand_state.raise_pot_amount * potFraction;

    if (tableState.decimal_display) {
      setTargetRaiseAmount(Math.floor(potRaise));
    } else {
      setTargetRaiseAmount(Math.floor(potRaise / 100) * 100);
    }
  };

  // Chatroom utilities
  // =====================

  const chatRoomConfig = tableState?.chat_room_config || {};
  const playerIsSittingAtTable = compact(tableState?.players || []).some(
    (p) => p.player_uuid === player.uuid,
  );
  const isPlayerStatusList = [
    'active',
    'pending_sitting_out',
    'sitting_out',
    'blinding-out',
    'leaving',
  ];
  const playerSession = compact(tableState?.player_sessions || []).find(
    (s) => s.player_uuid === player.uuid,
  );
  const userIsConsideredPlayer = !!(
    playerSession &&
    (playerSession.hands_played > 0 ||
      isPlayerStatusList.includes(
        playerSession.table_status || playerSession.tournament_status,
      ))
  );

  function joinVideo() {
    const permissionToJoin = {
      players: playerIsHost || userIsConsideredPlayer,
      everyone: true,
    };
    if (
      chatRoomConfig.state === 'open' &&
      permissionToJoin[chatRoomConfig.allowed_participants] &&
      !isInVideoRoom
    ) {
      setIsInVideoRoom(true);
      AnalyticsService.joinsChatroom(
        playerRef.current.uuid,
        playerRef.current.display,
        tableSessionUUID,
        tournamentUUID,
        chatRoomConfig.allowed_participants,
        chatRoomConfig.enabled_channels.includes('video'),
        chatRoomConfig.enabled_channels.includes('audio'),
      );
    }
  }

  // Rendering Logic that still needs to be abstracted into components
  // =====================

  // 1. Hand / Bet Actions
  let HandActions = <></>;
  let BetActions = <></>;
  let PreActions = <></>;
  let PlayerControls = null,
    raiseHandler = () => {};
  if (tableState && tableState.active_hand_state) {
    let playerHand = tableState.active_hand_state.players.find(
      (p) => p && p.player_uuid === player.uuid,
    );
    if (lastTableStateProcessedRef.current) {
      playerHand =
        lastTableStateProcessedRef.current.active_hand_state.players.find(
          (p) => p && p.player_uuid === player.uuid,
        );
    }
    let activePlayerHands = tableState.active_hand_state.players.filter(
      (p) => p && p.hand_status !== 'folded',
    );
    let playerHandIndex = tableState.active_hand_state.players.findIndex(
      (p) => p && p.player_uuid === player.uuid,
    );
    let targetRaiseButtonClass = 'hand-action-button raise-button';
    if (playerHand) {
      targetRaiseButtonClass =
        targetRaiseAmount >= tableState.active_hand_state.target_raise_amount ||
        targetRaiseAmount >=
          playerHand.chip_stack_amount + playerHand.chip_front_amount
          ? 'raise-button'
          : 'raise-button inactive';
    }

    let betSectionClass = 'bet-section';
    let handActionButtonClass = 'hand-action-button';
    if (tableState.mode === 'paused') {
      betSectionClass = 'bet-section inactive';
      handActionButtonClass = 'hand-action-button inactive';
    }

    raiseHandler = (e) => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }
      if (targetRaiseButtonClass === 'raise-button') {
        sendPlayerAction('raise', {
          seat_index: tableState.active_hand_state.actor_index,
          amount: Math.min(
            playerHand.chip_stack_amount + playerHand.chip_front_amount,
            targetRaiseAmount,
          ),
        });
      }
    };

    // Set Bet Actions
    if (
      activePlayerUUID === player.uuid &&
      !_.includes(
        ['pending', 'resolving', 'completed'],
        tableState.active_hand_state.hand_state,
      )
    ) {
      if (tableState.active_hand_state.community_cards.length > 0) {
        BetActions = (
          <div
            className="bet-actions"
            style={actionDimensions.betActionsContainer}
          >
            <div
              className={`${betSectionClass} pot-increment-section`}
              style={actionDimensions.potIncrementSection}
            >
              <div
                className={`bet-action-button quarter-pot-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => setPotTargetRaiseAmount(0.25)}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betOneQuarterPotAmount')}
                </p>
              </div>
              <div
                className={`bet-action-button half-pot-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => setPotTargetRaiseAmount(0.5)}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betHalfPotAmount')}
                </p>
              </div>
              <div
                className={`bet-action-button three-quarters-pot-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => setPotTargetRaiseAmount(0.75)}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betThreeQuarterPotAmount')}
                </p>
              </div>
              <div
                className={`bet-action-button pot-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => setPotTargetRaiseAmount(1)}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betPotAmount')}
                </p>
              </div>
              <div
                className={`bet-action-button all-in-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => {
                  setTargetRaiseAmount(
                    playerHand.chip_stack_amount + playerHand.chip_front_amount,
                  );
                }}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betAllInAmount')}
                </p>
              </div>
            </div>

            <div
              className={`${betSectionClass} blind-increment-section`}
              style={actionDimensions.blindIncrementSection}
            >
              <div
                className={`blind-action-button minus-blind-button`}
                style={
                  actionDimensions.blindIncrementSectionBetActionButtonFirstChild
                }
                onClick={() => {
                  setTargetRaiseAmount(
                    Math.max(targetRaiseAmount - tableState.blinds[1], 0),
                  );
                }}
              >
                <p
                  style={
                    actionDimensions.blindIncrementSectionBetActionButtonText
                  }
                >
                  -
                </p>
              </div>
              <div
                className={`blind-action-button plus-blind-button`}
                style={
                  actionDimensions.blindIncrementSectionBetActionButtonLastChild
                }
                onClick={() => {
                  setTargetRaiseAmount(
                    Math.min(
                      targetRaiseAmount + tableState.blinds[1],
                      playerHand.chip_stack_amount +
                        playerHand.chip_front_amount,
                    ),
                  );
                }}
              >
                <p
                  style={
                    actionDimensions.blindIncrementSectionBetActionButtonText
                  }
                >
                  +
                </p>
              </div>
            </div>

            <div className={`${betSectionClass} custom-increment-section`}>
              <NumberFormat
                className="amount"
                style={actionDimensions.customIncrementSectionInput}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={tableState.decimal_display ? 2 : 0}
                value={
                  targetRaiseAmount === 0
                    ? tableState.decimal_display
                      ? '.00'
                      : ''
                    : tableState.decimal_display
                    ? targetRaiseAmount / 100
                    : Math.floor(targetRaiseAmount / 100)
                }
                fixedDecimalScale={
                  tableState.decimal_display ? 'true' : 'false'
                }
                onChange={(e) => {
                  tableState.decimal_display
                    ? setTargetRaiseAmount(
                        Math.round(reverseNumberDisplay(e.target.value) * 100),
                      )
                    : setTargetRaiseAmount(
                        Math.floor(reverseNumberDisplay(e.target.value) * 100),
                      );
                }}
              />
            </div>
          </div>
        );
      } else {
        BetActions = (
          <div
            className="bet-actions"
            style={actionDimensions.betActionsContainer}
          >
            <div
              className={`${betSectionClass} pot-increment-section`}
              style={actionDimensions.potIncrementSection}
            >
              <div
                className={`bet-action-button 2x-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => {
                  setTargetRaiseAmount(
                    Math.max(
                      ...activePlayerHands.map(
                        (hand) => hand.chip_front_amount,
                      ),
                    ) * 2,
                  );
                }}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betTwoTimesAmount')}
                </p>
              </div>
              <div
                className={`bet-action-button 3x-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => {
                  setTargetRaiseAmount(
                    Math.max(
                      ...activePlayerHands.map(
                        (hand) => hand.chip_front_amount,
                      ),
                    ) * 3,
                  );
                }}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betThreeTimesAmount')}
                </p>
              </div>
              <div
                className={`bet-action-button 4x-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => {
                  setTargetRaiseAmount(
                    Math.max(
                      ...activePlayerHands.map(
                        (hand) => hand.chip_front_amount,
                      ),
                    ) * 4,
                  );
                }}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betFourTimesAmount')}
                </p>
              </div>
              <div
                className={`bet-action-button 5x-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => {
                  setTargetRaiseAmount(
                    Math.max(
                      ...activePlayerHands.map(
                        (hand) => hand.chip_front_amount,
                      ),
                    ) * 5,
                  );
                }}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betFiveTimesAmount')}
                </p>
              </div>
              <div
                className={`bet-action-button all-in-button`}
                style={actionDimensions.potIncrementSectionBetActionButton}
                onClick={() => {
                  setTargetRaiseAmount(
                    playerHand.chip_stack_amount + playerHand.chip_front_amount,
                  );
                }}
              >
                <p style={actionDimensions.potIncrementSectionText}>
                  {t('betAllInAmount')}
                </p>
              </div>
            </div>

            <div
              className={`${betSectionClass} blind-increment-section`}
              style={actionDimensions.blindIncrementSectionBetActionButtonText}
            >
              <div
                className={`blind-action-button minus-blind-button`}
                style={
                  actionDimensions.blindIncrementSectionBetActionButtonFirstChild
                }
                onClick={() => {
                  setTargetRaiseAmount(
                    Math.max(targetRaiseAmount - tableState.blinds[1], 0),
                  );
                }}
              >
                <p
                  style={
                    actionDimensions.blindIncrementSectionBetActionButtonText
                  }
                >
                  -
                </p>
              </div>
              <div
                className={`blind-action-button plus-blind-button`}
                style={
                  actionDimensions.blindIncrementSectionBetActionButtonLastChild
                }
                onClick={() => {
                  setTargetRaiseAmount(
                    Math.min(
                      targetRaiseAmount + tableState.blinds[1],
                      playerHand.chip_stack_amount +
                        playerHand.chip_front_amount,
                    ),
                  );
                }}
              >
                <p
                  style={
                    actionDimensions.blindIncrementSectionBetActionButtonText
                  }
                >
                  +
                </p>
              </div>
            </div>

            <div className={`${betSectionClass} custom-increment-section`}>
              <NumberFormat
                className="amount"
                style={actionDimensions.customIncrementSectionInput}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={tableState.decimal_display ? 2 : 0}
                value={
                  targetRaiseAmount === 0
                    ? tableState.decimal_display
                      ? '.00'
                      : ''
                    : tableState.decimal_display
                    ? targetRaiseAmount / 100
                    : Math.floor(targetRaiseAmount / 100)
                }
                fixedDecimalScale={
                  tableState.decimal_display ? 'true' : 'false'
                }
                onChange={(e) => {
                  tableState.decimal_display
                    ? setTargetRaiseAmount(
                        Math.round(reverseNumberDisplay(e.target.value) * 100),
                      )
                    : setTargetRaiseAmount(
                        Math.floor(reverseNumberDisplay(e.target.value) * 100),
                      );
                }}
              />
            </div>
          </div>
        );
      }
    }

    // Set Hand Actions
    if (
      preAction !== '' ||
      !_.includes(['active', 'paused', 'pending-complete'], tableState.mode)
    ) {
      HandActions = <></>;
      BetActions = <></>;
    } else if (
      lastTableStateProcessedRef.current &&
      lastTableStateProcessedRef.current.active_hand_state &&
      lastTableStateProcessedRef.current.last_event &&
      (lastTableStateProcessedRef.current.last_event[0] === 'pay-out-winners' ||
        lastTableStateProcessedRef.current.last_event[0] === 'reveal-hand')
    ) {
      if (playerHand && playerHand.hand_status !== 'revealed') {
        HandActions = (
          <div
            className="hand-actions"
            style={actionDimensions.handActionsContainer}
          >
            <div
              className={`${handActionButtonClass} reveal-button`}
              onClick={() => {
                sendPlayerAction('reveal-hand', {
                  seat_index: playerHandIndex,
                });
              }}
              style={actionDimensions.handActionButton}
            >
              <p style={actionDimensions.revealHandActionText}>
                {t('showCardsAction')}
              </p>
            </div>
          </div>
        );
        BetActions = <></>;
      }
    } else if (playerHand && activePlayerUUID === player.uuid) {
      // console.log([amountToCall, playerHand['chip_front_amount'], tableState.target_raise_amount]);
      // (playerHand['hand_status'] !== 'active' && amountToCall + playerHand['chip_front_amount'] < tableState.active_hand_state.target_raise_amount)
      if (amountToCall >= playerHand['chip_stack_amount'] && amountToCall > 0) {
        HandActions = (
          <div
            className="hand-actions"
            style={actionDimensions.handActionsContainer}
          >
            <div
              className={`${handActionButtonClass} fold-button`}
              onClick={() => {
                sendPlayerAction('fold', {
                  seat_index: tableState.active_hand_state.actor_index,
                });
              }}
              style={actionDimensions.handActionButton}
            >
              <p style={actionDimensions.handActionText}>
                {t('foldCardsAction')}
              </p>
            </div>
            <div
              className={`${handActionButtonClass} call-button`}
              ref={enterActionRef}
              onClick={() => {
                sendPlayerAction('call', {
                  seat_index: tableState.active_hand_state.actor_index,
                  amount: amountToCall,
                });
              }}
              style={actionDimensions.handActionButton}
            >
              <p style={actionDimensions.handActionText}>
                {t('callBetAction')}
              </p>
              <p style={actionDimensions.handActionText}>
                {numberDisplay(amountToCall / 100, tableState.decimal_display)}
              </p>
            </div>
            <div
              className={`${handActionButtonClass} invisible-placeholder`}
              style={actionDimensions.handActionButton}
            ></div>
          </div>
        );
        BetActions = <></>;
      } else if (
        playerHand['chip_stack_amount'] > amountToCall &&
        activePlayerHands.filter(
          (p) =>
            p.chip_stack_amount >
            calculateAmountToCall(tableState.active_hand_state, p.player_uuid),
        ).length === 1
      ) {
        HandActions = (
          <div
            className="hand-actions"
            style={actionDimensions.handActionsContainer}
          >
            <div
              className={`${handActionButtonClass} fold-button`}
              onClick={() => {
                sendPlayerAction('fold', {
                  seat_index: tableState.active_hand_state.actor_index,
                });
              }}
              style={actionDimensions.handActionButton}
            >
              <p style={actionDimensions.handActionText}>
                {t('foldCardsAction')}
              </p>
            </div>
            <div
              className={`${handActionButtonClass} call-button`}
              ref={enterActionRef}
              onClick={() => {
                sendPlayerAction('call', {
                  seat_index: tableState.active_hand_state.actor_index,
                  amount: amountToCall,
                });
              }}
              style={actionDimensions.handActionButton}
            >
              <p style={actionDimensions.handActionText}>
                {t('callBetAction')}
              </p>
              <p style={actionDimensions.handActionText}>
                {numberDisplay(amountToCall / 100, tableState.decimal_display)}
              </p>
            </div>
            <div
              className={`${handActionButtonClass} invisible-placeholder`}
              style={actionDimensions.handActionButton}
            ></div>
          </div>
        );
        BetActions = <></>;
      } else if (
        _.compact(tableState.active_hand_state.players).some(
          (p) => p['chip_front_amount'] > 0,
        ) &&
        amountToCall < playerHand['chip_stack_amount']
      ) {
        if (amountToCall > 0) {
          HandActions = (
            <div
              className="hand-actions"
              style={actionDimensions.handActionsContainer}
            >
              <div
                className={`${handActionButtonClass} fold-button`}
                onClick={() => {
                  sendPlayerAction('fold', {
                    seat_index: tableState.active_hand_state.actor_index,
                  });
                }}
                style={actionDimensions.handActionButton}
              >
                <p style={actionDimensions.handActionText}>
                  {t('foldCardsAction')}
                </p>
              </div>
              <div
                className={`${handActionButtonClass} call-button`}
                onClick={() => {
                  sendPlayerAction('call', {
                    seat_index: tableState.active_hand_state.actor_index,
                    amount: amountToCall,
                  });
                }}
                style={actionDimensions.handActionButton}
              >
                <p style={actionDimensions.handActionText}>
                  {t('callBetAction')}
                </p>
                <p style={actionDimensions.handActionText}>
                  {numberDisplay(
                    amountToCall / 100,
                    tableState.decimal_display,
                  )}
                </p>
              </div>
              <button
                className={`${handActionButtonClass} ${targetRaiseButtonClass}`}
                type="submit"
                ref={enterActionRef}
                style={actionDimensions.handActionButton}
              >
                <p style={actionDimensions.handActionText}>
                  {t('raiseToAmountAction')}
                </p>
                <p style={actionDimensions.handActionText}>
                  {numberDisplay(
                    Math.min(
                      playerHand.chip_stack_amount +
                        playerHand.chip_front_amount,
                      targetRaiseAmount,
                    ) / 100,
                    tableState.decimal_display,
                  )}
                </p>
              </button>
            </div>
          );
        } else {
          HandActions = (
            <div
              className="hand-actions"
              style={actionDimensions.handActionsContainer}
            >
              <div
                className={`${handActionButtonClass} invisible-placeholder`}
                style={actionDimensions.handActionButton}
              ></div>
              <div
                className={`${handActionButtonClass} check-button`}
                onClick={() => {
                  sendPlayerAction('check', {
                    seat_index: tableState.active_hand_state.actor_index,
                  });
                }}
                style={actionDimensions.handActionButton}
              >
                <p style={actionDimensions.handActionText}>
                  {t('checkAction')}
                </p>
              </div>
              <button
                className={`${handActionButtonClass} ${targetRaiseButtonClass}`}
                type="submit"
                ref={enterActionRef}
                style={actionDimensions.handActionButton}
              >
                <p style={actionDimensions.handActionText}>
                  {t('raiseToAmountAction')}
                </p>
                <p style={actionDimensions.handActionText}>
                  {numberDisplay(
                    Math.min(
                      playerHand.chip_stack_amount +
                        playerHand.chip_front_amount,
                      targetRaiseAmount,
                    ) / 100,
                    tableState.decimal_display,
                  )}
                </p>
              </button>
            </div>
          );
        }
      } else {
        HandActions = (
          <div
            className="hand-actions"
            style={actionDimensions.handActionsContainer}
          >
            <div
              className={`${handActionButtonClass} invisible-placeholder`}
              style={actionDimensions.handActionButton}
            ></div>
            <div
              className={`${handActionButtonClass} check-button`}
              onClick={() => {
                sendPlayerAction('check', {
                  seat_index: tableState.active_hand_state.actor_index,
                });
              }}
              style={actionDimensions.handActionButton}
            >
              <p style={actionDimensions.handActionText}>{t('checkAction')}</p>
            </div>
            <div
              className={`${handActionButtonClass} ${targetRaiseButtonClass}`}
              ref={enterActionRef}
              onClick={() => {
                if (targetRaiseButtonClass === 'raise-button') {
                  sendPlayerAction('bet', {
                    seat_index: tableState.active_hand_state.actor_index,
                    amount: Math.min(
                      playerHand.chip_stack_amount +
                        playerHand.chip_front_amount,
                      targetRaiseAmount,
                    ),
                  });
                }
              }}
              style={actionDimensions.handActionButton}
            >
              <p style={actionDimensions.handActionText}>{t('betAction')}</p>
              <p style={actionDimensions.handActionText}>
                {numberDisplay(
                  Math.min(
                    playerHand.chip_stack_amount + playerHand.chip_front_amount,
                    targetRaiseAmount,
                  ) / 100,
                  tableState.decimal_display,
                )}
              </p>
            </div>
          </div>
        );
      }
    }

    // Set Pre Actions
    if (
      _.includes(['active', 'paused', 'pending-complete'], tableState.mode) &&
      playerHand &&
      playerHand.hand.length > 0 &&
      playerHand.hand_status !== 'folded' &&
      (playerHand.hand_status === 'active' ||
        playerHand.chip_front_amount <
          Math.max(
            ..._.compact(tableState.active_hand_state.players).map((player) => {
              return player.chip_front_amount;
            }),
          )) &&
      playerHand.chip_stack_amount > 0 &&
      activePlayerUUID !== player.uuid &&
      tableState.active_hand_state.hand_state !== 'resolving'
    ) {
      if (amountToCall > 0) {
        PreActions = (
          <div
            className="pre-actions"
            style={actionDimensions.preActionsContainer}
          >
            <div
              className={`pre-action-button fold-button ${
                preAction === 'fold' ? 'active' : 'inactive'
              }`}
              onClick={() => {
                preAction === 'fold' ? setPreAction('') : setPreAction('fold');
              }}
              style={actionDimensions.preActionButton}
            >
              <p style={actionDimensions.preActionButtonText}>
                {t('foldCardsAction')}
              </p>
            </div>
            <div
              className={`pre-action-button call-button ${
                preAction === 'call' ? 'active' : 'inactive'
              }`}
              onClick={() => {
                preAction === 'call' ? setPreAction('') : setPreAction('call');
              }}
              style={actionDimensions.preActionButton}
            >
              <p style={actionDimensions.preActionButtonText}>
                {t('callBetAction')}
              </p>
              <p style={actionDimensions.preActionButtonText}>
                {numberDisplay(amountToCall / 100, tableState.decimal_display)}
              </p>
            </div>
          </div>
        );
      } else {
        PreActions = (
          <div
            className="pre-actions"
            style={actionDimensions.preActionsContainer}
          >
            <div
              className={`pre-action-button check-fold-button ${
                preAction === 'check-fold' ? 'active' : 'inactive'
              }`}
              onClick={() => {
                preAction === 'check-fold'
                  ? setPreAction('')
                  : setPreAction('check-fold');
              }}
              style={actionDimensions.preActionButton}
            >
              <p style={actionDimensions.preActionButtonText}>
                {t('checkOrFoldPreAction')}
              </p>
            </div>
            <div
              className={`pre-action-button check-button ${
                preAction === 'check' ? 'active' : 'inactive'
              }`}
              onClick={() => {
                preAction === 'check'
                  ? setPreAction('')
                  : setPreAction('check');
              }}
              style={actionDimensions.preActionButton}
            >
              <p style={actionDimensions.preActionButtonText}>
                {t('checkAction')}
              </p>
            </div>
          </div>
        );
      }
    }
  }

  // hide actions when player is sitting_out or blinding-out
  let playerTableStatus = '';
  if (
    lastTableStateProcessedRef.current &&
    lastTableStateProcessedRef.current.players.find(
      (p) => p && p.player_uuid === player.uuid,
    )
  ) {
    playerTableStatus = lastTableStateProcessedRef.current.players.find(
      (p) => p && p.player_uuid === player.uuid,
    ).table_status;
  } else if (
    tableState &&
    tableState.players.find((p) => p && p.player_uuid === player.uuid)
  ) {
    playerTableStatus = tableState.players.find(
      (p) => p && p.player_uuid === player.uuid,
    ).table_status;
  }
  if (_.includes(['sitting_out', 'blinding-out'], playerTableStatus)) {
    BetActions = <></>;
    HandActions = <></>;
    PreActions = <></>;
  }
  PlayerControls = (
    <form onSubmit={raiseHandler}>
      {shouldHideHandActions ? <></> : HandActions}
      {shouldHideHandActions || shouldHideBetActions ? <></> : BetActions}
      {shouldHidePreActions ? <></> : PreActions}
    </form>
  );

  // 2. Modals
  let ActiveModal = <></>;
  if (activeModalIdent === 'request-join') {
    ActiveModal = (
      <RequestChipsModal
        requestChips={requestJoin}
        isFetchingData={isFetchingData}
        formValidation={requestChipsFormValidation}
        tableState={tableState}
        recommendedStartingChips={recommendedStartingChips}
        closeModal={(action) => {
          if (!action) {
            unreserveSeat();
          }
          setActiveModalIdent('');
          setRequestChipsFormValidation({});
        }}
      />
    );
  } else if (activeModalIdent === 'add-chips') {
    ActiveModal = (
      <AddChipsModal
        requestChips={requestAddChips}
        isFetchingData={isFetchingData}
        formValidation={addChipsFormValidation}
        tableState={tableState}
        player={player}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'leave-table') {
    ActiveModal = (
      <LeavePokerTableModal
        leaveTable={leaveTable}
        remainingPlayers={remainingPlayers}
        playerIsHost={playerIsHost}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'finish-game') {
    ActiveModal = (
      <FinishGameModal
        finishGame={() => {
          FINISH_GAME({
            producer: producer.current,
            tableSessionUUID,
            tableStateNonce: tableState.nonce,
            creatorUUID: playerRef.current.uuid,
          });
        }}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'player-table-session-disconnected') {
    ActiveModal = <PlayerTableSessionDisconnectedModal />;
  } else if (activeModalIdent === 'host-settings') {
    ActiveModal = (
      <HostSettingsModal
        tableSessionUUID={tableSessionUUID}
        currentHost={{
          label: tableState.host_display,
          value: tableState.host_uuid,
        }}
        activePlayers={activePlayers}
        updateSettings={(tableSessionConfig) => {
          UPDATE_TABLE_SESSION_CONFIG({
            producer: producer.current,
            tableSessionUUID,
            tableStateNonce: tableState.nonce,
            tableSessionConfig,
            creatorUUID: playerRef.current.uuid,
          });
        }}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'tournament-settings') {
    ActiveModal = (
      <TournamentSettingsModal
        tournamentUUID={tournamentUUID}
        activePlayers={activePlayers}
        updateSettings={(tournamentConfig) => {
          UPDATE_TOURNAMENT_CONFIG({
            producer: producer.current,
            tournamentUUID,
            tableSessionUUID,
            tournamentNonce: tournament.nonce,
            tournamentConfig,
            creatorUUID: playerRef.current.uuid,
          });
        }}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'tournament-info') {
    ActiveModal = (
      <TournamentInfoModal
        tournamentUUID={tournamentUUID}
        playerUUID={player.uuid}
        playerIsHost={playerIsHost}
        openTournamentSettingsModal={() => {
          setActiveModalIdent('tournament-settings');
        }}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'tournament-player-standings') {
    ActiveModal = (
      <TournamentPlayerStandingsModal
        tournamentUUID={tournamentUUID}
        playerUUID={player.uuid}
        playerIsHost={playerIsHost}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'ring-game-info') {
    ActiveModal = (
      <RingGameInfoModal
        playerUUID={player.uuid}
        tableSessionUUID={tableSessionUUID}
        playerIsHost={playerIsHost}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'login') {
    ActiveModal = (
      <PlayerLoginModal
        closeModal={() => {
          setPlayerLoginFormValidation({});
          setActiveModalIdent('');
        }}
        isFetchingData={isFetchingData}
        formValidation={playerLoginFormValidation}
        resetFormErrors={() => setPlayerLoginFormValidation({})}
        signinInit={(signinPayload) => {
          setIsFetchingData(true);
          setSigninPayload(signinPayload);
          PLAYER_SIGNIN_INIT(signinPayload).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              setActiveModalIdent('verify-auth-code');
            } else {
              setPlayerLoginFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  } else if (activeModalIdent === 'verify-auth-code') {
    ActiveModal = (
      <VerifyAuthCodeModal
        closeModal={() => {
          setActiveModalIdent('');
        }}
        signinPayload={signinPayload}
        codeResending={codeResending}
        isFetchingData={isFetchingData}
        resendCode={resendCode}
        formValidation={playerAuthCodeFormValidation}
        verifyAuthCode={(otp) => {
          setIsFetchingData(true);
          PLAYER_SIGNIN_OTP({ ...signinPayload, otp }).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              Cookies.set('player', JSON.stringify(response.data.player), {
                expires: 3650,
              });
              setPlayer(response.data.player);
              setActiveModalIdent('');
            } else {
              setPlayerAuthCodeFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  } else if (activeModalIdent === 'tournament-busted') {
    ActiveModal = (
      <TournamentBustedModal
        tournament={tournament}
        tournamentFinish={activeModalParams.tournament_finish}
        gifURI={activeModalParams.gif_uri}
        requestRebuyTournament={requestRebuyTournament}
        closeModal={() => {
          setActiveModalIdent('');
          setActiveModalParams({});
        }}
      />
    );
  } else if (activeModalIdent === 'sounds') {
    ActiveModal = (
      <SoundsModal
        player={player}
        soundEffectFrequency={soundEffectFrequency}
        setSoundEffectFrequency={setSoundEffectFrequency}
        soundEffectVolume={soundEffectVolume}
        setSoundEffectVolume={setSoundEffectVolume}
        closeModal={() => {
          setActiveModalIdent('');
        }}
      />
    );
  } else if (activeModalIdent === 'tip-the-dealer') {
    ActiveModal = (
      <TipTheDealerDarkModal
        closeModal={() => {
          setActiveModalIdent('');
        }}
        submitForm={() => {}}
      />
    );
  }

  return (
    <div className="screen table-sessions-show-screen">
      <Helmet>
        <HeaderTags />
      </Helmet>
      <MobileAccessibilityBanner />
      <SiteNoticeBanner />
      <div
        className="preloadFont"
        style={{ fontFamily: 'Lato-Bold', fontSize: 0 }}
      >
        .
      </div>
      <div
        className="preloadFont"
        style={{ fontFamily: 'Lato-Regular', fontSize: 0 }}
      >
        .
      </div>
      <input
        className="invite-link"
        type="text"
        style={{ position: 'absolute', opacity: 0 }}
        readOnly
        value={window.location}
      />
      {isLoading ? (
        <div className="collapsible-sidebar-content-container">
          <PlayerCollapsibleSidebar startClosed={true} isExpandable={false} />
          <div className="content">
            <Spinner spinnerType="chase" />
          </div>
        </div>
      ) : (
        <div className="collapsible-sidebar-content-container">
          <PokerTableLeftSidebar
            tableSessionUUID={tableSessionUUID}
            player={player}
            tableState={tableState}
            tournament={tournament}
            sidebarAcknowledgement={sidebarAcknowledgement}
            acknowledgeSidebar={acknowledgeSidebar}
            setActiveModalIdent={setActiveModalIdent}
            onGuestCreated={onGuestCreated}
            signupGuest={signupGuest}
            isFetchingData={isFetchingData}
            guestSignupFormValidation={guestSignupFormValidation}
            resetGuestSignupFormValidation={() =>
              setGuestSignupFormValidation({})
            }
            playerConfirmEmailFormValidation={playerConfirmEmailFormValidation}
            resetPlayerConfirmEmailFormValidation={() =>
              setPlayerConfirmEmailFormValidation({})
            }
            verifyAuthCode={signupVerifyAuthCode}
          />
          <div
            className="content"
            onClick={() => eventEmitter.emit('close-sidebar-if-open', {})}
          >
            <div
              className="poker-table-container"
              style={{
                height: pokerTableContainerHeight,
                left: pokerTableContainerXOffset,
              }}
            >
              <NotificationCenter
                tableState={tableState}
                tournament={tournament}
                player={player}
                playerIsHost={playerIsHost}
                rejectRequestJoinTable={rejectRequestJoinTable}
                approveRequestJoinTable={approveRequestJoinTable}
                rejectRequestAddChips={rejectRequestAddChips}
                approveRequestAddChips={approveRequestAddChips}
                rejectRequestJoinTournament={rejectRequestJoinTournament}
                approveRequestJoinTournament={approveRequestJoinTournament}
                rejectRequestRebuyTournament={rejectRequestRebuyTournament}
                approveRequestRebuyTournament={approveRequestRebuyTournament}
              />
              <BlindsDisplay tableState={tableState} tournament={tournament} />
              <p
                className="tip-the-dealer-link"
                onClick={openTipTheDealerModal}
              >
                {t('tipTheDealerTitle')}
              </p>
              {tournamentUUID ? (
                <>
                  <p
                    className="tournament-info-link"
                    onClick={() => {
                      setActiveModalIdent('tournament-info');
                      AnalyticsService.opensTournamentInfoModal(player);
                    }}
                  >
                    {t('tableSessionShowTournamentInfo')}
                  </p>
                  <p
                    className="tournament-player-standings-link"
                    onClick={() => {
                      setActiveModalIdent('tournament-player-standings');
                      AnalyticsService.opensPlayerStandingsModal(player);
                    }}
                  >
                    {t('tableSessionShowTournamentPlayerStandings')}
                  </p>
                  <p className="copy-tournament-invite-link" onClick={copyURL}>
                    {copyingURL ? t('copiedLink') : t('copyInviteLink')}
                  </p>
                </>
              ) : (
                <>
                  <p
                    className="ring-game-info-link"
                    onClick={() => {
                      setActiveModalIdent('ring-game-info');
                      AnalyticsService.opensPlayerStandingsModal(player);
                    }}
                  >
                    {t('tableSessionShowRingGameInfo')}
                  </p>
                  <p className="copy-ring-game-invite-link" onClick={copyURL}>
                    {copyingURL ? t('copiedLink') : t('copyInviteLink')}
                  </p>
                </>
              )}
              <PokerTableActions
                tournamentUUID={tournamentUUID}
                tournament={tournament}
                player={player}
                playerIsSittingAtTable={playerIsSittingAtTable}
                playerIsHost={playerIsHost}
                tableState={tableState}
                lastTableStateProcessed={lastTableStateProcessedRef.current}
                setActiveModalIdent={setActiveModalIdent}
                pauseGame={pauseGame}
                resumeGame={resumeGame}
                sitIn={sitIn}
                sitOut={sitOut}
                sitInTournament={sitInTournament}
                sitOutTournament={sitOutTournament}
                pauseTournament={pauseTournament}
                resumeTournament={resumeTournament}
              />
              <PokerTable
                canvasID={CANVAS_ID}
                tableState={tableState}
                playerUUID={playerRef.current.uuid}
                reserveSeat={reserveSeat}
                isJoinable={tableIsJoinable}
                processTableStateRender={processTableStateRender}
                shotClock={shotClock}
                renderDimensions={renderDimensions}
                playerIsHost={playerIsHost}
                hostActionTargetPlayer={hostActionTargetPlayer}
                setHostActionTargetPlayer={setHostActionTargetPlayer}
              />
              {PlayerControls}
              <SecondaryNotificationCenter
                tournament={tournament}
                tableState={
                  lastTableStateProcessedRef.current
                    ? lastTableStateProcessedRef.current
                    : tableState
                }
                player={player}
                requestJoinTournament={requestJoinTournament}
                requestRebuyTournament={requestRebuyTournament}
              />
              <TableOverlayContent
                dimensions={tableOverlayDimensions}
                tableState={tableState}
                lastTableStateProcessed={lastTableStateProcessedRef.current}
                sidebarAcknowledgement={sidebarAcknowledgement}
                player={player}
                playerIsHost={playerIsHost}
                requestJoinTournament={requestJoinTournament}
                startTournament={startTournament}
                dealCards={dealCards}
                finishGame={finishGame}
                copyURL={copyURL}
                copyingURL={copyingURL}
                tournamentUUID={tournamentUUID}
                tournament={tournament}
                tableSessionUUID={tableSessionUUID}
                openTipTheDealerModal={openTipTheDealerModal}
              />
            </div>
          </div>
          <HostPlayerActionsContextMenu
            targetPlayer={hostActionTargetPlayer}
            producer={producer.current}
            tableSessionUUID={tableSessionUUID}
            tournamentUUID={tournamentUUID}
            tableState={tableState}
          />
          {ActiveModal}
        </div>
      )}
      <VideoRoomContainer
        tableSessionUUID={tableSessionUUID}
        tournamentUUID={tournamentUUID}
        playerUUID={playerRef.current.uuid}
        playerDisplay={playerDisplay}
        playerIsHost={playerIsHost}
        hostDisplay={tableState?.host_display}
        gameIsActive={['active', 'paused', 'pending-complete'].includes(
          tableState?.mode,
        )}
        userIsConsideredPlayer={userIsConsideredPlayer}
        containerWidth={videoRoomWidth}
        setVideoRoomWidth={setVideoRoomWidth}
        toggleContainerIsOpen={() => {
          if (!sidebarAcknowledgement) {
            return;
          }
          const newValue = !videoRoomContainerIsOpen;
          setVideoRoomContainerIsOpen(newValue);
        }}
        containerIsOpen={videoRoomContainerIsOpen}
        joinVideo={joinVideo}
        leaveVideoRoom={() => {
          setIsInVideoRoom(false);
          AnalyticsService.leavesChatroom(
            playerRef.current.uuid,
            playerRef.current.display,
            tableSessionUUID,
            tournamentUUID,
          );
        }}
        isInVideoRoom={isInVideoRoom}
        chatRoomConfig={chatRoomConfig}
        initChatRoom={(chatRoomConfig) => {
          const result = INIT_CHAT_ROOM({
            producer: producer.current,
            tableSessionUUID,
            tableStateNonce: tableState.nonce,
            chatRoomConfig,
            creatorUUID: playerRef.current.uuid,
          });
          AnalyticsService.startsChatroom(
            playerRef.current.uuid,
            playerRef.current.display,
            tableSessionUUID,
            tournamentUUID,
            chatRoomConfig.allowed_participants,
            chatRoomConfig.enabled_channels.includes('video'),
            chatRoomConfig.enabled_channels.includes('audio'),
          );
          return result;
        }}
        endChatRoom={() => {
          const result = END_CHAT_ROOM({
            producer: producer.current,
            tableSessionUUID,
            tableStateNonce: tableState.nonce,
            creatorUUID: playerRef.current.uuid,
          });
          AnalyticsService.endsChatroomForEveryone(
            playerRef.current.uuid,
            playerRef.current.display,
            tableSessionUUID,
            tournamentUUID,
          );
          return result;
        }}
        updateChatRoomConfig={(chatRoomConfig) => {
          const result = UPDATE_CHAT_ROOM_CONFIG({
            producer: producer.current,
            tableSessionUUID,
            tableStateNonce: tableState.nonce,
            chatRoomConfig,
            creatorUUID: playerRef.current.uuid,
          });
          AnalyticsService.updatesChatroomSettings(
            playerRef.current.uuid,
            playerRef.current.display,
            tableSessionUUID,
            tournamentUUID,
            chatRoomConfig.allowed_participants,
            chatRoomConfig.enabled_channels.includes('video'),
            chatRoomConfig.enabled_channels.includes('audio'),
          );
          return result;
        }}
        sendsTextMessageInChatroom={() => {
          AnalyticsService.sendsTextMessageInChatroom(
            playerRef.current.uuid,
            playerRef.current.display,
            tableSessionUUID,
            tournamentUUID,
          );
        }}
        chatroomPaneIsMinimized={() => {
          AnalyticsService.minimizesChatroomPane(
            playerRef.current.uuid,
            playerRef.current.display,
            tableSessionUUID,
            tournamentUUID,
          );
        }}
      />
    </div>
  );
}
