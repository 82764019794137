import React from 'react';
import logo from '../assets/brand/logo-unicolor.png';
import {
  numberDisplay,
  userNameDisplay,
  gameVariantDisplay,
} from '../poker/helpers';
import SidebarFooter from './SidebarFooter';
import { t } from '../i18n';

export default function WelcomeBackPlayerSidebar({
  player,
  tableState,
  tournament,
  acknowledgeSidebar,
  startClosed,
}) {
  const [isClosed, setIsClosed] = React.useState(startClosed);
  var classString = 'welcome-back-player-sidebar';
  if (isClosed) {
    classString += ' is-closed';
  }

  const acknowledgeSidebarDelay = () => {
    setIsClosed(true);
    setTimeout(acknowledgeSidebar, 300);
  };

  const renderGameInfo = (tableState, tournament) => {
    if (tableState.tournament_id) {
      if (tournament) {
        return (
          <>
            <h4 className="game-info-1">{`${gameVariantDisplay(
              tableState.game_variant,
            )} ${t('sidebarTournament')}`}</h4>
            <h4 className="game-info-2">
              {t(
                'sidebarChipsStartingStack',
                numberDisplay(tournament.starting_stack_size / 100),
              )}
            </h4>
            <h4 className="game-info-3">
              {t('sidebarLevelDuration', tournament.level_duration / 60)}
            </h4>
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <>
          <h4 className="game-info-1">
            {gameVariantDisplay(tableState.game_variant)}
          </h4>
          <h4 className="game-info-2">
            {`${numberDisplay(
              tableState.blinds[0] / 100,
              tableState.decimal_display,
            )}/${numberDisplay(
              tableState.blinds[1] / 100,
              tableState.decimal_display,
            )} ${t('blinds')}`}
          </h4>
        </>
      );
    }
  };

  return (
    <div className="welcome-back-player-sidebar-container">
      <div className={classString}>
        <div
          className="brand"
          onClick={() => {
            window.open('/');
          }}
        >
          <img className="logo" src={logo} alt="clubs-poker" />
          <p className="clubs">
            <span className="bold">Clubs</span> poker
          </p>
          <p className="now">now</p>
        </div>
        <div className="welcome-back-player-content-wrapper">
          <h2 className="welcome">
            {t('welcomeBackDisplay', userNameDisplay(player.display))}
          </h2>
          <div className="invite-info">
            <h4 className="host-info">{t('youveBeenInvitedToAPokerGame')}</h4>
            <h4 className="host-info">
              {t('hostedBy', tableState.host_display)}
            </h4>
            {renderGameInfo(tableState, tournament)}
          </div>
          <div
            className="choose-my-seat-button submit-button default-submit-button"
            onClick={acknowledgeSidebarDelay}
          >
            {tableState.tournament_id ? (
              <p>{t('gotIt')}</p>
            ) : (
              <p>{t('chooseMySeat')}</p>
            )}
          </div>
        </div>
        <div className="flex-spacer" />
        <SidebarFooter player={player} />
      </div>
    </div>
  );
}
