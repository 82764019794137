import React from 'react';

export default function Spinner({
  className = '',
  spinnerType,
  style,
  invertedDots,
}) {
  let SpinnerHTML;
  switch (spinnerType) {
    case 'chase':
      SpinnerHTML = (
        <div
          className={`${className} spinner sk-chase ${
            invertedDots ? 'inverted' : ''
          }`}
          style={style}
        >
          <div className={`sk-chase-dot`}></div>
          <div className={`sk-chase-dot`}></div>
          <div className={`sk-chase-dot`}></div>
          <div className={`sk-chase-dot`}></div>
          <div className={`sk-chase-dot`}></div>
          <div className={`sk-chase-dot`}></div>
        </div>
      );
      break;
    default:
      SpinnerHTML = (
        <div
          className={`${className} sk-chase ${invertedDots ? 'inverted' : ''}`}
        >
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      );
  }

  return SpinnerHTML;
}
