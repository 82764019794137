import React from 'react';
import Spinner from '../Spinner';
import 'react-phone-input-2/lib/style.css';
import { t } from '../../i18n';

export default function HostOnboardingForm({
  createPlayer,
  onboardingHostSignupFormValidation,
  isFetchingData,
}) {
  const [userName, setUserName] = React.useState('');
  const [email, setEmail] = React.useState('');

  const submitForm = (e) => {
    e.preventDefault();
    if (!userName || !email) {
      return;
    }
    return createPlayer({
      userName,
      email,
    });
  };

  return (
    <form
      className="clubs-form onboarding-form onboarding-signup-form"
      onSubmit={submitForm}
    >
      <div className="validated-input-field">
        <input
          className={
            onboardingHostSignupFormValidation.user_name ? 'error' : ''
          }
          type="text"
          required={true}
          placeholder="Nickname"
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
        />
        {onboardingHostSignupFormValidation.user_name ? (
          <span className="form-validation">
            {onboardingHostSignupFormValidation.user_name[0]}
          </span>
        ) : (
          <></>
        )}
      </div>
      <div className="validated-input-field">
        <input
          className={onboardingHostSignupFormValidation.email ? 'error' : ''}
          type="email"
          required={true}
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        {onboardingHostSignupFormValidation.email ? (
          <span className="form-validation">
            {onboardingHostSignupFormValidation.email[0]}
          </span>
        ) : (
          <></>
        )}
      </div>
      <button type="submit" className="button submit-button">
        {isFetchingData ? (
          <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
        ) : (
          <p>{t('signUp')}</p>
        )}
      </button>
    </form>
  );
}
