import React from 'react';
import { t } from '../../i18n';
import notificationIcon from '../../assets/icons/notification-icon.png';
import { numberDisplay } from '../../poker/helpers';

const TableRequestNotification = ({
  index,
  text,
  approveHandler,
  approveText,
  declineHandler,
  declineText,
}) => {
  return (
    <div
      className={`notification notification-${index} pending-join-notification`}
    >
      <img
        className="notification-icon"
        src={notificationIcon}
        alt="Notification Icon"
      />
      <p className="notification-text">{text}</p>
      <div
        className="notification-approve default-submit-button"
        onClick={approveHandler}
      >
        <p>{approveText}</p>
      </div>
      <div
        className="notification-decline default-decline-button"
        onClick={declineHandler}
      >
        <p>{declineText}</p>
      </div>
    </div>
  );
};

export const JoinTableNotification = ({
  tableState,
  tableRequest,
  approveRequestJoinTable,
  rejectRequestJoinTable,
  index,
}) => (
  <TableRequestNotification
    index={index}
    text={t(
      'userIsRequestingSeatAndChips',
      tableRequest.request_data.player_user_name,
      numberDisplay(
        tableRequest.request_data.chip_amount / 100,
        tableState.decimal_display,
      ),
    )}
    approveHandler={() => {
      approveRequestJoinTable(
        index,
        tableRequest.request_data.player_table_session_uuid,
      );
    }}
    approveText={t('tableSessionShowApproveNotification')}
    declineHandler={() => {
      rejectRequestJoinTable(
        index,
        tableRequest.request_data.player_table_session_uuid,
      );
    }}
    declineText="X"
  />
);

export const AddChipsNotification = ({
  tableState,
  tableRequest,
  approveRequestAddChips,
  rejectRequestAddChips,
  index,
}) => (
  <TableRequestNotification
    index={index}
    text={t(
      'userIsRequestingChips',
      tableRequest.request_data.player_user_name,
      numberDisplay(
        tableRequest.request_data.chip_amount / 100,
        tableState.decimal_display,
      ),
    )}
    approveHandler={() => {
      approveRequestAddChips(index);
    }}
    approveText={t('tableSessionShowApproveNotification')}
    declineHandler={() => {
      rejectRequestAddChips(index);
    }}
    declineText="X"
  />
);

export const JoinTournamentNotification = ({
  tournamentRequest,
  approveRequestJoinTournament,
  rejectRequestJoinTournament,
  index,
}) => (
  <TableRequestNotification
    index={index}
    text={t(
      'tournamentRequestingJoin',
      tournamentRequest.request_data.player_user_name,
    )}
    approveHandler={() => {
      approveRequestJoinTournament(
        index,
        tournamentRequest.request_data.player_uuid,
      );
    }}
    approveText={t('tableSessionShowApproveNotification')}
    declineHandler={() => {
      rejectRequestJoinTournament(index);
    }}
    declineText="X"
  />
);

export const RebuyTournamentNotification = ({
  tournamentRequest,
  approveRequestRebuyTournament,
  rejectRequestRebuyTournament,
  index,
}) => (
  <TableRequestNotification
    index={index}
    text={t(
      'tournamentRequestingRebuy',
      tournamentRequest.request_data.player_user_name,
    )}
    approveHandler={() => {
      approveRequestRebuyTournament(index);
    }}
    approveText={t('tableSessionShowApproveNotification')}
    declineHandler={() => {
      rejectRequestRebuyTournament(index);
    }}
    declineText="X"
  />
);
