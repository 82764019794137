import React, { useState, useRef, createRef } from 'react';

export default function OTPFields({ otpLength = 6, error, setValue }) {
  const [digits, setDigits] = useState(new Array(otpLength).fill(''));
  const digitRefs = useRef([]);
  digitRefs.current = digits.map(
    (digit, i) => digitRefs.current[i] ?? createRef(),
  );

  return (
    <div
      className="otp-fields"
      onPaste={(e) => {
        e.preventDefault();
        let pasteText = e.clipboardData.getData('Text');
        if (pasteText.length === otpLength) {
          const nextDigits = pasteText.split('');
          setDigits(nextDigits);
          setValue(nextDigits.join(''));
          digitRefs.current[otpLength - 1].current.focus();
        }
      }}
    >
      {digits.map((digit, i) => (
        <input
          autoFocus={i === 0}
          className={`digit-${i + 1} ${error ? 'error' : ''}`}
          maxLength="1"
          value={digit}
          ref={digitRefs.current[i]}
          onKeyDown={(e) => {
            const nextDigits = [...digits];
            if (e.which >= 48 && e.which <= 57) {
              nextDigits[i] = '';
              setDigits(nextDigits);
            } else if (e.which === 8) {
              nextDigits[i] = '';
              setDigits(nextDigits);
              if (i > 0) {
                digitRefs.current[i - 1].current.focus();
              }
            }
          }}
          onChange={(e) => {
            if (e.target.value !== '') {
              const nextDigits = [...digits];
              nextDigits[i] = e.target.value;
              setDigits(nextDigits);
              setValue(nextDigits.join(''));
              if (i < otpLength - 1) {
                digitRefs.current[i + 1].current.focus();
              }
            }
          }}
        />
      ))}
    </div>
  );
}
