import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Menu, Item } from 'react-contexify';
import { t } from '../../i18n';
import { numberDisplay } from '../../poker/helpers';
import { REMOVE_PLAYER, UPDATE_CHIPS } from '../../services/APIService';
import DarkModal from '../modals/DarkModal';
import Spinner from '../Spinner';
import { isFloatValuePositive, setNumberValue } from '../displayHelpers';
import AnalyticsService from '../../services/AnalyticsService';

export const HOST_PLAYER_ACTIONS_CONTEXT_MENU_ID = 'host-player-actions';
const tableStatusAllowingUpdateChips = [
  'active',
  'sitting_out',
  'blinding-out',
];

export default function HostPlayerActionsContextMenu({
  producer,
  tableSessionUUID,
  tournamentUUID,
  tableState,
  targetPlayer,
}) {
  const [targetAction, setTargetAction] = useState(null);
  const [newChipsCount, setNewChipsCount] = useState('');
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [formValidation, setFormValidation] = useState({});

  const updateChipsAllowed =
    targetPlayer &&
    tableStatusAllowingUpdateChips.includes(targetPlayer.table_status);
  if (tableState.mode === 'completed') {
    return null;
  }
  const creatorUUID = tableState.host_uuid;
  const hostUUID = tableState.host_uuid;
  const hostDisplay = tableState.host_display;

  const closeModal = () => {
    setTargetAction(null);
    setNewChipsCount('');
  };

  const removePlayer = async (event) => {
    event.preventDefault();
    if (!targetPlayer || !targetPlayer.player_uuid) {
      return;
    }
    setIsFetchingData(true);
    const result = await REMOVE_PLAYER({
      producer,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      playerUUID: targetPlayer.player_uuid,
      creatorUUID,
    });
    setIsFetchingData(false);
    closeModal();
    AnalyticsService.removesPlayerFromTable(
      hostUUID,
      hostDisplay,
      targetPlayer,
      tableSessionUUID,
      tournamentUUID,
    );
    return result;
  };

  let updateChipsCopyKey = 'addChips';
  let updateChipsDifference;
  let chipDelta;
  if (targetPlayer && typeof newChipsCount === 'number') {
    updateChipsCopyKey =
      newChipsCount < targetPlayer.chip_stack_amount
        ? 'removeNChips'
        : 'addNChips';
    chipDelta = newChipsCount - targetPlayer.chip_stack_amount;
    updateChipsDifference = numberDisplay(
      Math.abs(chipDelta) / 100,
      tableState.decimal_display,
    );
  }
  const updateChipsCopyParams = [
    updateChipsCopyKey,
    updateChipsDifference,
  ].filter((p) => p);
  const decimalScale = tableState.decimal_display ? 2 : 0;
  const newChipsAmountMaxLength =
    6 + decimalScale + (decimalScale === 0 ? 0 : 1);

  const updateChips = async (event) => {
    event.preventDefault();
    setFormValidation({});
    if (chipDelta !== 0 && !chipDelta) {
      return;
    }
    if (chipDelta === 0) {
      setFormValidation({
        newChipsCount: [
          `This player already has ${numberDisplay(
            newChipsCount / 100,
            tableState.decimal_display,
          )}!`,
        ],
      });
      return;
    }
    setIsFetchingData(true);
    const result = await UPDATE_CHIPS({
      producer,
      tableSessionUUID,
      tableStateNonce: tableState.nonce,
      playerUUID: targetPlayer.player_uuid,
      chipDelta,
      creatorUUID,
    });
    setIsFetchingData(false);
    setNewChipsCount('');
    closeModal();
    AnalyticsService.updatesPlayerChip(
      hostUUID,
      hostDisplay,
      targetPlayer,
      chipDelta,
      tableSessionUUID,
      tournamentUUID,
    );
    return result;
  };

  return (
    <>
      <Menu id={HOST_PLAYER_ACTIONS_CONTEXT_MENU_ID}>
        <Item
          disabled={!updateChipsAllowed}
          onClick={() => {
            setTargetAction('update-chips');
          }}
        >
          {t('updateChips')}
        </Item>
        <Item
          disabled={
            !targetPlayer ||
            (!tournamentUUID &&
              tableState.host_uuid === targetPlayer.player_uuid)
          }
          onClick={() => {
            setTargetAction('remove-player');
          }}
        >
          {t('removePlayer')}
        </Item>
      </Menu>
      {targetAction === 'update-chips' ? (
        <DarkModal title={t('updateChips')} closeModal={closeModal}>
          <h2 className="modal-subtitle">
            {t('updateChipsInstructions', targetPlayer.display)}
          </h2>
          <div className="custom-spacer" style={{ height: 30 }} />
          <form className="clubs-form" onSubmit={updateChips}>
            <div className="validated-input-field">
              <NumberFormat
                className={
                  'chips-amount-input' +
                  (formValidation.newChipsCount ? ' error' : '')
                }
                required
                placeholder={t('newChipsTotal')}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={decimalScale}
                fixedDecimalScale="true"
                maxLength={newChipsAmountMaxLength}
                isAllowed={isFloatValuePositive}
                min={0}
                value={
                  typeof newChipsCount === 'number'
                    ? newChipsCount / 100
                    : newChipsCount
                }
                onChange={setNumberValue(setNewChipsCount)}
              />
              {formValidation.newChipsCount ? (
                <span className="form-validation">
                  {formValidation.newChipsCount[0]}
                </span>
              ) : null}
            </div>
            <div className="custom-spacer" style={{ height: 40 }} />
            <p>
              {t(
                'currentChipCountInfo',
                numberDisplay(
                  targetPlayer.chip_stack_amount / 100,
                  tableState.decimal_display,
                ),
              )}
            </p>
            <div className="custom-spacer" style={{ height: 20 }} />
            <button className="button default-submit-button" type="submit">
              {isFetchingData ? (
                <Spinner
                  spinnerType="chase"
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                <p>{t(...updateChipsCopyParams)}</p>
              )}
            </button>
          </form>
        </DarkModal>
      ) : null}
      {targetAction === 'remove-player' ? (
        <DarkModal
          title={t('removePlayer')}
          closeModal={closeModal}
          contentStyle={{ height: 310 }}
        >
          <h2 className="modal-subtitle">
            {t('removePlayerInstructions', targetPlayer.display)}
          </h2>
          <div className="custom-spacer" style={{ height: 100 }} />
          <button
            onClick={removePlayer}
            className="button default-submit-button"
            type="button"
          >
            {isFetchingData ? (
              <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
            ) : (
              <p>{t('removePlayerConfirm')}</p>
            )}
          </button>
        </DarkModal>
      ) : null}
    </>
  );
}
