import React from 'react';
import { t } from '../i18n';
import moment from 'moment';

export default function SiteNoticeBanner() {
  const [dismissed, setDismissed] = React.useState(false);
  const targetDate = new Date(Date.UTC(2022, 7, 1, 16, 0, 0));
  const targetEndDate = new Date(+targetDate + 60 * 60 * 1000);
  const showAfter = new Date(+targetDate - 2 * 60 * 60 * 1000);
  const now = new Date();

  if (!dismissed && +now >= showAfter && +now < targetEndDate) {
    return (
      <div className="site-notice-banner">
        <p className="exclamation-icon">!</p>
        <div className="site-notice-content">
          <p className="site-notice-message">
            {t(
              'siteNoticeMessage',
              [
                moment(targetDate).format('MMMM Do, YYYY [starting at] h:mma'),
                `(${moment.tz.guess()})`,
              ].join(' '),
              moment(targetEndDate).format('h:mma'),
            )}
          </p>
          <button
            type="button"
            className="dismiss-button"
            onClick={() => setDismissed(true)}
          >
            <p>{t('dismiss')}</p>
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
