export function keyValueTuplesToQueryParams(keyValueTuples) {
  const keyValuePairsMap = keyValueTuples
    .filter((tuple) => tuple.value !== undefined) // Remove tuples which value is undefined
    .reduce((result, tuple) => {
      result[tuple.key] = tuple.value;
      return result;
    }, {});
  return new URLSearchParams(keyValuePairsMap);
}

export function buildURL(url, keyValueTuples) {
  const u = new URL(url);
  u.search = keyValueTuplesToQueryParams(keyValueTuples).toString();
  return u;
}
