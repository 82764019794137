import React from 'react';
import ReactTooltip from 'react-tooltip-lite';

export default function PlayerSummaryBadge({ content }) {
  return (
    <ReactTooltip
      className="player-summary-badge-wrapper"
      content={content}
      distance={8}
    >
      <div className="player-summary-badge" />
    </ReactTooltip>
  );
}
