import React, { useEffect, useRef } from 'react';
import { t } from '../i18n';
import { cardsDictionary } from '../poker/cards';
import { numberDisplay } from '../poker/helpers';
import { Tooltip } from '../components/Tooltip';
import { toOrdinal } from '../utils/numbers';

const HandStrength = ({ strength }) => (
  <div className="hand-strength">
    <div className="vertical-line" />
    <div className="horizontal-line" />
    <div className="vertical-line" />
    <div className="strength-indicator" style={{ left: strength }}>
      <div className="strength-indicator-text">{strength}</div>
      <div className="strength-indicator-dot" />
    </div>
  </div>
);

const Amount = ({ amount, color = true, decimalDisplay, ...rest }) => (
  <div
    className={
      'right ' +
      (color && amount > 0 ? 'positive' : amount < 0 ? 'negative' : '')
    }
    {...rest}
  >
    {(amount > 0 ? '+ ' : amount < 0 ? '- ' : '') +
      numberDisplay(Math.abs(amount / 100))}
  </div>
);

const loadedCardBack = new Image();
loadedCardBack.src = `${process.env.PUBLIC_URL}/assets/card-back.png`;

const FeaturedHand = ({ uuid, name, chipDelta, cards, decimalDisplay }) => {
  const canvasRef = useRef();
  const cardWidth = 16;
  const height = Math.round(cardWidth * 1.4);
  useEffect(() => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(
      cards[0]
        ? cardsDictionary[`${cards[0].suit}-${cards[0].rank}`].image
        : loadedCardBack,
      0,
      0,
      cardWidth,
      height,
    );
    context.drawImage(
      cards[1]
        ? cardsDictionary[`${cards[1].suit}-${cards[1].rank}`].image
        : loadedCardBack,
      cardWidth + 1,
      0,
      cardWidth,
      height,
    );
  }, [canvasRef]);
  return (
    <a
      href={`/hand-analyzer/${uuid}`}
      className="hand"
      key={uuid}
      target="_blank"
      rel="noopener noreferrer"
    >
      <canvas
        height={`${height}px`}
        width={`${cardWidth * 2 + 1}px`}
        ref={canvasRef}
      />
      <span role="img" aria-label="trophy">
        🏆
      </span>
      <div className="winner-name">{name}</div>
      <Amount amount={chipDelta} decimalDisplay={decimalDisplay} />
    </a>
  );
};

const medalEmojis = {
  '1st': '🥇',
  '2nd': '🥈',
  '3rd': '🥉',
};

const TournamentStanding = ({ standing }) => {
  const ordinal = toOrdinal(standing);
  const medalEmoji =
    ordinal in medalEmojis ? (
      <>
        <span role="img" aria-label="medal">
          {medalEmojis[ordinal]}
        </span>{' '}
      </>
    ) : null;
  return (
    <p className="misc-stat">
      <span className="misc-amount">
        {medalEmoji}
        {ordinal}
      </span>{' '}
      {t('tournamentStanding')}
    </p>
  );
};

const YourSummarySection = ({ summaryType, playerSummary, options }) => {
  if (!['player', 'admin-player'].includes(playerSummary?.response_role)) {
    return null;
  }
  const { decimalDisplay } = options;

  return (
    <div className="player-summary-section">
      <h2 className="section-title">{t('yourSummary')}</h2>
      <div className="flex">
        <table>
          <tbody>
            <tr>
              <td>
                {summaryType === 'ring-game' ? (
                  <p className="misc-stat">
                    <span
                      className={
                        playerSummary.winnings >= 0
                          ? 'winning-amount'
                          : 'losing-amount'
                      }
                    >
                      {playerSummary.winnings >= 0 ? '+' : ''}{' '}
                      {numberDisplay(
                        playerSummary.winnings / 100,
                        decimalDisplay,
                      )}
                    </span>{' '}
                    {t('winnings')}
                  </p>
                ) : null}
                {summaryType === 'tournament' ? (
                  <TournamentStanding
                    standing={playerSummary.tournament_standing}
                  />
                ) : null}
                <p className="misc-stat">
                  <span className="misc-amount">
                    {playerSummary.hands_played}
                  </span>{' '}
                  {t('handsPlayed')}
                </p>
                <p className="misc-stat">
                  <span className="misc-amount">{playerSummary.hands_won}</span>{' '}
                  {t('handsWon')}
                </p>
              </td>
              <td>
                <h3>
                  {t('startingHandStrength')}
                  <Tooltip content={t('startingHandStrengthTooltip')} />
                </h3>
                {playerSummary.hands_played >= 10 ? (
                  <HandStrength strength={playerSummary.hand_strength} />
                ) : (
                  <p>You weren’t dealt enough hands.</p>
                )}
              </td>

              <td className="featured-hands" rowSpan={2}>
                <h3>{t('featuredHands')}</h3>
                {playerSummary.featured_hands.length === 0 ? (
                  <p>You weren't dealt enough hands.</p>
                ) : (
                  playerSummary.featured_hands.map((hand) => (
                    <FeaturedHand
                      key={hand.uuid}
                      uuid={hand.uuid}
                      name={hand.winners[0]}
                      chipDelta={hand.chip_delta}
                      cards={hand.hand}
                      decimalDisplay={decimalDisplay}
                    />
                  ))
                )}
                <a
                  href={`/hand-analyzer/${playerSummary.first_hand_uuid}?sidebar=true`}
                  className="hand"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="left">
                    View all {playerSummary.total_hands_count} hands
                  </span>
                  <div className="right">
                    <img
                      className="external-link-arrow"
                      src="/assets/arrow-up-right.svg"
                      alt="external link arrow"
                    />
                  </div>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <h3>
                  {t('preflopAggression')}
                  <Tooltip content={t('preflopAggressionTooltip')} />
                </h3>
                <p className="preflop-aggression">
                  {playerSummary.hands_played > 0
                    ? [
                        Math.floor(playerSummary.vpip * 100) + '%',
                        Math.floor(playerSummary.preflop_raise * 100) + '%',
                      ].join(' / ')
                    : '0% / 0%'}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YourSummarySection;
