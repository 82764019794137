import React from 'react';
import tipTheDealerArrow from '../assets/svgs/tip-the-dealer-arrow.svg';
import { t } from '../i18n';

export default function TipTheDealerHeadsUp({
  dimensions,
  openTipTheDealerModal,
}) {
  return (
    <div
      className="table-overlay-component"
      style={dimensions.belowLogoComponent}
    >
      <p>
        <span>{t('tipTheDealerTableOverlaySentence1')}</span>
        <button className="link-like-button" onClick={openTipTheDealerModal}>
          {t('tipTheDealerTableOverlayCTA1')}
        </button>
        <span>{t('tipTheDealerTableOverlaySentence2')}</span>
      </p>
      <div className="skewed-message">
        <img
          className="tip-the-dealer-arrow"
          src={tipTheDealerArrow}
          alt="This helps us keep the site running"
          style={dimensions.skewedMessageImageComponent}
        />
      </div>
    </div>
  );
}
