import React from 'react';
import { TOURNAMENT_CONFIG } from '../../services/APIService';
import Spinner from '../Spinner';
import Dropdown from 'react-dropdown';
import { t } from '../../i18n';
import { numberDisplay } from '../../poker/helpers';

const startingStackSizeOptions = [
  { value: '20000', label: 'Short (20BB)' },
  { value: '50000', label: 'Medium (50BB)' },
  { value: '100000', label: 'Large (100BB)' },
  { value: '200000', label: 'X-Large (200BB)' },
];

const rebuyOptions = [
  { value: true, label: t('tournamentSettingsRebuyOptionsTrue') },
  { value: false, label: t('tournamentSettingsRebuyOptionsFalse') },
];

const levelDurationOptions = [
  { value: '120', label: t('tournamentDurationMin', '2') },
  { value: '180', label: t('tournamentDurationMin', '3') },
  { value: '300', label: t('tournamentDurationMin', '5') },
  { value: '480', label: t('tournamentDurationMin', '8') },
  { value: '600', label: t('tournamentDurationMin', '10') },
  { value: '900', label: t('tournamentDurationMin', '15') },
  { value: '1200', label: t('tournamentDurationMin', '20') },
];

const shotClockOptions = [
  { value: '20000', label: '20 seconds' },
  { value: '30000', label: '30 seconds' },
  { value: '60000', label: '60 seconds' },
  { value: '1000000000', label: 'Infinite' },
];

export default function TournamentSettingsModal({
  tournamentUUID,
  activePlayers,
  closeModal,
  updateSettings,
}) {
  const [isLoadingData, setIsLoadingData] = React.useState(true);

  const [antes, setAntes] = React.useState(false);
  const [startingStackSize, setStartingStackSize] = React.useState({});
  const [rebuysAllowed, setRebuysAllowed] = React.useState({});
  const [levelDuration, setLevelDuration] = React.useState({});
  const [shotClock, setShotClock] = React.useState({});
  const [tournamentHost, setTournamentHost] = React.useState({});

  const selectRebuysAllowed = (data) => {
    setRebuysAllowed(data);
  };
  const selectLevelDuration = (data) => {
    setLevelDuration(data);
  };
  const selectShotClock = (data) => {
    setShotClock(data);
  };
  const selectTournamentHost = (data) => {
    setTournamentHost(data);
  };

  React.useEffect(() => {
    TOURNAMENT_CONFIG({ tournamentUUID }).then((response) => {
      if (response.data.status === 'ok') {
        let startingStackSizeOption = startingStackSizeOptions.find(
          (option) =>
            option.value ===
            response.data.config.starting_stack_size.toString(),
        );
        if (!startingStackSizeOption) {
          startingStackSizeOption = {
            label: `Custom: ${numberDisplay(
              response.data.config.starting_stack_size / 100,
            )}`,
            value: response.data.config.starting_stack_size.toString(),
          };
        }
        setAntes(response.data.config.antes);
        setStartingStackSize(startingStackSizeOption);
        setRebuysAllowed(
          rebuyOptions.find(
            (option) => option.value === response.data.config.rebuys,
          ),
        );
        let levelDurationOption = levelDurationOptions.find(
          (option) =>
            option.value === response.data.config.level_duration.toString(),
        );
        if (!levelDurationOption) {
          levelDurationOption = {
            label: `${numberDisplay(
              response.data.config.level_duration / 60,
            )} minutes`,
            value: response.data.config.level_duration.toString(),
          };
          levelDurationOptions.unshift(levelDurationOption);
        }
        setLevelDuration(levelDurationOption);
        setShotClock(
          shotClockOptions.find(
            (option) =>
              option.value === response.data.config.shot_clock.toString(),
          ),
        );
        setTournamentHost({
          value: response.data.config.host_uuid,
          label: response.data.config.host_display,
        });
        setIsLoadingData(false);
      }
    });
  }, []);

  let ModalContent = (
    <div
      className="modal-content"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Spinner />
    </div>
  );
  if (!isLoadingData) {
    ModalContent = (
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('tournamentSettings')}</h1>
        <h2 className="modal-subtitle">
          {t('changesWillTakeEffectNextLevel')}
        </h2>
        <div className="clubs-form settings">
          <div className="setting disabled starting-stack-size-setting">
            <p className="label">{t('tournamentSettingsStartingStackSize')}</p>
            <Dropdown
              disabled
              className="select-field"
              value={startingStackSize}
            />
          </div>
          <div className="setting antes-setting checkbox-setting">
            <p className="antes-label">Antes?</p>
            <input
              className="antes-checkbox"
              type="checkbox"
              onChange={(e) => {
                setAntes(e.target.checked);
              }}
              checked={antes}
            />
          </div>
          <div className="setting rebuy-setting">
            <p className="label">{t('tournamentSettingsRebuyRequests')}</p>
            <Dropdown
              className="select-field"
              value={rebuysAllowed}
              options={rebuyOptions}
              onChange={selectRebuysAllowed}
            />
          </div>
          <div className="setting level-duration-setting">
            <p className="label">{t('tournamentSettingsLevelDuration')}</p>
            <Dropdown
              className="select-field"
              value={levelDuration}
              options={levelDurationOptions}
              onChange={selectLevelDuration}
            />
          </div>
          <div className="setting shot-clock-setting">
            <p className="label">{t('tournamentSettingsDecisionClock')}</p>
            <Dropdown
              className="select-field"
              value={shotClock}
              options={shotClockOptions}
              onChange={selectShotClock}
            />
          </div>
          <div className="setting tournament-host-setting">
            <p className="label">{t('tournamentSettingsTournamentHost')}</p>
            <Dropdown
              className="select-field"
              value={tournamentHost}
              options={activePlayers}
              onChange={selectTournamentHost}
            />
          </div>
        </div>
        <div
          className="default-submit-button update-settings"
          onClick={() => {
            updateSettings({
              antes: antes,
              rebuys: rebuysAllowed.value,
              level_duration: Number(levelDuration.value),
              shot_clock: Number(shotClock.value),
              host_uuid: tournamentHost.value,
              host_display: tournamentHost.label,
            });
            closeModal();
          }}
        >
          <p>Save</p>
        </div>
        <h2 className="close-modal" onClick={closeModal}>
          X
        </h2>
      </div>
    );
  }

  return (
    <div className="modal tournament-settings-modal" onClick={closeModal}>
      {ModalContent}
    </div>
  );
}
