import React from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { t } from '../i18n';
import { PlayerContext } from '../contexts/PlayerContext';
import {
  PLAYER_SIGNUP_INIT,
  PLAYER_SIGNIN_INIT,
  PLAYER_SIGNIN_OTP,
} from '../services/APIService';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import OnboardingHostSignupForm from '../components/forms/OnboardingHostSignupForm';
import table from '../assets/table2.png';
import PlayerLoginModal from '../components/modals/PlayerLoginModal';
import VerifyAuthCodeModal from '../components/modals/VerifyAuthCodeModal';
import { screenWidthBreakpoint } from '../poker/settings';
import AnalyticsService from '../services/AnalyticsService';

export default function OnboardingHostSignupScreen() {
  let history = useHistory();
  const { setPlayer } = React.useContext(PlayerContext);
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [
    onboardingHostSignupFormValidation,
    setOnboardingHostSignupFormValidation,
  ] = React.useState({});
  const [activeModalIdent, setActiveModalIdent] = React.useState('');
  const [playerLoginFormValidation, setPlayerLoginFormValidation] =
    React.useState({});
  const [playerAuthCodeFormValidation, setPlayerAuthCodeFormValidation] =
    React.useState({});
  const [signinPayload, setSigninPayload] = React.useState({});
  const [codeResending, setCodeResending] = React.useState(false);

  function createPlayer(playerData) {
    setIsFetchingData(true);
    let playerCreateData = Object.assign(playerData, {
      referralUUID: Cookies.get('referralUUID'),
    });

    PLAYER_SIGNUP_INIT(playerCreateData).then((response) => {
      setIsFetchingData(false);
      if (response.data.status === 'ok') {
        Cookies.set('playerUUID', response.data.player.uuid);
        Cookies.set('email', response.data.player.email);
        Cookies.set('player', JSON.stringify(response.data.player));
        Cookies.remove('referralUUID');
        setPlayer(response.data.player);
        if (window.innerWidth >= screenWidthBreakpoint) {
          AnalyticsService.identify({
            userId: response.data.player.uuid,
            traits: {
              host_signup_flow: 'desktop',
            },
          });
        } else {
          AnalyticsService.identify({
            userId: response.data.player.uuid,
            traits: {
              host_signup_flow: 'mobile',
            },
          });
        }
        history.push('/onboarding/host-email-confirmation');
      } else if (response.data.status === 'error') {
        setOnboardingHostSignupFormValidation(response.data.details);
      }
    });
  }

  const resendCode = () => {
    setCodeResending(true);
    setTimeout(() => {
      setCodeResending(false);
    }, 3000);
    PLAYER_SIGNIN_INIT({ email: signinPayload.email });
  };

  let ActiveModal = <></>;
  if (activeModalIdent === 'login') {
    ActiveModal = (
      <PlayerLoginModal
        closeModal={() => {
          setPlayerLoginFormValidation({});
          setActiveModalIdent('');
        }}
        isFetchingData={isFetchingData}
        formValidation={playerLoginFormValidation}
        resetFormErrors={() => setPlayerLoginFormValidation({})}
        signinInit={(signinPayload) => {
          setIsFetchingData(true);
          setSigninPayload(signinPayload);
          PLAYER_SIGNIN_INIT(signinPayload).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              setActiveModalIdent('verify-auth-code');
            } else if (response.data.status === 'error') {
              setPlayerLoginFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  } else if (activeModalIdent === 'verify-auth-code') {
    ActiveModal = (
      <VerifyAuthCodeModal
        closeModal={() => {
          setActiveModalIdent('');
        }}
        signinPayload={signinPayload}
        codeResending={codeResending}
        isFetchingData={isFetchingData}
        resendCode={resendCode}
        formValidation={playerAuthCodeFormValidation}
        verifyAuthCode={(otp) => {
          setIsFetchingData(true);
          PLAYER_SIGNIN_OTP({ ...signinPayload, otp }).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              Cookies.set('player', JSON.stringify(response.data.player), {
                expires: 3650,
              });
              setPlayer(response.data.player);
              history.push('/lobby');
            } else {
              setPlayerAuthCodeFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  }

  return (
    <div className="screen onboarding-host-signup-screen">
      <Helmet>
        <title>Clubs Poker</title>
      </Helmet>

      <div className="collapsible-sidebar-content-container">
        <PlayerCollapsibleSidebar startClosed={true} isExpandable={false} />

        <div className="content">
          <div className="poker-table">
            <img src={table} alt="poker-table" className="poker-table-image" />
            <p
              className="already-a-member"
              onClick={() => {
                setActiveModalIdent('login');
              }}
            >
              {t('heroSignIn')}
            </p>
          </div>
          <h1 className="primary-header">
            {t('onboardingHostSignupScreenPrimaryHeader')}
          </h1>
          <h2 className="secondary-header">
            {t('onboardingHostSignupScreenSecondaryHeader')}
          </h2>
          <OnboardingHostSignupForm
            createPlayer={createPlayer}
            isFetchingData={isFetchingData}
            onboardingHostSignupFormValidation={
              onboardingHostSignupFormValidation
            }
          />
          <div className="external-links">
            <a href="/privacy.html" target="_blank" className="external-link">
              {t('privacyPolicy')}
            </a>
            <span className="divider">|</span>
            <a
              href="/ClubsPokerNow-TOU-v012624.pdf"
              target="_blank"
              className="external-link"
            >
              {t('termsOfService')}
            </a>
            <span className="divider">|</span>
            <a
              className="external-link"
              href="https://blog.clubspokernow.com/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('contactUsLabel')}
            </a>
          </div>
        </div>

        {ActiveModal}
      </div>
    </div>
  );
}
