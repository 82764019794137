import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { PlayerContext } from '../contexts/PlayerContext';
import { Link } from 'react-router-dom';
import { compact } from 'lodash';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import Spinner from '../components/Spinner';
import {
  ARCHIVE_ACTIVE_TABLE,
  CLOSE_ACTIVE_TABLE,
  PLAYER_LOBBY,
} from '../services/APIService';
import { numberDisplay, gameVariantDisplay } from '../poker/helpers';
import chevronRightGrey from '../assets/icons/chevron-right-grey.png';
import PokerTable from '../components/PokerTable';
import { t } from '../i18n';
import AnalyticsService from '../services/AnalyticsService';
import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';
import SeasonalCTA from '../components/SeasonalCTA';
import LightModal from '../components/modals/LightModal';

function HoverStateOverlay({
  player,
  tableSession,
  setTableSession,
  setActiveModal,
}) {
  const isTableHost = tableSession.state.host_uuid === player.uuid;
  const canClose = tableSession.finished_hands > 0 && isTableHost;
  const canDelete = tableSession.finished_hands === 0 && isTableHost;

  function closeTableHandler(e) {
    e.preventDefault();
    e.stopPropagation();

    setTableSession(tableSession);
    setActiveModal('close-table');
  }

  function deleteTableHandler(e) {
    e.preventDefault();
    e.stopPropagation();

    setTableSession(tableSession);
    setActiveModal('delete-table');
  }

  return (
    <div className="hover-state-overlay">
      <div className="poker-table-actions">
        <div className="open-table-cta">{t('openTable')}</div>
        {canClose ? (
          <button className="host-only-cta" onClick={closeTableHandler}>
            {t('closeTable')}
          </button>
        ) : canDelete ? (
          <div className="host-only-cta" onClick={deleteTableHandler}>
            {t('deleteTable')}
          </div>
        ) : null}
      </div>
    </div>
  );
}

const modalTitles = {
  'close-table': t('closeTable'),
  'delete-table': t('deleteTable'),
};
const modalConfirmationTexts = {
  'close-table': t('closeTableConfirmationText'),
  'delete-table': t('deleteTableConfirmationText'),
};
const modalCTATexts = {
  'close-table': t('closeTableConfirmationCTA'),
  'delete-table': t('deleteTableConfirmationCTA'),
};

function TableManagementModal({
  player,
  tableSession,
  setTableSession,
  reloadLobby,
  activeModal,
  setActiveModal,
}) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  function closeModal(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setActiveModal(null);
    setTableSession(null);
  }

  async function closeTable() {
    setIsLoading(true);
    await CLOSE_ACTIVE_TABLE({
      jwt: player.jwt,
      uuid: tableSession.uuid,
    });
    setIsLoading(false);
    closeModal();
    if (tableSession.tournament_uuid) {
      history.push(
        `/tournaments/${tableSession.tournament_uuid}/summary?ref=home`,
      );
    } else {
      history.push(`/table-sessions/${tableSession.uuid}/summary?ref=home`);
    }
  }

  async function deleteTable() {
    setIsLoading(true);
    await ARCHIVE_ACTIVE_TABLE({
      jwt: player.jwt,
      uuid: tableSession.uuid,
    });
    setIsLoading(false);
    reloadLobby();
    closeModal();
  }

  const modalCTAHandlers = {
    'close-table': closeTable,
    'delete-table': deleteTable,
  };

  const modalTitle = modalTitles[activeModal];
  const modalConfirmationText = modalConfirmationTexts[activeModal];
  const modalCTAText = modalCTATexts[activeModal];
  const modalCTAHandler = modalCTAHandlers[activeModal];

  return activeModal !== null ? (
    <LightModal
      title={modalTitle}
      closeModal={closeModal}
      modalContentClassName="lobby-table-management-modal-content"
    >
      <h2 className="modal-subtitle">{modalConfirmationText}</h2>
      <div className="custom-spacer" style={{ height: 30 }} />
      <button
        className="button submit-button request-code-button"
        onClick={modalCTAHandler}
      >
        {isLoading ? (
          <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
        ) : (
          <p>{modalCTAText}</p>
        )}
      </button>
    </LightModal>
  ) : null;
}

export default function LobbyScreen() {
  const history = useHistory();
  const { player } = React.useContext(PlayerContext);

  const [isLoading, setIsLoading] = React.useState(true);
  const [lobbyPlayer, setLobbyPlayer] = React.useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [tableSession, setTableSession] = useState(null);

  async function loadLobby() {
    const response = await PLAYER_LOBBY({ uuid: player.uuid, jwt: player.jwt });
    setLobbyPlayer(response.data.player);
    setIsLoading(false);
  }

  React.useEffect(() => {
    loadLobby();
  }, []);

  function renderHistoricalPlayerTableSession(playerTableSession, index) {
    return (
      <div
        className="player-table-session"
        key={`player-table-session-${index}`}
      >
        <div className="date">
          <p className="day">
            {Moment.unix(playerTableSession.created_at).format('DD')}
          </p>
          <p className="month label">
            {Moment.unix(playerTableSession.created_at).format('MMM')}
          </p>
        </div>
        <div
          className="table-session-display"
          onClick={() => {
            history.push(
              `/table-sessions/${playerTableSession.table_session.uuid}/summary?ref=home`,
            );
          }}
        >
          <p className="variant-display">{`${numberDisplay(
            playerTableSession.table_session.blinds[0] / 100,
            playerTableSession.table_session.decimal_display,
          )}/${numberDisplay(
            playerTableSession.table_session.blinds[1] / 100,
            playerTableSession.table_session.decimal_display,
          )} - ${playerTableSession.table_session.variant}`}</p>
          <p className="host-display label">
            {t('hostedByGameHost', playerTableSession.table_session.host)}
          </p>
        </div>
        <div className="hands-played">
          <p className="hands-played-amount">
            {playerTableSession.hands_played}
          </p>
          <p className="hands-played-label label">{t('hands')}</p>
        </div>
        <div className="chip-delta">
          <p
            className={`chip-delta-amount ${
              playerTableSession.chip_delta > 0 ? 'positive' : ''
            }${playerTableSession.chip_delta < 0 ? 'negative' : ''}`}
          >
            {`${playerTableSession.chip_delta > 0 ? '+' : ''}${
              playerTableSession < 0 ? '-' : ''
            }${numberDisplay(
              playerTableSession.chip_delta / 100,
              playerTableSession.table_session.decimal_display,
            )}`}
          </p>
        </div>
        <div className="chevron">
          <img src={chevronRightGrey} alt="chevron-right" />
        </div>
      </div>
    );
  }

  function renderHistoricalPlayerTournamentSession(
    playerTournamentSession,
    index,
  ) {
    return (
      <div
        className="player-tournament-session"
        key={`player-tournament-session-${index}`}
      >
        <div className="date">
          <p className="day">
            {Moment.unix(playerTournamentSession.created_at).format('DD')}
          </p>
          <p className="month label">
            {Moment.unix(playerTournamentSession.created_at).format('MMM')}
          </p>
        </div>
        <div
          className="tournament-display"
          onClick={() => {
            history.push(
              `/tournaments/${playerTournamentSession.tournament.uuid}/summary?ref=home`,
            );
          }}
        >
          <p className="variant-display">{`${t('lobbyTournament')} - ${
            playerTournamentSession.tournament.variant
          }`}</p>
          <p className="host-display label">
            {t('hostedByGameHost', playerTournamentSession.tournament.host)}
          </p>
        </div>
        <div className="hands-played">
          <p className="hands-played-amount">
            {playerTournamentSession.hands_played}
          </p>
          <p className="hands-played-label label">{t('hands')}</p>
        </div>
        <div className="tournament-standing">
          <p className="tournament-standing-text">
            {playerTournamentSession.standing}
          </p>
        </div>
        <div className="chevron">
          <img src={chevronRightGrey} alt="chevron-right" />
        </div>
      </div>
    );
  }

  let History = <></>;
  if (lobbyPlayer && lobbyPlayer.history) {
    if (lobbyPlayer.history.length > 0) {
      History = lobbyPlayer.history.map((playerSession, index) => {
        if (playerSession.session_type === 'table_session') {
          return renderHistoricalPlayerTableSession(playerSession, index);
        } else if (playerSession.session_type === 'tournament') {
          return renderHistoricalPlayerTournamentSession(playerSession, index);
        }
        return null;
      });
    } else {
      History = <p className="history">{t('lobbyScreenHistoryZeroState')}</p>;
    }
  }

  function renderActiveTableSession(tableSession, index) {
    return (
      <div className="active-table" key={`active-table-${index}`}>
        <Link
          to={`/table-sessions/${tableSession.uuid}`}
          className="poker-table"
          onClick={() =>
            AnalyticsService.viewsActivePokerTable(
              player,
              'table-session',
              tableSession,
            )
          }
        >
          <PokerTable
            canvasID={`canvas-${index}`}
            tableState={tableSession.state}
            playerUUID={lobbyPlayer.uuid}
            joinTable={() => {}}
            processTableStateRender={() => {}}
            shotClock={30}
            renderDimensions={{ width: 268, height: 139 }}
            mouseEventsOffsets={{ x: 0, y: 0 }}
          />
          <HoverStateOverlay
            player={player}
            tableSession={tableSession}
            setTableSession={setTableSession}
            setActiveModal={setActiveModal}
          />
          <div className="active-table-info">
            {tableSession.mode === 'active' ? (
              <div className="table-mode">
                <div className="dot active" />
                <p>{t('inProgress')}</p>
              </div>
            ) : (
              <div className="table-mode">
                <span className="dot inactive" />
                <p>{t('waiting')}</p>
              </div>
            )}
            <p className="table-variant">{`${numberDisplay(
              tableSession.state.blinds[0] / 100,
              tableSession.decimal_display,
            )}/${numberDisplay(
              tableSession.state.blinds[1] / 100,
              tableSession.decimal_display,
            )} ${gameVariantDisplay(tableSession.game_variant)}`}</p>
            <p className="table-players-amount">{`${
              compact(tableSession.state.players).length
            } players`}</p>
          </div>
        </Link>
      </div>
    );
  }

  function renderActiveTournamentTableSession(tableSession, index) {
    return (
      <div className="active-table" key={`active-table-${index}`}>
        <Link
          to={`/tournaments/${tableSession.tournament_uuid}/table-sessions/${tableSession.uuid}`}
          className="poker-table"
          onClick={() =>
            AnalyticsService.viewsActivePokerTable(
              player,
              'tournament',
              tableSession,
            )
          }
        >
          <PokerTable
            canvasID={`canvas-${index}`}
            tableState={tableSession.state}
            playerUUID={lobbyPlayer.uuid}
            joinTable={() => {}}
            processTableStateRender={() => {}}
            shotClock={30}
            renderDimensions={{ width: 268, height: 139 }}
            mouseEventsOffsets={{ x: 0, y: 0 }}
          />
          <HoverStateOverlay
            player={player}
            tableSession={tableSession}
            setTableSession={setTableSession}
            setActiveModal={setActiveModal}
          />
          <div className="active-table-info">
            {tableSession.mode === 'active' ? (
              <div className="table-mode">
                <div className="dot active" />
                <p>{t('inProgress')}</p>
              </div>
            ) : (
              <div className="table-mode">
                <span className="dot inactive" />
                <p>{t('waiting')}</p>
              </div>
            )}
            <p className="table-variant">{`Tournament - ${gameVariantDisplay(
              tableSession.game_variant,
            )}`}</p>
            <p className="table-players-amount">{`${
              compact(tableSession.state.players).length
            } players`}</p>
          </div>
        </Link>
      </div>
    );
  }

  let ActiveTables = <></>;
  if (lobbyPlayer && lobbyPlayer.active_table_sessions) {
    ActiveTables = lobbyPlayer.active_table_sessions.map(
      (tableSession, index) => {
        if (tableSession.tournament_id) {
          return renderActiveTournamentTableSession(tableSession, index);
        } else {
          return renderActiveTableSession(tableSession, index);
        }
      },
    );
  }

  return (
    <div className="screen lobby-screen">
      <Helmet>
        <title>{t('lobbySiteTitle')}</title>
      </Helmet>
      <MobileAccessibilityBanner />
      <SiteNoticeBanner />

      <div className="collapsible-sidebar-content-container">
        <div className="content">
          {isLoading ? (
            <Spinner invertedDots={true} />
          ) : (
            <>
              <SeasonalCTA player={player} />
              <div className="title-section">
                <h2 className="lobby-title">{lobbyPlayer.display}</h2>
                <div className="title-section-secondary">
                  <p className="join-date">
                    {t('joined')}{' '}
                    <span>
                      {Moment.unix(lobbyPlayer.created_at).format(
                        'MMM DD, YYYY',
                      )}
                    </span>
                  </p>
                  <p className="spacer">|</p>
                  <p className="hands-played">
                    {t('handsPlayed')} <span>{lobbyPlayer.hands_played}</span>
                  </p>
                </div>
              </div>

              <hr className="title-section-border" />

              <div className="table-section">
                <h4 className="section-title">{t('active')}</h4>
                <div className="tables">
                  <div
                    className="create-new-table"
                    onClick={() => {
                      history.push(`/new-table-selection`);
                    }}
                  >
                    <p>{t('createNewTable')}</p>
                  </div>
                  {ActiveTables}
                </div>
              </div>
              <div className="history-section">
                <h4 className="section-title">{t('history')}</h4>
                {History}
              </div>
            </>
          )}
        </div>
        <PlayerCollapsibleSidebar startClosed={false} isCollapsible={false} />
        <TableManagementModal
          player={player}
          tableSession={tableSession}
          setTableSession={setTableSession}
          reloadLobby={loadLobby}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
        />
      </div>
    </div>
  );
}
