import React from 'react';
import { t } from '../../i18n';
import notificationIcon from '../../assets/icons/notification-icon.png';

export const AllLeavingPlayersNotification = ({ tableState }) => {
  const leavingPlayers = tableState.players.filter((p) => p && p.leaving_table);
  let display = 'Someone';
  if (leavingPlayers.length === 0) {
    return null;
  } else if (leavingPlayers.length === 1) {
    display = leavingPlayers[0].display;
  } else {
    display = `${leavingPlayers.length} players`;
  }
  return (
    <div className="notification">
      <img
        className="notification-icon"
        src={notificationIcon}
        alt="Notification Icon"
      />
      <p>{t('tableSessionShowNotificationCenterLeavingTable', display)}</p>
    </div>
  );
};

export const SingleLeavingPlayerNotification = ({ tablePlayer }) =>
  tablePlayer && tablePlayer.leaving_table ? (
    <div className="notification">
      <img
        className="notification-icon"
        src={notificationIcon}
        alt="Notification Icon"
      />
      <p>{t('tableSessionShowNotificationCenterLeavingTable', 'You')}</p>
    </div>
  ) : null;
