import { parsePhoneNumber } from 'libphonenumber-js';

export function gameVariantDisplay(gameVariant) {
  switch (gameVariant) {
    case 'no-limit-holdem':
      return "No Limit Hold'em";
    case 'short-deck-holdem':
      return "Short Deck Hold'em";
    default:
      return '';
  }
}

export function numberDisplay(number, decimal_display = false) {
  if (decimal_display) {
    return Number(number).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (number % 1 !== 0) {
    return Number(number).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return Number(number).toLocaleString();
  }
}

export function reverseNumberDisplay(displayString) {
  return Number(displayString.replace(/[^0-9.]/g, ''));
}

export function userNameDisplay(displayString, truncateLength = 14) {
  return displayString && displayString.length > truncateLength
    ? `${displayString.substring(0, truncateLength)}...`
    : displayString;
}

export function phoneNumberDisplay(countryCode, phoneNumber) {
  try {
    return parsePhoneNumber(
      `+${countryCode}${phoneNumber}`,
    ).formatInternational();
  } catch (error) {
    return `${countryCode}${phoneNumber}`;
  }
}

export function tournamentDurationDisplay(durationInSeconds) {
  let durationInSecondsNumber;
  if (Number(durationInSeconds) < 3600) {
    durationInSecondsNumber = Math.ceil(Number(durationInSeconds) / 300) * 300;
  } else {
    durationInSecondsNumber =
      Math.ceil(Number(durationInSeconds) / 1800) * 1800;
  }

  switch (durationInSecondsNumber) {
    case 0:
      return 'less than 5 minutes';
    case 300:
      return '5 minutes';
    case 600:
      return '10 minutes';
    case 900:
      return '15 minutes';
    case 1200:
      return '20 minutes';
    case 1500:
      return '25 minutes';
    case 1800:
      return '30 minutes';
    case 2100:
      return '35 minutes';
    case 2400:
      return '40 minutes';
    case 2700:
      return '45 minutes';
    case 3000:
      return '50 minutes';
    case 3300:
      return '55 minutes';
    case 3600:
      return '1 hour';
    case 5400:
      return '1.5 hours';
    case 7200:
      return '2 hours';
    case 9000:
      return '2.5 hours';
    case 10800:
      return '3 hours';
    case 12600:
      return '3.5 hours';
    case 14400:
      return '4 hours';
    case 16200:
      return `4.5 hours`;
    case 18000:
      return `5 hours`;
    case 19800:
      return `5.5 hours`;
    case 21600:
      return `6 hours`;
    case 23400:
      return `6.5 hours`;
    case 25200:
      return `7 hours`;
    case 27000:
      return `7.5 hours`;
    case 28800:
      return `8 hours`;
    case 30600:
      return `8.5 hours`;
    case 32400:
      return `9 hours`;
    case 34200:
      return `9.5 hours`;
    case 36000:
      return `10 hours`;
    default:
      return '';
  }
}
