import _ from 'lodash';
import THEME from '../../styles/themes/PokerTableTheme';
import { perspectiveToCanonicalIndex } from './Util';

let allInEquityBackground = new Image();
allInEquityBackground.src = `${process.env.PUBLIC_URL}/assets/all-in-equity-background.svg`;

export function drawPlayerRunout(
  activeHandState,
  ctx,
  dimensions,
  x,
  y,
  currentPlayerIndex,
  lastEvent,
  index,
) {
  if (
    activeHandState.players.filter(
      (p) => p && p.hand_status !== 'revealed' && p.hand_status !== 'folded',
    ).length > 0
  )
    return;
  let isRunoutPlayer =
    activeHandState.receipt &&
    activeHandState.receipt.win_probabilities &&
    activeHandState.receipt.win_probabilities.river &&
    _.some(activeHandState.receipt.win_probabilities.river, (runoutPlayer) => {
      return (
        runoutPlayer['seat_index'] ===
        (index + Math.max(currentPlayerIndex, 0)) % 10
      );
    }) &&
    lastEvent !== 'resolve-hand' &&
    (lastEvent !== 'reveal-hand' ||
      activeHandState.receipt.win_probabilities.river.length ===
        activeHandState.players.filter((p) => {
          return p && p['hand_status'] === 'revealed';
        }).length);

  // Draw all-in runout equity display
  if (isRunoutPlayer) {
    ctx.drawImage(
      allInEquityBackground,
      x - dimensions.playerBoxRunoutEquityWidth / 2,
      y - dimensions.playerBoxHeight / 1.5,
      dimensions.playerBoxRunoutEquityWidth,
      dimensions.playerBoxRunoutEquityHeight,
    );

    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 0;
    ctx.fillStyle = THEME.emptySeatPlayerDisplayBoxText;
    ctx.font = `${dimensions.tableWidth / 52}px Lato-Bold`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(
      runoutEquityForPlayer(
        activeHandState,
        perspectiveToCanonicalIndex(currentPlayerIndex, index),
      ),
      x,
      y -
        dimensions.playerBoxHeight / 1.5 +
        dimensions.playerBoxRunoutEquityHeight / 2,
    );
  }
}

function runoutEquityForPlayer(active_hand_state, seatIndex) {
  let winProbability = 0;
  switch (active_hand_state.community_cards.length) {
    case 0:
      winProbability = `${
        active_hand_state.receipt.win_probabilities.preflop.find((p) => {
          return p['seat_index'] === seatIndex;
        }).win_probability
      }`;
      break;
    case 3:
      winProbability = `${
        active_hand_state.receipt.win_probabilities.flop.find((p) => {
          return p['seat_index'] === seatIndex;
        }).win_probability
      }`;
      break;
    case 4:
      winProbability = `${
        active_hand_state.receipt.win_probabilities.turn.find((p) => {
          return p['seat_index'] === seatIndex;
        }).win_probability
      }`;
      break;
    case 5:
      winProbability = `${
        active_hand_state.receipt.win_probabilities.river.find((p) => {
          return p['seat_index'] === seatIndex;
        }).win_probability
      }`;
      if (winProbability !== `0`) {
        winProbability = `1`;
      }
      break;
    default:
      break;
  }

  return parseFloat(winProbability).toLocaleString('en', { style: 'percent' });
}
