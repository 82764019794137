import React, { useContext, useEffect, useState } from 'react';
import GuestSignupFormSidebar from '../components/GuestSignupFormSidebar';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import WelcomeBackPlayerSidebar from '../components/WelcomeBackPlayerSidebar';
import SidebarSignInForm from '../components/SidebarSignInForm';
import PlayerConfirmEmailSidebar from '../components/PlayerConfirmEmailSidebar';
import { EventEmitterContext } from '../contexts/EventEmitterContext';

export default function PokerTableLeftSidebar({
  tableSessionUUID,
  player,
  tableState,
  tournament,
  sidebarAcknowledgement,
  acknowledgeSidebar,
  setActiveModalIdent,
  onGuestCreated,
  signupGuest,
  verifyAuthCode,
  guestSignupFormValidation,
  resetGuestSignupFormValidation,
  isFetchingData,
  playerConfirmEmailFormValidation,
  resetPlayerConfirmEmailFormValidation,
}) {
  const eventEmitter = useContext(EventEmitterContext);
  const [activeContent, setActiveContent] = useState('signup-form');
  const playerSessionExists = player && player.uuid && player.display;
  const playerIsGuest = player && player.guest;
  const playerIsHost = player && player.uuid === tableState.host_uuid;

  useEffect(() => {
    if (playerIsHost) {
      // TODO: Might be better to directly use `unlockTable()` instead
      acknowledgeSidebar();
    }
  }, [playerIsHost]);

  if (playerIsHost) {
    return (
      <PlayerCollapsibleSidebar
        startClosed={true}
        activeTableSessionUUID={tableSessionUUID}
      />
    );
  } else if (!playerSessionExists) {
    return (
      <SidebarSignInForm
        tableState={tableState}
        tournament={tournament}
        startClosed={false}
        onGuestCreated={onGuestCreated}
        setActiveModalIdent={setActiveModalIdent}
        acknowledgeSidebar={acknowledgeSidebar}
      />
    );
  } else {
    if (playerSessionExists && !sidebarAcknowledgement) {
      return (
        <WelcomeBackPlayerSidebar
          player={player}
          startClosed={false}
          tableState={tableState}
          tournament={tournament}
          acknowledgeSidebar={acknowledgeSidebar}
        />
      );
    } else if (playerIsGuest) {
      if (activeContent === 'signup-form') {
        return (
          <GuestSignupFormSidebar
            player={player}
            startClosed={true}
            signupGuest={async (signupPayload) => {
              const success = await signupGuest(signupPayload);
              if (success) {
                setActiveContent('email-confirmation');
              }
            }}
            isFetchingData={isFetchingData}
            guestSignupFormValidation={guestSignupFormValidation}
            resetFormValidation={resetGuestSignupFormValidation}
          />
        );
      }
      if (activeContent === 'email-confirmation') {
        return (
          <PlayerConfirmEmailSidebar
            player={player}
            startClosed={false}
            isFetchingData={isFetchingData}
            reEnterSignupDetails={() => {
              setActiveContent('signup-form');
              // TODO: Keep sidebar's open/closed state in React state VS relying on this hack
              setTimeout(
                () => eventEmitter.emit('open-sidebar-if-closed', {}),
                10,
              );
            }}
            formValidation={playerConfirmEmailFormValidation}
            resetFormValidation={resetPlayerConfirmEmailFormValidation}
            verifyAuthCode={verifyAuthCode}
          />
        );
      }
      return null;
    } else {
      return (
        <PlayerCollapsibleSidebar
          startClosed={true}
          activeTableSessionUUID={tableSessionUUID}
        />
      );
    }
  }
}
