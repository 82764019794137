import React from 'react';
import logo from '../assets/brand/logo-unicolor.png';
import { numberDisplay, gameVariantDisplay } from '../poker/helpers';
import { useHotkeys } from 'react-hotkeys-hook';
import SidebarFooter from './SidebarFooter';
import AnalyticsService from '../services/AnalyticsService';
import { t } from '../i18n';
import { CREATE_GUEST } from '../services/APIService';

export default function SidebarSignInForm({
  tableState,
  tournament,
  onGuestCreated,
  setActiveModalIdent,
  startClosed,
}) {
  const [nickname, setNickname] = React.useState('');
  const [formValidation, setFormValidation] = React.useState({});
  const [isClosed, setIsClosed] = React.useState(startClosed);

  const nicknameRef = React.useRef('');
  React.useEffect(() => {
    nicknameRef.current = nickname;
  }, [nickname]);

  const submitForm = async (e) => {
    e.preventDefault();
    if (nicknameRef.current.length > 0) {
      const response = await CREATE_GUEST({ display: nicknameRef.current });
      if (
        response.data.status === 'ok' &&
        response.data.uuid &&
        response.data.jwt
      ) {
        AnalyticsService.playAsGuestEvent(nicknameRef.current);
        const guest = {
          cookie_version: process.env.REACT_APP_COOKIE_VERSION,
          guest: true,
          display: nicknameRef.current,
          uuid: response.data.uuid,
          jwt: response.data.jwt,
        };
        setIsClosed(true);
        setTimeout(() => onGuestCreated(guest), 300);
      } else {
        setFormValidation({ user_name: ['Unable to create guest'] });
      }
    } else {
      setFormValidation({ user_name: ['We need a nickname!'] });
    }
  };

  useHotkeys(
    'enter',
    (event, handler) => {
      submitForm();
    },
    {
      filter: (event) => {
        return true;
      },
    },
  );

  var classString = 'clubs-form sidebar-sign-in-form';
  if (isClosed) {
    classString += ' is-closed';
  }

  const renderGameInfo = (tableState, tournament) => {
    if (tableState.tournament_id) {
      if (tournament) {
        return (
          <>
            <h4 className="game-info-1">{`${gameVariantDisplay(
              tableState.game_variant,
            )} ${t('sidebarTournament')}`}</h4>
            <h4 className="game-info-2">
              {t(
                'sidebarChipsStartingStack',
                numberDisplay(tournament.starting_stack_size / 100),
              )}
            </h4>
            <h4 className="game-info-3">
              {t('sidebarLevelDuration', tournament.level_duration / 60)}
            </h4>
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <>
          <h4 className="game-info-1">
            {gameVariantDisplay(tableState.game_variant)}
          </h4>
          <h4 className="game-info-2">
            {`${numberDisplay(
              tableState.blinds[0] / 100,
              tableState.decimal_display,
            )}/${numberDisplay(
              tableState.blinds[1] / 100,
              tableState.decimal_display,
            )} ${t('blinds')}`}
          </h4>
        </>
      );
    }
  };

  return (
    <form onSubmit={submitForm} className="sidebar-sign-in-form-container">
      <div className={classString}>
        <div
          className="brand"
          onClick={() => {
            window.open('/');
          }}
        >
          <img className="logo" src={logo} alt="clubs-poker" />
          <p className="clubs">
            <span className="bold">Clubs</span> poker
          </p>
          <p className="now">now</p>
        </div>
        <div className="sidebar-sign-in-form-content-wrapper">
          <h2 className="welcome">{t('welcome')}</h2>
          <div className="invite-info">
            <h4 className="host-info">{t('youveBeenInvitedToJoinGame')}</h4>
            <h4 className="host-info">
              {t('hostedBy', tableState.host_display)}
            </h4>
            {renderGameInfo(tableState, tournament)}
          </div>
          <div className="validated-input-field">
            <input
              className={
                formValidation.user_name ? `nickname error` : `nickname`
              }
              value={nickname}
              onChange={(e) => {
                setNickname(e.target.value);
              }}
              placeholder={t('nickname')}
            />
            {formValidation.user_name ? (
              <span className="form-validation">
                {formValidation.user_name[0]}
              </span>
            ) : (
              <></>
            )}
          </div>
          <button className="play-as-guest-button" type="submit">
            <p>{t('playAsGuest')}</p>
          </button>
          <p
            className="already-a-member"
            onClick={() => {
              setActiveModalIdent('login');
            }}
          >
            {t('alreadyAMember')}
          </p>
        </div>
        <div className="flex-spacer" />
        <SidebarFooter />
      </div>
    </form>
  );
}
