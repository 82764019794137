import React from 'react';
import { Helmet } from 'react-helmet';
import { PlayerContext } from '../contexts/PlayerContext';
import { useHistory, useLocation } from 'react-router-dom';
import { t } from '../i18n';
import { CREATE_TABLE_SESSION_WITH_CONFIG } from '../services/APIService';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import TableSessionCreateFormQuick from '../components/forms/TableSessionCreateFormQuick';
import TableSessionCreateFormAdvanced from '../components/forms/TableSessionCreateFormAdvanced';
import table from '../assets/table3.png';
import { gameVariantDisplay } from '../poker/helpers';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import AnalyticsService from '../services/AnalyticsService';
import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';

export default function TableSessionCreateScreen() {
  let history = useHistory();
  let location = useLocation();
  const eventEmitter = React.useContext(EventEmitterContext);
  const { player } = React.useContext(PlayerContext);
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [activeFormIdent, setActiveFormIdent] = React.useState('quick');
  const [initialFormShouldAnimate, setInitialFormShouldAnimate] =
    React.useState(false);
  const [
    tableSessionCreateFormValidation,
    setTableSessionCreateFormValidation,
  ] = React.useState({});

  const activeFormIdentRef = React.useRef(activeFormIdent);

  let gameVariantIdent =
    new URLSearchParams(location.search).get('gv') || 'no-limit-holdem';

  React.useEffect(() => {
    activeFormIdentRef.current = activeFormIdent;
    if (activeFormIdent === 'advanced') {
      setInitialFormShouldAnimate(true);
    }
  }, [activeFormIdent]);

  // const { playerTableSessions, setPlayerTableSessions } = React.useContext(PlayerTableSessionsContext);
  // const consumer = Cable.createConsumer(`${process.env.REACT_APP_API_ENDPOINT}/cable`);

  // function createTableSession(tableSessionData) {
  //   setIsFetchingData(true);
  //   CREATE_TABLE_SESSION_WITH_CONFIG(Object.assign(tableSessionData, {playerUUID: player.uuid}))
  //   .then((response) => {
  //     setIsFetchingData(false);
  //     if(response.data.status === 'ok') {
  //       let nextIndex = playerTableSessions.length;
  //       let nextPlayerTableSessions = playerTableSessions;
  //       nextPlayerTableSessions[nextIndex] = response.data.table_session;
  //       setPlayerTableSessions(nextPlayerTableSessions);
  //       consumer.subscriptions.create({channel: 'PlayerTableSessionChannel', table_session_uuid: response.data.table_session.uuid}, {
  //         received: (data) => {
  //           let pts = playerTableSessions;
  //           pts[nextIndex] = {uuid: response.data.table_session.uuid, state: data}
  //           setPlayerTableSessions(pts);
  //         }
  //       })
  //       history.push(`/table-sessions/${response.data.table_session.uuid}`);
  //     } else if(response.data.status === 'error') {
  //       setOnboardingTableSessionCreateFormValidation(response.data.details);
  //     }
  //   })
  // }

  const handleSetupLater = () => {
    AnalyticsService.setupGameLaterEvent(player, 'table-session-create');
    history.push('/lobby');
  };

  const createTableSession = (tableSessionData) => {
    setIsFetchingData(true);
    CREATE_TABLE_SESSION_WITH_CONFIG(
      Object.assign(tableSessionData, {
        playerUUID: player.uuid,
        gameVariant: gameVariantIdent,
      }),
    ).then((response) => {
      setIsFetchingData(false);
      if (response.data.status === 'ok') {
        history.push(`/table-sessions/${response.data.table_session.uuid}`);
      } else if (response.data.status === 'error') {
        setTableSessionCreateFormValidation(response.data.details);
      }
    });
  };

  const toggleActiveForm = (nextFormIdent) => {
    setActiveFormIdent(nextFormIdent);
  };

  const renderTournamentForm = () => {
    if (activeFormIdent === 'quick') {
      return (
        <TableSessionCreateFormQuick
          shouldAnimate={initialFormShouldAnimate}
          createTableSession={createTableSession}
          isFetchingData={isFetchingData}
          toggleActiveForm={toggleActiveForm}
          tableSessionCreateFormValidation={tableSessionCreateFormValidation}
        />
      );
    } else if (activeFormIdent === 'advanced') {
      return (
        <TableSessionCreateFormAdvanced
          createTableSession={createTableSession}
          isFetchingData={isFetchingData}
          toggleActiveForm={toggleActiveForm}
          tableSessionCreateFormValidation={tableSessionCreateFormValidation}
        />
      );
    }
  };

  return (
    <div className="screen table-session-create-screen">
      <Helmet>
        <title>Clubs Poker</title>
      </Helmet>
      <MobileAccessibilityBanner />
      <SiteNoticeBanner />

      <div className="collapsible-sidebar-content-container">
        <PlayerCollapsibleSidebar startClosed={true} />
        <div
          className="content"
          onClick={() => {
            eventEmitter.emit('close-sidebar-if-open', {});
          }}
        >
          <div className="poker-table">
            <img src={table} alt="poker-table" className="poker-table-image" />
            <p className="set-up-later" onClick={handleSetupLater}>
              {t('tournamentCreateScreenSetUpLater')}
            </p>
          </div>
          <h1 className="primary-header">
            {t(
              'onboardingTableSessionCreateScreenPrimaryHeader',
              gameVariantDisplay(gameVariantIdent).toLowerCase(),
            )}
          </h1>
          <h2 className="secondary-header">
            {t('playerTableSessionCreateScreenPrimary')}
          </h2>
          {renderTournamentForm()}
        </div>
      </div>
    </div>
  );
}
