import React from 'react';
import { t } from '../i18n';
import { screenWidthBreakpoint } from '../poker/settings';

export default function MobileAccessibilityBanner() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [dismissed, setDismissed] = React.useState(false);

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!dismissed && windowWidth < screenWidthBreakpoint) {
    return (
      <div className="mobile-accessibility-banner">
        <p className="exclamation-icon">!</p>
        <div className="mobile-accessibility-content">
          <p className="mobile-accessibility-message">
            {t('mobileAccessibilityMessage')}
          </p>
          <button
            type="button"
            className="dismiss-button"
            onClick={() => setDismissed(true)}
          >
            <p>{t('dismiss')}</p>
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
