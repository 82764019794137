import React from 'react';
import { Helmet } from 'react-helmet';
import { PlayerContext } from '../contexts/PlayerContext';
import _ from 'lodash';
import HandAnalyzerPokerTable from '../components/HandAnalyzerPokerTable';
import HandAnalyzerSidebar from '../components/HandAnalyzerSidebar';
import { POKER_HAND_ANALYZER } from '../services/APIService';
import { useParams, useHistory } from 'react-router-dom';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import { numberDisplay } from '../poker/helpers';
import Spinner from '../components/Spinner';
import backIcon from '../assets/icons/back-icon.png';
import playIcon from '../assets/icons/play-icon.png';
import momentTz from 'moment-timezone';
import { t } from '../i18n';
import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';

export default function HandAnalyzerScreen() {
  let history = useHistory();
  let timezone = momentTz.tz.guess();
  const eventEmitter = React.useContext(EventEmitterContext);
  const { player } = React.useContext(PlayerContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tableSessionHands, setTableSessionHands] = React.useState(null);
  const [currentHand, setCurrentHand] = React.useState({});
  const [handStates, setHandStates] = React.useState([]);
  const [sidebarAccess, setSidebarAccess] = React.useState(false);
  const [handStateCursor, setHandStateCursor] = React.useState(0);
  const [flopCursor, setFlopCursor] = React.useState(null);
  const [turnCursor, setTurnCursor] = React.useState(null);
  const [riverCursor, setRiverCursor] = React.useState(null);
  const [handsFaceUp, setHandsFaceUp] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const handUUID = useParams().uuid;
  const [renderDimensions, setRenderDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handStateCursorRef = React.useRef(0);
  React.useEffect(() => {
    handStateCursorRef.current = handStateCursor;
  }, [handStateCursor]);

  const currentIntervalRef = React.useRef(undefined);

  React.useEffect(() => {
    if (isPlaying) {
      currentIntervalRef.current = setInterval(() => {
        setHandStateCursor(
          Math.min(handStateCursorRef.current + 1, handStates.length - 1),
        );
      }, 1000);
    } else {
      clearInterval(currentIntervalRef.current);
    }
  }, [isPlaying]);

  // Component Will Mount
  React.useEffect(() => {
    POKER_HAND_ANALYZER({ uuid: handUUID, jwt: player.jwt }).then(
      (response) => {
        let hs = JSON.parse(
          JSON.stringify(response.data.current_hand.hand_states),
        );
        hs.shift();
        hs.shift();
        hs.shift();
        hs.shift();
        hs = _.reject(hs, (handState) => {
          return (
            handState.last_event === 'assign-actor' ||
            handState.last_event === 'consolidate-chips'
          );
        });
        let flopIndex = _.findIndex(hs, (handState) => {
          return handState.last_event === 'deal-flop';
        });
        let turnIndex = _.findIndex(hs, (handState) => {
          return handState.last_event === 'deal-turn';
        });
        let riverIndex = _.findIndex(hs, (handState) => {
          return handState.last_event === 'deal-river';
        });
        setCurrentHand(response.data.current_hand);
        setTableSessionHands(response.data.table_session_hands);
        setSidebarAccess(response.data.sidebar_access);
        setHandStates(hs);
        setHandStateCursor(hs.length - 1);
        if (flopIndex >= 0) {
          setFlopCursor(flopIndex);
        }
        if (turnIndex >= 0) {
          setTurnCursor(turnIndex);
        }
        if (riverIndex >= 0) {
          setRiverCursor(riverIndex);
        }
        setIsLoading(false);
      },
    );
  }, [handUUID]);

  // Window Resize handler
  React.useEffect(() => {
    function handleResize() {
      setRenderDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleHandsFaceUp = () => {
    if (handsFaceUp) {
      setHandsFaceUp(false);
    } else {
      setHandsFaceUp(true);
    }
  };

  const handleHandChange = (handUUID) => {
    setIsLoading(true);
    history.push(`/hand-analyzer/${handUUID}`);
  };

  return (
    <div className="screen hand-analyzer-screen">
      <Helmet>
        <title>Clubs Poker | Review hand</title>
      </Helmet>
      <MobileAccessibilityBanner />
      <SiteNoticeBanner />
      <div
        className="preloadFont"
        style={{ fontFamily: 'Lato-Bold', fontSize: 0 }}
      >
        .
      </div>
      <div
        className="preloadFont"
        style={{ fontFamily: 'Lato-Regular', fontSize: 0 }}
      >
        .
      </div>

      <div className="collapsible-sidebar-content-container">
        <HandAnalyzerSidebar
          startClosed={true}
          isExpandable={sidebarAccess}
          tableSessionHands={tableSessionHands}
          handleHandChange={handleHandChange}
        />
        <div
          className="content"
          onClick={() => {
            eventEmitter.emit('close-sidebar-if-open', {});
          }}
        >
          {isLoading ? (
            <Spinner spinnerType="chase" />
          ) : (
            <HandAnalyzerPokerTable
              handState={handStates[handStateCursor]}
              finalHandState={handStates[handStates.length - 1]}
              playerUUID={player.uuid}
              handsFaceUp={handsFaceUp}
              renderDimensions={renderDimensions}
            />
          )}
          {isLoading ? (
            <></>
          ) : (
            <div className="table-actions">
              <div className="top-actions hand-analyzer-actions">
                <p>{`Hand #${currentHand.hand_index + 1}`}</p>
                <p>{`${numberDisplay(
                  currentHand.hand_blinds[0] / 100,
                  currentHand.decimal_display,
                )}/${numberDisplay(
                  currentHand.hand_blinds[1] / 100,
                  currentHand.decimal_display,
                )} ${currentHand.hand_game_variant}`}</p>
                <p>
                  {momentTz
                    .unix(currentHand.hand_timestamp, timezone)
                    .tz(timezone)
                    .format('MMM D, YYYY, h:mma z')}
                </p>
                {tableSessionHands && !player.guest ? (
                  <button
                    className="submit-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      eventEmitter.emit('open-sidebar-if-closed');
                    }}
                  >
                    <p>{`Review ${tableSessionHands.length} hand${
                      tableSessionHands.length > 1 ? 's' : ''
                    }`}</p>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
          {isLoading ? (
            <></>
          ) : (
            <div className="analyzer-actions">
              <div className="analyzer-group">
                <p
                  className="analyzer-action jump"
                  onClick={() => {
                    setHandStateCursor(0);
                  }}
                >
                  {t('analyzerActionPreFlop')}
                </p>
                {flopCursor ? (
                  <p
                    className="analyzer-action jump"
                    onClick={() => {
                      setIsPlaying(false);
                      setHandStateCursor(flopCursor);
                    }}
                  >
                    {t('analyzerActionFlop')}
                  </p>
                ) : (
                  <></>
                )}
                {turnCursor ? (
                  <p
                    className="analyzer-action jump"
                    onClick={() => {
                      setIsPlaying(false);
                      setHandStateCursor(turnCursor);
                    }}
                  >
                    {t('analyzerActionTurn')}
                  </p>
                ) : (
                  <></>
                )}
                {riverCursor ? (
                  <p
                    className="analyzer-action jump"
                    onClick={() => {
                      setIsPlaying(false);
                      setHandStateCursor(riverCursor);
                    }}
                  >
                    {t('analyzerActionRiver')}
                  </p>
                ) : (
                  <></>
                )}
                <p
                  className="analyzer-action jump"
                  onClick={() => {
                    setHandStateCursor(handStates.length - 1);
                  }}
                >
                  {t('analyzerActionFinal')}
                </p>
              </div>
              <div className="hands-face-up analyzer-action">
                <input
                  type="checkbox"
                  name="hands-face-up"
                  checked={handsFaceUp}
                  onChange={toggleHandsFaceUp}
                />
                <span
                  onClick={toggleHandsFaceUp}
                  className="custom-checkbox"
                ></span>
                <p className="action-label" onClick={toggleHandsFaceUp}>
                  {t('analyzerActionHandsUp')}
                </p>
              </div>
              <div className="analyzer-action steps">
                <div
                  className="step back"
                  onClick={() => {
                    setIsPlaying(false);
                    setHandStateCursor(Math.max(handStateCursor - 1, 0));
                  }}
                >
                  <img src={backIcon} alt="Back icon" />
                </div>
                {isPlaying ? (
                  <div
                    className="step pause"
                    onClick={() => {
                      setIsPlaying(false);
                    }}
                  >
                    <i className="icofont-ui-pause" />
                  </div>
                ) : (
                  <div
                    className="step play"
                    onClick={() => {
                      if (handStateCursor === handStates.length - 1) {
                        setHandStateCursor(0);
                      }
                      setIsPlaying(true);
                    }}
                  >
                    {handStateCursor === handStates.length - 1 ? (
                      <img src={playIcon} alt="Play icon" />
                    ) : (
                      <img src={playIcon} alt="Play icon" />
                    )}
                  </div>
                )}
                <div
                  className="step forward"
                  onClick={() => {
                    setIsPlaying(false);
                    setHandStateCursor(
                      Math.min(handStateCursor + 1, handStates.length - 1),
                    );
                  }}
                >
                  <img className="rotate" src={backIcon} alt="Forward icon" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
