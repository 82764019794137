import React from 'react';
import { Helmet } from 'react-helmet';
import brandMark from '../assets/brand/brand-mark.svg';
import { Link } from 'react-router-dom';
import { t } from '../i18n';

function Logo() {
  return (
    <div className="logo">
      <img src={brandMark} alt="" className="brand-mark" />
      <p className="brand-name">
        <span className="clubs">Clubs</span> poker
      </p>
      <p className="now">now</p>
    </div>
  );
}

export default function GenericInfoPageShell({ title, children }) {
  return (
    <div className="generic-info-page-screen landing">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        className={`header-container`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="header">
          <Link to="/">
            <Logo />
          </Link>
        </div>
      </div>

      <div className="generic-info-page-container">{children}</div>

      <div className="footer-container">
        <div className="left">
          <Logo />
          <pre className="spacer"> - </pre>
          <div className="made">
            Made with <i className="icofont-heart-alt heart"></i> in NYC
          </div>
        </div>
        <div className="right">
          <div className="links">
            <a
              href="https://www.facebook.com/playonclubs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('facebook')}
            </a>
            <pre className="spacer"> | </pre>
            <a
              href="https://twitter.com/playonclubs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('twitter')}
            </a>
            <pre className="spacer"> | </pre>
            <a href="/privacy.html" target="_blank">
              {t('privacy')}
            </a>
            <pre className="spacer"> | </pre>
            <a href="/ClubsPokerNow-TOU-v012624.pdf" target="_blank">
              {t('terms')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
