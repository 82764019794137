import React, { useState } from 'react';
import { numberDisplay } from '../../poker/helpers';
import { isFloatValuePositive, setNumberValue } from '../displayHelpers';
import NumberFormat from 'react-number-format';
import Spinner from '../Spinner';
import { t } from '../../i18n';
import { globalChipRequestLimit } from '../../poker/settings';

export default function RequestChipsModal({
  closeModal,
  requestChips,
  isFetchingData,
  formValidation,
  tableState,
  recommendedStartingChips,
}) {
  const [requestedChipsAmount, setRequestedChipsAmount] = useState(
    recommendedStartingChips,
  );
  const minBuyInAmount = tableState.blinds[1] * tableState.min_buy_in;
  const maxBuyInAmount = tableState.blinds[1] * tableState.max_buy_in;
  const minDisplay = numberDisplay(
    Math.max(minBuyInAmount, 0) / 100,
    tableState.decimal_display,
  );
  const maxDisplay = numberDisplay(
    Math.min(maxBuyInAmount, globalChipRequestLimit) / 100,
    tableState.decimal_display,
  );
  const decimalScale = tableState.decimal_display ? 2 : 0;
  const requestedChipsAmountMaxLength =
    6 + decimalScale + (decimalScale === 0 ? 0 : 1);

  function submitForm(e) {
    e.preventDefault();
    return requestChips(requestedChipsAmount);
  }

  return (
    <div
      className="modal request-chips-modal"
      onClick={() => {
        closeModal(false);
      }}
    >
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('requestChips')}</h1>
        <h2 className="modal-subtitle">{t('requestChipsSubHeader')}</h2>
        <form className="clubs-form" onSubmit={submitForm}>
          <div className="validated-input-field">
            <NumberFormat
              className={`chip-amount ${formValidation.message ? 'error' : ''}`}
              placeholder={
                recommendedStartingChips
                  ? numberDisplay(
                      recommendedStartingChips / 100,
                      tableState.decimal_display,
                    )
                  : t('requestChipsInputPlaceholder')
              }
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={decimalScale}
              fixedDecimalScale="true"
              maxLength={requestedChipsAmountMaxLength}
              isAllowed={isFloatValuePositive}
              value={
                requestedChipsAmount === undefined
                  ? requestedChipsAmount
                  : requestedChipsAmount / 100
              }
              onChange={setNumberValue(setRequestedChipsAmount)}
            />
            {/* {formValidation.message ? (<span className='form-validation'>{formValidation.message}</span>) : (<></>)} */}
          </div>
          <div className="min-max-blinds">
            <p
              className={`min-blinds ${formValidation.message ? 'error' : ''}`}
            >
              {t('minAmountToRequest', minDisplay)}
            </p>
            <div className="flex-spacer" />
            <p
              className={`max-blinds ${formValidation.message ? 'error' : ''}`}
            >
              {t('maxAmountToRequest', maxDisplay)}
            </p>
          </div>
          <div
            className="default-submit-button request-chips"
            onClick={() => {
              requestChips(requestedChipsAmount);
            }}
          >
            {isFetchingData ? (
              <Spinner spinnerType="chase" styl={{ width: 20, height: 20 }} />
            ) : (
              <p>
                {t(
                  'requestChipAmountButton',
                  requestedChipsAmount
                    ? numberDisplay(requestedChipsAmount / 100)
                    : '',
                )}
              </p>
            )}
          </div>
        </form>
        <h2
          className="close-modal"
          onClick={() => {
            closeModal(false);
          }}
        >
          X
        </h2>
      </div>
    </div>
  );
}
