import React from 'react';
import Spinner from '../Spinner';
import { t } from '../../i18n';

export default function PlayerTableSessionDisconnectedModal() {
  return (
    <div className="modal player-table-session-disconnected-modal">
      <Spinner
        spinnerType="chase"
        style={{ marginTop: 20, marginBottom: 20, height: 30, width: 30 }}
      />
      <h1>{t('reconnectingToGame')}</h1>
      <h2>{t('havingTroubleReconnecting')}</h2>
    </div>
  );
}
