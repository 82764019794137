import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { PlayerContext } from '../contexts/PlayerContext';
import brandMark from '../assets/brand/brand-mark.svg';
// import heroTable from '../assets/landing/hero-table.png';
import sendLinkToTable from '../assets/landing/send-link-to-table.png';
import sendLinkToTableMobile from '../assets/landing/send-link-to-table-mobile.png';
import videoChatroom from '../assets/landing/video-chatroom.png';
import postGameSummary from '../assets/landing/post-game-summary.svg';
import handReplayer from '../assets/landing/hand-replayer.png';
import gameMode from '../assets/landing/game-mode.png';
import brandedTable from '../assets/landing/on-deck-w-players.png';
import taylor from '../assets/landing/taylor.png';
import drew from '../assets/landing/drew.png';
import tamara from '../assets/landing/tamara.png';
import { t } from '../i18n';

import { TABLE_STATES } from './HomeScreenHandStates';
import { PLAYER_SIGNIN_INIT, PLAYER_SIGNIN_OTP } from '../services/APIService';
import PokerTable from '../components/PokerTable';
import PlayerLoginModal from '../components/modals/PlayerLoginModal';
import VerifyAuthCodeModal from '../components/modals/VerifyAuthCodeModal';

const CANVAS_ID = Math.random().toString(36).slice(2).replace(/[0-9]/g, '');

export default function HomeScreen() {
  let history = useHistory();
  const { setPlayer } = React.useContext(PlayerContext);
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [playerLoginFormValidation, setPlayerLoginFormValidation] =
    React.useState({});
  const [playerAuthCodeFormValidation, setPlayerAuthCodeFormValidation] =
    React.useState({});

  const [activeTableState, setActiveTableState] = React.useState(
    TABLE_STATES[0],
  );
  const activeTableStateCursorRef = React.useRef(0);
  const [renderDimensions, setRenderDimensions] = React.useState({
    width: Math.max(window.innerWidth, 1000),
    height: Math.max(window.innerHeight, 625),
  });
  const [activeModalIdent, setActiveModalIdent] = React.useState('');
  const [signinPayload, setSigninPayload] = React.useState({});
  const [codeResending, setCodeResending] = React.useState(false);

  const resendCode = () => {
    setCodeResending(true);
    setTimeout(() => {
      setCodeResending(false);
    }, 3000);
    PLAYER_SIGNIN_INIT({ email: signinPayload.email });
  };

  // Tick the table state of the poker table animation
  React.useEffect(() => {
    setInterval(() => {
      if (activeTableStateCursorRef.current >= TABLE_STATES.length - 1) {
        activeTableStateCursorRef.current = 0;
      } else {
        activeTableStateCursorRef.current =
          activeTableStateCursorRef.current + 1;
      }
      setActiveTableState(TABLE_STATES[activeTableStateCursorRef.current]);
    }, 1000);
  }, []);

  // Use javascript to resize the canvas height to be a consistent relation to it's width
  React.useEffect(() => {
    function handleResize() {
      let canvas = document.querySelector('canvas.poker-table');
      if (window.innerWidth >= 651) {
        let canvasWidth = 1.0 * window.innerWidth - 0.06 * window.innerWidth;
        let canvasHeight = 0.5 * canvasWidth;
        setRenderDimensions({
          width: Math.max(canvasWidth + 400, 1000),
          height: Math.max(canvasHeight + 200, 625),
        });
        canvas.style.marginTop = `20px`;
        canvas.style.top = 0;
        canvas.style.left = 0;
      } else {
        let canvasWidth = window.innerWidth;
        let canvasHeight = window.innerWidth / 2;
        setRenderDimensions({
          width: Math.max(canvasWidth + 150, 1000),
          height: Math.max(canvasHeight + 75, 625),
        });
        canvas.style.marginTop = `25px`;
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [smallScreenNavExpanded, setSmallScreenNavExpanded] =
    React.useState(false);

  const renderLogo = () => {
    return (
      <div className="header-left">
        <Link to="/" className="logo">
          <img src={brandMark} alt="" className="brand-mark" />
          <p className="brand-name">
            <span className="clubs">Clubs</span> poker
          </p>
          <p className="now">now</p>
        </Link>
      </div>
    );
  };

  const renderNavItems = (screenSize) => {
    let className = 'nav-container-' + screenSize;

    let extraNavItemsHTML = (
      <div className="extra-nav-items-container">
        <a
          className="nav-item"
          href="https://blog.clubspokernow.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('headerBlog')}
        </a>
      </div>
    );

    let extraNavItems =
      screenSize === 'small-screen' ? extraNavItemsHTML : null;

    return (
      <div className={className}>
        <a
          href="#how-it-works"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('howItWorksLabel')}
        </a>
        <a
          href="#features"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('featuresLabel')}
        </a>
        <a
          href="#for-companies"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('forCompaniesLabel')}
        </a>
        <a
          href="#meet-the-team"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('meetTheTeamLabel')}
        </a>
        <a
          href="#contact-us"
          onClick={(e) => {
            setSmallScreenNavExpanded(false);
          }}
          className="nav-item"
        >
          {t('contactUsLabel')}
        </a>
        {extraNavItems}
      </div>
    );
  };

  const closeNavIfOpen = () => {
    if (smallScreenNavExpanded) {
      setSmallScreenNavExpanded(false);
    }
  };

  const renderTextSection = (heading, description1, arrowCta) => {
    return (
      <div>
        <div className="heading">{heading}</div>
        <div className="description-1">{description1}</div>
        {arrowCta ? renderArrowCta(arrowCta[0], arrowCta[1]) : null}
      </div>
    );
  };

  const renderHeroSignupCta = () => {
    return (
      <div className="hero-signup-cta">
        <Link to="/onboarding/host-signup">
          <div className="cta-large-button">
            <p className="large-button-text">{t('heroCta')}</p>
          </div>
        </Link>
        <div className="sign-in-container">
          <p
            className="sign-in-link"
            onClick={() => {
              setActiveModalIdent('login');
            }}
          >
            {t('heroSignIn')}
          </p>
        </div>
      </div>
    );
  };

  const renderArrowCta = (linkText, href) => {
    return (
      <div className="arrow-cta">
        <div className="arrow">&#10140;</div>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
      </div>
    );
  };

  let ActiveModal = <></>;
  if (activeModalIdent === 'login') {
    ActiveModal = (
      <PlayerLoginModal
        closeModal={() => {
          setPlayerLoginFormValidation({});
          setActiveModalIdent('');
        }}
        isFetchingData={isFetchingData}
        formValidation={playerLoginFormValidation}
        resetFormErrors={() => setPlayerLoginFormValidation({})}
        signinInit={(signinPayload) => {
          setIsFetchingData(true);
          setSigninPayload(signinPayload);
          PLAYER_SIGNIN_INIT(signinPayload).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              setActiveModalIdent('verify-auth-code');
            } else {
              setPlayerLoginFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  } else if (activeModalIdent === 'verify-auth-code') {
    ActiveModal = (
      <VerifyAuthCodeModal
        closeModal={() => {
          setActiveModalIdent('');
        }}
        signinPayload={signinPayload}
        codeResending={codeResending}
        isFetchingData={isFetchingData}
        resendCode={resendCode}
        formValidation={playerAuthCodeFormValidation}
        verifyAuthCode={(otp) => {
          setIsFetchingData(true);
          PLAYER_SIGNIN_OTP({ ...signinPayload, otp }).then((response) => {
            setIsFetchingData(false);
            if (response.data.status === 'ok') {
              Cookies.set('player', JSON.stringify(response.data.player), {
                expires: 3650,
              });
              setPlayer(response.data.player);
              history.push('/lobby');
            } else {
              setPlayerAuthCodeFormValidation(response.data.details);
            }
          });
        }}
      />
    );
  }

  return (
    <div
      className="landing"
      onClick={(e) => {
        closeNavIfOpen();
      }}
    >
      <Helmet>
        <title>{t('homeSiteTitle')}</title>
      </Helmet>
      <div
        className={`header-container expanded-${smallScreenNavExpanded}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="header">
          {renderLogo()}
          <div className="header-right">
            <a
              href="https://blog.clubspokernow.com"
              target="_blank"
              rel="noopener noreferrer"
              className="header-link"
            >
              {t('headerBlog')}
            </a>
            <p
              className="header-link"
              onClick={() => {
                setActiveModalIdent('login');
              }}
            >
              {t('headerLogin')}
            </p>
          </div>
          <div
            className="hamburger"
            onClick={(e) => {
              setSmallScreenNavExpanded(!smallScreenNavExpanded);
            }}
          >
            <i className="icofont-navigation-menu"></i>
          </div>
        </div>
        {renderNavItems('small-screen')}
      </div>
      {renderNavItems('large-screen')}
      <div className="hero-container">
        <div className="hero-text-container">
          <h1 className="hero-primary">{t('heroPrimary')}</h1>
          <p className="hero-secondary">{t('heroSecondary')}</p>
        </div>
        {/*renderHeroSignupCta() Until users can sign up*/}
        {renderHeroSignupCta()}
        <PokerTable
          canvasID={CANVAS_ID}
          tableState={activeTableState}
          playerUUID="000"
          joinTable={() => {}}
          processTableStateRender={() => {}}
          shotClock={30000}
          renderDimensions={renderDimensions}
          mouseEventsOffsets={{ x: 0, y: 0 }}
        />

        {/*<img src={heroTable} alt="" className="hero-table"/>*/}
      </div>
      <div className="testimonials-container">
        <div className="testimonial-left">
          <img src={tamara} alt="" />
          <p className="user-name">{t('testimonialLeftName')}</p>
          <p className="quotation">{t('testimonialLeftQuotation')}</p>
        </div>
        <div className="testimonial-right">
          <img src={drew} alt="" />
          <p className="user-name">{t('testimonialRightName')}</p>
          <p className="quotation">{t('testimonialRightQuotation')}</p>
        </div>
      </div>
      <div className="how-it-works-container">
        <div className="anchor" id="how-it-works"></div>
        <div className="send-link-to-table content-row">
          <div className="text-container">
            <div className="label">{t('howItWorksLabel')}</div>
            <div className="heading">{t('sendLinkToTableHeading')}</div>
            <div className="description-1">
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('howItWorksBullet1')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('howItWorksBullet2')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('howItWorksBullet3')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('howItWorksBullet4')}
              </div>
            </div>
            {renderArrowCta(
              t('clHowItWorksArrowCta'),
              'https://www.loom.com/share/c7478596ad9e4ff59638e4443a218ec6',
            )}
          </div>
          <div className="send-link-to-table-img-container">
            <img
              src={sendLinkToTable}
              alt=""
              className="img-complement desktop-show"
            />
            <img
              src={sendLinkToTableMobile}
              alt=""
              className="img-complement mobile-show"
            />
          </div>
        </div>
      </div>
      <div className="features-container">
        <div className="anchor" id="features"></div>
        <div className="video-chatroom content-row">
          <div className="text-container">
            <div className="label">{t('featuresLabel')}</div>
            {renderTextSection(
              t('videoChatroomHeading'),
              t('videoChatroomDescription'),
            )}
          </div>
          <img src={videoChatroom} alt="" className="img-complement" />
        </div>
        <div className="post-game-summary content-row">
          <img src={postGameSummary} alt="" className="img-complement" />
          <div className="text-container">
            {renderTextSection(
              t('postGameSummaryHeading'),
              t('postGameSummaryDescription'),
            )}
          </div>
        </div>
        <div className="game-mode content-row">
          <div className="text-container">
            {renderTextSection(t('gameModeHeading'), t('gameModeDescription'), [
              t('gameModeArrowCta'),
              'https://blog.clubspokernow.com/tournaments-vs-cash-games/',
            ])}
          </div>
          <img src={gameMode} alt="" className="img-complement" />
        </div>
        <div className="hand-replayer content-row">
          <img src={handReplayer} alt="" className="img-complement" />
          <div className="text-container">
            {renderTextSection(
              t('handReplayerHeading'),
              t('handReplayerDescription'),
            )}
          </div>
        </div>
        <div className="features-cta">
          <div className="cta-container">
            <div className="copy-container">
              <p>{t('featuresCtaLabel')}</p>
            </div>
            <div className="create-first-game-container">
              <Link to="/onboarding/host-signup">
                <div className="create-first-game-button">
                  {t('featuresCtaButton')}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="for-companies-container">
        <div className="anchor" id="for-companies"></div>
        <div className="for-companies content-row">
          <div className="text-container">
            <div className="label">{t('forCompaniesLabel')}</div>
            <div className="heading">{t('forCompaniesHeading')}</div>
            <div className="description-1">
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('forCompaniesBullet1')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('forCompaniesBullet2')}
              </div>
              <div className="bullet">
                <div>
                  <i className="icofont-heart-alt heart"></i>
                </div>
                {t('forCompaniesBullet3')}
              </div>
            </div>
            {renderArrowCta(
              t('forCompaniesArrowCta'),
              '/for-companies?ref=root',
            )}
          </div>
          <img src={brandedTable} alt="" className="img-complement" />
        </div>
      </div>
      <div className="meet-the-team-container">
        <div className="anchor" id="meet-the-team"></div>
        <div className="meet-the-team content-row">
          <div className="text-container">
            <div className="label">{t('meetTheTeamLabel')}</div>
            {renderTextSection(
              t('meetTheTeamHeading'),
              t('meetTheTeamDescription'),
            )}
          </div>
          <div className="img-complement">
            <div className="person">
              <img src={taylor} alt="" />
              <p className="name">{t('taylorName')}</p>
              <a
                href="https://twitter.com/taykcrane"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('taylorHandle')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-container">
        <div className="anchor" id="contact-us"></div>
        <div className="contact-us content-row">
          <div className="text-container">
            <div className="label">{t('contactUsLabel')}</div>
            <div>
              <div className="heading">{t('contactUsHeading')}</div>
              <a
                href="https://blog.clubspokernow.com/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
                className="description-1"
              >
                {t('contactUsDescription')}
              </a>
              <div className="description-2">{t('contactUsDescription2')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="final-message-container">
        <h3 className="final-message-text">{t('finalMessageText')}</h3>
        {renderHeroSignupCta()}
      </div>
      <div className="footer-container">
        <div className="left">
          {renderLogo()}
          <pre className="spacer"> - </pre>
          <div className="made">
            Made with <i className="icofont-heart-alt heart"></i> in NYC
          </div>
        </div>
        <div className="right">
          <div className="links">
            <a
              href="https://blog.clubspokernow.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('headerBlog')}
            </a>
            <pre className="spacer"> | </pre>
            <a
              href="https://www.facebook.com/playonclubs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('facebook')}
            </a>
            <pre className="spacer"> | </pre>
            <a
              href="https://twitter.com/playonclubs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('twitter')}
            </a>
            <pre className="spacer"> | </pre>
            <a href="/privacy.html" target="_blank">
              {t('privacy')}
            </a>
            <pre className="spacer"> | </pre>
            <a href="/ClubsPokerNow-TOU-v012624.pdf" target="_blank">
              {t('terms')}
            </a>
          </div>
        </div>
      </div>

      {ActiveModal}
    </div>
  );
}
