import React from 'react';
import caratUp from '../../assets/icons/carat-up.png';
import Spinner from '../Spinner';
import NumberFormat from 'react-number-format';
import Dropdown from 'react-dropdown';
import { isFloatValuePositive, setNumberValue } from '../displayHelpers';
import { t } from '../../i18n';

const shotClockOptions = [
  { value: '20000', label: '20 seconds' },
  { value: '30000', label: '30 seconds' },
  { value: '60000', label: '60 seconds' },
  { value: '1000000000', label: 'Infinite' },
];

const minBuyInOptions = [
  { value: '1', label: '1BB' },
  { value: '10', label: '10BB' },
  { value: '20', label: '20BB' },
  { value: '50', label: '50BB' },
];

const maxBuyInOptions = [
  { value: '50', label: '50BB' },
  { value: '100', label: '100BB' },
  { value: '200', label: '200BB' },
  { value: '1000', label: '1,000BB' },
];

const showdownRevealOptions = [
  { value: 'all-hands', label: 'All hands' },
  { value: 'winning-hand', label: 'Winning hand' },
];

export default function TableSessionCreateFormAdvanced({
  createTableSession,
  isFetchingData,
  toggleActiveForm,
  tableSessionCreateFormValidation,
}) {
  const [smallBlind, setSmallBlind] = React.useState();
  const [bigBlind, setBigBlind] = React.useState();
  const [minBuyIn, setMinBuyIn] = React.useState();
  const [maxBuyIn, setMaxBuyIn] = React.useState();
  const [startingChips, setStartingChips] = React.useState();
  const [shotClock, setShotClock] = React.useState();
  const [showdownReveal, setShowdownReveal] = React.useState();

  const submitForm = () => {
    createTableSession({
      setupMode: 'advanced',
      blinds: `${smallBlind}-${bigBlind}`,
      startingChips: startingChips,
      minBuyIn: minBuyIn !== undefined ? minBuyIn : 0,
      maxBuyIn: maxBuyIn !== undefined ? maxBuyIn : 0,
      shotClock: shotClock !== undefined ? shotClock : 0,
      showdownReveal: showdownReveal !== undefined ? showdownReveal : '',
    });
  };

  const handleToggleForm = (event) => {
    toggleActiveForm('quick');
  };

  return (
    <div className="clubs-form table-session-create-form-advanced">
      <div className="toggle-active-form" onClick={handleToggleForm}>
        <img
          src={caratUp}
          className="toggle-active-form-icon"
          alt="arrow pointing up"
        />
        <p className="toggle-active-form-text">
          {t('tableSessionCreateFormQuickSetup')}
        </p>
      </div>
      <h4 className="form-title">{t('tableSessionCreateFormAdvancedSetup')}</h4>
      <div className="form-section blinds-section">
        <p className="label">Customize the blinds</p>
        <div className="validated-input-fields">
          <NumberFormat
            className={`input-field small-blind ${
              tableSessionCreateFormValidation.blinds ? 'error' : ''
            }`}
            placeholder={t('tableSessionCreateFormAdvancedSmallBlind')}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale="true"
            maxLength={6}
            isAllowed={isFloatValuePositive}
            value={smallBlind === undefined ? smallBlind : smallBlind / 100}
            onChange={setNumberValue(setSmallBlind)}
          />
          <NumberFormat
            className={`input-field big-blind ${
              tableSessionCreateFormValidation.blinds ? 'error' : ''
            }`}
            placeholder={t('tableSessionCreateFormAdvancedBigBlind')}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale="true"
            maxLength={6}
            isAllowed={isFloatValuePositive}
            value={bigBlind === undefined ? bigBlind : bigBlind / 100}
            onChange={setNumberValue(setBigBlind)}
          />
          {tableSessionCreateFormValidation.blinds ? (
            <span className="form-validation">
              {tableSessionCreateFormValidation.blinds[0]}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="form-section recommended-starting-stack-section">
        <p className="label">Recommend a starting stack</p>
        <div className="validated-input-fields">
          <NumberFormat
            className={`input-field starting-chips ${
              tableSessionCreateFormValidation.recommended_starting_chips
                ? 'error'
                : ''
            }`}
            placeholder={t('tableSessionCreateFormNumberOfChips')}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale="true"
            maxLength={9}
            isAllowed={isFloatValuePositive}
            value={
              startingChips === undefined ? startingChips : startingChips / 100
            }
            onChange={setNumberValue(setStartingChips)}
          />
          {tableSessionCreateFormValidation.recommended_starting_chips ? (
            <span className="form-validation">
              {tableSessionCreateFormValidation.recommended_starting_chips[0]}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="form-section buy-in-section">
        <p className="label">Set the buy-in limit (big blinds)</p>
        <div className="validated-input-fields">
          <Dropdown
            className={`select-field min-buy-in ${
              tableSessionCreateFormValidation.min_buy_in ? 'error' : ''
            }`}
            value={minBuyIn}
            options={minBuyInOptions}
            onChange={({ value }) => setMinBuyIn(value)}
            placeholder={t('tableSessionCreateFormAdvancedMinBuyIn')}
          />
          <Dropdown
            className={`select-field max-buy-in ${
              tableSessionCreateFormValidation.max_buy_in ? 'error' : ''
            }`}
            value={maxBuyIn}
            options={maxBuyInOptions}
            onChange={({ value }) => setMaxBuyIn(value)}
            placeholder={t('tableSessionCreateFormAdvancedMaxBuyIn')}
          />
          {tableSessionCreateFormValidation.buy_in ? (
            <span className="form-validation">
              {tableSessionCreateFormValidation.buy_in[0]}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="form-section config-section">
        <p className="label">Change other settings</p>
        <div className="validated-input-fields">
          <Dropdown
            className={`select-field shot-clock ${
              tableSessionCreateFormValidation.shot_clock ? 'error' : ''
            }`}
            value={shotClock}
            options={shotClockOptions}
            onChange={({ value }) => setShotClock(value)}
            placeholder={t('hostSettingsDecisionClockPlaceholder')}
          />
          <Dropdown
            className={`select-field showdown-reveal ${
              tableSessionCreateFormValidation.showdown_reveal ? 'error' : ''
            }`}
            value={showdownReveal}
            options={showdownRevealOptions}
            onChange={({ value }) => setShowdownReveal(value)}
            placeholder={t('tableSessionCreateFormAdvancedShowdownReveal')}
          />
        </div>
      </div>
      <div className="submit-button" onClick={submitForm}>
        {isFetchingData ? (
          <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
        ) : (
          <p className="submit-button-text">
            {t('tableSessionCreateFormCreateGame')}
          </p>
        )}
      </div>
    </div>
  );
}
