import React from 'react';
import { t } from '../../i18n';
import allSounds from '../../assets/icons/all-sounds.png';
import allSoundsSelected from '../../assets/icons/all-sounds-selected.png';
import importantSounds from '../../assets/icons/important-sounds.png';
import importantSoundsSelected from '../../assets/icons/important-sounds-selected.png';
import noSounds from '../../assets/icons/no-sounds.png';
import noSoundsSelected from '../../assets/icons/no-sounds-selected.png';
import AnalyticsService from '../../services/AnalyticsService';

export default function SoundsModal({
  player,
  closeModal,
  soundEffectFrequency,
  setSoundEffectFrequency,
  soundEffectVolume,
  setSoundEffectVolume,
}) {
  return (
    <div
      className="modal sounds-modal"
      onClick={() => {
        closeModal(false);
      }}
    >
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1 className="modal-title">{t('soundsModalTitle')}</h1>
        <h2 className="modal-subtitle">{t('soundsModalSubtitle')}</h2>
        <div className="sound-frequency-selection">
          {soundEffectFrequency === 'no-sounds' ? (
            <p className="sound-label-text">
              {t('soundFrequencyLabelTextNone')}
            </p>
          ) : (
            <></>
          )}
          {soundEffectFrequency === 'important-sounds' ? (
            <p className="sound-label-text">
              {t('soundFrequencyLabelTextImportant')}
            </p>
          ) : (
            <></>
          )}
          {soundEffectFrequency === 'all-sounds' ? (
            <p className="sound-label-text">
              {t('soundFrequencyLabelTextAll')}
            </p>
          ) : (
            <></>
          )}
          <div className="sound-selections">
            {soundEffectFrequency === 'no-sounds' ? (
              <img
                src={noSoundsSelected}
                className="sound-icon no-sounds-icon"
                alt="no sounds"
              />
            ) : (
              <img
                src={noSounds}
                className="sound-icon no-sounds-icon"
                alt="turn on no sounds"
                onClick={() => {
                  setSoundEffectFrequency('no-sounds');
                  AnalyticsService.changesSoundMode(player, 'no-sounds');
                }}
              />
            )}
            {soundEffectFrequency === 'important-sounds' ? (
              <img
                src={importantSoundsSelected}
                className="sound-icon important-sounds-icon"
                alt="important sounds"
              />
            ) : (
              <img
                src={importantSounds}
                className="sound-icon important-sounds-icon"
                alt="turn on important sounds"
                onClick={() => {
                  setSoundEffectFrequency('important-sounds');
                  AnalyticsService.changesSoundMode(player, 'important-sounds');
                }}
              />
            )}
            {soundEffectFrequency === 'all-sounds' ? (
              <img
                src={allSoundsSelected}
                className="sound-icon all-sounds-icon"
                alt="all sounds"
              />
            ) : (
              <img
                src={allSounds}
                className="sound-icon all-sounds-icon"
                alt="turn on all sounds"
                onClick={() => {
                  setSoundEffectFrequency('all-sounds');
                  AnalyticsService.changesSoundMode(player, 'all-sounds');
                }}
              />
            )}
          </div>
        </div>
        <div className="sound-volume-selection">
          <p className="sound-label-text">
            {t('soundVolumeLabelText', soundEffectVolume * 100)}
          </p>
          <input
            type="range"
            className="volume-slider"
            min="0.0"
            max="1.0"
            step="0.1"
            value={soundEffectVolume}
            onMouseUp={(e) => {
              AnalyticsService.changesSoundVolume(player, e.target.value);
            }}
            onChange={(e) => {
              setSoundEffectVolume(e.target.value);
            }}
          />
        </div>
        <div
          className="submit-button default-submit-button"
          onClick={() => {
            closeModal(false);
          }}
        >
          <p>{t('imDoneText')}</p>
        </div>
        <h2
          className="close-modal"
          onClick={() => {
            closeModal(false);
          }}
        >
          X
        </h2>
      </div>
    </div>
  );
}
