import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { t } from '../i18n';
import Cookies from 'js-cookie';
import { PlayerContext } from '../contexts/PlayerContext';
import AnalyticsService from '../services/AnalyticsService';

const BLOG_URL = 'https://blog.clubspokernow.com/';

export default function OnboardingSignupSuccessfulScreen() {
  const playerCookie = Cookies.get('player');
  const [displayingAddToWaitlistCTA, setDisplayingAddToWaitlistCTA] =
    useState(true);
  const { player } = React.useContext(PlayerContext);

  React.useEffect(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'conversion', {
        send_to: 'AW-10817934796/RXC0CKaE14gDEMyrsqYo',
      });
    }
  }, []);

  if (!playerCookie) {
    return <Redirect to="/onboarding/host-signup" />;
  }

  const addNameToWaitlist = () => {
    if (!displayingAddToWaitlistCTA) {
      return;
    }
    AnalyticsService.addNameToMobileWaitlist(player);
    setDisplayingAddToWaitlistCTA(false);
    setTimeout(() => setDisplayingAddToWaitlistCTA(true), 2000);
  };

  return (
    <div className="screen onboarding-host-signup-successful-screen">
      <Helmet>
        <title>Clubs Poker</title>
      </Helmet>

      <div className="collapsible-sidebar-content-container">
        <div className="content">
          <div className="poker-table" />
          <h1 className="primary-header">{t('onboardingSignupSuccessful')}</h1>
          <p className="main-message">
            <strong>{t('onboardingYouNeedAComputer')}</strong>{' '}
            <span>{t('onboardingInTheMeantime')}</span>
          </p>
          <a
            href="sms:?&body=https://clubspokernow.com?ref=smsfriend"
            className="submit-button"
          >
            <p>{t('sendToFriend')}</p>
          </a>
          <div className="additional-ctas">
            <div className="additional-cta">
              <div className="secondary-message">
                <p>{t('wantMobileApp')}</p>
              </div>
              <button
                className="secondary-message link-styled"
                onClick={() => addNameToWaitlist()}
              >
                <p>
                  {displayingAddToWaitlistCTA
                    ? t('addNameToWaitlist')
                    : t('nameAddedToWaitlist')}
                </p>
              </button>
            </div>
            <div className="additional-cta">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={BLOG_URL}
                className="secondary-message blog-link"
                onClick={() => AnalyticsService.opensBlog(player, BLOG_URL)}
              >
                <p>{t('readOurBlog')}</p>
              </a>
            </div>
            <div className="additional-cta">
              <Link to="/lobby" className="secondary-message">
                <p>{t('takeMeToLobbyAnyway')}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
