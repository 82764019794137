import React from 'react';
import { t } from '../i18n';
import notificationIcon from '../assets/icons/notification-icon.png';
import {
  JoinTableNotification,
  AddChipsNotification,
  JoinTournamentNotification,
  RebuyTournamentNotification,
} from './notification-center/TableRequestNotifications';
import {
  AllLeavingPlayersNotification,
  SingleLeavingPlayerNotification,
} from './notification-center/LeavingPlayersNotifications';

export default function NotificationCenter({
  tableState,
  player,
  playerIsHost,
  tournament,
  rejectRequestJoinTable,
  approveRequestJoinTable,
  rejectRequestAddChips,
  approveRequestAddChips,
  rejectRequestJoinTournament,
  approveRequestJoinTournament,
  rejectRequestRebuyTournament,
  approveRequestRebuyTournament,
}) {
  const findPlayerByTableApproval = (approval) => (p) =>
    p && p.player_uuid === approval.approval_data.player_uuid;

  function renderTableRequestNotification(tableRequest, index) {
    if (tableRequest.request_type === 'join-table') {
      return (
        <JoinTableNotification
          tableState={tableState}
          tableRequest={tableRequest}
          approveRequestJoinTable={approveRequestJoinTable}
          rejectRequestJoinTable={rejectRequestJoinTable}
          index={index}
          key={`notification-table-request-${index}`}
        />
      );
    }
    if (tableRequest.request_type === 'add-chips') {
      return (
        <AddChipsNotification
          tableState={tableState}
          tableRequest={tableRequest}
          approveRequestAddChips={approveRequestAddChips}
          rejectRequestAddChips={rejectRequestAddChips}
          index={index}
          key={`notification-table-request-${index}`}
        />
      );
    }
    return null;
  }

  function renderTournamentRequestNotification(tournamentRequest, index) {
    if (tournamentRequest.request_type === 'join-tournament') {
      return (
        <JoinTournamentNotification
          tournamentRequest={tournamentRequest}
          approveRequestJoinTournament={approveRequestJoinTournament}
          rejectRequestJoinTournament={rejectRequestJoinTournament}
          index={index}
          key={`notification-tournament-request-${index}`}
        />
      );
    }
    if (tournamentRequest.request_type === 'rebuy-tournament') {
      return (
        <RebuyTournamentNotification
          tournamentRequest={tournamentRequest}
          approveRequestRebuyTournament={approveRequestRebuyTournament}
          rejectRequestRebuyTournament={rejectRequestRebuyTournament}
          index={index}
          key={`notification-tournament-request-${index}`}
        />
      );
    }
    return null;
  }

  function renderTableApprovals() {
    let subject = '';
    const chipsUpdateApprovals = tableState.table_approvals.filter(
      (approval) =>
        ['host-add-chips', 'grant-add-chips', 'pocket-chips'].includes(
          approval.approval_type,
        ) &&
        (playerIsHost || approval.approval_data.player_uuid === player.uuid),
    );
    if (chipsUpdateApprovals.length === 0) {
      return null;
    } else if (chipsUpdateApprovals.length === 1) {
      const [approval] = chipsUpdateApprovals;
      const tableStatePlayer = tableState.players.find(
        findPlayerByTableApproval(approval),
      );
      if (!tableStatePlayer) {
        return null;
      }
      subject = tableStatePlayer.display;
    } else {
      subject = `${chipsUpdateApprovals.length} players`;
    }

    const copyKey = playerIsHost
      ? 'hostTableSessionChipUpdateNotification'
      : 'tableSessionChipUpdateNotification';
    const copyParams = [copyKey];
    if (playerIsHost) {
      copyParams.push(subject);
    }
    return (
      <div className="notification">
        <p className="notification-text">
          <img src={notificationIcon} alt="notification-icon" />
          {t(...copyParams)}
        </p>
      </div>
    );
  }
  if (!tableState) {
    return null;
  }

  const tablePlayer = tableState.players.find(
    (p) => p && p.player_uuid === player.uuid,
  );

  if (playerIsHost) {
    const tableRequests = (tableState && tableState.table_requests) || [];
    const tournamentRequests =
      (tournament && tournament.tournament_requests) || [];
    return (
      <>
        <div className="notification-center">
          {tableRequests.map((tableRequest, index) => {
            return renderTableRequestNotification(tableRequest, index);
          })}
          {tournamentRequests.map((tournamentRequest, index) => {
            return renderTournamentRequestNotification(
              tournamentRequest,
              index,
            );
          })}
          {renderTableApprovals()}
          <AllLeavingPlayersNotification tableState={tableState} />
        </div>
      </>
    );
  } else {
    return (
      <div className="notification-center">
        {renderTableApprovals()}
        <SingleLeavingPlayerNotification tablePlayer={tablePlayer} />
      </div>
    );
  }
}
