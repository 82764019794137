import React, { useContext, useEffect, useState } from 'react';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import { t } from '../i18n';
import logo from '../assets/brand/logo-unicolor.png';
import chevronRight from '../assets/icons/chevron-right.png';
import Spinner from './Spinner';
import SidebarFooter from './SidebarFooter';
import AnalyticsService from '../services/AnalyticsService';

export default function GuestSignupFormSidebar({
  player,
  startClosed,
  isCollapsible = true,
  isExpandable = true,
  signupGuest,
  isFetchingData,
  guestSignupFormValidation,
  resetFormValidation = () => {},
}) {
  const eventEmitter = useContext(EventEmitterContext);
  const [isClosed, setIsClosed] = useState(startClosed);
  const [userName, setUserName] = useState(player.display);
  const [email, setEmail] = useState('');

  var classString = 'guest-signup-form-sidebar';
  if (isClosed) {
    classString += ' is-closed';
  }

  const toggleSidebar = () => {
    if (isClosed) {
      setIsClosed(false);
      AnalyticsService.opensSidebarEvent(player);
    } else {
      setIsClosed(true);
    }
  };

  useEffect(() => {
    const closeSidebar = () => {
      if (!isClosed && isCollapsible) {
        setIsClosed(true);
      }
    };
    eventEmitter.on('close-sidebar-if-open', closeSidebar);
    return () => eventEmitter.off('close-sidebar-if-open', closeSidebar);
  });

  useEffect(() => {
    const openSidebar = () => {
      if (isClosed && isExpandable) {
        setIsClosed(false);
      }
    };
    eventEmitter.on('open-sidebar-if-closed', openSidebar);
    return () => eventEmitter.off('open-sidebar-if-closed', openSidebar);
  });

  useEffect(() => {
    if (!isClosed) {
      resetFormValidation();
    }
  }, [isClosed]);

  function submitForm(e) {
    e.preventDefault();
    if (userName && email) {
      signupGuest({
        guestUUID: player.uuid,
        userName,
        email,
      });
    }
  }

  const SidebarContent = isClosed ? (
    <>
      <img
        className="brand logo"
        src={logo}
        alt="clubs-poker"
        onClick={() => {
          window.open('/');
        }}
      />
      {isExpandable ? (
        <div className="expand" onClick={toggleSidebar}>
          <img src={chevronRight} alt="expand-sidebar" />
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <form onSubmit={submitForm} className="sidebar-content">
      <div
        className="brand"
        onClick={() => {
          window.open('/');
        }}
      >
        <img className="logo" src={logo} alt="clubs-poker" />
        <p className="clubs">
          <span className="bold">Clubs</span> poker
        </p>
        <p className="now">now</p>
      </div>
      <div className="expand">
        <img src={chevronRight} alt="expand-sidebar" />
      </div>
      <div className="guest-signup-form-content-wrapper">
        <p className="onboarding-text">{t('signUpToAccessPostSummary')}</p>
        <p className="onboarding-text">{t('statsToKeepTrack')}</p>
        <p className="onboarding-text">{t('andHostYourOwnGame')}</p>
        <a className="learn-more-text" href="/" target="_blank">
          {t('guestSignupLearnMoreText')}
        </a>
        <div className="clubs-form">
          <input
            type="text"
            required
            placeholder={t('nickname')}
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
          <div className="validated-input-field">
            <input
              type="email"
              required
              placeholder={t('email')}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {guestSignupFormValidation.email ? (
              <span className="form-validation">
                {guestSignupFormValidation.email[0]}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="form-actions">
          <button className="signup-button" type="submit">
            {isFetchingData ? (
              <Spinner spinnerType="chase" style={{ width: 20, height: 20 }} />
            ) : (
              <p>{t('signUp')}</p>
            )}
          </button>
        </div>
      </div>
    </form>
  );

  return (
    <div className={classString}>
      {SidebarContent}
      {isClosed ? (
        <></>
      ) : (
        <>
          <div className="flex-spacer" />
          <SidebarFooter />
        </>
      )}
    </div>
  );
}
