import React from 'react';
import { Helmet } from 'react-helmet';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import { PlayerContext } from '../contexts/PlayerContext';
import { useHistory } from 'react-router-dom';
import { t } from '../i18n';
import table from '../assets/table-selection.png';
import AnalyticsService from '../services/AnalyticsService';
import PlayerCollapsibleSidebar from '../components/PlayerCollapsibleSidebar';
import MobileAccessibilityBanner from '../components/MobileAccessibilityBanner';
import SiteNoticeBanner from '../components/SiteNoticeBanner';
import Dropdown from 'react-dropdown';
import GameVariants from '../poker/GameVariants';

const BLOG_URL = 'https://blog.clubspokernow.com/tournaments-vs-cash-games/';

export default function NewTableSelectionScreen() {
  let history = useHistory();
  const eventEmitter = React.useContext(EventEmitterContext);
  const { player } = React.useContext(PlayerContext);
  const [gameVariant, setGameVariant] = React.useState(GameVariants[0]);

  const gameVariantRef = React.useRef(gameVariant);

  React.useEffect(() => {
    gameVariantRef.current = gameVariant;
  }, [gameVariant]);

  React.useEffect(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'conversion', {
        send_to: 'AW-10817934796/RXC0CKaE14gDEMyrsqYo',
      });
    }
  }, []);

  const selectGameVariant = (data) => {
    setGameVariant(data);
  };

  const handleSetupLater = () => {
    AnalyticsService.setupGameLaterEvent(player, 'new-table-selection');
    history.push('/lobby');
  };

  const submitForm = (route) => {
    history.push(`${route}?gv=${gameVariantRef.current.value}`);
  };

  return (
    <div className="screen new-table-selection-screen">
      <Helmet>
        <title>Clubs Poker</title>
      </Helmet>
      <MobileAccessibilityBanner />
      <SiteNoticeBanner />

      <div className="collapsible-sidebar-content-container">
        <PlayerCollapsibleSidebar startClosed={true} />
        <div
          className="content"
          onClick={() => {
            eventEmitter.emit('close-sidebar-if-open', {});
          }}
        >
          <div className="poker-table">
            <img src={table} alt="poker-table" className="poker-table-image" />
            <p className="set-up-later" onClick={handleSetupLater}>
              {t('tournamentCreateScreenSetUpLater')}
            </p>
          </div>
          <h1 className="primary-header">
            {t('newTableSelectionScreenPrimaryHeader')}
          </h1>
          <h2 className="secondary-header">
            {t('newTableSelectionScreenSecondaryHeader')}
          </h2>
          <div className="clubs-form">
            <Dropdown
              required
              className="game-variant select-field"
              value={gameVariant}
              options={GameVariants}
              onChange={selectGameVariant}
              placeholder="No limit hold'em"
            />
            <div className="table-variants">
              <div className="table-variant table-variant-ring-game">
                <h4>{t('newTableSelectionScreenTableVariantRingGame')}</h4>
                <p>
                  {t('newTableSelectionScreenTableVariantRingGameDescription1')}
                </p>
                <p>
                  {t('newTableSelectionScreenTableVariantRingGameDescription2')}
                </p>
                <p>
                  {t('newTableSelectionScreenTableVariantRingGameDescription3')}
                </p>
                <div
                  className="submit-button"
                  onClick={() => {
                    submitForm('/table-session-create');
                  }}
                >
                  <p className="submit-button-text">
                    {t('newTableSelectionScreenTableVariantRingGameButtonText')}
                  </p>
                </div>
              </div>
              <div className="table-variant table-variant-single-table-tournament">
                <h4>
                  {t(
                    'newTableSelectionScreenTableVariantSingleTableTournament',
                  )}
                </h4>
                <p>
                  {t(
                    'newTableSelectionScreenTableVariantSingleTableTournamentDescription1',
                  )}
                </p>
                <p>
                  {t(
                    'newTableSelectionScreenTableVariantSingleTableTournamentDescription2',
                  )}
                </p>
                <p>
                  {t(
                    'newTableSelectionScreenTableVariantSingleTableTournamentDescription3',
                  )}
                </p>
                <div
                  className="submit-button"
                  onClick={() => {
                    submitForm('/tournament-create');
                  }}
                >
                  <p className="submit-button-text">
                    {t(
                      'newTableSelectionScreenTableVariantSingleTableTournamentButtonText',
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="additional-links">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={BLOG_URL}
                className="additional-link"
                onClick={() => AnalyticsService.opensBlog(player, BLOG_URL)}
              >
                <p>
                  {t('newTableSelectionScreenTableVariantNotSureWhichToChoose')}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
