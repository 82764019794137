import React from 'react';
import { PlayerContext } from '../contexts/PlayerContext';
import { PlayerTableSessionsContext } from '../contexts/PlayerTableSessionsContext';
import { EventEmitterContext } from '../contexts/EventEmitterContext';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import logo from '../assets/brand/logo-unicolor.png';
import chevronRight from '../assets/icons/chevron-right.png';
import { numberDisplay } from '../poker/helpers';
import CARDS from '../poker/cards';
import SidebarFooter from './SidebarFooter';
import AnalyticsService from '../services/AnalyticsService';
import { t } from '../i18n';

export default function PlayerCollapsibleSidebar({
  startClosed = true,
  isCollapsible = true,
  isExpandable = true,
  activeTableSessionUUID = '',
}) {
  let history = useHistory();
  const eventEmitter = React.useContext(EventEmitterContext);
  const { player } = React.useContext(PlayerContext);
  const { playerTableSessions } = React.useContext(PlayerTableSessionsContext);

  const [isClosed, setIsClosed] = React.useState(startClosed);

  var classString = 'collapsible-sidebar';
  if (isClosed) {
    classString += ' is-closed';
  }

  const toggleSidebar = () => {
    if (isClosed && isExpandable) {
      setIsClosed(false);
      AnalyticsService.opensSidebarEvent(player);
    } else {
      setIsClosed(true);
    }
  };

  React.useEffect(() => {
    const closeSidebar = () => {
      if (!isClosed && isCollapsible) {
        setIsClosed(true);
      }
    };
    eventEmitter.on('close-sidebar-if-open', closeSidebar);
    return () => eventEmitter.off('close-sidebar-if-open', closeSidebar);
  });

  const SidebarContent = isClosed ? (
    <>
      <img
        className="brand logo"
        src={logo}
        alt="clubs-poker"
        onClick={() => {
          history.push('/lobby');
        }}
      />
      {isExpandable ? (
        <div className="expand" onClick={toggleSidebar}>
          <img src={chevronRight} alt="expand-sidebar" />
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <>
      <div
        className="brand"
        onClick={() => {
          history.push('/lobby');
        }}
      >
        <img className="logo" src={logo} alt="clubs-poker" />
        <p className="clubs">
          <span className="bold">Clubs</span> poker
        </p>
        <p className="now">now</p>
      </div>
      <div className="expand" onClick={toggleSidebar}>
        <img src={chevronRight} alt="expand-sidebar" />
      </div>
      <div className="nav-items">
        <div
          className={`nav-item home ${
            history.location.pathname === '/lobby' ||
            history.location.pathname.endsWith('/summary')
              ? 'active'
              : ''
          }`}
          onClick={() => {
            history.push('/lobby');
          }}
        >
          <p>{t('home')}</p>
        </div>
        {playerTableSessions.map((playerTableSession, index) => {
          if (!playerTableSession.state.active_hand_state) {
            return <></>;
          }
          let pts = _.find(
            _.compact(playerTableSession.state.active_hand_state.players),
            (p) => player && p.player_uuid === player.uuid,
          );
          if (playerTableSession.tournament_id) {
            return (
              <div
                className={`nav-item poker-table ${
                  activeTableSessionUUID === playerTableSession.uuid
                    ? 'active'
                    : ''
                }`}
                key={`poker-table-${index}`}
                onClick={() => {
                  AnalyticsService.viewsActivePokerTable(
                    player,
                    'tournament',
                    playerTableSession,
                  );
                  history.push(
                    `/tournaments/${playerTableSession.tournament_uuid}/table-sessions/${playerTableSession.uuid}`,
                  );
                }}
              >
                {pts && !_.isEmpty(pts.hand) ? (
                  <>
                    <img
                      className="card card-one"
                      src={`${
                        _.find(
                          CARDS,
                          (card) =>
                            card.suit === pts.hand[0].suit &&
                            card.rank === pts.hand[0].rank,
                        ).img
                      }`}
                      alt="card-one"
                    />
                    <img
                      className="card card-two"
                      src={`${
                        _.find(
                          CARDS,
                          (card) =>
                            card.suit === pts.hand[1].suit &&
                            card.rank === pts.hand[1].rank,
                        ).img
                      }`}
                      alt="card-two"
                    />
                  </>
                ) : (
                  <></>
                )}
                <p>{`Poker Table (Tournament)`}</p>
              </div>
            );
          } else {
            return (
              <div
                className={`nav-item poker-table ${
                  activeTableSessionUUID === playerTableSession.uuid
                    ? 'active'
                    : ''
                }`}
                key={`poker-table-${index}`}
                onClick={() => {
                  AnalyticsService.viewsActivePokerTable(
                    player,
                    'table-session',
                    playerTableSession,
                  );
                  history.push(`/table-sessions/${playerTableSession.uuid}`);
                }}
              >
                {pts && !_.isEmpty(pts.hand) ? (
                  <>
                    <img
                      className="card card-one"
                      src={`${
                        _.find(
                          CARDS,
                          (card) =>
                            card.suit === pts.hand[0].suit &&
                            card.rank === pts.hand[0].rank,
                        ).img
                      }`}
                      alt="card-one"
                    />
                    <img
                      className="card card-two"
                      src={`${
                        _.find(
                          CARDS,
                          (card) =>
                            card.suit === pts.hand[1].suit &&
                            card.rank === pts.hand[1].rank,
                        ).img
                      }`}
                      alt="card-two"
                    />
                  </>
                ) : (
                  <></>
                )}
                <p>
                  {t(
                    'pokerTableWithBlindsDisplay',
                    numberDisplay(
                      playerTableSession.state.blinds[0] / 100,
                      playerTableSession.state.decimal_display,
                    ),
                    numberDisplay(
                      playerTableSession.state.blinds[1] / 100,
                      playerTableSession.state.decimal_display,
                    ),
                  )}
                </p>
              </div>
            );
          }
        })}
        <div
          className={`nav-item account ${
            history.location.pathname === '/account' ? 'active' : ''
          }`}
          onClick={() => {
            history.push('/account');
          }}
        >
          <p>{t('myAccount')}</p>
        </div>
        <div className="flex-spacer" />
        <SidebarFooter player={player} />
      </div>
    </>
  );

  return <div className={classString}>{SidebarContent}</div>;
}
